import styles from './CustomInput.module.scss'

export interface CustomInputProps{
    name: string,
    value?: string,
    type: string,
    label: string,
    className?: string,
    onChange?: (e:any) => void,
    error?: boolean,
}

const CustomInput = (props: CustomInputProps) => {

    const {name, value, type, label, className, error} = props;

    const handleChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if(props.onChange){
            props.onChange({
                name : name,
                value: value
            })
        }
    }

    return (
        <div className={styles.customInput}>
            <label className={styles.label} htmlFor="">{label}: </label>
            <br/>
            <input onChange={handleChange} className={styles.input} type={type} value={value ? value : ""} name={name} />
            {
                error && <p className={styles.error}>Mục này không được để trống</p>
            }
        </div>
    )
}

export default CustomInput