import axiosDonation from './axiosDonation';

const Address = {
  async getProvinces () {
    return axiosDonation.post('/serene/address/get-provinces', {})
  },
  async getDistricts (province_id: string) {
    return axiosDonation.post('/serene/address/get-districts', {
      provinceId: province_id
    })
  },
  async getWards (district_id: string) {
    return axiosDonation.post('/serene/address/get-wards', {
      districtId: district_id
    })
  },
  // async getAdresses () {
  //   return axiosAuth.post('/user/get-user-addresses')
  // },
  // async getAdressType () {
  //   return axiosAuth.post('/public/master-data/get-common-code', {
  //     group_id: "1003"
  //   })
  // },
  // async getDistrictById (district_id: string) {
  //   return axiosDonation.post('/serene/address/get-district-by-id',{
  //     id: district_id
  //   })
  // }
};

export default Address;