import axiosDonation from "./axiosDonation";

const shippingFeeApi = {
  addShippingFee: (data: any) => {
    const url =
      "/serene/partner-shipping-fee-config/create-partner-shipping-fee-config";
    return axiosDonation.post(url, data);
  },

  deleteShippingFee: (data: any) => {
    const url =
      "/serene/partner-shipping-fee-config/delete-partner-shipping-fee-config";
    return axiosDonation.post(url, data);
  },

  importShippingFee: (data: any) => {
    const url =
      "/serene/partner-shipping-fee-config/import-partner-shipping-fee-config";
    return axiosDonation.post(url, data);
  },

  getShippingFee: (data: any) => {
    const url =
      "/serene/partner-shipping-fee-config/search-partner-shipping-fee-config";
    return axiosDonation.post(url, data);
  },

  updateShippingFee: (data: any) => {
    const url =
      "/serene/partner-shipping-fee-config/update-partner-shipping-fee-config";
    return axiosDonation.post(url, data);
  },

  // getProvince: (params: any) => {
  //   const url = "/public/master-data/get-address-province";
  //   return axiosAuth.post(url, params);
  // },
};
export default shippingFeeApi;
