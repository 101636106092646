import styles from "./RecycleItem.module.scss";
import { parseAddress } from "../../../../utils/format";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import recycling from "../../../../apis/recycling";
import { useSnackbar } from "notistack";
import { serviceType, typeRecycle } from "../../../../constants/constant";

type RecycleItemProps = {
  item: any;
  handleReload: () => void;
  setDataCancel: any;
  onOpenModalCancel: any;
};

const RecycleItem = (props: RecycleItemProps) => {
  const { item, handleReload, setDataCancel, onOpenModalCancel} = props;
  const { enqueueSnackbar } = useSnackbar();

  
  const handleConfirmRecycle = async (id: string) => {
    if (id) {
      const res = await recycling.confirm(id);
      if (res.data.code === 0) {
        enqueueSnackbar("Xác nhận thành công", { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    }
  };

  const handleCancel = (id:any) => {
    onOpenModalCancel();
    setDataCancel(id);
  }

  const handleProcessRecycle = async (id: string) => {
    if (id) {
      const res = await recycling.process(id);
      if (res.data.code === 0) {
        enqueueSnackbar("Cập nhật thành công", { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    }
  };

  const handleFinishRecycle = async (id: string) => {
    if (id) {
      const res = await recycling.finish(id);
      if (res.data.code === 0) {
        enqueueSnackbar("Hoàn thành đơn", { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    }
  };

  
  return (
    <>
      <tr className={styles.item}>
        <td>{item.code}</td>
        <td>
          Khách hàng: {item.customerName}
          <br />
          Sđt: {item.customerPhone}
          <br />
        </td>
        <td>{parseAddress(item.customerAddress)}</td>
        <td>{item.materialType}</td>
        <td>
          {item.serviceType &&
            item.serviceType.length > 0 &&
            item.serviceType.map((item: any,key:any) => {
              return <p key={key}>{serviceType(item)}</p>;
            })}
        </td>
        <td>{typeRecycle(item.packageType)}</td>
        <td>{dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}</td>
        <td>{dayjs(item.updateAt).format("DD/MM/YYYY HH:mm")}</td>
        <td className={styles.action}>
          {item.status === "new" && (
            <Button
              variant="contained"
              color="success"
              onClick={() => handleConfirmRecycle(item.id)}
            >
              Xác nhận
            </Button>
          )}
          {item.status === "confirm" && (
            <Button
              variant="contained"
              color="success"
              onClick={() => handleProcessRecycle(item.id)}
            >
              Cập nhật
            </Button>
          )}
          {item.status === "processing" && (
            <Button
              variant="contained"
              color="success"
              onClick={() => handleFinishRecycle(item.id)}
            >
              Hoàn thành
            </Button>
          )}
          {item.status !== "cancel" && item.status !== "done" &&
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleCancel(item.id)}
            >
              Hủy
            </Button>
          }
        </td>
      </tr>
    </>
  );
};

export default RecycleItem;
