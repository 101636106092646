import React, { useState } from "react";
import clsx from "clsx";

import styles from "./AddShippingFee.module.scss";

import shippingFeeApi from "../../../apis/shippingFeeApi";
import ModalShippingFee from "../Modal/Modal";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
export interface AddShippingFeeProps {
  className?: string;
  dataSubmit: any;
  setDataSubmit: any;
}

const AddShippingFee = (props: AddShippingFeeProps) => {
  const { className, setDataSubmit } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [packgeType, setPackgeType] = useState<any>("");
  const [provinceCode, setProvinceCode] = useState<any>("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("tokenRESHARE");
    localStorage.removeItem("tokenHYDRA");
    localStorage.removeItem("partnerData");
    localStorage.removeItem("warehouseData");
    navigate("/login");
  };

  const addShippingFee = async (data: any) => {
    try {
      const res = await shippingFeeApi.addShippingFee(data);
      const dataRES = res?.data?.data;

      if (res?.data?.message === "Unauthenticated") {
        localStorage.clear();
        handleLogout();
      }

      if (dataRES) {
        enqueueSnackbar("Thêm thành công", { variant: "success" });
        handleClose();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFile = async (e: any) => {
    const warehouseData = JSON.parse(
      localStorage.getItem("warehouseData") || "{}"
    );

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("serviceId", "123");
    formData.append("warehouseId", warehouseData?.id);
    try {
      const res = await shippingFeeApi.importShippingFee(formData);
      const dataRES = res?.data?.data;
      if (dataRES) {
        enqueueSnackbar("Thêm thành công", { variant: "success" });
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      <div className={styles.addShippingFee}>
        <div onClick={handleOpen} className={styles.addShippingFeeTitle}>
          Thêm mới thủ công
        </div>
        <div>
          <input
            className={styles.inputFile}
            id="files"
            type="file"
            onChange={(e) => {
              handleFile(e);
            }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>

        <ModalShippingFee
          open={open}
          handleClose={handleClose}
          setDataSubmit={setDataSubmit}
          handleSubmitModal={addShippingFee}
          packgeType={packgeType}
          setPackgeType={setPackgeType}
          provinceCode={provinceCode}
          setProvinceCode={setProvinceCode}
        />
      </div>
    </div>
  );
};

export default AddShippingFee;
