const PROVINCE = [
  {
    code: "201",
    label: "Hà Nội",
  },
  {
    code: "202",
    label: "Hồ Chí Minh",
  },
  {
    code: "203",
    label: "Đà Nẵng",
  },
  {
    code: "204",
    label: "Đồng Nai",
  },
  {
    code: "205",
    label: "Bình Dương",
  },
  {
    code: "206",
    label: "Bà Rịa - Vũng Tàu",
  },
  {
    code: "207",
    label: "Gia Lai",
  },
  {
    code: "208",
    label: "Khánh Hòa",
  },
  {
    code: "209",
    label: "Lâm Đồng",
  },
  {
    code: "210",
    label: "Đắk Lắk",
  },
  {
    code: "211",
    label: "Long An",
  },
  {
    code: "212",
    label: "Tiền Giang",
  },
  {
    code: "213",
    label: "Bến Tre",
  },
  {
    code: "214",
    label: "Trà Vinh",
  },
  {
    code: "215",
    label: "Vĩnh Long",
  },
  {
    code: "216",
    label: "Đồng Tháp",
  },
  {
    code: "217",
    label: "An Giang",
  },
  {
    code: "218",
    label: "Sóc Trăng",
  },
  {
    code: "219",
    label: "Kiên Giang",
  },
  {
    code: "220",
    label: "Cần Thơ",
  },
  {
    code: "221",
    label: "Vĩnh Phúc",
  },
  {
    code: "223",
    label: "Thừa Thiên - Huế",
  },
  {
    code: "224",
    label: "Hải Phòng",
  },
  {
    code: "225",
    label: "Hải Dương",
  },
  {
    code: "226",
    label: "Thái Bình",
  },
  {
    code: "227",
    label: "Hà Giang",
  },
  {
    code: "228",
    label: "Tuyên Quang",
  },
  {
    code: "229",
    label: "Phú Thọ",
  },
  {
    code: "230",
    label: "Quảng Ninh",
  },
  {
    code: "231",
    label: "Nam Định",
  },
  {
    code: "232",
    label: "Hà Nam",
  },
  {
    code: "233",
    label: "Ninh Bình",
  },
  {
    code: "234",
    label: "Thanh Hóa",
  },
  {
    code: "235",
    label: "Nghệ An",
  },
  {
    code: "236",
    label: "Hà Tĩnh",
  },
  {
    code: "237",
    label: "Quảng Bình",
  },
  {
    code: "238",
    label: "Quảng Trị",
  },
  {
    code: "239",
    label: "Bình Phước",
  },
  {
    code: "240",
    label: "Tây Ninh",
  },
  {
    code: "241",
    label: "Đắk Nông",
  },
  {
    code: "242",
    label: "Quảng Ngãi",
  },
  {
    code: "243",
    label: "Quảng Nam",
  },
  {
    code: "244",
    label: "Thái Nguyên",
  },
  {
    code: "245",
    label: "Bắc Kạn",
  },
  {
    code: "246",
    label: "Cao Bằng",
  },
  {
    code: "247",
    label: "Lạng Sơn",
  },
  {
    code: "248",
    label: "Bắc Giang",
  },
  {
    code: "249",
    label: "Bắc Ninh",
  },
  {
    code: "250",
    label: "Hậu Giang",
  },
  {
    code: "252",
    label: "Cà Mau",
  },
  {
    code: "253",
    label: "Bạc Liêu",
  },
  {
    code: "258",
    label: "Bình Thuận",
  },
  {
    code: "259",
    label: "Kon Tum",
  },
  {
    code: "260",
    label: "Phú Yên",
  },
  {
    code: "261",
    label: "Ninh Thuận",
  },
  {
    code: "262",
    label: "Bình Định",
  },
  {
    code: "263",
    label: "Yên Bái",
  },
  {
    code: "264",
    label: "Lai Châu",
  },
  {
    code: "265",
    label: "Điện Biên",
  },
  {
    code: "266",
    label: "Sơn La",
  },
  {
    code: "267",
    label: "Hòa Bình",
  },
  {
    code: "268",
    label: "Hưng Yên",
  },
  {
    code: "269",
    label: "Lào Cai",
  },
];

export default PROVINCE;
