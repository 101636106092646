import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";

import styles from "./ShippingFeeList.module.scss";
import {
  HEADER_TABLE_SHIPPING_FEE,
  PACKAGE_TYPE,
} from "../../../constants/constant";
import shippingFeeApi from "../../../apis/shippingFeeApi";
import ModalShippingFee, {
  convertPartnerPackageTypesConfigId,
} from "../Modal/Modal";
import PROVINCE from "../../../constants/province";
import Loading from "../../Loading";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export interface ShippingFeeListProps {
  className?: string;
  dataSubmit: any;
  setDataSubmit: any;
}

const ShippingFeeList = (props: ShippingFeeListProps) => {
  const { className, dataSubmit } = props;
  const [shippingFee, setShippingFee] = useState<any>([]);
  const [dataSubmitEdit, setDataSubmitEdit] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [idItemEdit, setIdItemEdit] = useState<any>("");

  const navigate = useNavigate();

  const [urbanFee, setUrbanFee] = useState<any>("");

  const [idRemove, setIdRemove] = useState<any>("");

  const [packgeType, setPackgeType] = useState<any>("");
  const [provinceCode, setProvinceCode] = useState<any>("");

  const [nextPage, setNextPage] = useState({
    offset: 0,
    limit: 20,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { enqueueSnackbar } = useSnackbar();

  const removeShippingFee = async (id: number) => {
    try {
      const res = await shippingFeeApi.deleteShippingFee({
        id: id,
      });
      const dataRES = res?.data?.data;

      if (dataRES) {
        setIdRemove(id);
        enqueueSnackbar("Xoá thành công", { variant: "success" });
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const warehouseData = JSON.parse(
    localStorage.getItem("warehouseData") || "{}"
  );

  const partnerData = JSON.parse(localStorage.getItem("partnerData") || "{}");

  const [dataRequest, setDataRequest] = useState({
    filter: {
      partnerId: partnerData?.id,
      partnerWareHouseId: warehouseData?.id,
    },
    paging: {
      limit: 20,
      offset: 0,
    },
  });

  useEffect(() => {
    const getShippingFee = async () => {
      setLoading(true);
      const res = await shippingFeeApi.getShippingFee(dataRequest);

      const dataRES = res?.data?.data?.shippingFeeConfig;

      if (res?.data?.message === "Unauthenticated") {
        localStorage.clear();
        navigate("/login");
      }

      const result = dataRES?.reduce((acc: any, cur: any) => {
        const found = acc.find(
          (item: any) => item.provinceId === cur.provinceId
        );
        if (found) {
          found.shipping_fee.push({
            id: cur.id,
            packageType: cur.partnerPackageTypesConfigId,
            shipping_fee: cur.price,
          });
        } else {
          acc.push({
            provinceId: cur.provinceId,
            shipping_fee: [
              {
                id: cur.id,
                packageType: cur.partnerPackageTypesConfigId,
                shipping_fee: cur.price,
              },
            ],
          });
        }
        return acc;
      }, []);

      result?.forEach((item: any) => {
        item.shipping_fee.sort((a: any, b: any) => {
          return (
            convertPartnerPackageTypesConfigId.findIndex(
              (item) => item.to === a.packageType
            ) -
            convertPartnerPackageTypesConfigId.findIndex(
              (item) => item.to === b.packageType
            )
          );
        });
      });

      setNextPage({
        offset: res?.data?.data?.nextPage?.offset,
        limit: res?.data?.data?.nextPage?.limit,
      });

      const prevBtn = document.querySelector(
        `.${styles.prevBtn}`
      ) as HTMLButtonElement;
      const nextBtn = document.querySelector(
        `.${styles.nextBtn}`
      ) as HTMLButtonElement;

      if (prevBtn) {
        if (res?.data?.data?.nextPage?.offset === 20) {
          prevBtn.disabled = true;
        } else {
          prevBtn.disabled = false;
        }
      }

      if (nextBtn) {
        if (res?.data?.data?.nextPage === null) {
          nextBtn.disabled = true;
        } else {
          nextBtn.disabled = false;
        }
      }

      setShippingFee(result);
      setLoading(false);
    };

    const tokenHYDRAInLocal = localStorage.getItem("tokenHYDRA");
    if (tokenHYDRAInLocal) {
      getShippingFee();
    }
  }, [
    dataSubmit,
    enqueueSnackbar,
    open,
    dataSubmitEdit,
    idRemove,
    navigate,
    dataRequest,
  ]);

  const editShippingFee = async (data: any) => {
    try {
      const res = await shippingFeeApi.updateShippingFee({
        ...data,
        id: idItemEdit,
      });

      const dataRES = res?.data?.data;

      if (dataRES) {
        enqueueSnackbar("Chỉnh sửa thành công", { variant: "success" });
        setPackgeType("");
        setProvinceCode("");
        setUrbanFee("");
        handleClose();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (dataItem: any) => {
    setProvinceCode(dataItem.provinceId);
    setPackgeType(dataItem.shipping_fee.packageType);
    setUrbanFee(dataItem.shipping_fee.shipping_fee);
    setIdItemEdit(dataItem.shipping_fee.id);
    handleOpen();
  };

  const parsePackageType = (type: string) => {
    const result = convertPartnerPackageTypesConfigId.find(
      (item) => item.to === type
    );

    const result2 = PACKAGE_TYPE.find(
      (item) => item.packageType === result?.from
    );
    return result2?.label;
  };

  const parseCodeToProvince = (code: string) => {
    const result = PROVINCE.find((item) => item.code === code);
    return result?.label;
  };

  const handleNextPage = () => {
    setDataRequest({
      filter: {
        partnerId: partnerData?.id,
        partnerWareHouseId: warehouseData?.id,
      },
      paging: {
        limit: nextPage?.limit,
        offset: nextPage?.offset,
      },
    });
  };

  const handlePrevPage = () => {
    setDataRequest({
      filter: {
        partnerId: partnerData?.id,
        partnerWareHouseId: warehouseData?.id,
      },
      paging: {
        limit: nextPage?.limit,
        offset: nextPage?.offset - 40,
      },
    });
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      <div className={styles.orderListWrap}>
        <div className={styles.orderList}>
          <table className={styles.orderListTable}>
            <thead>
              <tr>
                {HEADER_TABLE_SHIPPING_FEE.map((item) => {
                  return <th key={item.id}>{item.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <Fragment>
                {shippingFee?.length > 0 ? (
                  shippingFee
                    ?.sort((a: any, b: any) => {
                      if (a.provinceId < b.provinceId) {
                        return -1;
                      }
                      if (a.provinceId > b.provinceId) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.map((item: any) => {
                      return (
                        <Fragment key={item.id}>
                          <tr className={clsx(styles.item)}>
                            <td rowSpan={item?.shipping_fee?.length + 1}>
                              {item.provinceId}
                            </td>
                            <td rowSpan={item?.shipping_fee?.length + 1}>
                              {parseCodeToProvince(item.provinceId)}
                            </td>
                          </tr>

                          {item?.shipping_fee
                            ?.sort((a: any, b: any) => {
                              return a.id - b.id;
                            })
                            ?.map((shipType: any, index: number) => {
                              return (
                                <tr className={clsx(styles.item)} key={index}>
                                  <td>
                                    {parsePackageType(shipType.packageType)}
                                  </td>

                                  <td>
                                    {shipType.shipping_fee.toLocaleString()}
                                  </td>

                                  <td className={styles.tdAction}>
                                    <div
                                      className={styles.finished}
                                      onClick={() =>
                                        handleEdit({
                                          provinceId: item.provinceId,
                                          shipping_fee: shipType,
                                        })
                                      }
                                    >
                                      Chỉnh sửa
                                    </div>
                                    <div
                                      className={styles.canceled}
                                      onClick={() =>
                                        removeShippingFee(shipType.id)
                                      }
                                    >
                                      Xoá
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </Fragment>
                      );
                    })
                ) : loading ? (
                  <tr>
                    <td className={styles.noneData} colSpan={5}>
                      <div className={styles.loadingWrap}>
                        <Loading />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className={styles.noneData} colSpan={5}>
                      Không có dữ liệu
                    </td>
                  </tr>
                )}
              </Fragment>
            </tbody>
          </table>
        </div>
      </div>

      <div className={styles.pagination}>
        <button
          onClick={handlePrevPage}
          className={styles.prevBtn}
          disabled={loading}
        >
          &laquo; Prev
        </button>
        <button
          onClick={handleNextPage}
          className={styles.nextBtn}
          disabled={loading}
        >
          Next &raquo;
        </button>
      </div>

      <ModalShippingFee
        open={open}
        handleClose={handleClose}
        isEdit={true}
        setDataSubmit={setDataSubmitEdit}
        handleSubmitModal={editShippingFee}
        packgeType={packgeType}
        setPackgeType={setPackgeType}
        provinceCode={provinceCode}
        setProvinceCode={setProvinceCode}
        urbanFee={urbanFee}
        setUrbanFee={setUrbanFee}
        parsePackageType={parsePackageType}
      />
    </div>
  );
};

export default ShippingFeeList;
