import queryString from "query-string"
import { useMemo } from "react";
import { useLocation } from "react-router-dom"

const useFilterParam =  () => {
    const location = useLocation();
    
    const params = useMemo( () => {
        const query = queryString.parse(location.search)
        return {
          ...query
        }
      }, [location.search])

    return {
        params
    }
}

export default useFilterParam