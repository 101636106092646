import donationApi from "../../apis/donationApi";
import District from "../../components/OrderShip/DistrictItem/DistrictItem";
import OrderItem from "../../components/OrderShip/OrderItem/OrderItem";
import ProvinceItem from "../../components/OrderShip/ProvinceItem";
import styles from "./OrderShip.module.scss";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Address from "../../apis/Address";
export interface OrdersShipProps {
  className?: string;
}
const initNextPage = {
  limit: 15,
  offset: 0,
};
const OrderShip = (props: OrdersShipProps) => {
  const { className } = props;
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [userDonations, setUserDonations] = useState([]);
  const [nextPage, setNextPage] = useState(initNextPage);
  const [reload, setReload] = useState(false)
  const [dataReq, setDataReq] = useState({
    filter: {
      provinceId: "202",
      shippingTypeId: "11123",
      statuses: ["new", "confirm"],
      districtId: districtId,
    },
    paging: {
      limit: 15,
      offset: 0,
    },
  });
  const handleReload = () => {
    setReload(!reload)
  }
  useEffect(() => {
    if (districtId) {
      (async () => {
        try {
          const res = await donationApi.getDonations(dataReq);

          if (res.data.code === 0) {
            setUserDonations(res.data.data.userDonations);
            setNextPage(res.data.data.nextPage);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [dataReq, districtId, reload]);

  useEffect(() => {
    (async () => {
      try {
        const res = await Address.getProvinces();
        if (res.data.code === 0) {
          setProvinces(res.data.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [reload]);

  useEffect(() => {
    (async () => {
      try {
        const res = await Address.getDistricts("202");
        if (res.data.code === 0) {
          setDistricts(res.data.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [reload]);

  const handleChangeDistrict = (districtId: any) => {
    if (districtId) {
      setDistrictId(districtId);
      setDataReq((prev: any) => {
        return {
          ...prev,
          filter: {
            districtId: districtId,
            provinceId: "202",
            shippingTypeId: "11123",
            statuses: ["new", "confirm"],
          },
          paging: {
            offset: 0,
            limit: 15,
          },
        };
      });
    }
  };
  const handleNextPage = () => {
    setDataReq({
      ...dataReq,
      paging: {
        offset: nextPage?.offset ? nextPage.offset : 0,
        limit: nextPage ? nextPage?.limit : 15,
      },
    });
  };
  const handlePrevPage = () => {
    setDataReq({
      ...dataReq,
      paging: {
        offset: nextPage?.offset ? nextPage.offset - nextPage.limit * 2 : 0,
        limit: nextPage ? nextPage?.limit : 15,
      },
    });
  };
  return (
    <div className={clsx(styles.root, className && className)}>
      <div className={styles.province}>
        <p className={styles.label}>Tỉnh/Thành phố</p>
        <ProvinceItem reload={reload} />
      </div>
      <div className={styles.district}>
        <p className={styles.label}>Quận/Huyện</p>
        {districts &&
          districts.length > 0 &&
          districts.map((district,key) => (
            <District
              key={key}
              reload = {reload}
              handleChangeDistrict={handleChangeDistrict}
              district={district}
            />
          ))}
      </div>
      <div className={styles.orders}>
        <table className={styles.tableOrder}>
          <thead>
            <th>Đơn hàng</th>
            <th>Khách hàng</th>
            <th>Địa chỉ</th>
            <th>Hành động</th>
          </thead>
          <tbody>
            {userDonations &&
              userDonations.length > 0 &&
              userDonations.map((order,key) => <OrderItem key={key} order={order} handleReload={handleReload}/>)}
          </tbody>
        </table>
        {userDonations && userDonations.length >0 &&
        <div className={styles.pagination}>
        <button
          onClick={handlePrevPage}
          className={styles.prevBtn}
          disabled={nextPage?.offset === 15 ? true : false}
        >
          &laquo; Prev
        </button>
        <button
          onClick={handleNextPage}
          className={styles.nextBtn}
          disabled={!nextPage ? true : false}
        >
          Next &raquo;
        </button>
      </div>
        }

      </div>
    </div>
  );
};

export default OrderShip;
