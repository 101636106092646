import React, { useState, useEffect } from "react";
import clsx from "clsx";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./Filter.module.scss";
import { Button } from "@mui/material";
import { INIT_DATA_REQUEST_FILTER } from "../../../pages/Orders";
import { COLLECT_TIME, PACKAGE_TYPE_VER_2 } from "../../../constants/constant";
import DatePicker from "../../DatePicker";
import Address from "../../../apis/Address";
import PROVINCE from "../../../constants/province";
import donationApi from "../../../apis/donationApi";
import dayjs from "dayjs";

export interface FilterProps {
  className?: string;
  setDataRequest: (data: any) => void;
  dataRequest: any;
}

const SHIPPING_TYPE = [
  { label: "Shipper đến lấy hàng", shippingTypeId: "11123" },
  { label: "Tự đem đến kho", shippingTypeId: "11124" },
];

const Filter = (props: FilterProps) => {
  const { className } = props;
  const dayjs = require('dayjs')
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)

  const [valueFilter, setValueFilter] = useState<any>({
    shippingTypeId: "",
    customerName: "",
    packageTypeId: "",
    collectFromTime: "",
    collectToTime: "",
    provinceId: "",
    districtId: "",
    wardId: "",
    customerId: "",
    code: "",
    customerPhone: "",
    bagId: "",
    createdAts: {
      from: dayjs().startOf("day"),
      to: dayjs().endOf("day")
    }
  });
  
  const [district, setDistrict] = useState<any>([]);
  const [ward, setWard] = useState<any>([]);
  
  const handleGetDistrict = async (id: any) => {
    const res = await Address.getDistricts(id);
    const newArr = res?.data?.data.data.map((item: any) => {
      return {
        label: item.districtName,
        code: item.districtId,
      };
    });
    setDistrict(newArr);
  };
  

  const handleExportFile = async () => {
    if (props.dataRequest) {
      const res = await donationApi.exportFile(props.dataRequest);
      if (res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    }
  }

  const handleGetWard = async (id: any) => {
    const res = await Address.getWards(id);
    const newArr = res.data.data.data.map((item: any) => {
      return {
        label: item.wardName,
        code: item.wardId,
      };
    });
    setWard(newArr);
  };

  useEffect(() => {
    if (valueFilter.provinceId) {
      handleGetDistrict(valueFilter.provinceId);
    }

    if (valueFilter.districtId) {
      handleGetWard(valueFilter.districtId);
    }
  }, [valueFilter.districtId, valueFilter.provinceId]);

  const handleFilterInput = (e: { target: { value: any; id: any } }) => {
    const key = e.target.id;
    const value = e.target.value;
    setValueFilter({ ...valueFilter, [key]: value });
  };

  const removeKey = (obj: any, deleteKey: any) => {
    return Object.keys(obj)
      .filter((key) => key !== deleteKey)
      .reduce((result: any, current: any) => {
        result[current] = obj[current];
        return result;
      }, {});
  };

  const handleClickFilter = () => {
    const data = {
      filter: { ...props.dataRequest.filter, ...valueFilter },
    };

    const dataRemoveKey = removeKey(data.filter, "input_auto_complete");

    props.setDataRequest({
      filter: { ...props.dataRequest.filter, ...dataRemoveKey },
      paging: { ...props.dataRequest.paging },
    });
  };

  const handleResetFilter = () => {
    const dataRemoveKey = removeKey(INIT_DATA_REQUEST_FILTER, "statuses");

    let element: any = document.querySelectorAll(
      ".css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator"
    );

    element.forEach((item: any) => {
      item.click();
    });

    setValueFilter({
      shippingTypeId: "",
      customerName: "",
      packageTypeId: "",
      collectFromTime: "",
      collectToTime: "",
      provinceId: "",
      districtId: "",
      wardId: "",
      customerId: "",
      code: "",
      customerPhone: "",
      bagId: "",
      createdAts: {
        from: dayjs().startOf("day"),
        to: dayjs().endOf("day")
      }
    });
    setDateUpdateAtFrom(null);
    setDateUpdateAtTo(null);
    const objFilter = {
      ...dataRemoveKey,
      statuses: [...props.dataRequest.filter.statuses],
    };

    props.setDataRequest({
      filter: {
        ...objFilter,
      },
      paging: { ...props.dataRequest.paging },
    });
  };

  const [datePicker, setDatePicker] = useState<any>("");
  const [dateUpdateAtFrom, setDateUpdateAtFrom] = useState<any>("");
  const [dateUpdateAtTo, setDateUpdateAtTo] = useState<any>("");
  
  return (
    <div className={clsx(styles.root, className && className)}>
      <h3 className={styles.title}>Lọc đơn hàng</h3>
      <div className={styles.filterWrap}>
        <TextField
          id="code"
          className="filter"
          label="Mã đơn hàng"
          value={valueFilter.code}
          onChange={handleFilterInput}
          size="small"
        />

        <TextField
          id="customerId"
          label="Mã khách hàng"
          value={valueFilter.customerId}
          onChange={handleFilterInput}
          size="small"
        />

        <TextField
          id="customerName"
          label="Tên khách hàng"
          value={valueFilter.customerName}
          onChange={handleFilterInput}
          size="small"
        />

        <TextField
          id="customerPhone"
          label="Số điện thoại"
          value={valueFilter.customerPhone}
          onChange={handleFilterInput}
          size="small"
        />

        <TextField
          id="bagId"
          label="Túi ID"
          onChange={handleFilterInput}
          value={valueFilter.bagId}
          size="small"
        />

        <Autocomplete
          size="small"
          disablePortal
          id="input_auto_complete"
          options={SHIPPING_TYPE}
          sx={{ width: 250 }}
          onChange={(event, value) =>
            setValueFilter({
              ...valueFilter,
              shippingTypeId: value?.shippingTypeId,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Loại hình vận chuyển"
              onChange={handleFilterInput}
            />
          )}
        />

        <Autocomplete
          size="small"
          disablePortal
          id="input_auto_complete"
          options={PACKAGE_TYPE_VER_2}
          sx={{ width: 250 }}
          onChange={(event, value) =>
            setValueFilter({
              ...valueFilter,
              packageTypeId: value?.packageTypeId,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Trọng lượng đơn hàng"
              onChange={handleFilterInput}
            />
          )}
        />

        <Autocomplete
          size="small"
          disablePortal
          onChange={(event, value) =>
            setValueFilter({ ...valueFilter, ...value })
          }
          id="input_auto_complete"
          options={COLLECT_TIME}
          sx={{ width: 250 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Thời gian nhận hàng"
              onChange={handleFilterInput}
            />
          )}
        />

        <DatePicker
          setValue={setDatePicker}
          label="Ngày nhận hàng"
          value={datePicker}
          onChange={(value) => {
            setValueFilter({
              ...valueFilter,
              collectDate: value,
            });
          }}
        />

        <div>
        <DatePicker
          setValue={(value) => {
            setValueFilter({
              ...valueFilter,
              createdAts : {
                ...valueFilter.createdAts,
                from: dayjs(value).startOf("day")
              }
            })
          }}
          label="Từ ngày"
          value={valueFilter?.createdAts?.from}
          onChange={(value) => {
            setValueFilter({
              ...valueFilter,
              createdAts : {
                ...valueFilter.createdAts,
                from: dayjs(value).startOf("day")
              }
            });
          }}
        />
        <DatePicker
          setValue={(value) => {
              setValueFilter({
                ...valueFilter,
                createdAts : {
                  ...valueFilter.createdAts,
                  to: dayjs(value).endOf("day")
                }
              });
            }
          }
          label="Đến ngày"
          value={valueFilter?.createdAts?.to}
          onChange={(value) => {
            setValueFilter({
              ...valueFilter,
              createdAts : {
                ...valueFilter.createdAts,
                to: dayjs(value).endOf("day")
              }
            });
          }}
        />
        </div>

        <Autocomplete
          size="small"
          disablePortal
          id="input_auto_complete"
          options={PROVINCE}
          sx={{ width: 250 }}
          onChange={(event, value) =>
            setValueFilter({
              ...valueFilter,
              provinceId: value?.code,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tỉnh/Thành phố"
              onChange={handleFilterInput}
            />
          )}
        />

        {valueFilter.provinceId && (
          <Autocomplete
            size="small"
            disablePortal
            onChange={(event, value: any) =>
              setValueFilter({
                ...valueFilter,
                districtId: value?.code,
              })
            }
            id="input_auto_complete"
            options={district}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField {...params} label="Quận/Huyện" />
            )}
          />
        )}

        {valueFilter.districtId && (
          <Autocomplete
            size="small"
            disablePortal
            onChange={(event, value: any) =>
              setValueFilter({
                ...valueFilter,
                wardId: value?.code,
              })
            }
            id="input_auto_complete"
            options={ward}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField {...params} label="Phường/Xã" />
            )}
          />
        )}
        <Button variant="outlined" onClick={handleClickFilter}>
          Tìm đơn hàng
        </Button>

        <Button variant="outlined" onClick={handleResetFilter}>
          Huỷ lọc
        </Button>

        <Button variant="contained" onClick={handleExportFile}>
          Xuất file
        </Button>
      </div>
    </div>
  );
};

export default Filter;
