import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./CustomSelect.module.scss";
import { error } from "console";

export interface CustomSelectProps {
  onChange: (e:any) => void,
  value: string,
  name: string,
  options: Array<any>,
  error?: boolean
}

const CustomSelect = (props: CustomSelectProps) => {
  const {value,name,onChange,options, error} = props;

  const handleChange = (event: SelectChangeEvent) => {
    let name = event.target.name;
    let value = event.target.value;
    if (onChange) {
      onChange({ name: name, value: value });
    }
  };

  return (
    <div className={styles.customSelect}>
      <label className={styles.label}>Chọn dịch vụ vận chuyển: </label>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select
            onChange={handleChange}
            name={name}
            value={value ? value : ""}
            className={styles.select}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
          >
            {
              options && options.length > 0 &&
              options.map((service:any, key: any) => (
                  <MenuItem key={key} value={service.value}>{service.label}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
      {error && <p className={styles.error}>Mục này không được để trống</p>}
    </div>
  );
};
export default CustomSelect;
