import React, { useEffect } from "react";
import clsx from "clsx";

import styles from "./SelectWarehouse.module.scss";
import loginApi from "../../apis/loginApi";
import partnerWarehouse from "../../apis/partnerWarehouse";
import { useNavigate } from "react-router-dom";

export interface SelectWarehouseProps {
  className?: string;
}

const autoLogin = async () => {
  const res = await loginApi.loginHydra({
    password: "123456789",
    username: "donation@reshare.vn",
  });

  const dataResponse = res.data.data;

  localStorage.setItem("tokenHYDRA", dataResponse?.token);
};

const checkRefreshToken = async () => {
  const tokenHYDRAInLocal = localStorage.getItem("tokenHYDRA");
  if (tokenHYDRAInLocal) {
    const tokenHYDRA = JSON.parse(tokenHYDRAInLocal);
    const jwtPayload = JSON.parse(window.atob(tokenHYDRA.split(".")[1]));

    const exp = new Date(jwtPayload.exp * 1000);
    const now = new Date();
    if (now > exp) {
      await autoLogin();
    }
  }
};

const SelectWarehouse = (props: SelectWarehouseProps) => {
  const { className } = props;

  const [warehouses, setWarehouses] = React.useState<any>([]);

  const navigate = useNavigate();

  useEffect(() => {
    checkRefreshToken();
    try {
      const getCurrentProfile = async () => {
        await autoLogin();
        const res = await loginApi.getCurrentProfile();
        const dataResponse = res.data.data;

        localStorage.setItem("partnerData", JSON.stringify(dataResponse));

        if (dataResponse) {
          const param = {
            filter: {
              partnerId: dataResponse.id,
            },
            paging: {
              limit: 0,
              offset: 0,
            },
          };

          const searchPartnerWarehouse = async () => {
            const res = await partnerWarehouse.searchPartnerWarehouse(param);
            const dataResponse = res.data.data;
            setWarehouses(dataResponse?.userDonations);
          };

          searchPartnerWarehouse();
        }
      };
      getCurrentProfile();
    } catch (error) {}
  }, []);

  const handleSelectWarehouse = (item: any) => {
    localStorage.setItem("warehouseData", JSON.stringify(item));
    navigate("/shipping-fee");
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      {warehouses?.length > 0 ? (
        <div className={styles.textCenter}>
          <h1 className={styles.title}>Select Warehouse</h1>
          <p className={styles.description}>
            Please select a warehouse to continue
          </p>

          <div className={styles.warehouseSelectWrap}>
            <div className={styles.warehouseSelect}>
              {warehouses?.map((item: any) => {
                return (
                  <div
                    className={styles.warehouseSelectItem}
                    onClick={() => handleSelectWarehouse(item)}
                    key={item?.id}
                  >
                    <div className={styles.warehouseSelectItemIcon}>
                      <img
                        draggable="false"
                        src="/images/warehouse.svg"
                        alt="warehouse"
                      />

                      <div className={styles.warehouseSelectItemIconText}>
                        <p className={styles.warehouseSelectItemIconTextTitle}>
                          {item?.name}
                        </p>
                        <p
                          className={
                            styles.warehouseSelectItemIconTextDescription
                          }
                        >
                          {item?.address?.address}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.loading}>
          <div className={styles.loadingIcon}></div>
        </div>
      )}
    </div>
  );
};

export default SelectWarehouse;
