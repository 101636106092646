import { useNavigate } from 'react-router-dom'
import donationApi from '../../../apis/donationApi'
import styles from './ProvinceItem.module.scss'
import {useEffect, useState} from 'react'

const initPayload={
    "filter": {
        "provinceId": "202",
        "shippingTypeId": "11123",
        "statuses": [
          "new",
          "confirm"
        ]
      }
}

interface ProvinceItemProps{
    reload: boolean
}
const ProvinceItem = (props:ProvinceItemProps) => {
    const [quantityOrder, setQuantityOrder] = useState(0)
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem("tokenRESHARE");
        localStorage.removeItem("tokenHYDRA");
        localStorage.removeItem("partnerData");
        localStorage.removeItem("warehouseData");
        navigate("/login");
      };
    useEffect(()=> {
        (async () => {
            try {
                const res = await donationApi.countOrder(initPayload)
                if (res.data.code === 0) {    
                    setQuantityOrder(res.data.data.quantity)
                }
                else{
                    handleLogout()
                }
            } catch (error) {
                console.log(error);
            }
        })()
    },[props.reload])
    
    return (
        <div className={styles.provinceIt}>
            <p>Hồ Chí Minh({quantityOrder} đơn)</p>
        </div>
    )
}

export default ProvinceItem