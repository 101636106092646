import axiosDonation from "./axiosDonation";

const dashboard = {
    getCustomerQuantityInfo : (data:any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-customer-quantity-info",data)
    },

    getCustomerQuantityChartInfo : (data: any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-customer-quantity-chart-info",data)
    },

    getOrderAmountInfo : (data: any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-order-amount-info",data)
    },

    getOrderAmountChartInfo : (data: any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-order-amount-chart-info",data)
    },

    getOrderQuantityInfo : (data: any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-order-quantity-info",data)
    },

    getOrderQuantityChartInfo : (data: any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-order-quantity-chart-info",data)
    },

    getOrderWeightInfo : (data: any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-order-weight-info",data)
    },

    getOrderWeightChartInfo : (data: any) => {
        return axiosDonation.post("/serene/dashboard-donation/get-order-weight-chart-info",data)
    },
}


export default dashboard