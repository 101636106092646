import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularColor() {
  return (
    <Stack sx={{ color: "#53B476" }} spacing={2} direction="row">
      <CircularProgress color="success" />
    </Stack>
  );
}
