import styles from "./ZeroWaste.module.scss";
import ZeroWasteItem from "./ZeroWasteItem/ZeroWasteItem";
import ModalUpdateFee from "./ModalUpdateFee/ModalUpdateFee";
import { useState } from "react";
import donationApi from "../../apis/donationApi";
import { useSnackbar } from "notistack";

interface ZeroWasteListProps {
  zeroWasteList: any;
  handleReload: () => void;
}

const ZeroWasteList = (props: ZeroWasteListProps) => {
  const { zeroWasteList, handleReload } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleShowModalUpdate = (id: string, price: number) => {
    setIsOpen(true);
    setData({
      id: id,
      price: +price,
    });
  };
  console.log(data);
  
  const handleUpdateFee = () => {
    (async () => {
      if (data) {
        const res = await donationApi.updateZeroWaste(data);
        if (res) {
          setIsOpen(false);
          handleReload();
          enqueueSnackbar("Cập nhật thành công", { variant: "success" });
        } else {
          setIsOpen(false);
          enqueueSnackbar("Cập nhật thất bại", { variant: "error" });
        }
      }
    })();
  };

  return (
    <div className={styles.zeroList}>
      <table>
        <thead>
          <th>Trọng lượng</th>
          <th>Mức giá</th>
          <th>Hành động</th>
        </thead>
        <tbody>
          {zeroWasteList &&
            zeroWasteList.length > 0 &&
            zeroWasteList.map((item: any) => (
              <ZeroWasteItem item={item} onOpen={handleShowModalUpdate} />
            ))}
        </tbody>
      </table>
      <ModalUpdateFee
        handleUpdate = {handleUpdateFee}
        data={data}
        setData={setData}
        open={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZeroWasteList;
