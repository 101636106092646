import { Autocomplete, Button, TextField } from "@mui/material";
import styles from "./Filter.module.scss";
import { useLayoutEffect, useState } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

const initFilter = {
  code: "",
  customerPhone: "",
  materialType: "none",
  packageType: "none"
};
const PACKAGE_TYPE = [
    { 
      label: "100kg - 500kg", 
      value: "100To500" 
    },
    { 
      label: "600kg - 1000kg", 
      value: "600To1000" 
    },
    { 
      label: "2000kg - 5000kg", 
      value: "2000To5000" 
    },
  ];
const MATERIAL_TYPE = [
  { 
    label: "polyester", 
    value: "polyester" 
  },
  { 
    label: "organic", 
    value: "organic" 
  },
  { 
    label: "mixture", 
    value: "mixture" 
  },
  { 
    label: "cotton", 
    value: "cotton" 
  },
];
type FilterProps = {
  setPage: (page:number) => void;
};

const Filter = ({setPage}:FilterProps) => {
  const [filterData, setFilterData] = useState(initFilter);
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const params = queryString.parse(location.search);
    setFilterData({
      ...filterData,
      ...params,
    });
  }, [location.search]);

  const handleFilterInput = (e: any) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = () => {
    const params = queryString.parse(location.search);
    const query = queryString.stringify({ ...params, ...filterData });
    navigate(`${location.pathname}?${query}`);
    setPage(1);
  };

  const handleReset = () => {
    navigate(`${location.pathname}`);
    setFilterData(initFilter);
    setPage(1);
  };

  return (
    <div className={styles.filter}>
      <div className={styles.form}>
        <TextField
          className="filter"
          name="code"
          label="Mã đơn hàng"
          value={filterData.code}
          onChange={handleFilterInput}
          size="small"
        />
        <TextField
          className="filter"
          name="customerPhone"
          label="SĐT khách hàng"
          value={filterData.customerPhone}
          onChange={handleFilterInput}
          size="small"
        />
        <Autocomplete
          size="small"
          disablePortal
          id="materialType"
          options={MATERIAL_TYPE}
          sx={{ width: 250 }}
          value={MATERIAL_TYPE.find((option) => option.value === filterData.materialType) || null}
          onChange={(event, value) =>
             setFilterData({
                ...filterData,
                materialType: value?.value || ""
             })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Chất liệu"
              onChange={handleFilterInput}
            />
          )}
        />
        <Autocomplete
          size="small"
          disablePortal
          id="packageType"
          options={PACKAGE_TYPE}
          sx={{ width: 250 }}
          value={PACKAGE_TYPE.find((option) => option.value === filterData.packageType) || null}
          onChange={(event, value) =>
             setFilterData({
                ...filterData,
                packageType: value?.value || ""
             })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Khối lượng"
              onChange={handleFilterInput}
            />
          )}
        />
      </div>
      <div className={styles.action}>
        <Button onClick={handleSubmit} variant="contained">
          Tìm kiếm
        </Button>
        <Button onClick={handleReset} variant="outlined">
          Đặt lại
        </Button>
      </div>
    </div>
  );
};

export default Filter;
