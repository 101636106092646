import axiosDonation from "./axiosDonation";

const recycling = {
    getList: (data:any) => {
        const url = "/serene/recycle-order/search-recycle-order";
        return axiosDonation.post(url, data);
    },
    count: (data:any) => {
        const url = "/serene/recycle-order/count-recycle-order";
        return axiosDonation.post(url, data);
    },
    cancel: (id:string) => {
        const url = "/serene/recycle-order/cancel-recycle-order";
        return axiosDonation.post(url, {id});
    },
    confirm: (id:string) => {
        const url = "/serene/recycle-order/confirm-recycle-order";
        return axiosDonation.post(url,{id});
    },
    finish: (id:string) => {
        const url = "/serene/recycle-order/finish-recycle-order";
        return axiosDonation.post(url, {id});
    },
    process: (id: string) => {
        const url = "/serene/recycle-order/process-recycle-order";
        return axiosDonation.post(url, {id});
    }
    

}


export default recycling