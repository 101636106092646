import { useForm, SubmitHandler } from "react-hook-form";
import clsx from "clsx";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./Modal.module.scss";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import PROVINCE from "../../../constants/province";

export interface ModalProps {
  className?: string;
  open: boolean;
  handleClose: () => void;
  isEdit?: boolean;
  setDataSubmit: any;
  handleSubmitModal: any;
  packgeType: string;
  provinceCode: string;
  setPackgeType: any;
  setProvinceCode: any;
  urbanFee?: any;
  parsePackageType?: any;
  setUrbanFee?: any;
}

type Inputs = {
  packageType: string;
  provinceID: string;
  urban_fee: any;
  suburban_fee: any;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const packgeTypeOption = [
  { label: "1 đến 5 kg", packageType: "1To5kg" },
  { label: "6 đến 10 kg", packageType: "6To10kg" },
  { label: "11 đến 15 kg", packageType: "11To15kg" },
  { label: "16 đến 20 kg", packageType: "16To20kg" },
  { label: "21 đến 30 kg", packageType: "21To30kg" },
];

export const convertPartnerPackageTypesConfigId = [
  { from: "1To5kg", to: "456" },
  { from: "6To10kg", to: "457" },
  { from: "11To15kg", to: "458" },
  { from: "16To20kg", to: "459"},
  { from: "21To30kg", to: "461"}
];

const ModalShippingFee = (props: ModalProps) => {
  const {
    className,
    open,
    handleClose,
    isEdit = false,
    setDataSubmit,
    handleSubmitModal,
    packgeType,
    provinceCode,
    setPackgeType,
    setProvinceCode,
    urbanFee,
    setUrbanFee,
  } = props;

  const { register, handleSubmit } = useForm<Inputs>();

  const warehouseData = JSON.parse(
    localStorage.getItem("warehouseData") || "{}"
  );

  const partnerData = JSON.parse(localStorage.getItem("partnerData") || "{}");

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const packgeTypeConvert = convertPartnerPackageTypesConfigId.find(
      (item) => item.from === packgeType
    )?.to;

    const dataParse = {
      partnerId: partnerData?.id,
      partnerPackageTypesConfigId: packgeTypeConvert || packgeType,
      partnerWareHouseId: warehouseData?.id,
      price: parseFloat(data.urban_fee),
      provinceId: provinceCode,
    };
    handleSubmitModal(dataParse);
    setDataSubmit(dataParse);
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isEdit ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {" "}
              Sửa phí vận chuyển{" "}
            </Typography>
          ) : (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {" "}
              Thêm mới phí vận chuyển{" "}
            </Typography>
          )}

          <form
            className={styles.formShippingFee}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={PROVINCE}
              value={
                PROVINCE.find((item) => item.code === provinceCode) || null
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...register("provinceID")}
                  {...params}
                  label="Thành phố"
                />
              )}
              onChange={(event, value) => setProvinceCode(value?.code)}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={packgeTypeOption}
              value={
                packgeTypeOption.find(
                  (item) =>
                    item.packageType ===
                    convertPartnerPackageTypesConfigId.find(
                      (item) => item.to === packgeType
                    )?.from
                ) || null
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...register("packageType")}
                  {...params}
                  label="Trọng lượng hàng"
                />
              )}
              onChange={(event, value) =>
                setPackgeType(
                  convertPartnerPackageTypesConfigId.find(
                    (item) => item.from === value?.packageType
                  )?.to
                )
              }
            />

            <TextField
              id="outlined-basic"
              label="Phí vận chuyển"
              variant="outlined"
              {...register("urban_fee", { required: true })}
              value={urbanFee}
              onChange={(e) => {
                setUrbanFee && setUrbanFee(e.target.value);
              }}
            />

            {isEdit ? (
              <Button type="submit" variant="contained">
                Sửa
              </Button>
            ) : (
              <Button type="submit" variant="contained">
                Thêm mới
              </Button>
            )}
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalShippingFee;
