

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styles from "./AddressSelect.module.scss";
import {useState, useEffect} from "react";
import Address from '../../apis/Address';
import { useSnackbar } from 'notistack';

export interface AddressSelectProps{
  value: string,
  name: string,
  onChange: (e:any) => void,
  apiMethod: 'getProvinces'| 'getDistricts' | 'getWards',
  code: string,
  label: string,
  keyLabel: string,
  keyValue: string
}

const  AddressSelect = (props: AddressSelectProps) => {
  const {value,name, onChange,code,apiMethod, label, keyLabel, keyValue} = props;
  const [state, setState] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      if(apiMethod && code){
        const resp = await Address[apiMethod](code);
        if (resp?.data?.code === 0) {
          setState(resp.data.data.data)
        }
        else{
          enqueueSnackbar(resp?.data?.message, { variant: "error" });
        }
      }
    })()
  },[code, enqueueSnackbar,apiMethod, value]) 
  
  const handleChange = (event: SelectChangeEvent) => {
    let name = event.target.name;
    let value = event.target.value;
    if(onChange){
      onChange({name: name, value: value})
    }
  };
  
  return (
    <div className={styles.selectAddress}>
    <label className={styles.label}>{label}: </label>
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          name={name}
          className={styles.select}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value ? value : ""}
          onChange={handleChange}
        >
         {state && state.length > 0 &&
          state.map((address:any, key:any) => {
            return (
                <MenuItem key={key} value={address[keyValue]}>{address[keyLabel]}</MenuItem>
            )
          })
         }
        </Select>
      </FormControl>
    </Box>
    </div>
  );
}
export default AddressSelect