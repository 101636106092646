import React, { Fragment, useState } from "react";
import clsx from "clsx";

import styles from "./OrderList.module.scss";
import {
  HEADER_TABLE,
  PACKAGE_TYPE,
  parseStatus,
  STATUS_TAB,
  paymentStatus,
  paymentMethod,
  colorPaymentStatus,
  colorPaymentMethod,
  parseShipperType,
  parseDonationForm,
} from "../../../constants/constant";
import { NEXT_PAGE, USER_DONATIONS } from "../../../global/type";
import dayjs from "dayjs";
import Loading from "../../Loading";
import ModalAddBag from "../ModalAddBag";
import PROVINCE from "../../../constants/province";
import DISTRICTS from "../../../constants/district";
import WARD from "../../../constants/ward";
import Button from "@mui/material/Button";
import ModalUpdatePaymentStatus from "../ModalUpdatePaymentStatus/ModalUpdatePaymentStatus";
import CreateOrderCode from "../../ProcessDelivery/CreateOrderCode";
import ModalUpdateNote from "../ModalUpdateNote/ModalUpdateNote";
import CreateOrderCodeDirect from "../../ProcessDelivery/CreateOrderCodeDirect";
import { useSnackbar } from "notistack";

export interface OrderListProps {
  className?: string;
  userDonations: USER_DONATIONS[];
  setDataRequest: (data: any) => void;
  dataRequest: any;
  handlePostDonationShipment: (id: string) => void;
  loading: boolean;
  handleCancelDonation: (id: string) => void;
  handleDoneDonation: (id: string) => void;
  setDataAddBag: any;
  dataAddBag: any;
  handleConfirmDonation: (id: string) => void;
  handleAddBag: any;
  handleChangeTab: (value: string[]) => void;
  selectedTab: string[];
  arrIndexShowmore: number[];
  handleShowMore: (index: number) => void;
  handleUpdatePaymentStatus: any;
  reload: () => void;
}

const OrderList = (props: OrderListProps) => {
  const {
    className,
    userDonations,
    loading,
    handlePostDonationShipment,
    handleConfirmDonation,
    handleCancelDonation,
    handleDoneDonation,
    setDataAddBag,
    dataAddBag,
    handleAddBag,
    handleChangeTab,
    selectedTab,
    arrIndexShowmore,
    handleShowMore,
    handleUpdatePaymentStatus,
    reload,
  } = props;

  const [open, setOpen] = useState(false);
  const [isOpenUpdatePayment, setIsOpenUpdatePayment] = useState(false);
  const [dataUpdatePayment, setDataUpdatePayment] = useState({});
  const [isOpenUpdateNote, setIsOpenUpdateNote] = useState(false);
  const [dataUpdateNote, setDataUpdateNote] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = (donationId: string, customerNote: string) => {
    setIsOpenUpdateNote(true);
    setDataUpdateNote({
      ...dataUpdateNote,
      donationId: donationId,
      note: customerNote,
    });
  };

  const handleOpen = (donation_id: string) => {
    setOpen(true);
    setDataAddBag({
      ...dataAddBag,
      donation_id: donation_id,
    });
  };
  const handleClose = () => setOpen(false);
  const handleOpenModalUpdatePayment = (
    donation_id: string,
    status: string
  ) => {
    setIsOpenUpdatePayment(true);
    setDataUpdatePayment({
      donationId: donation_id,
      status: status,
    });
  };

  const handleCloseUpdatePayment = () => setIsOpenUpdatePayment(false);
  const parseAddress = (address: any) => {
    const province = PROVINCE.find(
      (p: any) => p.code === address?.provinceID
    ) || { label: "" };

    const district = DISTRICTS.find(
      (d: any) => d.code === address?.districtID
    ) || { name: "" };

    const ward = WARD.find((w: any) => w.code === address?.wardID) || {
      name: "",
    };

    return `${address?.address}, ${ward.name}, ${district.name}, ${province.label}`;
  };

  const copyInfoOrder = (item: any) => {
    const customerAddress = parseAddress(item?.customerAddress);
    const createdAt = dayjs(item.createdAt).format("DD/MM/YYYY");
    const shippingType =
      item?.shippingType === "shipper" ? "Shipper đến lấy" : "Giao tại kho";
    const collectDate = dayjs(item?.collectDate).format("DD/MM/YYYY");
    const deliveryTime = `${item?.collectFromTime?.slice(
      0,
      5
    )} - ${item?.collectToTime?.slice(0, 5)}`;
    const packageType = PACKAGE_TYPE.find(
      (p: any) => p.packageType === item?.packageType
    )?.label;
    const promotion = item?.promotionalPrice?.toLocaleString("vi");
    const shippingPrice = item?.shippingPrice?.toLocaleString("vi");
    const totalPrice = item?.totalPrice?.toLocaleString("vi");

    const shippingTypeAddress = parseAddress(item?.shippingTypeAddress);
    const contentCopy = `
      Mã đơn hàng: ${item.code}
      Tên khách hàng: ${item.customerName}
      Số điện thoại: ${item.customerPhone}
      Email: ${item.customerEmail}
      Địa chỉ: ${customerAddress}
      Ngày đặt hàng: ${createdAt}
      Hình thức vận chuyển: ${shippingType}
      Ngày dự kiến nhận hàng: ${collectDate}
      Thời gian giao hàng: ${deliveryTime}
      Trọng lượng hàng: ${packageType}
      Mã khuyến mãi: ${item?.promo_code}
      Khuyến mãi: ${promotion} VNĐ
      Giá ship: ${shippingPrice} VNĐ
      Tổng tiền: ${totalPrice} VNĐ
      Kho nhận hàng: ${shippingTypeAddress}
    `;
    navigator.clipboard.writeText(contentCopy);
    enqueueSnackbar("Đã sao chép!", { variant: "success" });
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      <div className={styles.orderListWrap}>
        <div className={styles.statusTab}>
          <div className={styles.statusTabItem}>
            {STATUS_TAB.map((item) => {
              return (
                <div
                  onClick={() => handleChangeTab(item.value)}
                  key={item.id}
                  className={clsx(
                    styles.statusTabItemTitle,
                    selectedTab === item.value && styles.active
                  )}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.orderList}>
          <table className={styles.orderListTable}>
            <thead>
              <tr>
                <Fragment>
                  <th></th>
                </Fragment>
                {HEADER_TABLE.map((item) => {
                  return <th key={item.id}>{item.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {userDonations?.length > 0 ? (
                userDonations?.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <tr
                        className={clsx(
                          styles.item,
                          arrIndexShowmore.includes(index) && styles.active
                        )}
                        key={item.id}
                        onClick={() => handleShowMore(index)}
                      >
                        <td className={clsx(styles.iconWrap)}>
                          {arrIndexShowmore.includes(index) ? (
                            <img src="/images/show.svg" alt="" />
                          ) : (
                            <img src="/images/hide.svg" alt="" />
                          )}
                        </td>
                        <td>{item.code}</td>

                        <td>{parseAddress(item?.customerAddress)}</td>

                        <td>
                          {item?.order_bags?.map((bag: any) => {
                            return (
                              <Fragment>
                                <div className={styles.bagId}>{bag.id}</div>
                              </Fragment>
                            );
                          })}
                        </td>

                        <td>
                          {(item.status === "new" ||
                            item.status === "confirm" ||
                            item.status === "processing") && (
                            <Button onClick={() => handleOpen(item.id)}>
                              Thêm túi
                            </Button>
                          )}
                        </td>
                        <td>
                          {item?.donationForms &&
                            item?.donationForms.length > 0 &&
                            item.donationForms.map((item: any, key: any) => {
                              return <p key={key}>{parseDonationForm(item)}</p>;
                            })}
                        </td>
                        <td>
                          {item?.shippingType === "shipper"
                            ? `Shipper đến lấy (${parseShipperType(
                                item?.shipperType
                              )})`
                            : "Giao tại kho"}
                          <div>
                            {item?.shippingType === "shipper" &&
                              item.status !== "new" && (
                                <CreateOrderCode
                                  {...item}
                                  reload={props.reload}
                                />
                              )}
                            {item?.shippingType !== "shipper" &&
                              item.status !== "new" && (
                                <CreateOrderCodeDirect
                                  {...item}
                                  reload={props.reload}
                                />
                              )}
                          </div>
                        </td>
                        <td>
                          {item?.totalPrice?.toLocaleString("vi")}
                        </td>
                        <td>{parseStatus(item.status)}</td>
                        <td>
                          <p>{item.customerNote}</p>
                          <Button
                            style={{ marginTop: "10px" }}
                            onClick={() =>
                              handleOpenModal(item.id, item.customerNote)
                            }
                            variant="contained"
                            size="small"
                          >
                            Cập nhật
                          </Button>
                        </td>
                        <td
                          style={{
                            color: colorPaymentMethod(item.paymentMethod),
                          }}
                        >
                          {paymentMethod(item.paymentMethod)}
                        </td>
                        <td
                          style={{
                            color: colorPaymentStatus(item.paymentStatus),
                          }}
                        >
                          {paymentStatus(item.paymentStatus)}
                          {item.status !== "done" &&
                            item.status !== "cancel" && (
                              <Button
                                style={{ marginTop: "10px" }}
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  handleOpenModalUpdatePayment(
                                    item.id,
                                    item.paymentStatus
                                  )
                                }
                              >
                                Cập nhật
                              </Button>
                            )}
                        </td>
                        <td>
                          {dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td>
                          {dayjs(item.updatedAt).format("DD/MM/YYYY HH:mm")}
                        </td>
                        {item.status !== "cancel" && item.status !== "done" && (
                          <td className={styles.tdAction}>
                            {item.status !== "processing" &&
                              item.status !== "confirm" && (
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => handleConfirmDonation(item.id)}
                                >
                                  Xác nhận
                                </Button>
                              )}

                            {item.status !== "processing" &&
                              item.status !== "new" &&
                              item.status !== "pending" && (
                                <Button
                                  color="secondary"
                                  onClick={() =>
                                    handlePostDonationShipment(item.id)
                                  }
                                >
                                  Cập nhật
                                </Button>
                              )}

                            {item.status !== "new" &&
                              item.status !== "confirm" &&
                              item.status !== "pending" && (
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => handleDoneDonation(item.id)}
                                  size="small"
                                >
                                  Hoàn thành
                                </Button>
                              )}

                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleCancelDonation(item.id)}
                              size="small"
                            >
                              Huỷ
                            </Button>
                          </td>
                        )}
                      </tr>
                      {arrIndexShowmore.includes(index) && (
                        <tr className={styles.itemDetail}>
                          <td
                            colSpan={HEADER_TABLE.length + 1}
                            className={styles.itemDetailWrap}
                          >
                            <table>
                              <thead>
                                <tr>
                                  <th>
                                    Thông tin{" "}
                                    <img
                                      onClick={() => copyInfoOrder(item)}
                                      className={styles.copy}
                                      src="/images/copy.png"
                                      alt="Copy"
                                    />
                                  </th>
                                  <th>Sản phẩm</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td className={styles.itemDetailInfoWrap}>
                                    <div className={styles.itemDetailInfo}>
                                      <p>Tên khách hàng: {item.customerName}</p>

                                      <p>Số điện thoại: {item.customerPhone}</p>

                                      <p>Email: {item?.customerEmail}</p>

                                      <p>
                                        Địa chỉ:{" "}
                                        {parseAddress(item?.customerAddress)}
                                      </p>

                                      <p>
                                        Ngày đặt hàng:{" "}
                                        {dayjs(item.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>

                                      <p>
                                        Hình thức vận chuyển:{" "}
                                        {item?.shippingType === "shipper"
                                          ? "Shipper đến lấy"
                                          : "Giao tại kho"}
                                      </p>

                                      <p>
                                        Ngày dự kiến nhận hàng:{" "}
                                        {dayjs(item?.collectDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>

                                      <p>
                                        Thời gian giao hàng:{" "}
                                        {item?.collectFromTime?.slice(0, 5)} -
                                        {item?.collectToTime?.slice(0, 5)}
                                      </p>

                                      <p>
                                        Trọng lượng hàng:{" "}
                                        {
                                          PACKAGE_TYPE.find(
                                            (p: any) =>
                                              p.packageType ===
                                              item?.packageType
                                          )?.label
                                        }
                                      </p>

                                      <p>Mã khuyến mãi: {item?.promo_code}</p>

                                      <p>
                                        Khuyến mãi:{" "}
                                        {item?.promotionalPrice?.toLocaleString(
                                          "vi"
                                        )}{" "}
                                        VNĐ
                                      </p>
                                      <p>
                                        Phí xử lí:{" "}
                                        {item?.zeroWastePrice?.toLocaleString(
                                          "vi"
                                        )}{" "}
                                        VNĐ
                                      </p>

                                      <p>
                                        Giá ship:{" "}
                                        {item?.shippingPrice?.toLocaleString(
                                          "vi"
                                        )}{" "}
                                        VNĐ
                                      </p>

                                      <p>
                                        Tổng tiền:{" "}
                                        {item?.totalPrice?.toLocaleString("vi")}{" "}
                                        VNĐ
                                      </p>

                                      <p>
                                        Kho nhận hàng:{" "}
                                        {parseAddress(
                                          item?.shippingTypeAddress
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={styles.itemDetailProduct}>
                                      <div
                                        className={styles.itemDetailProductImg}
                                      >
                                        {item.items?.picture?.map((item) => {
                                          return (
                                            <img src={item} alt="" key={item} />
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })
              ) : loading ? (
                <tr>
                  <td className={styles.noneData} colSpan={6}>
                    <div className={styles.loadingWrap}>
                      <Loading />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className={styles.noneData} colSpan={6}>
                    Không có dữ liệu
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ModalAddBag
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
        setDataAddBag={setDataAddBag}
        dataAddBag={dataAddBag}
        handleAddBag={handleAddBag}
      />
      <ModalUpdatePaymentStatus
        open={isOpenUpdatePayment}
        setDataUpdatePayment={setDataUpdatePayment}
        dataUpdatePayment={dataUpdatePayment}
        handleClose={handleCloseUpdatePayment}
        handleUpdatePaymentStatus={handleUpdatePaymentStatus}
      />

      <ModalUpdateNote
        open={isOpenUpdateNote}
        handleClose={() => setIsOpenUpdateNote(false)}
        dataUpdateNote={dataUpdateNote}
        setDataUpdateNote={setDataUpdateNote}
        handleReload={reload}
      />
    </div>
  );
};

export default OrderList;
