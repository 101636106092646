import { useCallback, useEffect, useState } from "react";
import styles from "./Recycle.module.scss";
import clsx from "clsx";
import recycling from "../../apis/recycling";
import RecycleList from "../../components/Recycle/RecycleList/RecycleList";
import { STATUS_TAB } from "../../constants/constant";
import ModalValidateCancel from "../../components/ModalValidateCancel";
import { useSnackbar } from "notistack";
import Filter from "../../components/Recycle/Fillter/Filter";
import useFilterParam from "../../hooks/useFilterParam";
import Pagination from "@mui/material/Pagination";

export interface RecycleProps {
  className?: string;
}

const Recycle = (props: RecycleProps) => {
  const {params} = useFilterParam();
  const [recycles, setRecycles] = useState([]);
  const { className } = props;
  const [reload, setReload] = useState(true);
  const [openValidate, setOpenValidate] = useState(false);
  const handleOpenValidate = () => setOpenValidate(true);
  const handleCloseValidate = () => setOpenValidate(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectTab, setSelectTab] = useState(STATUS_TAB[0].value)
  const [dataCancel, setDataCancel] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const limit = 10;
  
  const handleChangeStatus = (value:any) => {
    setSelectTab(value)
    setPage(1);
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleReload = useCallback(() => {
    setReload((reload) => !reload);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await recycling.getList(
          {
            filter: {
              ...params,
              statuses: selectTab,
            },
            paging: {
              limit: limit,
              offset: (page - 1) * limit,
            },
          }
        );
        if (res.data.code === 0) {
          setRecycles(res?.data?.data?.recycleOrders);
        }
      } catch (error) {}
    })();
  }, [page, params, reload, selectTab]);


  useEffect(() => {
    (async () => {
      const res = await recycling.count(
        {
          filter: {
            ...params,
            statuses: selectTab,
          }
        }
      );
      if (res.data.code === 0) {
        setTotalData(res.data.data.quantity);
      } else {
        enqueueSnackbar(res?.data?.message, { variant: "error" });
      }
    })();
  }, [enqueueSnackbar, params, selectTab]);
  
  const handleCancelRecycle = async (id: string) => {
    if (id) {
      const res = await recycling.cancel(id);
      if (res.data.code === 0) {
        enqueueSnackbar("Hủy đơn thành công", { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    }
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      <h2>Tái chế</h2>
      <Filter setPage={setPage}/>
      <div className={styles.zeroWaste}>
        <div className={styles.orderListWrap}>
          <div className={styles.statusTab}>
            <div className={styles.statusTabItem}>
              {STATUS_TAB.map((item) => {
                return (
                  <div
                    onClick={() => handleChangeStatus(item.value)}
                    key={item.id}
                    className={clsx(styles.statusTabItemTitle, 
                      selectTab === item.value && styles.active  
                      )
                    }
                    
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <RecycleList setDataCancel={setDataCancel} onOpenModalCancel={handleOpenValidate} onReload={handleReload} recycles={recycles} />
        <ModalValidateCancel
            open={openValidate}
            handleClose={handleCloseValidate}
            handleOpen={handleOpenValidate}
            handleCancelDonation={handleCancelRecycle}
            idOrderCancel={dataCancel}
          />
      </div>
      <div className={styles.pagination}>
            <Pagination
              count={Math.ceil(totalData / limit)}
              page={page}
              onChange={changePage}
              variant="outlined"
              color="primary"
              size="large"
            />
      </div>
    </div>
  );
};

export default Recycle;
