import { useEffect, useState } from "react";
import styles from "./CustomerChart.module.scss";
import Chart from "react-apexcharts";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

type CustomerChartProps = {
  data: any;
  dataInfo: any;
};

type DataChartType = {
  options: any;
  series: number[];
  labels: string[];
};

type Province = {
  provinceId: string;
  provinceName: string;
  districts: {
    districtId: string;
    districtName: string;
    customerNumber: number;
  }[];
};

const CustomerChart = (props: CustomerChartProps) => {
  const { data, dataInfo } = props;
  const [dataChart, setDataChart] = useState<DataChartType>();
  const [list, setList] = useState<Province[]>([]);
  const [listShowDistrict, setListShowDistrict] = useState<number[]>([]);
  

  useEffect(() => {
    if (dataInfo) {
      setDataChart({
        series: [
          dataInfo?.donationCustomerQuantity,
          dataInfo?.zeroWasteCustomerQuantity,
          dataInfo?.recycleCustomerQuantity,
        ],
        labels: ["Cho tặng", "Xử lí không phát thải", "Tái chế"],
        options: {
          labels: [
            `Cho tặng(${dataInfo?.donationCustomerQuantity})`,
            `Xử lí không phát thải(${dataInfo?.zeroWasteCustomerQuantity})`,
            `Tái chế(${dataInfo?.recycleCustomerQuantity})`,
          ],
        },
      });
    }
  }, [dataInfo]);

  useEffect(() => {
    let newList: Province[] = [];

    data &&
      data.length > 0 &&
      data.forEach((element: any) => {
        const existingProvince = newList.find(
          (item) => item.provinceId === element.provinceId
        );

        if (existingProvince) {
          existingProvince.districts.push({
            districtId: element.districtId,
            districtName: element.districtName,
            customerNumber: element.customerNumber,
          });
        } else {
          newList.push({
            provinceId: element.provinceId,
            provinceName: element.provinceName,
            districts: [
              {
                districtId: element.districtId,
                districtName: element.districtName,
                customerNumber: element.customerNumber,
              },
            ],
          });
        }
      });

    setList(newList);
  }, [data]);

  const handleShowHideDistrict = (index: number) => {
    setListShowDistrict((listPrev) =>
      listPrev.includes(index)
        ? listPrev.filter((item) => item !== index)
        : [...listPrev, index]
    );
  };

  return (
    <div className={styles.chart}>
      <h4>Khách hàng</h4>
      <div id="chart">
        {dataChart && (
          <Chart
            options={dataChart.options}
            series={dataChart.series}
            labels={dataChart.labels}
            type="pie"
            width="400"
          />
        )}
        <h5>Tổng: {dataInfo?.customerQuantity}</h5>
      </div>
      <table style={{ width: "100%" }}>
        <tr>
          <th>Tỉnh/Thành phố</th>
          <th>Quận/Huyện</th>
          <th>Số lượng khách hàng</th>
        </tr>
        {list &&
          list.length > 0 &&
          list.map((item: any, index: any) => (
            <tr key={index}>
              <td className={styles.province}>
                <div className={styles.icon}>
                    <span onClick={() => handleShowHideDistrict(index)}>
                      {
                        listShowDistrict.includes(index) ? <HorizontalRuleIcon className={styles.add} /> : <AddIcon className={styles.add} /> 
                      }   
                    </span>
                </div>
                <div>{item.provinceName}</div>
              </td>
              <td>
                <tr><b>Tổng đơn:</b></tr>
                {
                  listShowDistrict.includes(index) &&
                  item?.districts &&
                  item.districts.length > 0 &&
                  item?.districts.map((element: any) => (
                    <tr className={styles.districtName} key={element.districtId}>{element.districtName}</tr>
                  ))
                }
              </td>
              <td>
                <tr><b>
                {item.districts && item.districts.length > 0 && 
                  item?.districts?.reduce((sum: number, element: any) => {
                    return sum + element.customerNumber
                  },0)
                }
                </b>
                </tr>
                {
                  listShowDistrict.includes(index) &&
                  item?.districts &&
                  item.districts.length > 0 &&
                  item?.districts.map((element: any) => (
                    <tr className={styles.customerNumber} key={element.districtId}>{element.customerNumber}</tr>
                  ))
                }
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default CustomerChart;
