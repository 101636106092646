import React, { useState, useEffect } from "react";
import clsx from "clsx";

import styles from "./ShippingFee.module.scss";
import ShippingFeeList from "../../components/ShippingFee/ShippingFeeList";
import AddShippingFee from "../../components/ShippingFee/AddShippingFee";
import { useNavigate } from "react-router-dom";

export interface ShippingFeeProps {
  className?: string;
}

const ShippingFee = (props: ShippingFeeProps) => {
  const { className } = props;
  const [dataSubmit, setDataSubmit] = useState<any>([]);
  const [dataWarehouse, setDataWarehouse] = useState<any>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const dataWarehouseInlocal = localStorage.getItem("warehouseData");
    if (dataWarehouseInlocal) {
      const dataWarehouse = JSON.parse(dataWarehouseInlocal);
      setDataWarehouse(dataWarehouse);
    } else {
      navigate("/select-warehouse");
    }
  }, [navigate]);

  const handleSwitch = () => {
    localStorage.removeItem("warehouseData");
    navigate("/select-warehouse");
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      <h3 className={styles.title}>Cấu hình giá ship</h3>

      <div className={styles.subTitle}>
        <div className={styles.subTitleLeft}>
          <p className={styles.subTitleLeftText}>
            Kho {dataWarehouse?.name} - {dataWarehouse?.address?.address}
          </p>
        </div>
        <div className={styles.subTitleRight}>
          <button className={styles.subTitleRightBtn} onClick={handleSwitch}>
            Chọn kho khác
          </button>
        </div>
      </div>

      <AddShippingFee dataSubmit={dataSubmit} setDataSubmit={setDataSubmit} />
      <ShippingFeeList dataSubmit={dataSubmit} setDataSubmit={setDataSubmit} />
    </div>
  );
};

export default ShippingFee;
