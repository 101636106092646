import { useNavigate } from "react-router-dom";
import donationApi from "../../../apis/donationApi";
import styles from "./District.module.scss";

import { useEffect, useState } from "react";

interface DistrictProps {
  district: any;
  handleChangeDistrict : (districtID:string) => void;
  reload: boolean
}

const District = (props: DistrictProps) => {
  const [quantityOrder, setQuantityOrder] = useState();
  const navigate = useNavigate();
  const initPayload = {
    filter: {
      provinceId: "202",
      shippingTypeId: "11123",
      statuses: ["new", "confirm"],
      districtId: props.district.districtId,
    },
  };

  const handleLogout = () => {
    localStorage.removeItem("tokenRESHARE");
    localStorage.removeItem("tokenHYDRA");
    localStorage.removeItem("partnerData");
    localStorage.removeItem("warehouseData");
    navigate("/login");
  };
  useEffect(() => {
    (async () => {
      try {
        const res = await donationApi.countOrder(initPayload);
        if (res.data.code === 0) {
          setQuantityOrder(res.data.data.quantity);
        } else {
          handleLogout();
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.reload]);

  const handleDistrict = () => {
    props.handleChangeDistrict(props.district.districtId)
  }
  return (
    <div className={styles.districtIt} onClick={handleDistrict}>
      {quantityOrder && quantityOrder > 0 && (
        <p>
          {props.district["districtName"]} ({quantityOrder} đơn)
        </p>
      )}
    </div>
  );
};

export default District;
