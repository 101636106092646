import {
  Button,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import styles from "./CreateOrderCode.module.scss"
import { useEffect, useState, useMemo } from "react";
import AddOrderCode from "./AddOrderCode";
import donationApi from "../../apis/donationApi";
import { processesStatus } from "../../constants/constant";
import ConfirmCancelDelivery from "./ConfirmCancelDelivery";
import { useSnackbar } from "notistack";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    height: 650,
    overflow: "scroll"
  };
export interface CreateOrderCodeProps {
  id: string,
  deliveryId?: string,
  reload: () => void,
  status: string
}

const CreateOrderCode = (props: CreateOrderCodeProps) => {
  
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const {id, deliveryId, status} = props;
  const [processes, setProcesses] = useState([]);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [deliveryTrackingCode, setDeliveryTrackingCode] = useState('');
  const {enqueueSnackbar} = useSnackbar()

  useEffect(()=> {
    (async () => {
      if(deliveryId) {
        const resp = await donationApi.getLogs({
          donationId: id
        })
        if (resp?.data?.code === 0) {
          setProcesses(resp?.data?.data?.data)
        }
      } else {
        setProcesses([])
      }
    })();
  },[deliveryId])
  
  const showAddModal = () => {
    setOpenAddModal(true);
  };
  
  const showCancelModel = () => {
    setIsCancelOpen(true)
  }
  useEffect(()=> {
    (async () => {
      try {
        if (deliveryId) {
          const resp = await donationApi.getInfoDelivery({deliveryId: deliveryId});
          if (resp?.data.code === 0) {
            setDeliveryTrackingCode(resp?.data?.data?.deliveryTrackingCode);
          }
          else{
            setDeliveryTrackingCode("");  
          }
        }
      } catch (error) {
        setDeliveryTrackingCode("");  
        enqueueSnackbar("Có lỗi xảy ra", { variant: "error" });
      }
    })()
  },[deliveryId, enqueueSnackbar])


  const canCreate = useMemo(() => {
    if (!deliveryId) {
      return true
    }
    if(processes?.length > 0) {
      if(processes[processes.length - 1]['status'] === "returned") {
        return true
      }
      if(processes[processes.length - 1]['status']  === "cancelled") {
        return true
      }
      return false
    }
  }, [processes, deliveryId])
  return (
    <div>
      {
        processes?.length > 0  && deliveryId &&
        <>
        <p className={styles.processes}>({processesStatus(processes[processes.length - 1]['status'])})</p> 
        </>
      }
      {
        processes?.length > 0 && deliveryTrackingCode &&
        <p>{deliveryTrackingCode}</p> 
      }
      {
        !deliveryId && canCreate && status !== "cancel" &&
        <Button onClick={showAddModal} size="small" variant="contained">
        Tạo mã
        </Button>
      }
      {
        deliveryId && 
        <Button style={{ backgroundColor: "red" }} onClick={showCancelModel} size="small" variant="contained">
         Hủy đơn vận chuyển
        </Button>
      }
      {
        isCancelOpen && 
        <Modal
        open={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  sx={style} >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ConfirmCancelDelivery handleReload = {props.reload} handleClose={() => setIsCancelOpen(false)} {...props}/>
          </Typography>
        </Box>
      </Modal> 
      }
      {
        isOpenAddModal &&
        <Modal
        open={isOpenAddModal}
        onClose={() => setOpenAddModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  sx={style} >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <AddOrderCode handleReload = {props.reload} handelClose = {() => setOpenAddModal(false)} {...props}/>
          </Typography>
        </Box>
      </Modal> 
      }
     
    </div>
  );
};

export default CreateOrderCode;
