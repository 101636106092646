import RecycleItem from "./RecycleItem/RecycleItem";
import styles from "./RecycleList.module.scss";

type RecycleProps = {
  recycles: any,
  onReload: () => void;
  setDataCancel: any;
  onOpenModalCancel: any;
}

const RecycleList = (props: RecycleProps) => {
  const {recycles, onReload, setDataCancel, onOpenModalCancel} = props;
  

  return (
    <div className={styles.recycleList}>
      <table className={styles.list}>
        <thead>
        <tr>
          <th>Mã đơn hàng</th>
          <th>Khách hàng</th>
          <th>Địa chỉ</th>
          <th>Loại chất liệu</th>
          <th>Dịch vụ tái chế</th>
          <th>Cân nặng</th>
          <th>Ngày tạo</th>
          <th>Thời gian cập nhật</th>
          <th>Hành động</th>
        </tr>
        </thead>
        <tbody>
        {
          recycles && recycles.length > 0 &&
          recycles.map((item:any,key: any) => <RecycleItem key={key} onOpenModalCancel={onOpenModalCancel} setDataCancel={setDataCancel} handleReload={onReload} item={item}/>)
        }
        </tbody>
      </table>
    </div>
  );
};

export default RecycleList;
