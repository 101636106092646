import DISTRICTS from "../constants/district";
import PROVINCE from "../constants/province";
import WARD from "../constants/ward";

export const parseAddress = (address: any) => {
    const province = PROVINCE.find(
      (p: any) => p.code === address?.provinceID
    ) || { label: "" };

    const district = DISTRICTS.find(
      (d: any) => d.code === address?.districtID
    ) || { name: "" };

    const ward = WARD.find((w: any) => w.code === address?.wardID) || {
      name: "",
    };

    return `${address?.address}, ${ward.name}, ${district.name}, ${province.label}`;
};