import { Button } from "@mui/material";
import { typeZeroWaste } from "../../../constants/constant";

interface ZeroWasteItemProps {
  item: any;
  onOpen: (id: string, price:number) => void;
}

const ZeroWasteItem = (props: ZeroWasteItemProps) => {
  const { item,onOpen } = props;

  const handleUpdate = (id:string, price:number) => {
    onOpen(id,price);
  }
  return (
    <tr>
      <td>{typeZeroWaste(item.partnerPackageTypesConfigId)}</td>
      <td>{item.price.toLocaleString("vi")} VNĐ</td>
      <td>
        <Button onClick={() => handleUpdate(item.id, item.price)} variant="contained" size="small">
          Cập nhật
        </Button>
      </td>
    </tr>
  );
};

export default ZeroWasteItem;
