import { Input, Button } from "@mui/material";
import styles from "./CreateOrderCode.module.scss";
import CustomInput from "../CustomInput/CustomInput";
import { useState, useEffect } from "react";
import donationApi from "../../apis/donationApi";
import AddressSelect from "../AddressSelect/AddressSelect";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useSnackbar } from "notistack";
export interface AddOrderCodeProps {
  handelClose: () => void;
  id?: string;
  handleReload: () => void;
}

export const initState: any = {
  weight: 0,
  totalFee: 0,
};

const AddOrderCode = (props: AddOrderCodeProps) => {
  const { handelClose, id } = props;
  const [state, setState] = useState(initState);
  const [canCreate, setCanCreate] = useState(false);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const {enqueueSnackbar} = useSnackbar()
  

  const handleChangeInput = (e: any) => {
    let name = e.name;
    let value = e.value;
    setState((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangeSelect = (e: any) => {
    let name = e.name;
    let value = e.value;
    setState((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    (async () => {
      const resp = await donationApi.getDeliveryLocation({ donationId: id });
      if (resp?.data?.code === 0) {
        const data = resp?.data?.data;
        setState((prev: any) => {
          return {
            ...prev,
            weight: data.weight/1000,
            totalFee: data.totalFee,
            donationId: data.donationId,
            addressFrom: data.from.address,
            addressTo: data.to.address,
            nameFrom: data.from.name,
            nameTo: data.to.name,
            phoneFrom: data.from.phone,
            phoneTo: data.to.phone,
            districtCodeFrom: data.from.districtCode,
            districtCodeTo: data.to.districtCode,
            provinceCodeFrom: data.from.provinceCode,
            provinceCodeTo: data.to.provinceCode,
            wardCodeFrom: data.from.wardCode,
            wardCodeTo: data.to.wardCode,
          };
        });
      }
    })();
  }, [id, canCreate]);
  
  const handleClickYes = () => {
    if (!canCreate) {
      if (Number(state.weight) <= 0) {
        setError(true);
        return;
      }
      const data = {
        donationId: state.donationId,
        from: {
          address: state.addressFrom,
          districtCode: state.districtCodeFrom,
          name: state.nameFrom,
          phone: state.phoneFrom,
          provinceCode: state.provinceCodeFrom,
          wardCode: state.wardCodeFrom,
        },
        to: {
          address: state.addressTo,
          districtCode: state.districtCodeTo,
          name: state.nameTo,
          phone: state.phoneTo,
          provinceCode: state.provinceCodeTo,
          wardCode: state.wardCodeTo,
        },
        totalFee: +state.totalFee,
        weight: +state.weight*1000,
      };
      try {
        (async () => {
          const res = await donationApi.updateDeliveryInfo(data);
          if(res?.data.code === 0){
            setCanCreate(true);
          }
          else{
            enqueueSnackbar(res.data.message);
          }
        })();
      } catch (error) {
        console.log(error); 
      }
    } else {
      if(!state.service) { 
        setError(true)
        return
      }
      try {
        (async () => {
          const resp = await donationApi.createDelivery({
            donationId: id,
            note: state.note,
            service: state.service,
            totalFee: state.totalFee
          });
          if (resp?.data.code === 0) {
            enqueueSnackbar("Tạo mã vận chuyển thành công", { variant: "success" });
            props.handleReload()
            handelClose();
          } else {
            enqueueSnackbar(resp.data.message, { variant: "error" });
            return;
          }
        })();
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (!canCreate) return;
    (async () => {
      const resp = await donationApi.estimateFee({ donationId: id });
      if (resp?.data?.code === 0) {
        const data = resp.data.data.data;
        if (data && data.length > 0) {
          const list = data.map((item: any) => ({
            label: `${item.name}(${item.fee.total}đ)`,
            value: item.code,
          }));
          setOptions(list);
        } else {
          setOptions([]);
        }
      } else {
        enqueueSnackbar("Số điện thoại không hợp lệ",{variant : "error"});
      }
    })();
  }, [id, canCreate]);

  

  return (
    <div className={styles.addOrder}>
      <div className={styles.title}>
        {canCreate ? "Tạo mã vận đơn" : "Thông tin đơn hàng"}
      </div>
      {canCreate ? (
        <>
          <CustomSelect
            onChange={handleChangeSelect}
            options={options}
            name="service"
            value={state.service}
            error={error}
          />
          <CustomInput
            onChange={handleChangeInput}
            value={state.note}
            name="note"
            type="text"
            label="Ghi chú"
          />
        </>
      ) : (
        <>
          <div className={styles.info}>
            <CustomInput
              onChange={handleChangeInput}
              value={state.weight}
              name="weight"
              type="number"
              label="Nhập khối lượng đơn hàng(kg)"
              error={error}
            />
            <CustomInput
              onChange={handleChangeInput}
              value={state.totalFee}
              name="totalFee"
              type="number"
              label="Tổng giá"
            />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Thông tin người gửi</div>
            <CustomInput
              onChange={handleChangeInput}
              name="nameFrom"
              value={state.nameFrom}
              type="text"
              label="Tên người gửi"
            />
            <CustomInput
              onChange={handleChangeInput}
              name="phoneFrom"
              value={state.phoneFrom}
              type="text"
              label="Số điện thoại người gửi"
            />
            <CustomInput
              onChange={handleChangeInput}
              name="addressFrom"
              value={state.addressFrom}
              type="text"
              label="Địa chỉ người gửi"
            />
            <AddressSelect
              label="Thành phố"
              apiMethod="getProvinces"
              code=" "
              onChange={handleChangeSelect}
              keyLabel="name"
              keyValue="id"
              name="provinceCodeFrom"
              value={state.provinceCodeFrom}
            />
            <AddressSelect
              label="Huyện/Quận"
              apiMethod="getDistricts"
              code={state.provinceCodeFrom}
              onChange={handleChangeSelect}
              keyLabel="districtName"
              keyValue="districtId"
              name="districtCodeFrom"
              value={state.districtCodeFrom}
            />
            <AddressSelect
              label="Xã/Phường"
              apiMethod="getWards"
              code={state.districtCodeFrom}
              onChange={handleChangeSelect}
              keyLabel="wardName"
              keyValue="wardId"
              name="wardCodeFrom"
              value={state.wardCodeFrom}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Thông tin người nhận</div>
            <CustomInput
              onChange={handleChangeInput}
              name="nameTo"
              value={state.nameTo}
              type="text"
              label="Tên người nhận"
            />
            <CustomInput
              onChange={handleChangeInput}
              name="phoneTo"
              value={state.phoneTo}
              type="text"
              label="Số điện thoại người nhận"
            />
            <CustomInput
              onChange={handleChangeInput}
              name="addressTo"
              value={state.addressTo}
              type="text"
              label="Địa chỉ người nhận"
            />
            <AddressSelect
              label="Thành phố"
              apiMethod="getProvinces"
              code=" "
              keyLabel="name"
              keyValue="id"
              onChange={handleChangeSelect}
              name="provinceCodeTo"
              value={state.provinceCodeTo}
            />
            <AddressSelect
              label="Huyện/Quận"
              apiMethod="getDistricts"
              code={state.provinceCodeTo}
              onChange={handleChangeSelect}
              keyLabel="districtName"
              keyValue="districtId"
              name="districtCodeTo"
              value={state.districtCodeTo}
            />
            <AddressSelect
              label="Xã/Phường"
              apiMethod="getWards"
              code={state.districtCodeTo}
              onChange={handleChangeSelect}
              keyLabel="wardName"
              keyValue="wardId"
              name="wardCodeTo"
              value={state.wardCodeTo}
            />
          </div>
        </>
      )}
      <div className={styles.btn}>
        <Button
          className={styles.btnAccept}
          onClick={handleClickYes}
          variant="contained"
          size="small"
        >
          Xác nhận
        </Button>
        <Button variant="outlined" onClick={handelClose} size="small">
          Thoát
        </Button>
      </div>
    </div>
  );
};

export default AddOrderCode;
