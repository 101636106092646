import styles from "./ModalValidateCancel.module.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface ModalValidateCancelProps {
  className?: string;
  handleOpen: () => void;
  open: boolean;
  handleClose: () => void;
  handleCancelDonation: (id: string) => void;
  idOrderCancel: string;
}

const ModalValidateCancel = (props: ModalValidateCancelProps) => {
  const { handleClose, open, handleCancelDonation, idOrderCancel } = props;

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = () => {
    handleCancelDonation(idOrderCancel);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Xác nhận hủy đơn hàng này?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Bạn có chắc chắn muốn hủy đơn hàng này?
          </Typography>
          <div className={styles.buttons}>
            <Button onClick={handleCancel} variant="outlined" color="error">
              Huỷ bỏ
            </Button>
            <Button onClick={handleConfirm} variant="contained" color="primary">
              Xác nhận huỷ đơn
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalValidateCancel;
