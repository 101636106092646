import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export interface DatePickerProps {
  className?: string;
  setValue: (value: Dayjs | null) => void;
  value: Dayjs | null;
  onChange?: (newValue: Dayjs | null) => void;
  label?:string;
}

const DatePickerCustom = (props: DatePickerProps) => {
  const { setValue, value, onChange, label } = props;
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        inputFormat="DD/MM/YYYY"
        onChange={(newValue) => {
          setValue(newValue);
          onChange && onChange(newValue);
        }}
        renderInput={(params) => <TextField size="small" {...params} />}
      />
    </LocalizationProvider>
  );
};

export default DatePickerCustom;
