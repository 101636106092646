let ENV_API_URL =
  process.env.REACT_APP_INTERNAL_API_URL || process.env.REACT_APP_API_AUTH_URL;

let ENV_API_URL_DONATION =
  process.env.REACT_APP_INTERNAL_API_DONATION_URL ||
  process.env.REACT_APP_API_DONATION_URL;

let ENV_INTERNAL_API_URL = process.env.REACT_APP_INTERNAL_API_URL;
let ENV_INTERNAL_API_DONATION_URL =
  process.env.REACT_APP_INTERNAL_API_DONATION_URL;

let ENV_ENV = process.env.REACT_APP_ENV;

export const API_URL = ENV_API_URL;
export const API_URL_DONATION = ENV_API_URL_DONATION;
export const INTERNAL_API_URL = ENV_INTERNAL_API_URL;
export const INTERNAL_API_DONATION_URL = ENV_INTERNAL_API_DONATION_URL;
export const ENV = ENV_ENV;

console.log("API_URL", API_URL);
console.log("API_URL_DONATION", API_URL_DONATION);
console.log("INTERNAL_API_URL", INTERNAL_API_URL);
console.log("INTERNAL_API_DONATION_URL", INTERNAL_API_DONATION_URL);
console.log("ENV", ENV);

export const getOptimizedImageUrl = (
  url?: string,
  options?: {
    width?: number;
    quality?: number;
    freeSize?: boolean;
  }
) => {
  if (ENV !== "development" && url) {
    const width = options?.width || 300;
    const quality = options?.quality || 75;
    if (options?.freeSize) {
      return `https://reshare.vn/cdn-cgi/image/quality=${quality}/${url}`;
    }
    return `https://reshare.vn/cdn-cgi/image/width=${width},quality=${quality}/${url}`;
  }
  return url || "/images/blur.svg";
  //  No newline at end of file
};
