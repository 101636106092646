import React from "react";
import clsx from "clsx";

import styles from "./ModalAddBag.module.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};
export interface ModalAddBagProps {
  className?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleClose: () => void;
  setDataAddBag: any;
  dataAddBag: any;
  handleAddBag: any;
}

const ModalAddBag = (props: ModalAddBagProps) => {
  const {
    className,
    open,
    handleClose,
    setDataAddBag,
    dataAddBag,
    handleAddBag,
  } = props;

  return (
    <div className={clsx(styles.root, className && className)}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Thêm túi
          </Typography>
          <div className={styles.inputWrap}>
            <TextField
              id="outlined-basic"
              label="Nhập mã túi"
              variant="outlined"
              className={styles.input}
              onChange={(e) =>
                setDataAddBag({
                  ...dataAddBag,
                  id: e.target.value,
                })
              }
            />
          </div>
          <div className={styles.buttonWrap}>
            <Button
              variant="outlined"
              className={styles.button}
              onClick={() => {
                handleAddBag(dataAddBag.id, dataAddBag.donation_id);
                handleClose();
              }}
            >
              Thêm túi ngay
            </Button>

            <Button
              variant="outlined"
              className={styles.button}
              color="error"
              onClick={handleClose}
            >
              Thêm sau
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalAddBag;
