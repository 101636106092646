import axiosAuth from "./axiosAuth";
import axiosDonation from "./axiosDonation";

const loginApi = {
  // loginReshare: (data: any) => {
  //   const url = "/public/auth/login";
  //   return axiosAuth.post(url, data);
  // },

  loginHydra: (data: any) => {
    const url = "/hydra/auth/login-username-password";
    return axiosDonation.post(url, data);
  },

  getCurrentProfile: () => {
    const url = "/hydra/user/get-current-profile";
    return axiosDonation.post(url, null);
  },
};

export default loginApi;
