import { Modal, Box, Typography, FormControl, Button } from "@mui/material"
import { useState } from "react"
import styles from './ModalUpdateFee.module.scss'

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

interface ModalUpdateFeeProps{
    open: boolean;
    handleClose: () => void;
    data: any;
    setData: any;
    handleUpdate: () => void;
}

const ModalUpdateFee = (props:ModalUpdateFeeProps) => {
    const {open,handleClose, data, setData, handleUpdate} = props;


    const onChange = (e:any) => {
        setData({
            ...data,
            price: +e.target.value
        })
    }
    console.log(data);
    
    // const handleClick = () => {
    //     handleUpdate();
    // }

    return(
        <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className={styles.title}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Cập nhật mức giá xử lí
          </Typography>
          <FormControl
            className={styles.form}
            sx={{ minWidth: 120 }}
            size="small"
          >
            <h5>Mức giá</h5>
            <input type="number" onChange={onChange} value={data.price}/>
          </FormControl>
          <div className={styles.button}>
            <Button variant="contained" size="small" onClick={handleUpdate}>
              Cập nhật
            </Button>
            <Button
              onClick={handleClose}
              className={styles.btnCancel}
              variant="outlined"
              size="small"
            >
              Thoát
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
    )
}

export default ModalUpdateFee