import { useEffect, useState } from "react";
import ZeroWasteList from "../../components/ZeroWaste/ZeroWasteList";
import styles from "./ZeroWasteFee.module.scss";
import clsx from "clsx";
import donationApi from "../../apis/donationApi";
import { useSnackbar } from "notistack";

export interface ZeroWasteFeeProps {
  className?: string;
}
const initPayload = {
  filter: {
    ids: [
    ],
    packageTypeId: "",
    partnerId: "",
  },
  paging: {
    limit: 0,
    offset: 0,
  },
};
const ZeroWasteFee = (props: ZeroWasteFeeProps) => {
  const { className } = props;
  const [payload, setPayload] = useState(initPayload);
  const [zeroWasteList, setZeroWasteList] = useState();
  const {enqueueSnackbar} = useSnackbar();
  const [reload, setReload] = useState(false)

  useEffect(() => {
    (async () => {
      const res = await donationApi.searchZeroWaste(payload);
      if(res.data.code === 0){
        setZeroWasteList(res.data.data.data)
      } 
      else{
        enqueueSnackbar("Lỗi hệ thống", { variant: "error" });
      }
    })();
  },[payload, reload]);
  
  const handleReload = () => {
    setReload(reload => !reload)
  }

  return (
    <div className={clsx(styles.root, className && className)}>
      <h2>Cấu hình phí xử lí không phát thải</h2>
      <div className={styles.zeroWaste}>
        <ZeroWasteList handleReload = {handleReload}  zeroWasteList = {zeroWasteList}/>
      </div>
    </div>
  );
};

export default ZeroWasteFee;
