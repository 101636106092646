import { Box, Button, FormControl, Modal, Typography } from "@mui/material";
import styles from "./ModalUpdateNote.module.scss";
import { useEffect, useState } from "react";
import donationApi from "../../../apis/donationApi";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface ModalUpdateNoteProps {
  open: boolean;
  handleClose: () => void;
  dataUpdateNote: any;
  setDataUpdateNote: any;
  handleReload: () => void
}

const ModalUpdateNote = (props: ModalUpdateNoteProps) => {
  const { open, handleClose, dataUpdateNote, setDataUpdateNote, handleReload } = props;
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (e: any) => {
    const note = e.target.value;
    setDataUpdateNote({
      ...dataUpdateNote,
      note: note,
    });
  };

  const handleUpdateNote = () => {
    (async () => {
      const res = await donationApi.updateNoteDonation(dataUpdateNote);
      if (res.data.code === 0) {
        handleClose();
        enqueueSnackbar("Cập nhật thành công", { variant: "success" });
        handleReload();
      }
      else{
        handleClose();
        enqueueSnackbar("Có lỗi xảy ra", { variant: "error" });
      }
    })();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className={styles.title}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Cập nhật ghi chú
          </Typography>
          <FormControl
            className={styles.form}
            sx={{ minWidth: 120 }}
            size="small"
          >
            <h5>Nội dung ghi chú</h5>
            <textarea
              className={styles.note}
              onChange={handleChange}
              rows={5}
              cols={50}
              value={dataUpdateNote.note}
            />
          </FormControl>
          <div className={styles.button}>
            <Button onClick={handleUpdateNote} variant="contained" size="small">
              Cập nhật
            </Button>
            <Button
              onClick={handleClose}
              className={styles.btnCancel}
              variant="outlined"
              size="small"
            >
              Thoát
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalUpdateNote;
