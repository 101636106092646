import clsx from "clsx";
import styles from "./SideBar.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StorageService from "../../services/StorageService";

export interface SideBarProps {
  className?: string;
}

const SideBar = (props: SideBarProps) => {
  const { className } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    StorageService.token = ""
    navigate("/login");
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      <div className={styles.SidebarWrap}>
        <div className={styles.Sidebar}>
          <div className={styles.SidebarHeader}>
            <div className={styles.SidebarHeaderLogo}>
              <img src="/images/logo.svg" alt="logo" />
            </div>
            <div className={styles.SidebarHeaderTitle}>Admin for Donation</div>
          </div>
          <div className={styles.SidebarMenu}>
            <Link
              className={clsx(
                styles.SidebarMenuItem,
                location.pathname === "/" && styles.active
              )}
              to="/"
            >
              <div className={styles.SidebarMenuItemIcon}>
                <img src="/images/home-button-for-interface.svg" alt="home" />
              </div>
              <div className={styles.SidebarMenuItemText}>Trang chủ</div>
            </Link>

            <Link
              className={clsx(
                styles.SidebarMenuItem,
                location.pathname === "/orders" && styles.active
              )}
              to="/orders"
            >
              <div className={clsx(styles.SidebarMenuItemIcon)}>
                <img style={{backgroundColor: "#fffffff"}} src="/images/shopping-list.svg" alt="home" />
              </div>
              <div className={clsx(styles.SidebarMenuItemText)}>
                Đơn hàng cho tặng
              </div>
            </Link>
            {/* <Link
              className={clsx(
                styles.SidebarMenuItem,
                location.pathname === "/recycles" && styles.active
              )}
              to="/recycles"
            >
              <div className={clsx(styles.SidebarMenuItemIcon)}>
                <img src="/images/recycle.png" alt="recycle" />
              </div>
              <div className={clsx(styles.SidebarMenuItemText)}>
                Tái chế
              </div>
            </Link> */}
            
            <Link
              className={clsx(
                styles.SidebarMenuItem,
                location.pathname === "/orders-ship" && styles.active
              )}
              to="/orders-ship"
            >
              <div className={clsx(styles.SidebarMenuItemIcon)}>
                <img src="/images/img_453179.png" alt="home" />
              </div>
              <div className={clsx(styles.SidebarMenuItemText)}>
                Ship hàng
              </div>
            </Link>

            <Link
              className={clsx(
                styles.SidebarMenuItem,
                location.pathname === "/shipping-fee" && styles.active
              )}
              to="/shipping-fee"
            >
              <div className={clsx(styles.SidebarMenuItemIcon)}>
                <img src="/images/box.svg" alt="home" />
              </div>
              <div className={clsx(styles.SidebarMenuItemText)}>
                Cấu hình giá ship
              </div>
            </Link>
            <Link
              className={clsx(
                styles.SidebarMenuItem,
                location.pathname === "/zero-waste-fee" && styles.active
              )}
              to="/zero-waste-fee"
            >
              <div className={clsx(styles.SidebarMenuItemIcon)}>
                <img src="/images/box.svg" alt="home" />
              </div>
              <div className={clsx(styles.SidebarMenuItemText)}>
                Cấu hình giá xử lí
              </div>
            </Link>

            <div className={styles.SidebarMenuItem} onClick={handleLogout}>
              <div className={styles.SidebarMenuItemIcon}>
                <img src="/images/logout.svg" alt="home" />
              </div>
              <div className={styles.SidebarMenuItemText}>Đăng xuất</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
