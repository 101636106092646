import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/Login";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Orders from "./pages/Orders";
import { SnackbarProvider } from "notistack";
import ShippingFee from "./pages/ShippingFee";
import SelectWarehouse from "./pages/SelectWarehouse";
import OrderShip from "./pages/OrderShip/OrderShip";
import ZeroWasteFee from "./pages/ZeroWasteFee";
import Recycle from "./pages/Recycle";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/orders", element: <Orders /> },
      {
        path: "/shipping-fee",
        element: <ShippingFee />,
      },
      { path: "/orders-ship", element: <OrderShip /> },
      { path: "/zero-waste-fee", element: <ZeroWasteFee /> },
      { path: "/recycles", element: <Recycle />, },
    ],
  },

  {
    path: "/select-warehouse",
    element: <SelectWarehouse />,
  },
  {
    path: "/orders-ship",
    element: <OrderShip />,
  },
  {
    path: "/zero-waste-fee",
    element: <ZeroWasteFee />,
  },
  {
    path: "/recycles",
    element: <Recycle />,
  },
]);

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
