const WARD = [
  {
    code: "03071",
    name: "Thị trấn An Lão",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030710",
    name: "Xã Quang Hưng",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030711",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030712",
    name: "Xã Quốc Tuấn",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030713",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030714",
    name: "Xã Tân Viên",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030715",
    name: "Xã Thái Sơn",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030716",
    name: "Xã Trường Thành",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "030717",
    name: "Xã Trường Thọ",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03072",
    name: "Thị trấn Trường Sơn",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03073",
    name: "Xã An Thái",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03074",
    name: "Xã An Thắng",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03075",
    name: "Xã An Thọ",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03076",
    name: "Xã An Tiến",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03077",
    name: "Xã Bát Trang",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03078",
    name: "Xã Chiến Thắng",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "03079",
    name: "Xã Mỹ Đức",
    is_higher_price: false,
    district_code: "1820",
  },
  {
    code: "100101",
    name: "Phường Chi Lăng",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100102",
    name: "Phường Đông Kinh",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100103",
    name: "Phường Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100104",
    name: "Phường Tam Thanh",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100105",
    name: "Phường Vĩnh Trại",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100106",
    name: "Xã Hoàng Đồng",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100107",
    name: "Xã Mai Pha",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100108",
    name: "Xã Quảng Lạc",
    is_higher_price: false,
    district_code: "1642",
  },
  {
    code: "100201",
    name: "Thị trấn Thất Khê",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100202",
    name: "Xã Bắc Ái",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100203",
    name: "Xã Cao Minh",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100204",
    name: "Xã Chi Lăng",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100205",
    name: "Xã Chí Minh",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100206",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100207",
    name: "Xã Đào Viên",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100208",
    name: "Xã Đề Thám",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100209",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100210",
    name: "Xã Đội Cấn",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100211",
    name: "Xã Hùng Sơn",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100212",
    name: "Xã Hùng Việt",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100213",
    name: "Xã Kháng Chiến",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100214",
    name: "Xã Khánh Long",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100215",
    name: "Xã Kim Đồng",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100216",
    name: "Xã Quốc Khánh",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100217",
    name: "Xã Quốc Việt",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100218",
    name: "Xã Tân Minh",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100219",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100220",
    name: "Xã Tân Yên",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100221",
    name: "Xã Tri Phương",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100222",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100223",
    name: "Xã Vĩnh Tiến",
    is_higher_price: false,
    district_code: "2036",
  },
  {
    code: "100301",
    name: "Thị trấn Bình Gia",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100302",
    name: "Xã Bình La",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100303",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100304",
    name: "Xã Hoa Thám",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100305",
    name: "Xã Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100306",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100307",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100308",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100309",
    name: "Xã Minh Khai",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100310",
    name: "Xã Mông Ân",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100311",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100312",
    name: "Xã Quý Hòa",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100313",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100314",
    name: "Xã Tân Văn",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100315",
    name: "Xã Thiện Hòa",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100316",
    name: "Xã Thiện Long",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100317",
    name: "Xã Thiện Thuật",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100318",
    name: "Xã Tô Hiệu",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100319",
    name: "Xã Vĩnh Yên",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100320",
    name: "Xã Yên Lỗ",
    is_higher_price: false,
    district_code: "3138",
  },
  {
    code: "100401",
    name: "Thị trấn Na Sầm",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100402",
    name: "Xã  Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100403",
    name: "Xã An Hùng",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100404",
    name: "Xã Bắc La",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100405",
    name: "Xã Gia Miễn",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100406",
    name: "Xã Hoàng Việt",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100407",
    name: "Xã Hội Hoan",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100408",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100409",
    name: "Xã Nam La",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100410",
    name: "Xã Nhạc Kỳ",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100411",
    name: "Xã Tân Lang",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100412",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100413",
    name: "Xã Tân Tác",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100414",
    name: "Xã Tân Thanh",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100415",
    name: "Xã Tân Việt",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100416",
    name: "Xã Thành Hòa",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100417",
    name: "Xã Thanh Long",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100418",
    name: "Xã Thụy Hùng",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100419",
    name: "Xã Trùng Khánh",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100420",
    name: "Xã Trùng Quán",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "100501",
    name: "Thị trấn Bắc Sơn",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100502",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100503",
    name: "Xã Chiến Thắng",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100504",
    name: "Xã Chiêu Vũ",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100505",
    name: "Xã Đồng ý",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100506",
    name: "Xã Hưng Vũ",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100507",
    name: "Xã Hữu Vĩnh",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100508",
    name: "Xã Long Đống",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100509",
    name: "Xã Nhất Hòa",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100510",
    name: "Xã Nhất Tiến",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100511",
    name: "Xã Quỳnh Sơn",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100512",
    name: "Xã Tân Hương",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100513",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100514",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100515",
    name: "Xã Tân Tri",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100516",
    name: "Xã Trấn Yên",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100517",
    name: "Xã Vạn Thủy",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100518",
    name: "Xã Vũ Lăng",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100519",
    name: "Xã Vũ Lễ",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100520",
    name: "Xã Vũ Sơn",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "100601",
    name: "Thị trấn Văn Quan",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100602",
    name: "Xã Bình Phúc",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100603",
    name: "Xã Chu Túc",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100604",
    name: "Xã Đại An",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100605",
    name: "Xã Đồng Giáp",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100606",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100607",
    name: "Xã Hữu Lễ",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100608",
    name: "Xã Khánh Khê",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100609",
    name: "Xã Lương Năng",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100610",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100611",
    name: "Xã Song Giang",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100612",
    name: "Xã Tân Đoàn",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100613",
    name: "Xã Trấn Ninh",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100614",
    name: "Xã Tràng Các",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100615",
    name: "Xã Tràng Phái",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100616",
    name: "Xã Tràng Sơn",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100617",
    name: "Xã Tri Lễ",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100618",
    name: "Xã Tú Xuyên",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100619",
    name: "Xã Văn An",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100620",
    name: "Xã Vân Mộng",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100621",
    name: "Xã Việt Yên",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100622",
    name: "Xã Vĩnh Lại",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100623",
    name: "Xã Xuân Mai",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100624",
    name: "Xã Yên Phúc",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "100701",
    name: "Thị trấn Cao Lộc",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100702",
    name: "Thị trấn Đồng Đăng",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100703",
    name: "Xã Bảo Lâm",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100704",
    name: "Xã Bình Trung",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100705",
    name: "Xã Cao Lâu",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100706",
    name: "Xã Công Sơn",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100707",
    name: "Xã Gia Cát",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100708",
    name: "Xã Hải Yến",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100709",
    name: "Xã Hòa Cư",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100710",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100711",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100712",
    name: "Xã Lộc Yên",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100713",
    name: "Xã Mẫu Sơn",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100714",
    name: "Xã Phú Xá",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100715",
    name: "Xã Song Giáp",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100716",
    name: "Xã Tân Liên",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100717",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100718",
    name: "Xã Thạch Đạn",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100719",
    name: "Xã Thanh Lòa",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100720",
    name: "Xã Thụy Hùng",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100721",
    name: "Xã Xuân Long",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100722",
    name: "Xã Xuất Lễ",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100723",
    name: "Xã Yên Trạch",
    is_higher_price: false,
    district_code: "1904",
  },
  {
    code: "100801",
    name: "Thị trấn Lộc Bình",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100802",
    name: "Thị trấn Na Dương",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100803",
    name: "Xã Ái Quốc",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100804",
    name: "Xã Bằng Khánh",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100805",
    name: "Xã Đồng Bục",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100806",
    name: "Xã Đông Quan",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100807",
    name: "Xã Hiệp Hạ",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100808",
    name: "Xã Hữu Khánh",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100809",
    name: "Xã Hữu Lân",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100810",
    name: "Xã Khuất Xá",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100811",
    name: "Xã Lợi Bác",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100812",
    name: "Xã Lục Thôn",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100813",
    name: "Xã Mẫu Sơn",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100814",
    name: "Xã Minh Phát",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100815",
    name: "Xã Nam Quan",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100816",
    name: "Xã Như Khuê",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100817",
    name: "Xã Nhượng Bạn",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100818",
    name: "Xã Quan Bản",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100819",
    name: "Xã Sàn Viên",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100820",
    name: "Xã Tam Gia",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100821",
    name: "Xã Tĩnh Bắc",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100822",
    name: "Xã Tú Đoạn",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100823",
    name: "Xã Tú Mịch",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100824",
    name: "Xã Vân Mộng",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100825",
    name: "Xã Xuân Dương",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100826",
    name: "Xã Xuân Lễ",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100827",
    name: "Xã Xuân Mãn",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100828",
    name: "Xã Xuân Tình",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100829",
    name: "Xã Yên Khoái",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "100901",
    name: "Thị trấn Chi Lăng",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100902",
    name: "Thị trấn Đồng Mỏ",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100903",
    name: "Xã Bắc Thủy",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100904",
    name: "Xã Bằng Hữu",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100905",
    name: "Xã Bằng Mạc",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100906",
    name: "Xã Chi Lăng",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100907",
    name: "Xã Chiến Thắng",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100908",
    name: "Xã Gia Lộc",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100909",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100910",
    name: "Xã Hữu Kiên",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100911",
    name: "Xã Lâm Sơn",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100912",
    name: "Xã Liên Sơn",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100913",
    name: "Xã Mai Sao",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100914",
    name: "Xã Nhân Lý",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100915",
    name: "Xã Quan Sơn",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100916",
    name: "Xã Quang Lang",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100917",
    name: "Xã Thượng Cường",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100918",
    name: "Xã Vân An",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100919",
    name: "Xã Vạn Linh",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100920",
    name: "Xã Vân Thủy",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "100921",
    name: "Xã Y Tịch",
    is_higher_price: false,
    district_code: "3156",
  },
  {
    code: "101001",
    name: "Thị trấn Đình Lập",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101002",
    name: "Thị trấn NT Thái Bình",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101003",
    name: "Xã Bắc Lãng",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101004",
    name: "Xã Bắc Xa",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101005",
    name: "Xã Bính Xá",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101006",
    name: "Xã Châu Sơn",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101007",
    name: "Xã Cường Lợi",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101008",
    name: "Xã Đình Lập",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101009",
    name: "Xã Đồng Thắng",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101010",
    name: "Xã Kiên Mộc",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101011",
    name: "Xã Lâm Ca",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101012",
    name: "Xã Thái Bình",
    is_higher_price: false,
    district_code: "3182",
  },
  {
    code: "101101",
    name: "Thị trấn Hữu Lũng",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101102",
    name: "Xã Cai Kinh",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101103",
    name: "Xã Đô Lương",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101104",
    name: "Xã Đồng Tân",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101105",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101106",
    name: "Xã Hồ Sơn",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101107",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101108",
    name: "Xã Hòa Lạc",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101109",
    name: "Xã Hòa Sơn",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101110",
    name: "Xã Hòa Thắng",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101111",
    name: "Xã Hữu Liên",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101112",
    name: "Xã Minh Hòa",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101113",
    name: "Xã Minh Sơn",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101114",
    name: "Xã Minh Tiến",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101115",
    name: "Xã Nhật Tiến",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101116",
    name: "Xã Quyết Thắng",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101117",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101118",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101119",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101120",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101121",
    name: "Xã Thiện Kỵ",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101122",
    name: "Xã Vân Nham",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101123",
    name: "Xã Yên Bình",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101124",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101125",
    name: "Xã Yên Thịnh",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "101126",
    name: "Xã Yên Vượng",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "11001",
    name: "Phường Cổ Nhuế 1",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11002",
    name: "Phường Cổ Nhuế 2",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11003",
    name: "Phường Đông Ngạc",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11004",
    name: "Phường Đức Thắng",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11005",
    name: "Phường Liên Mạc",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11006",
    name: "Phường Minh Khai",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11007",
    name: "Phường Phú Diễn",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11008",
    name: "Phường Phúc Diễn",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11009",
    name: "Phường Tây Tựu",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11010",
    name: "Phường Thượng Cát",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "110101",
    name: "Phường Đức Xuân",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "110102",
    name: "Phường Huyền Tụng",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "110103",
    name: "Phường Nguyễn Thị Minh Khai",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "110104",
    name: "Phường Phùng Chí Kiên",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "110105",
    name: "Phường Sông Cầu",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "110106",
    name: "Phường Xuất Hóa",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "110107",
    name: "Xã Dương Quang",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "110108",
    name: "Xã Nông Thượng",
    is_higher_price: false,
    district_code: "1640",
  },
  {
    code: "11011",
    name: "Phường Thụy Phương",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11012",
    name: "Phường Xuân Đỉnh",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "11013",
    name: "Phường Xuân Tảo",
    is_higher_price: false,
    district_code: "1482",
  },
  {
    code: "110201",
    name: "Thị trấn Bằng Lũng",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110202",
    name: "Xã Bản Thi",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110203",
    name: "Xã Bằng Lãng",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110204",
    name: "Xã Bằng Phúc",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110205",
    name: "Xã Bình Trung",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110206",
    name: "Xã Đại Sảo",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110207",
    name: "Xã Đồng Lạc",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110208",
    name: "Xã Đông Viên",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110209",
    name: "Xã Lương Bằng",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110210",
    name: "Xã Nam Cường",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110211",
    name: "Xã Nghĩa Tá",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110212",
    name: "Xã Ngọc Phái",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110213",
    name: "Xã Phong Huân",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110214",
    name: "Xã Phương Viên",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110215",
    name: "Xã Quảng Bạch",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110216",
    name: "Xã Rã Bản",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110217",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110218",
    name: "Xã Xuân Lạc",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110219",
    name: "Xã Yên Mỹ",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110220",
    name: "Xã Yên Nhuận",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110221",
    name: "Xã Yên Thịnh",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110222",
    name: "Xã Yên Thượng",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "110301",
    name: "Thị trấn Phủ Thông",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110302",
    name: "Xã Cẩm Giàng",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110303",
    name: "Xã Cao Sơn",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110304",
    name: "Xã Đôn Phong",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110305",
    name: "Xã Dương Phong",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110306",
    name: "Xã Hà Vị",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110307",
    name: "Xã Lục Bình",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110308",
    name: "Xã Mỹ Thanh",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110309",
    name: "Xã Nguyên Phúc",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110310",
    name: "Xã Phương Linh",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110311",
    name: "Xã Quân Bình",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110312",
    name: "Xã Quang Thuận",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110313",
    name: "Xã Sĩ Bình",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110314",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110315",
    name: "Xã Tú Trĩ",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110316",
    name: "Xã Vi Hương",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110317",
    name: "Xã Vũ Muộn",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "110401",
    name: "Thị trấn Yến Lạc",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110402",
    name: "Xã Ân Tình",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110403",
    name: "Xã Côn Minh",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110404",
    name: "Xã Cư Lễ",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110405",
    name: "Xã Cường Lợi",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110406",
    name: "Xã Đổng Xá",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110407",
    name: "Xã Dương Sơn",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110408",
    name: "Xã Hảo Nghĩa",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110409",
    name: "Xã Hữu Thác",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110410",
    name: "Xã Kim Hỷ",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110411",
    name: "Xã Kim Lư",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110412",
    name: "Xã Lam Sơn",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110413",
    name: "Xã Lạng San",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110414",
    name: "Xã Liêm Thuỷ",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110415",
    name: "Xã Lương Hạ",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110416",
    name: "Xã Lương Thành",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110417",
    name: "Xã Lương Thượng",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110418",
    name: "Xã Quang Phong",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110419",
    name: "Xã Văn Học",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110420",
    name: "Xã Văn Minh",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110421",
    name: "Xã Vũ Loan",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110422",
    name: "Xã Xuân Dương",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "110501",
    name: "Thị trấn Nà Phặc",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110502",
    name: "Xã Bằng Vân",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110503",
    name: "Xã Cốc Đán",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110504",
    name: "Xã Đức Vân",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110505",
    name: "Xã Hương Nê",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110506",
    name: "Xã Lãng Ngâm",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110507",
    name: "Xã Thuần Mang",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110508",
    name: "Xã Thượng Ân",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110509",
    name: "Xã Thượng Quan",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110510",
    name: "Xã Trung Hoà",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110511",
    name: "Xã Vân Tùng",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "110601",
    name: "Thị trấn Chợ Rã",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110602",
    name: "Xã Bành Trạch",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110603",
    name: "Xã Cao Thượng",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110604",
    name: "Xã Cao Trĩ",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110605",
    name: "Xã Chu Hương",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110606",
    name: "Xã Địa Linh",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110607",
    name: "Xã Đồng Phúc",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110608",
    name: "Xã Hà Hiệu",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110609",
    name: "Xã Hoàng Trĩ",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110610",
    name: "Xã Khang Ninh",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110611",
    name: "Xã Mỹ Phương",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110612",
    name: "Xã Nam Mẫu",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110613",
    name: "Xã Phúc Lộc",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110614",
    name: "Xã Quảng Khê",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110615",
    name: "Xã Thượng Giáo",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "110616",
    name: "Xã Yến Dương",
    is_higher_price: false,
    district_code: "1887",
  },
  {
    code: "11071",
    name: "Thị trấn Chợ Mới",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110710",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110711",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110712",
    name: "Xã Thanh Mai",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110713",
    name: "Xã Thanh Vận",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110714",
    name: "Xã Yên Cư",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110715",
    name: "Xã Yên Đĩnh",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110716",
    name: "Xã Yên Hân",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11072",
    name: "Xã Bình Văn",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11073",
    name: "Xã Cao Kỳ",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11074",
    name: "Xã Hoà Mục",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11075",
    name: "Xã Mai Lạp",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11076",
    name: "Xã Như Cố",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11077",
    name: "Xã Nông Hạ",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11078",
    name: "Xã Nông Thịnh",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "11079",
    name: "Xã Quảng Chu",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "110801",
    name: "Xã An Thắng",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110802",
    name: "Xã Bằng Thành",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110803",
    name: "Xã Bộc Bố",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110804",
    name: "Xã Cao Tân",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110805",
    name: "Xã Cổ Linh",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110806",
    name: "Xã Công Bằng",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110807",
    name: "Xã Giáo Hiệu",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110808",
    name: "Xã Nghiên Loan",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110809",
    name: "Xã Nhạn Môn",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "110810",
    name: "Xã Xuân La",
    is_higher_price: false,
    district_code: "3249",
  },
  {
    code: "120101",
    name: "Phường Cam Giá",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120102",
    name: "Phường Đồng Quang",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120103",
    name: "Phường Gia Sàng",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120104",
    name: "Phường Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120105",
    name: "Phường Hương Sơn",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120106",
    name: "Phường Phan Đình Phùng",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120107",
    name: "Phường Phú Xá",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120108",
    name: "Phường Quán Triều",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120109",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120110",
    name: "Phường Quang Vinh",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120111",
    name: "Phường Tân Lập",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120112",
    name: "Phường Tân Long",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120113",
    name: "Phường Tân Thành",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120114",
    name: "Phường Tân Thịnh",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120115",
    name: "Phường Thịnh Đán",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120116",
    name: "Phường Tích Lương",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120117",
    name: "Phường Trung Thành",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120118",
    name: "Phường Trưng Vương",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120119",
    name: "Phường Túc Duyên",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120120",
    name: "Xã Cao Ngạn",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120121",
    name: "Xã Đồng Bẩm",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120122",
    name: "Xã Phúc Hà",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120123",
    name: "Xã Phúc Trìu",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120124",
    name: "Xã Phúc Xuân",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120125",
    name: "Xã Quyết Thắng",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120126",
    name: "Xã Tân Cương",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120127",
    name: "Xã Thịnh Đức",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "120201",
    name: "Phường Bách Quang",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120202",
    name: "Phường Cải Đan",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120203",
    name: "Phường Lương Châu",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120204",
    name: "Phường Lương Sơn",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120205",
    name: "Phường Mỏ Chè",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120206",
    name: "Phường Phố Cò",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120207",
    name: "Phường Thắng Lợi",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120208",
    name: "Xã Bá Xuyên",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120209",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120210",
    name: "Xã Tân Quang",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120211",
    name: "Xã Vinh Sơn",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "120301",
    name: "Thị trấn Chợ Chu",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120302",
    name: "Xã Bảo Cường",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120303",
    name: "Xã Bảo Linh",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120304",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120305",
    name: "Xã Bình Yên",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120306",
    name: "Xã Bộc Nhiêu",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120307",
    name: "Xã Điềm Mặc",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120308",
    name: "Xã Định Biên",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120309",
    name: "Xã Đồng Thịnh",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120310",
    name: "Xã Kim Phượng",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120311",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120312",
    name: "Xã Lam Vỹ",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120313",
    name: "Xã Linh Thông",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120314",
    name: "Xã Phú Đình",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120315",
    name: "Xã Phú Tiến",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120316",
    name: "Xã Phúc Chu",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120317",
    name: "Xã Phượng Tiến",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120318",
    name: "Xã Quy Kỳ",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120319",
    name: "Xã Sơn Phú",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120320",
    name: "Xã Tân Dương",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120321",
    name: "Xã Tân Thịnh",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120322",
    name: "Xã Thanh Định",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120323",
    name: "Xã Trung Hội",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120324",
    name: "Xã Trung Lương",
    is_higher_price: false,
    district_code: "1924",
  },
  {
    code: "120401",
    name: "Thị trấn Đu",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120402",
    name: "Thị trấn Giang Tiên",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120403",
    name: "Xã Cổ Lũng",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120404",
    name: "Xã Động Đạt",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120405",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120406",
    name: "Xã Ôn Lương",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120407",
    name: "Xã Phấn Mễ",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120408",
    name: "Xã Phú Đô",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120409",
    name: "Xã Phủ Lý",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120410",
    name: "Xã Sơn Cẩm",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120411",
    name: "Xã Tức Tranh",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120412",
    name: "Xã Vô Tranh",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120413",
    name: "Xã Yên Đổ",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120414",
    name: "Xã Yên Lạc",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120415",
    name: "Xã Yên Ninh",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120416",
    name: "Xã Yên Trạch",
    is_higher_price: false,
    district_code: "2195",
  },
  {
    code: "120501",
    name: "Thị trấn Đình Cả",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120502",
    name: "Xã Bình Long",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120503",
    name: "Xã Cúc Đường",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120504",
    name: "Xã Dân Tiến",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120505",
    name: "Xã La Hiên",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120506",
    name: "Xã Lâu Thượng",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120507",
    name: "Xã Liên Minh",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120508",
    name: "Xã Nghinh Tường",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120509",
    name: "Xã Phú Thượng",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120510",
    name: "Xã Phương Giao",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120511",
    name: "Xã Sảng Mộc",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120512",
    name: "Xã Thần Xa",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120513",
    name: "Xã Thượng Nung",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120514",
    name: "Xã Tràng Xá",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120515",
    name: "Xã Vũ Chấn",
    is_higher_price: false,
    district_code: "2051",
  },
  {
    code: "120601",
    name: "Thị trấn Hùng Sơn",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120602",
    name: "Thị trấn Quân Chu",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120603",
    name: "Xã An Khánh",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120604",
    name: "Xã Bản Ngoại",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120605",
    name: "Xã Bình Thuận",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120606",
    name: "Xã Cát Nê",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120607",
    name: "Xã Cù Vân",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120608",
    name: "Xã Đức Lương",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120609",
    name: "Xã Hà Thượng",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120610",
    name: "Xã Hoàng Nông",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120611",
    name: "Xã Khôi Kỳ",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120612",
    name: "Xã Ký Phú",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120613",
    name: "Xã La Bằng",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120614",
    name: "Xã Lục Ba",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120615",
    name: "Xã Minh Tiến",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120616",
    name: "Xã Mỹ Yên",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120617",
    name: "Xã Na Mao",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120618",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120619",
    name: "Xã Phú Lạc",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120620",
    name: "Xã Phú Thịnh",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120621",
    name: "Xã Phú Xuyên",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120622",
    name: "Xã Phục Linh",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120623",
    name: "Xã Phúc Lương",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120624",
    name: "Xã Quân Chu",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120625",
    name: "Xã Tân Linh",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120626",
    name: "Xã Tân Thái",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120627",
    name: "Xã Tiên Hội",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120628",
    name: "Xã Vạn Thọ",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120629",
    name: "Xã Văn Yên",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120630",
    name: "Xã Yên Lãng",
    is_higher_price: false,
    district_code: "1918",
  },
  {
    code: "120701",
    name: "Thị trấn Chùa Hang",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120702",
    name: "Thị trấn Sông Cầu",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120703",
    name: "Thị trấn Trại Cau",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120704",
    name: "Xã Cây Thị",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120705",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120706",
    name: "Xã Hóa Thượng",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120707",
    name: "Xã Hóa Trung",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120708",
    name: "Xã Hợp Tiến",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120709",
    name: "Xã Huống Thượng",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120710",
    name: "Xã Khe Mo",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120711",
    name: "Xã Linh Sơn",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120712",
    name: "Xã Minh Lập",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120713",
    name: "Xã Nam Hòa",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120714",
    name: "Xã Quang Sơn",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120715",
    name: "Xã Tân Lợi",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120716",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120717",
    name: "Xã Văn Hán",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120718",
    name: "Xã Văn Lăng",
    is_higher_price: false,
    district_code: "1731",
  },
  {
    code: "120801",
    name: "Thị trấn Hương Sơn",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120802",
    name: "Xã Bàn Đạt",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120803",
    name: "Xã Bảo Lý",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120804",
    name: "Xã Đào Xá",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120805",
    name: "Xã Điềm Thụy",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120806",
    name: "Xã Đồng Liên",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120807",
    name: "Xã Dương Thành",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120808",
    name: "Xã Hà Châu",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120809",
    name: "Xã Kha Sơn",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120810",
    name: "Xã Lương Phú",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120811",
    name: "Xã Nga My",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120812",
    name: "Xã Nhã Lộng",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120813",
    name: "Xã Tân Đức",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120814",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120815",
    name: "Xã Tân Khánh",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120816",
    name: "Xã Tân Kim",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120817",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120818",
    name: "Xã Thanh Ninh",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120819",
    name: "Xã Thượng Đình",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120820",
    name: "Xã Úc Kỳ",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120821",
    name: "Xã Xuân Phương",
    is_higher_price: false,
    district_code: "1991",
  },
  {
    code: "120901",
    name: "Phường Ba Hàng",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120902",
    name: "Phường Bắc Sơn",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120903",
    name: "Phường Bãi Bông",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120904",
    name: "Phường Đồng Tiến",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120905",
    name: "Xã Đắc Sơn",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120906",
    name: "Xã Đông Cao",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120907",
    name: "Xã Hồng Tiến",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120908",
    name: "Xã Minh Đức",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120909",
    name: "Xã Nam Tiến",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120910",
    name: "Xã Phúc Tân",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120911",
    name: "Xã Phúc Thuận",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120912",
    name: "Xã Tân Hương",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120913",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120914",
    name: "Xã Thành Công",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120915",
    name: "Xã Thuận Thành",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120916",
    name: "Xã Tiên Phong",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120917",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "120918",
    name: "Xã Vạn Phái",
    is_higher_price: false,
    district_code: "1990",
  },
  {
    code: "13001",
    name: "Phường Cầu Diễn",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13002",
    name: "Phường Đại Mỗ",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13003",
    name: "Phường Mễ Trì",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13004",
    name: "Phường Mỹ Đình 1",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13005",
    name: "Phường Mỹ Đình 2",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13006",
    name: "Phường Phú Đô",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13007",
    name: "Phường Phương Canh",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13008",
    name: "Phường Tây Mỗ",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13009",
    name: "Phường Trung Văn",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "13010",
    name: "Phường Xuân Phương",
    is_higher_price: false,
    district_code: "3440",
  },
  {
    code: "130101",
    name: "Phường Đồng Tâm",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130102",
    name: "Phường Hồng Hà",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130103",
    name: "Phường Hợp Minh",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130104",
    name: "Phường Minh Tân",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130105",
    name: "Phường Nam Cường",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130106",
    name: "Phường Nguyễn Phúc",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130107",
    name: "Phường Nguyễn Thái Học",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130108",
    name: "Phường Yên Ninh",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130109",
    name: "Phường Yên Thịnh",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130110",
    name: "Xã Âu Lâu",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130111",
    name: "Xã Giới Phiên",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130112",
    name: "Xã Minh Bảo",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130113",
    name: "Xã Phúc Lộc",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130114",
    name: "Xã Tân Thịnh",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130115",
    name: "Xã Tuy Lộc",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130116",
    name: "Xã Văn Phú",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130117",
    name: "Xã Văn Tiến",
    is_higher_price: false,
    district_code: "1674",
  },
  {
    code: "130201",
    name: "Phường Cầu Thia",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "130202",
    name: "Phường Pú Trạng",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "130203",
    name: "Phường Tân An",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "130204",
    name: "Phường Trung Tâm",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "130205",
    name: "Xã Nghĩa An",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "130206",
    name: "Xã Nghĩa Lợi",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "130207",
    name: "Xã Nghĩa Phúc",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "130301",
    name: "Thị trấn Mậu A",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130302",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130303",
    name: "Xã An Thịnh",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130304",
    name: "Xã Châu Quế Hạ",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130305",
    name: "Xã Châu Quế Thượng",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130306",
    name: "Xã Đại Phác",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130307",
    name: "Xã Đại Sơn",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130308",
    name: "Xã Đông An",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130309",
    name: "Xã Đông Cuông",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130310",
    name: "Xã Hoàng Thắng",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130311",
    name: "Xã Lâm Giang",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130312",
    name: "Xã Lang Thíp",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130313",
    name: "Xã Mậu Đông",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130314",
    name: "Xã Mỏ Vàng",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130315",
    name: "Xã Nà Hẩu",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130316",
    name: "Xã Ngòi A",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130317",
    name: "Xã Phong Dụ Hạ",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130318",
    name: "Xã Phong Dụ Thượng",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130319",
    name: "Xã Quang Minh",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130320",
    name: "Xã Tân Hợp",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130321",
    name: "Xã Viễn Sơn",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130322",
    name: "Xã Xuân Ái",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130323",
    name: "Xã Xuân Tầm",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130324",
    name: "Xã Yên Hợp",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130325",
    name: "Xã Yên Hưng",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130326",
    name: "Xã Yên Phú",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130327",
    name: "Xã Yên Thái",
    is_higher_price: false,
    district_code: "2047",
  },
  {
    code: "130401",
    name: "Thị trấn Thác Bà",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130402",
    name: "Thị trấn Yên Bình",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130403",
    name: "Xã Bạch Hà",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130404",
    name: "Xã Bảo Ái",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130405",
    name: "Xã Cảm Ân",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130406",
    name: "Xã Cảm Nhân",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130407",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130408",
    name: "Xã Đại Minh",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130409",
    name: "Xã Hán Đà",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130410",
    name: "Xã Mông Sơn",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130411",
    name: "Xã Mỹ Gia",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130412",
    name: "Xã Ngọc Chấn",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130413",
    name: "Xã Phú Thịnh",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130414",
    name: "Xã Phúc An",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130415",
    name: "Xã Phúc Ninh",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130416",
    name: "Xã Tân Hương",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130417",
    name: "Xã Tân Nguyên",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130418",
    name: "Xã Thịnh Hưng",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130419",
    name: "Xã Tích Cốc",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130420",
    name: "Xã Văn Lãng",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130421",
    name: "Xã Vĩnh Kiên",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130422",
    name: "Xã Vũ Linh",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130423",
    name: "Xã Xuân Lai",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130424",
    name: "Xã Xuân Long",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130425",
    name: "Xã Yên Bình",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130426",
    name: "Xã Yên Thành",
    is_higher_price: false,
    district_code: "2266",
  },
  {
    code: "130501",
    name: "Thị trấn Mù Căng Chải",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130502",
    name: "Xã Cao Phạ",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130503",
    name: "Xã Chế Cu Nha",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130504",
    name: "Xã Chế Tạo",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130505",
    name: "Xã Dế Su Phình",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130506",
    name: "Xã Hồ Bốn",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130507",
    name: "Xã Khao Mang",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130508",
    name: "Xã Kim Nọi",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130509",
    name: "Xã La Pán Tẩn",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130510",
    name: "Xã Lao Chải",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130511",
    name: "Xã Mồ Dề",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130512",
    name: "Xã Nậm Có",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130513",
    name: "Xã Nậm Khắt",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130514",
    name: "Xã Púng Luông",
    is_higher_price: false,
    district_code: "1977",
  },
  {
    code: "130601",
    name: "Thị trấn NT Liên Sơn",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130602",
    name: "Thị trấn NT Nghĩa Lộ",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130603",
    name: "Thị trấn NT Trần Phú",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130604",
    name: "Xã An Lương",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130605",
    name: "Xã Bình Thuận",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130606",
    name: "Xã Cát Thịnh",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130607",
    name: "Xã Chấn Thịnh",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130608",
    name: "Xã Đại Lịch",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130609",
    name: "Xã Đồng Khê",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130610",
    name: "Xã Gia Hội",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130611",
    name: "Xã Hạnh Sơn",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130612",
    name: "Xã Minh An",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130613",
    name: "Xã Nậm Búng",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130614",
    name: "Xã Nậm Lành",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130615",
    name: "Xã Nậm Mười",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130616",
    name: "Xã Nghĩa Sơn",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130617",
    name: "Xã Nghĩa Tâm",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130618",
    name: "Xã Phù Nham",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130619",
    name: "Xã Phúc Sơn",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130620",
    name: "Xã Sơn A",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130621",
    name: "Xã Sơn Lương",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130622",
    name: "Xã Sơn Thịnh",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130623",
    name: "Xã Sùng Đô",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130624",
    name: "Xã Suối Bu",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130625",
    name: "Xã Suối Giàng",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130626",
    name: "Xã Suối Quyền",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130627",
    name: "Xã Tân Thịnh",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130628",
    name: "Xã Thạch Lương",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130629",
    name: "Xã Thanh Lương",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130630",
    name: "Xã Thượng Bằng La",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130631",
    name: "Xã Tú Lệ",
    is_higher_price: false,
    district_code: "2044",
  },
  {
    code: "130701",
    name: "Thị trấn Cổ Phúc",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130702",
    name: "Xã Báo Đáp",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130703",
    name: "Xã Bảo Hưng",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130704",
    name: "Xã Cường Thịnh",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130705",
    name: "Xã Đào Thịnh",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130706",
    name: "Xã Hòa Cuông",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130707",
    name: "Xã Hồng Ca",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130708",
    name: "Xã Hưng Khánh",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130709",
    name: "Xã Hưng Thịnh",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130710",
    name: "Xã Kiên Thành",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130711",
    name: "Xã Lương Thịnh",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130712",
    name: "Xã Minh Quán",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130713",
    name: "Xã Minh Quân",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130714",
    name: "Xã Minh Tiến",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130715",
    name: "Xã Nga Quán",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130716",
    name: "Xã Quy Mông",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130717",
    name: "Xã Tân Đồng",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130718",
    name: "Xã Vân Hội",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130719",
    name: "Xã Việt Cường",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130720",
    name: "Xã Việt Hồng",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130721",
    name: "Xã Việt Thành",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130722",
    name: "Xã Y Can",
    is_higher_price: false,
    district_code: "2039",
  },
  {
    code: "130801",
    name: "Thị trấn Trạm Tấu",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130802",
    name: "Xã Bản Công",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130803",
    name: "Xã Bản Mù",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130804",
    name: "Xã Hát Lìu",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130805",
    name: "Xã Làng Nhì",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130806",
    name: "Xã Pá Hu",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130807",
    name: "Xã Pá Lau",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130808",
    name: "Xã Phình Hồ",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130809",
    name: "Xã Tà Si Láng",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130810",
    name: "Xã Trạm Tấu",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130811",
    name: "Xã Túc Đán",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130812",
    name: "Xã Xà Hồ",
    is_higher_price: false,
    district_code: "2248",
  },
  {
    code: "130901",
    name: "Thị trấn Yên Thế",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130902",
    name: "Xã An Lạc",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130903",
    name: "Xã An Phú",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130904",
    name: "Xã Động Quan",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130905",
    name: "Xã Khai Trung",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130906",
    name: "Xã Khánh Hoà",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130907",
    name: "Xã Khánh Thiện",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130908",
    name: "Xã Lâm Thượng",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130909",
    name: "Xã Liễu Đô",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130910",
    name: "Xã Mai Sơn",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130911",
    name: "Xã Minh Chuẩn",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130912",
    name: "Xã Minh Tiến",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130913",
    name: "Xã Minh Xuân",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130914",
    name: "Xã Mường Lai",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130915",
    name: "Xã Phan Thanh",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130916",
    name: "Xã Phúc Lợi",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130917",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130918",
    name: "Xã Tân Lĩnh",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130919",
    name: "Xã Tân Phượng",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130920",
    name: "Xã Tô Mậu",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130921",
    name: "Xã Trúc Lâu",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130922",
    name: "Xã Trung Tâm",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130923",
    name: "Xã Vĩnh Lạc",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "130924",
    name: "Xã Yên Thắng",
    is_higher_price: false,
    district_code: "1967",
  },
  {
    code: "140101",
    name: "Phường Chiềng An",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140102",
    name: "Phường Chiềng Cơi",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140103",
    name: "Phường Chiềng Lề",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140104",
    name: "Phường Chiềng Sinh",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140105",
    name: "Phường Quyết Tâm",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140106",
    name: "Phường Quyết Thắng",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140107",
    name: "Phường Tô Hiệu",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140108",
    name: "Xã Chiềng Cọ",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140109",
    name: "Xã Chiềng Đen",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140110",
    name: "Xã Chiềng Ngần",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140111",
    name: "Xã Chiềng Xôm",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140112",
    name: "Xã Hua La",
    is_higher_price: false,
    district_code: "1677",
  },
  {
    code: "140201",
    name: "Xã Cà Nàng",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140202",
    name: "Xã Chiềng Bằng",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140203",
    name: "Xã Chiềng Khay",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140204",
    name: "Xã Chiềng Khoang",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140205",
    name: "Xã Chiềng Ơn",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140206",
    name: "Xã Mường Chiên",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140207",
    name: "Xã Mường Giàng",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140208",
    name: "Xã Mường Giôn",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140209",
    name: "Xã Mường Sại",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140210",
    name: "Xã Nậm ét",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140211",
    name: "Xã Pá Ma Pha Khinh",
    is_higher_price: false,
    district_code: "2204",
  },
  {
    code: "140301",
    name: "Thị trấn Ít Ong",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140302",
    name: "Xã Chiềng Ân",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140303",
    name: "Xã Chiềng Công",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140304",
    name: "Xã Chiềng Hoa",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140305",
    name: "Xã Chiềng Lao",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140306",
    name: "Xã Chiềng Muôn",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140307",
    name: "Xã Chiềng San",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140308",
    name: "Xã Hua Trai",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140309",
    name: "Xã Mường Bú",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140310",
    name: "Xã Mường Chùm",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140311",
    name: "Xã Mường Trai",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140312",
    name: "Xã Nậm Giôn",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140313",
    name: "Xã Nậm Păm",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140314",
    name: "Xã Ngọc Chiến",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140315",
    name: "Xã Pi Toong",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140316",
    name: "Xã Tạ Bú",
    is_higher_price: false,
    district_code: "3230",
  },
  {
    code: "140401",
    name: "Thị trấn Thuận Châu",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140402",
    name: "Xã Bản Lầm",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140403",
    name: "Xã Bó Mười",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140404",
    name: "Xã Bon Phặng",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140405",
    name: "Xã Chiềng Bôm",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140406",
    name: "Xã Chiềng La",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140407",
    name: "Xã Chiềng Ly",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140408",
    name: "Xã Chiềng Ngàm",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140409",
    name: "Xã Chiềng Pấc",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140410",
    name: "Xã Chiềng Pha",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140411",
    name: "Xã Chiềng Sơ",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140412",
    name: "Xã Co Mạ",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140413",
    name: "Xã Co Tòng",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140414",
    name: "Xã é Tòng",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140415",
    name: "Xã Liệp Tè",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140416",
    name: "Xã Long Hẹ",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140417",
    name: "Xã Muội Nọi",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140418",
    name: "Xã Mường Bám",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140419",
    name: "Xã Mường é",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140420",
    name: "Xã Mường Khiêng",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140421",
    name: "Xã Nậm Lầu",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140422",
    name: "Xã Nong Lay",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140423",
    name: "Xã Pá Lông",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140424",
    name: "Xã Phỏng Lái",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140425",
    name: "Xã Phỏng Lập",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140426",
    name: "Xã Púng Tra",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140427",
    name: "Xã Thôn Mòn",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140428",
    name: "Xã Tòng Cọ",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140429",
    name: "Xã Tòng Lệnh",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "140501",
    name: "Thị trấn Bắc Yên",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140502",
    name: "Xã Bắc Ngà",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140503",
    name: "Xã Chiềng Sại",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140504",
    name: "Xã Chim Vàn",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140505",
    name: "Xã Hang Chú",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140506",
    name: "Xã Háng Đồng",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140507",
    name: "Xã Hồng Ngài",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140508",
    name: "Xã Hua Nhàn",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140509",
    name: "Xã Làng Chếu",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140510",
    name: "Xã Mường Khoa",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140511",
    name: "Xã Phiêng Ban",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140512",
    name: "Xã Phiêng Kôn",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140513",
    name: "Xã Song Pe",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140514",
    name: "Xã Tạ Khoa",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140515",
    name: "Xã Tà Xùa",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140516",
    name: "Xã Xín Vàng",
    is_higher_price: false,
    district_code: "2079",
  },
  {
    code: "140601",
    name: "Thị trấn Phù Yên",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140602",
    name: "Xã Bắc Phong",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140603",
    name: "Xã Đá Đỏ",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140604",
    name: "Xã Gia Phù",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140605",
    name: "Xã Huy Bắc",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140606",
    name: "Xã Huy Hạ",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140607",
    name: "Xã Huy Tân",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140608",
    name: "Xã Huy Thượng",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140609",
    name: "Xã Huy Tường",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140610",
    name: "Xã Kim Bon",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140611",
    name: "Xã Mường Bang",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140612",
    name: "Xã Mường Cơi",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140613",
    name: "Xã Mường Do",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140614",
    name: "Xã Mường Lang",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140615",
    name: "Xã Mường Thải",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140616",
    name: "Xã Nam Phong",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140617",
    name: "Xã Quang Huy",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140618",
    name: "Xã Sập Xa",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140619",
    name: "Xã Suối Bau",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140620",
    name: "Xã Suối Tọ",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140621",
    name: "Xã Tân Lang",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140622",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140623",
    name: "Xã Tường Hạ",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140624",
    name: "Xã Tường Phong",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140625",
    name: "Xã Tường Phù",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140626",
    name: "Xã Tường Thượng",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140627",
    name: "Xã Tường Tiến",
    is_higher_price: false,
    district_code: "1996",
  },
  {
    code: "140701",
    name: "Thị trấn Hát Lót",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140702",
    name: "Xã Chiềng Ban",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140703",
    name: "Xã Chiềng Chăn",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140704",
    name: "Xã Chiềng Chung",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140705",
    name: "Xã Chiềng Dong",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140706",
    name: "Xã Chiềng Kheo",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140707",
    name: "Xã Chiềng Lương",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140708",
    name: "Xã Chiềng Mai",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140709",
    name: "Xã Chiềng Mung",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140710",
    name: "Xã Chiềng Nơi",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140711",
    name: "Xã Chiềng Sung",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140712",
    name: "Xã Chiềng Ve",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140713",
    name: "Xã Cò Nòi",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140714",
    name: "Xã Hát Lót",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140715",
    name: "Xã Mường Bằng",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140716",
    name: "Xã Mường Bon",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140717",
    name: "Xã Mương Tranh",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140718",
    name: "Xã Nà Ơt",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140719",
    name: "Xã Nà Pó",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140720",
    name: "Xã Phiêng Cằm",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140721",
    name: "Xã Phiêng Pằn",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140722",
    name: "Xã Tà Hộc",
    is_higher_price: false,
    district_code: "1971",
  },
  {
    code: "140801",
    name: "Thị trấn Yên Châu",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140802",
    name: "Xã Chiềng Đông",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140803",
    name: "Xã Chiềng Hặc",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140804",
    name: "Xã Chiềng Khoi",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140805",
    name: "Xã Chiềng On",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140806",
    name: "Xã Chiềng Pằn",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140807",
    name: "Xã Chiềng Sàng",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140808",
    name: "Xã Chiềng Tương",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140809",
    name: "Xã Lóng Phiêng",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140810",
    name: "Xã Mường Lựm",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140811",
    name: "Xã Phiêng Khoài",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140812",
    name: "Xã Sập Vạt",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140813",
    name: "Xã Tú Nang",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140814",
    name: "Xã Viêng Lán",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140815",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "2267",
  },
  {
    code: "140901",
    name: "Thị trấn Sông Mã",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140902",
    name: "Xã Bó Sinh",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140903",
    name: "Xã Chiềng Cang",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140904",
    name: "Xã Chiềng En",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140905",
    name: "Xã Chiềng Khoong",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140906",
    name: "Xã Chiềng Khương",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140907",
    name: "Xã Chiềng Phung",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140908",
    name: "Xã Chiềng Sơ",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140909",
    name: "Xã Đứa Mòn",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140910",
    name: "Xã Huổi Một",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140911",
    name: "Xã Mường Cai",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140912",
    name: "Xã Mường Hung",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140913",
    name: "Xã Mường Lầm",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140914",
    name: "Xã Mường Sai",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140915",
    name: "Xã Nà Ngựu",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140916",
    name: "Xã Nậm Mằn",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140917",
    name: "Xã Nậm Ty",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140918",
    name: "Xã Pú Pẩu",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "140919",
    name: "Xã Yên Hưng",
    is_higher_price: false,
    district_code: "2007",
  },
  {
    code: "141001",
    name: "Thị trấn Mộc Châu",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141002",
    name: "Thị trấn NT Mộc Châu",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141003",
    name: "Xã Chiềng Hắc",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141004",
    name: "Xã Chiềng Khừa",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141005",
    name: "Xã Chiềng Sơn",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141006",
    name: "Xã Đông Sang",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141007",
    name: "Xã Hua Păng",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141008",
    name: "Xã Lóng Sập",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141009",
    name: "Xã Mường Sang",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141010",
    name: "Xã Nà Mường",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141011",
    name: "Xã Phiêng Luông",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141012",
    name: "Xã Qui Hướng",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141013",
    name: "Xã Tà Lai",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141014",
    name: "Xã Tân Hợp",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141015",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1976",
  },
  {
    code: "141101",
    name: "Xã Dồm Cang",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141102",
    name: "Xã Mường Lạn",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141103",
    name: "Xã Mường Lèo",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141104",
    name: "Xã Mường Và",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141105",
    name: "Xã Nậm Lạnh",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141106",
    name: "Xã Púng Bánh",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141107",
    name: "Xã Sam Kha",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141108",
    name: "Xã Xốp Cộp",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "141201",
    name: "Xã Chiềng Khoa",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141202",
    name: "Xã Chiềng Xuân",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141203",
    name: "Xã Chiềng Yên",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141204",
    name: "Xã Liên Hoà",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141205",
    name: "Xã Lóng Luông",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141206",
    name: "Xã Mường Men",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141207",
    name: "Xã Mường Tè",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141208",
    name: "Xã Quang Minh",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141209",
    name: "Xã Song Khủa",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141210",
    name: "Xã Suối Bàng",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141211",
    name: "Xã Tân Xuân",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141212",
    name: "Xã Tô Múa",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141213",
    name: "Xã Vân Hồ",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "141214",
    name: "Xã Xuân Nha",
    is_higher_price: false,
    district_code: "2255",
  },
  {
    code: "150101",
    name: "Phường Bạch Hạc",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150102",
    name: "Phường Bến Gót",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150103",
    name: "Phường Dữu Lâu",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150104",
    name: "Phường Gia Cẩm",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150105",
    name: "Phường Minh Nông",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150106",
    name: "Phường Minh Phương",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150107",
    name: "Phường Nông Trang",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150108",
    name: "Phường Tân Dân",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150109",
    name: "Phường Thanh Miếu",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150110",
    name: "Phường Thọ Sơn",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150111",
    name: "Phường Tiên Cát",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150112",
    name: "Phường Vân Cơ",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150113",
    name: "Phường Vân Phú",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150114",
    name: "Xã Chu Hóa",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150115",
    name: "Xã Hùng Lô",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150116",
    name: "Xã Hy Cương",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150117",
    name: "Xã Kim Đức",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150118",
    name: "Xã Phượng Lâu",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150119",
    name: "Xã Sông Lô",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150120",
    name: "Xã Tân Đức",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150121",
    name: "Xã Thanh Đình",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150122",
    name: "Xã Thụy Vân",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150123",
    name: "Xã Trưng Vương",
    is_higher_price: false,
    district_code: "1602",
  },
  {
    code: "150201",
    name: "Phường Âu Cơ",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150202",
    name: "Phường Hùng Vương",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150203",
    name: "Phường Phong Châu",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150204",
    name: "Phường Thanh Vinh",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150205",
    name: "Phường Trường Thịnh",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150206",
    name: "Xã Hà Lộc",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150207",
    name: "Xã Hà Thạch",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150208",
    name: "Xã Phú Hộ",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150209",
    name: "Xã Thanh Minh",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150210",
    name: "Xã Văn Lung",
    is_higher_price: false,
    district_code: "2064",
  },
  {
    code: "150301",
    name: "Thị trấn Đoan Hùng",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150302",
    name: "Xã Bằng Doãn",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150303",
    name: "Xã Bằng Luân",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150304",
    name: "Xã Ca Đình",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150305",
    name: "Xã Chân Mộng",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150306",
    name: "Xã Chí Đám",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150307",
    name: "Xã Đại Nghĩa",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150308",
    name: "Xã Đông Khê",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150309",
    name: "Xã Hùng Long",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150310",
    name: "Xã Hùng Quan",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150311",
    name: "Xã Hữu Đô",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150312",
    name: "Xã Minh Lương",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150313",
    name: "Xã Minh Phú",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150314",
    name: "Xã Minh Tiến",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150315",
    name: "Xã Nghinh Xuyên",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150316",
    name: "Xã Ngọc Quan",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150317",
    name: "Xã Phong Phú",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150318",
    name: "Xã Phú Thứ",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150319",
    name: "Xã Phúc Lai",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150320",
    name: "Xã Phương Trung",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150321",
    name: "Xã Quế Lâm",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150322",
    name: "Xã Sóc Đăng",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150323",
    name: "Xã Tây Cốc",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150324",
    name: "Xã Tiêu Sơn",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150325",
    name: "Xã Vân Đồn",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150326",
    name: "Xã Vân Du",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150327",
    name: "Xã Vụ Quang",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150328",
    name: "Xã Yên Kiện",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "150401",
    name: "Thị trấn Thanh Ba",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150402",
    name: "Xã Chí Tiên",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150403",
    name: "Xã Đại An",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150404",
    name: "Xã Đỗ Sơn",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150405",
    name: "Xã Đỗ Xuyên",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150406",
    name: "Xã Đông Lĩnh",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150407",
    name: "Xã Đông Thành",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150408",
    name: "Xã Đồng Xuân",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150409",
    name: "Xã Hanh Cù",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150410",
    name: "Xã Hoàng Cương",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150411",
    name: "Xã Khải Xuân",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150412",
    name: "Xã Lương Lỗ",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150413",
    name: "Xã Mạn Lạn",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150414",
    name: "Xã Năng Yên",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150415",
    name: "Xã Ninh Dân",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150416",
    name: "Xã Phương Lĩnh",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150417",
    name: "Xã Quảng Nạp",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150418",
    name: "Xã Sơn Cương",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150419",
    name: "Xã Thái Ninh",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150420",
    name: "Xã Thanh Hà",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150421",
    name: "Xã Thanh Vân",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150422",
    name: "Xã Thanh Xá",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150423",
    name: "Xã Vân Lĩnh",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150424",
    name: "Xã Võ Lao",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150425",
    name: "Xã Vũ Yển",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150426",
    name: "Xã Yển Khê",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150427",
    name: "Xã Yên Nội",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "150501",
    name: "Thị trấn Hạ Hoà",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150502",
    name: "Xã Ấm Hạ",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150503",
    name: "Xã Bằng Giã",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150504",
    name: "Xã Cáo Điền",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150505",
    name: "Xã Chính Công",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150506",
    name: "Xã Chuế Lưu",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150507",
    name: "Xã Đại Phạm",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150508",
    name: "Xã Đan Hà",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150509",
    name: "Xã Đan Thượng",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150510",
    name: "Xã Động Lâm",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150511",
    name: "Xã Gia Điền",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150512",
    name: "Xã Hà Lương",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150513",
    name: "Xã Hậu Bổng",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150514",
    name: "Xã Hiền Lương",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150515",
    name: "Xã Hương Xạ",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150516",
    name: "Xã Lâm Lợi",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150517",
    name: "Xã Lang Sơn",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150518",
    name: "Xã Lệnh Khanh",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150519",
    name: "Xã Liên Phương",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150520",
    name: "Xã Mai Tùng",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150521",
    name: "Xã Minh Côi",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150522",
    name: "Xã Minh Hạc",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150523",
    name: "Xã Phụ Khánh",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150524",
    name: "Xã Phương Viên",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150525",
    name: "Xã Quân Khê",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150526",
    name: "Xã Văn Lang",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150527",
    name: "Xã Vĩnh Chân",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150528",
    name: "Xã Vô Tranh",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150529",
    name: "Xã Vụ Cầu",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150530",
    name: "Xã Xuân Áng",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150531",
    name: "Xã Y Sơn",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150532",
    name: "Xã Yên Kỳ",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150533",
    name: "Xã Yên Luật",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "150601",
    name: "Thị trấn Sông Thao",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150602",
    name: "Xã Cấp Dẫn",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150603",
    name: "Xã Cát Trù",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150604",
    name: "Xã Chương Xá",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150605",
    name: "Xã Điêu Lương",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150606",
    name: "Xã Đồng Cam",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150607",
    name: "Xã Đồng Lương",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150608",
    name: "Xã Hiền Đa",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150609",
    name: "Xã Hương Lung",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150610",
    name: "Xã Ngô Xá",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150611",
    name: "Xã Phú Khê",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150612",
    name: "Xã Phú Lạc",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150613",
    name: "Xã Phùng Xá",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150614",
    name: "Xã Phượng Vĩ",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150615",
    name: "Xã Phương Xá",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150616",
    name: "Xã Sai Nga",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150617",
    name: "Xã Sơn Nga",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150618",
    name: "Xã Sơn Tình",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150619",
    name: "Xã Tạ Xá",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150620",
    name: "Xã Tam Sơn",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150621",
    name: "Xã Thanh Nga",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150622",
    name: "Xã Thụy Liễu",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150623",
    name: "Xã Tiên Lương",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150624",
    name: "Xã Tình Cương",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150625",
    name: "Xã Tùng Khê",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150626",
    name: "Xã Tuy Lộc",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150627",
    name: "Xã Văn Bán",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150628",
    name: "Xã Văn Khúc",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150629",
    name: "Xã Xương Thịnh",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150630",
    name: "Xã Yên Dưỡng",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150631",
    name: "Xã Yên Tập",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "150701",
    name: "Thị trấn Yên Lập",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150702",
    name: "Xã Đồng Lạc",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150703",
    name: "Xã Đồng Thịnh",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150704",
    name: "Xã Hưng Long",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150705",
    name: "Xã Lương Sơn",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150706",
    name: "Xã Minh Hòa",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150707",
    name: "Xã Mỹ Lung",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150708",
    name: "Xã Mỹ Lương",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150709",
    name: "Xã Nga Hoàng",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150710",
    name: "Xã Ngọc Đồng",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150711",
    name: "Xã Ngọc Lập",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150712",
    name: "Xã Phúc Khánh",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150713",
    name: "Xã Thượng Long",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150714",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150715",
    name: "Xã Xuân An",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150716",
    name: "Xã Xuân Thủy",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150717",
    name: "Xã Xuân Viên",
    is_higher_price: false,
    district_code: "2268",
  },
  {
    code: "150801",
    name: "Thị trấn Thanh Sơn",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150802",
    name: "Xã Cự Đồng",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150803",
    name: "Xã Cự Thắng",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150804",
    name: "Xã Địch Quả",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150805",
    name: "Xã Đông Cửu",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150806",
    name: "Xã Giáp Lai",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150807",
    name: "Xã Hương Cần",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150808",
    name: "Xã Khả Cửu",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150809",
    name: "Xã Lương Nha",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150810",
    name: "Xã Sơn Hùng",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150811",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150812",
    name: "Xã Tân Minh",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150813",
    name: "Xã Tất Thắng",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150814",
    name: "Xã Thạch Khoán",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150815",
    name: "Xã Thắng Sơn",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150816",
    name: "Xã Thục Luyện",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150817",
    name: "Xã Thượng Cửu",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150818",
    name: "Xã Tinh Nhuệ",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150819",
    name: "Xã Văn Miếu",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150820",
    name: "Xã Võ Miếu",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150821",
    name: "Xã Yên Lãng",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150822",
    name: "Xã Yên Lương",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150823",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "2029",
  },
  {
    code: "150901",
    name: "Thị trấn Phong Châu",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150902",
    name: "Xã An Đạo",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150903",
    name: "Xã Bảo Thanh",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150904",
    name: "Xã Bình Bộ",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150905",
    name: "Xã Gia Thanh",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150906",
    name: "Xã Hạ Giáp",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150907",
    name: "Xã Lệ Mỹ",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150908",
    name: "Xã Liên Hoa",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150909",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150910",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150911",
    name: "Xã Phú Nham",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150912",
    name: "Xã Phù Ninh",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150913",
    name: "Xã Tiên Du",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150914",
    name: "Xã Tiên Phú",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150915",
    name: "Xã Trạm Thản",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150916",
    name: "Xã Trị Quận",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150917",
    name: "Xã Trung Giáp",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150918",
    name: "Xã Tử Đà",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "150919",
    name: "Xã Vĩnh Phú",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "151001",
    name: "Thị trấn Hùng Sơn",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151002",
    name: "Thị trấn Lâm Thao",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151003",
    name: "Xã Bản Nguyên",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151004",
    name: "Xã Cao Xá",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151005",
    name: "Xã Hợp Hải",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151006",
    name: "Xã Kinh Kệ",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151007",
    name: "Xã Sơn Dương",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151008",
    name: "Xã Sơn Vi",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151009",
    name: "Xã Thạch Sơn",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151010",
    name: "Xã Tiên Kiên",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151011",
    name: "Xã Tứ Xã",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151012",
    name: "Xã Vĩnh Lại",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151013",
    name: "Xã Xuân Huy",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "151014",
    name: "Xã Xuân Lũng",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "15111",
    name: "Thị trấn Hưng Hoá",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151110",
    name: "Xã Phương Thịnh",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151111",
    name: "Xã Quang Húc",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151112",
    name: "Xã Tam Cường",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151113",
    name: "Xã Tề Lễ",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151114",
    name: "Xã Thanh Uyên",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151115",
    name: "Xã Thọ Văn",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151116",
    name: "Xã Thượng Nông",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151117",
    name: "Xã Tứ Mỹ",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151118",
    name: "Xã Văn Lương",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151119",
    name: "Xã Vực Trường",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15112",
    name: "Xã Cổ Tiết",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151120",
    name: "Xã Xuân Quang",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15113",
    name: "Xã Dậu Dương",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15114",
    name: "Xã Dị Nậu",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15115",
    name: "Xã Hiền Quan",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15116",
    name: "Xã Hồng Đà",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15117",
    name: "Xã Hùng Đô",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15118",
    name: "Xã Hương Nha",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "15119",
    name: "Xã Hương Nộn",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "151201",
    name: "Thị trấn Thanh Thủy",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151202",
    name: "Xã Bảo Yên",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151203",
    name: "Xã Đào Xá",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151204",
    name: "Xã Đoan Hạ",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151205",
    name: "Xã Đồng Luận",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151206",
    name: "Xã Hoàng Xá",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151207",
    name: "Xã Phượng Mao",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151208",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151209",
    name: "Xã Tân Phương",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151210",
    name: "Xã Thạch Đồng",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151211",
    name: "Xã Trung Nghĩa",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151212",
    name: "Xã Trung Thịnh",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151213",
    name: "Xã Tu Vũ",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151214",
    name: "Xã Xuân Lộc",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151215",
    name: "Xã Yến Mao",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "151301",
    name: "Xã Đồng Sơn",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151302",
    name: "Xã Kiệt Sơn",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151303",
    name: "Xã Kim Thượng",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151304",
    name: "Xã Lai Đồng",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151305",
    name: "Xã Long Cốc",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151306",
    name: "Xã Minh Đài",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151307",
    name: "Xã Mỹ Thuận",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151308",
    name: "Xã Tam Thanh",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151309",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151310",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151311",
    name: "Xã Thạch Kiệt",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151312",
    name: "Xã Thu Cúc",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151313",
    name: "Xã Thu Ngạc",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151314",
    name: "Xã Văn Luông",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151315",
    name: "Xã Vinh Tiền",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151316",
    name: "Xã Xuân Đài",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "151317",
    name: "Xã Xuân Sơn",
    is_higher_price: false,
    district_code: "2015",
  },
  {
    code: "160101",
    name: "Phường Đống Đa",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160102",
    name: "Phường Đồng Tâm",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160103",
    name: "Phường Hội Hợp",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160104",
    name: "Phường Khai Quang",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160105",
    name: "Phường Liên Bảo",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160106",
    name: "Phường Ngô Quyền",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160107",
    name: "Phường Tích Sơn",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160108",
    name: "Xã Định Trung",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160109",
    name: "Xã Thanh Trù",
    is_higher_price: false,
    district_code: "1578",
  },
  {
    code: "160201",
    name: "Thị trấn Hợp Hòa",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160202",
    name: "Xã An Hòa",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160203",
    name: "Xã Đạo Tú",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160204",
    name: "Xã Đồng Tĩnh",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160205",
    name: "Xã Duy Phiên",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160206",
    name: "Xã Hoàng Đan",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160207",
    name: "Xã Hoàng Hoa",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160208",
    name: "Xã Hoàng Lâu",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160209",
    name: "Xã Hợp Thịnh",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160210",
    name: "Xã Hướng Đạo",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160211",
    name: "Xã Kim Long",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160212",
    name: "Xã Thanh Vân",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160213",
    name: "Xã Vân Hội",
    is_higher_price: false,
    district_code: "2009",
  },
  {
    code: "160301",
    name: "Thị trấn Hoa Sơn",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160302",
    name: "Thị trấn Lập Thạch",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160303",
    name: "Xã Bắc Bình",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160304",
    name: "Xã Bàn Giản",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160305",
    name: "Xã Đình Chu",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160306",
    name: "Xã Đồng Ích",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160307",
    name: "Xã Hợp Lý",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160308",
    name: "Xã Liên Hòa",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160309",
    name: "Xã Liễn Sơn",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160310",
    name: "Xã Ngọc Mỹ",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160311",
    name: "Xã Quang Sơn",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160312",
    name: "Xã Sơn Đông",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160313",
    name: "Xã Thái Hòa",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160314",
    name: "Xã Tiên Lữ",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160315",
    name: "Xã Triệu Đề",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160316",
    name: "Xã Tử Du",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160317",
    name: "Xã Văn Quán",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160318",
    name: "Xã Vân Trục",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160319",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160320",
    name: "Xã Xuân Lôi",
    is_higher_price: false,
    district_code: "1960",
  },
  {
    code: "160401",
    name: "Thị Trấn Thổ Tang",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160402",
    name: "Thị trấn Tứ Trưng",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160403",
    name: "Thị trấn Vĩnh Tường",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160404",
    name: "Xã An Tường",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160405",
    name: "Xã Bình Dương",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160406",
    name: "Xã Bồ Sao",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160407",
    name: "Xã Cao Đại",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160408",
    name: "Xã Chấn Hưng",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160409",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160410",
    name: "Xã Kim Xá",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160411",
    name: "Xã Lũng Hoà",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160412",
    name: "Xã Lý Nhân",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160413",
    name: "Xã Nghĩa Hưng",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160414",
    name: "Xã Ngũ Kiên",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160415",
    name: "Xã Phú Đa",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160416",
    name: "Xã Phú Thịnh",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160417",
    name: "Xã Tam Phúc",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160418",
    name: "Xã Tân Cương",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160419",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160420",
    name: "Xã Thượng Trưng",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160421",
    name: "Xã Tuân Chính",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160422",
    name: "Xã Vân Xuân",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160423",
    name: "Xã Việt Xuân",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160424",
    name: "Xã Vĩnh Ninh",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160425",
    name: "Xã Vĩnh Sơn",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160426",
    name: "Xã Vĩnh Thịnh",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160427",
    name: "Xã Vũ Di",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160428",
    name: "Xã Yên Bình",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160429",
    name: "Xã Yên Lập",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "160501",
    name: "Thị trấn Yên Lạc",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160502",
    name: "Xã Bình Định",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160503",
    name: "Xã Đại Tự",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160504",
    name: "Xã Đồng Cương",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160505",
    name: "Xã Đồng Văn",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160506",
    name: "Xã Hồng Châu",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160507",
    name: "Xã Hồng Phương",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160508",
    name: "Xã Liên Châu",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160509",
    name: "Xã Nguyệt Đức",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160510",
    name: "Xã Tam Hồng",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160511",
    name: "Xã Tề Lỗ",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160512",
    name: "Xã Trung Hà",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160513",
    name: "Xã Trung Kiên",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160514",
    name: "Xã Trung Nguyên",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160515",
    name: "Xã Văn Tiến",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160516",
    name: "Xã Yên Đồng",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160517",
    name: "Xã Yên Phương",
    is_higher_price: false,
    district_code: "1734",
  },
  {
    code: "160601",
    name: "Thị trấn Gia Khánh",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160602",
    name: "Thị trấn Hương Canh",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160603",
    name: "Thị trấn Thanh Lãng",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160604",
    name: "Xã Bá Hiến",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160605",
    name: "Xã Đạo Đức",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160606",
    name: "Xã Hương Sơn",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160607",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160608",
    name: "Xã Quất Lưu",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160609",
    name: "Xã Sơn Lôi",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160610",
    name: "Xã Tam Hợp",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160611",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160612",
    name: "Xã Thiện Kế",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160613",
    name: "Xã Trung Mỹ",
    is_higher_price: false,
    district_code: "1732",
  },
  {
    code: "160701",
    name: "Thị trấn Tam Sơn",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160702",
    name: "Xã Bạch Lưu",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160703",
    name: "Xã Cao Phong",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160704",
    name: "Xã Đôn Nhân",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160705",
    name: "Xã Đồng Quế",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160706",
    name: "Xã Đồng Thịnh",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160707",
    name: "Xã Đức Bác",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160708",
    name: "Xã Hải Lựu",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160709",
    name: "Xã Lãng Công",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160710",
    name: "Xã Nhân Đạo",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160711",
    name: "Xã Nhạo Sơn",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160712",
    name: "Xã Như Thụy",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160713",
    name: "Xã Phương Khoan",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160714",
    name: "Xã Quang Yên",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160715",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160716",
    name: "Xã Tứ Yên",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160717",
    name: "Xã Yên Thạch",
    is_higher_price: false,
    district_code: "3265",
  },
  {
    code: "160801",
    name: "Phường Đồng Xuân",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160802",
    name: "Phường Hùng Vương",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160803",
    name: "Phường Phúc Thắng",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160804",
    name: "Phường Trưng Nhị",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160805",
    name: "Phường Trưng Trắc",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160806",
    name: "Phường Xuân Hoà",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160807",
    name: "Xã Cao Minh",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160808",
    name: "Xã Nam Viêm",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160809",
    name: "Xã Ngọc Thanh",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160810",
    name: "Xã Tiền Châu",
    is_higher_price: false,
    district_code: "2065",
  },
  {
    code: "160901",
    name: "Thị trấn Tam Đảo",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160902",
    name: "Xã Bồ Lý",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160903",
    name: "Xã Đại Đình",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160904",
    name: "Xã Đạo Trù",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160905",
    name: "Xã Hồ Sơn",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160906",
    name: "Xã Hợp Châu",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160907",
    name: "Xã Minh Quang",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160908",
    name: "Xã Tam Quan",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "160909",
    name: "Xã Yên Dương",
    is_higher_price: false,
    district_code: "3271",
  },
  {
    code: "170101",
    name: "Phường Bạch Đằng",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170102",
    name: "Phường Bãi Cháy",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170103",
    name: "Phường Cao Thắng",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170104",
    name: "Phường Cao Xanh",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170105",
    name: "Phường Đại Yên",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170106",
    name: "Phường Giếng Đáy",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170107",
    name: "Phường Hà Khánh",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170108",
    name: "Phường Hà Khẩu",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170109",
    name: "Phường Hà Lầm",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170110",
    name: "Phường Hà Phong",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170111",
    name: "Phường Hà Trung",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170112",
    name: "Phường Hà Tu",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170113",
    name: "Phường Hồng Gai",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170114",
    name: "Phường Hồng Hà",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170115",
    name: "Phường Hồng Hải",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170116",
    name: "Phường Hùng Thắng",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170117",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170118",
    name: "Phường Tuần Châu",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170119",
    name: "Phường Việt Hưng",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170120",
    name: "Phường Yết Kiêu",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "170201",
    name: "Phường Cẩm Bình",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170202",
    name: "Phường Cẩm Đông",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170203",
    name: "Phường Cẩm Phú",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170204",
    name: "Phường Cẩm Sơn",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170205",
    name: "Phường Cẩm Tây",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170206",
    name: "Phường Cẩm Thạch",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170207",
    name: "Phường Cẩm Thành",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170208",
    name: "Phường Cẩm Thịnh",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170209",
    name: "Phường Cẩm Thủy",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170210",
    name: "Phường Cẩm Trung",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170211",
    name: "Phường Cửa Ông",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170212",
    name: "Phường Mông Dương",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170213",
    name: "Phường Quang Hanh",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170214",
    name: "Xã Cẩm Hải",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170215",
    name: "Xã Cộng Hòa",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170216",
    name: "Xã Dương Huy",
    is_higher_price: false,
    district_code: "1683",
  },
  {
    code: "170301",
    name: "Phường Bắc Sơn",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170302",
    name: "Phường Nam Khê",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170303",
    name: "Phường Phương Đông",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170304",
    name: "Phường Phương Nam",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170305",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170306",
    name: "Phường Thanh Sơn",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170307",
    name: "Phường Trưng Vương",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170308",
    name: "Phường Vàng Danh",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170309",
    name: "Phường Yên Thanh",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170310",
    name: "Xã Điền Công",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170311",
    name: "Xã Thượng Yên Công",
    is_higher_price: false,
    district_code: "1686",
  },
  {
    code: "170401",
    name: "Phường Bình Ngọc",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170402",
    name: "Phường Hải Hoà",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170403",
    name: "Phường Hải Yên",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170404",
    name: "Phường Hoà Lạc",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170405",
    name: "Phường Ka Long",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170406",
    name: "Phường Ninh Dương",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170407",
    name: "Phường Trà Cổ",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170408",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170409",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170410",
    name: "Xã Hải Đông",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170411",
    name: "Xã Hải Sơn",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170412",
    name: "Xã Hải Tiến",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170413",
    name: "Xã Hải Xuân",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170414",
    name: "Xã Quảng Nghĩa",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170415",
    name: "Xã Vạn Ninh",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170416",
    name: "Xã Vĩnh Thực",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170417",
    name: "Xã Vĩnh Trung",
    is_higher_price: false,
    district_code: "1603",
  },
  {
    code: "170501",
    name: "Thị trấn Bình Liêu",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170502",
    name: "Xã Đồng Tâm",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170503",
    name: "Xã Đồng Văn",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170504",
    name: "Xã Hoành Mô",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170505",
    name: "Xã Húc Động",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170506",
    name: "Xã Lục Hồn",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170507",
    name: "Xã Tình Húc",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170508",
    name: "Xã Vô Ngại",
    is_higher_price: false,
    district_code: "1896",
  },
  {
    code: "170601",
    name: "Thị trấn Đầm Hà",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170602",
    name: "Xã Đại Bình",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170603",
    name: "Xã Đầm Hà",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170604",
    name: "Xã Dực Yên",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170605",
    name: "Xã Quảng An",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170606",
    name: "Xã Quảng Lâm",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170607",
    name: "Xã Quảng Lợi",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170608",
    name: "Xã Quảng Tân",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170609",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170610",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "3180",
  },
  {
    code: "170701",
    name: "Thị trấn Quảng Hà",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170702",
    name: "Xã Cái Chiên",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170703",
    name: "Xã Đường Hoa",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170704",
    name: "Xã Phú Hải",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170705",
    name: "Xã Quảng Chính",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170706",
    name: "Xã Quảng Điền",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170707",
    name: "Xã Quảng Đức",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170708",
    name: "Xã Quảng Long",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170709",
    name: "Xã Quảng Minh",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170710",
    name: "Xã Quảng Phong",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170711",
    name: "Xã Quảng Sơn",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170712",
    name: "Xã Quảng Thắng",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170713",
    name: "Xã Quảng Thành",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170714",
    name: "Xã Quảng Thịnh",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170715",
    name: "Xã Quảng Trung",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170716",
    name: "Xã Tiến Tới",
    is_higher_price: false,
    district_code: "1940",
  },
  {
    code: "170801",
    name: "Thị trấn Tiên Yên",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170802",
    name: "Xã Đại Dực",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170803",
    name: "Xã Đại Thành",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170804",
    name: "Xã Điền Xá",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170805",
    name: "Xã Đông Hải",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170806",
    name: "Xã Đông Ngũ",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170807",
    name: "Xã Đồng Rui",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170808",
    name: "Xã Hà Lâu",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170809",
    name: "Xã Hải Lạng",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170810",
    name: "Xã Phong Dụ",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170811",
    name: "Xã Tiên Lãng",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170812",
    name: "Xã Yên Than",
    is_higher_price: false,
    district_code: "2019",
  },
  {
    code: "170901",
    name: "Thị trấn Ba Chẽ",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "170902",
    name: "Xã Đạp Thanh",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "170903",
    name: "Xã Đồn Đạc",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "170904",
    name: "Xã Lương Mông",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "170905",
    name: "Xã Minh Cầm",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "170906",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "170907",
    name: "Xã Thanh Lâm",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "170908",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "3126",
  },
  {
    code: "171001",
    name: "Phường Đông Triều",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171002",
    name: "Phường Đức Chính",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171003",
    name: "Phường Hưng Đạo",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171004",
    name: "Phường Kim Sơn",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171005",
    name: "Phường Mạo Khê",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171006",
    name: "Phường Xuân Sơn",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171007",
    name: "Xã An Sinh",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171008",
    name: "Xã Bình Dương",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171009",
    name: "Xã Bình Khê",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171010",
    name: "Xã Hoàng Quế",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171011",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171012",
    name: "Xã Hồng Thái Đông",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171013",
    name: "Xã Hồng Thái Tây",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171014",
    name: "Xã Nguyễn Huệ",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171015",
    name: "Xã Tân Việt",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171016",
    name: "Xã Thủy An",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171017",
    name: "Xã Tràng An",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171018",
    name: "Xã Tràng Lương",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171019",
    name: "Xã Việt Dân",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171020",
    name: "Xã Yên Đức",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171021",
    name: "Xã Yên Thọ",
    is_higher_price: false,
    district_code: "3185",
  },
  {
    code: "171101",
    name: "Phường Cộng Hòa",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171102",
    name: "Phường Đông Mai",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171103",
    name: "Phường Hà An",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171104",
    name: "Phường Minh Thành",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171105",
    name: "Phường Nam Hoà",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171106",
    name: "Phường Phong Cốc",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171107",
    name: "Phường Phong Hải",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171108",
    name: "Phường Quảng Yên",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171109",
    name: "Phường Tân An",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171110",
    name: "Phường Yên Giang",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171111",
    name: "Phường Yên Hải",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171112",
    name: "Xã Cẩm La",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171113",
    name: "Xã Hiệp Hòa",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171114",
    name: "Xã Hoàng Tân",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171115",
    name: "Xã Liên Hòa",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171116",
    name: "Xã Liên Vị",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171117",
    name: "Xã Sông Khoai",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171118",
    name: "Xã Tiền An",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171119",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "2066",
  },
  {
    code: "171201",
    name: "Thị trấn Trới",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171202",
    name: "Xã Bằng Cả",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171203",
    name: "Xã Dân Chủ",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171204",
    name: "Xã Đồng Lâm",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171205",
    name: "Xã Đồng Sơn",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171206",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171207",
    name: "Xã Kỳ Thượng",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171208",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171209",
    name: "Xã Quảng La",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171210",
    name: "Xã Sơn Dương",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171211",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171212",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171213",
    name: "Xã Vũ Oai",
    is_higher_price: false,
    district_code: "3199",
  },
  {
    code: "171301",
    name: "Thị trấn Cái Rồng",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171302",
    name: "Xã Bản Sen",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171303",
    name: "Xã Bình Dân",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171304",
    name: "Xã Đài Xuyên",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171305",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171306",
    name: "Xã Đông Xá",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171307",
    name: "Xã Hạ Long",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171308",
    name: "Xã Minh Châu",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171309",
    name: "Xã Ngọc Vừng",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171310",
    name: "Xã Quan Lạn",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171311",
    name: "Xã Thắng Lợi",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171312",
    name: "Xã Vạn Yên",
    is_higher_price: false,
    district_code: "1920",
  },
  {
    code: "171401",
    name: "Thị trấn Cô Tô",
    is_higher_price: false,
    district_code: "2109",
  },
  {
    code: "171402",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "2109",
  },
  {
    code: "171403",
    name: "Xã Thanh Lân",
    is_higher_price: false,
    district_code: "2109",
  },
  {
    code: "180101",
    name: "Phường Đa Mai",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180102",
    name: "Phường Dĩnh Kế",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180103",
    name: "Phường Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180104",
    name: "Phường Lê Lợi",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180105",
    name: "Phường Mỹ Độ",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180106",
    name: "Phường Ngô Quyền",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180107",
    name: "Phường Thọ Xương",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180108",
    name: "Phường Trần Nguyên Hãn",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180109",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180110",
    name: "Phường Xương Giang",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180111",
    name: "Xã Dĩnh Trì",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180112",
    name: "Xã Đồng Sơn",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180113",
    name: "Xã Song Khê",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180114",
    name: "Xã Song Mai",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180115",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180116",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1643",
  },
  {
    code: "180201",
    name: "Thị trấn Bố Hạ",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180202",
    name: "Thị trấn Cầu Gồ",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180203",
    name: "Xã An Thượng",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180204",
    name: "Xã Bố Hạ",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180205",
    name: "Xã Canh Nậu",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180206",
    name: "Xã Đồng Hưu",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180207",
    name: "Xã Đồng Kỳ",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180208",
    name: "Xã Đồng Lạc",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180209",
    name: "Xã Đông Sơn",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180210",
    name: "Xã Đồng Tâm",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180211",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180212",
    name: "Xã Đồng Vương",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180213",
    name: "Xã Hồng Kỳ",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180214",
    name: "Xã Hương Vĩ",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180215",
    name: "Xã Phồn Xương",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180216",
    name: "Xã Tam Hiệp",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180217",
    name: "Xã Tam Tiến",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180218",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180219",
    name: "Xã Tân Sỏi",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180220",
    name: "Xã Tiến Thắng",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180221",
    name: "Xã Xuân Lương",
    is_higher_price: false,
    district_code: "1765",
  },
  {
    code: "180301",
    name: "Thị trấn Chũ",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180302",
    name: "Xã Biển Động",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180303",
    name: "Xã Biên Sơn",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180304",
    name: "Xã Cấm Sơn",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180305",
    name: "Xã Đèo Gia",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180306",
    name: "Xã Đồng Cốc",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180307",
    name: "Xã Giáp Sơn",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180308",
    name: "Xã Hộ Đáp",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180309",
    name: "Xã Hồng Giang",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180310",
    name: "Xã Kiên Lao",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180311",
    name: "Xã Kiên Thành",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180312",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180313",
    name: "Xã Mỹ An",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180314",
    name: "Xã Nam Dương",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180315",
    name: "Xã Nghĩa Hồ",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180316",
    name: "Xã Phì Điền",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180317",
    name: "Xã Phong Minh",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180318",
    name: "Xã Phong Vân",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180319",
    name: "Xã Phú Nhuận",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180320",
    name: "Xã Phượng Sơn",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180321",
    name: "Xã Quý Sơn",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180322",
    name: "Xã Sơn Hải",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180323",
    name: "Xã Tân Hoa",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180324",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180325",
    name: "Xã Tân Mộc",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180326",
    name: "Xã Tân Quang",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180327",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180328",
    name: "Xã Thanh Hải",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180329",
    name: "Xã Trù Hựu",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180330",
    name: "Xã Xa Lý",
    is_higher_price: false,
    district_code: "1966",
  },
  {
    code: "180401",
    name: "Thị trấn An Châu",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180402",
    name: "Thị trấn Thanh Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180403",
    name: "Xã An Bá",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180404",
    name: "Xã An Châu",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180405",
    name: "Xã An Lạc",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180406",
    name: "Xã An Lập",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180407",
    name: "Xã Bồng Am",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180408",
    name: "Xã Cẩm Đàn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180409",
    name: "Xã Chiên Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180410",
    name: "Xã Dương Hưu",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180411",
    name: "Xã Giáo Liêm",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180412",
    name: "Xã Hữu Sản",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180413",
    name: "Xã Lệ Viễn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180414",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180415",
    name: "Xã Phúc Thắng",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180416",
    name: "Xã Quế Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180417",
    name: "Xã Thạch Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180418",
    name: "Xã Thanh Luận",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180419",
    name: "Xã Tuấn Đạo",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180420",
    name: "Xã Tuấn Mậu",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180421",
    name: "Xã Vân Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180422",
    name: "Xã Vĩnh Khương",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180423",
    name: "Xã Yên Định",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "180501",
    name: "Thị trấn Đồi Ngô",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180502",
    name: "Thị trấn Lục Nam",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180503",
    name: "Xã Bắc Lũng",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180504",
    name: "Xã Bảo Đài",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180505",
    name: "Xã Bảo Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180506",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180507",
    name: "Xã Cẩm Lý",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180508",
    name: "Xã Chu Điện",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180509",
    name: "Xã Cương Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180510",
    name: "Xã Đan Hội",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180511",
    name: "Xã Đông Hưng",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180512",
    name: "Xã Đông Phú",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180513",
    name: "Xã Huyền Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180514",
    name: "Xã Khám Lạng",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180515",
    name: "Xã Lan Mẫu",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180516",
    name: "Xã Lục Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180517",
    name: "Xã Nghĩa Phương",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180518",
    name: "Xã Phương Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180519",
    name: "Xã Tam Dị",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180520",
    name: "Xã Thanh Lâm",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180521",
    name: "Xã Tiên Hưng",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180522",
    name: "Xã Tiên Nha",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180523",
    name: "Xã Trường Giang",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180524",
    name: "Xã Trường Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180525",
    name: "Xã Vô Tranh",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180526",
    name: "Xã Vũ Xá",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180527",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "1965",
  },
  {
    code: "180601",
    name: "Thị trấn Cao Thượng",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180602",
    name: "Thị trấn Nhã Nam",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180603",
    name: "Xã An Dương",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180604",
    name: "Xã Cao Thượng",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180605",
    name: "Xã Cao Xá",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180606",
    name: "Xã Đại Hóa",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180607",
    name: "Xã Hợp Đức",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180608",
    name: "Xã Lam Cốt",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180609",
    name: "Xã Lan Giới",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180610",
    name: "Xã Liên Chung",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180611",
    name: "Xã Liên Sơn",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180612",
    name: "Xã Ngọc Châu",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180613",
    name: "Xã Ngọc Lý",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180614",
    name: "Xã Ngọc Thiện",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180615",
    name: "Xã Ngọc Vân",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180616",
    name: "Xã Nhã Nam",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180617",
    name: "Xã Phúc Hòa",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180618",
    name: "Xã Phúc Sơn",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180619",
    name: "Xã Quang Tiến",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180620",
    name: "Xã Quế Nham",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180621",
    name: "Xã Song Vân",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180622",
    name: "Xã Tân Trung",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180623",
    name: "Xã Việt Lập",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180624",
    name: "Xã Việt Ngọc",
    is_higher_price: false,
    district_code: "1762",
  },
  {
    code: "180701",
    name: "Thị trấn Thắng",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180702",
    name: "Xã Bắc Lý",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180703",
    name: "Xã Châu Minh",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180704",
    name: "Xã Đại Thành",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180705",
    name: "Xã Danh Thắng",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180706",
    name: "Xã Đoan Bái",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180707",
    name: "Xã Đông Lỗ",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180708",
    name: "Xã Đồng Tân",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180709",
    name: "Xã Đức Thắng",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180710",
    name: "Xã Hòa Sơn",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180711",
    name: "Xã Hoàng An",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180712",
    name: "Xã Hoàng Lương",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180713",
    name: "Xã Hoàng Thanh",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180714",
    name: "Xã Hoàng Vân",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180715",
    name: "Xã Hợp Thịnh",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180716",
    name: "Xã Hùng Sơn",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180717",
    name: "Xã Hương Lâm",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180718",
    name: "Xã Lương Phong",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180719",
    name: "Xã Mai Đình",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180720",
    name: "Xã Mai Trung",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180721",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180722",
    name: "Xã Quang Minh",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180723",
    name: "Xã Thái Sơn",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180724",
    name: "Xã Thanh Vân",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180725",
    name: "Xã Thường Thắng",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180726",
    name: "Xã Xuân Cẩm",
    is_higher_price: false,
    district_code: "1759",
  },
  {
    code: "180801",
    name: "Thị trấn Kép",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180802",
    name: "Thị trấn Vôi",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180803",
    name: "Xã An Hà",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180804",
    name: "Xã Đại Lâm",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180805",
    name: "Xã Đào Mỹ",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180806",
    name: "Xã Dương Đức",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180807",
    name: "Xã Hương Lạc",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180808",
    name: "Xã Hương Sơn",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180809",
    name: "Xã Mỹ Hà",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180810",
    name: "Xã Mỹ Thái",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180811",
    name: "Xã Nghĩa Hòa",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180812",
    name: "Xã Nghĩa Hưng",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180813",
    name: "Xã Phi Mô",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180814",
    name: "Xã Quang Thịnh",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180815",
    name: "Xã Tân Dĩnh",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180816",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180817",
    name: "Xã Tân Thanh",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180818",
    name: "Xã Tân Thịnh",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180819",
    name: "Xã Thái Đào",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180820",
    name: "Xã Tiên Lục",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180821",
    name: "Xã Xuân Hương",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180822",
    name: "Xã Xương Lâm",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180823",
    name: "Xã Yên Mỹ",
    is_higher_price: false,
    district_code: "1760",
  },
  {
    code: "180901",
    name: "Thị trấn  Bích Động",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180902",
    name: "Thị trấn Nếnh",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180903",
    name: "Xã Bích Sơn",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180904",
    name: "Xã Hoàng Ninh",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180905",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180906",
    name: "Xã Hương Mai",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180907",
    name: "Xã Minh Đức",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180908",
    name: "Xã Nghĩa Trung",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180909",
    name: "Xã Ninh Sơn",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180910",
    name: "Xã Quang Châu",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180911",
    name: "Xã Quảng Minh",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180912",
    name: "Xã Tăng Tiến",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180913",
    name: "Xã Thượng Lan",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180914",
    name: "Xã Tiên Sơn",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180915",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180916",
    name: "Xã Tự Lạn",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180917",
    name: "Xã Vân Hà",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180918",
    name: "Xã Vân Trung",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "180919",
    name: "Xã Việt Tiến",
    is_higher_price: false,
    district_code: "1763",
  },
  {
    code: "181001",
    name: "Thị trấn Neo",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181002",
    name: "Thị trấn Tân Dân",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181003",
    name: "Xã Cảnh Thụy",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181004",
    name: "Xã Đồng Phúc",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181005",
    name: "Xã Đồng Việt",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181006",
    name: "Xã Đức Giang",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181007",
    name: "Xã Hương Gián",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181008",
    name: "Xã Lãng Sơn",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181009",
    name: "Xã Lão Hộ",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181010",
    name: "Xã Nham Sơn",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181011",
    name: "Xã Nội Hoàng",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181012",
    name: "Xã Quỳnh Sơn",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181013",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181014",
    name: "Xã Tân Liễu",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181015",
    name: "Xã Thắng Cương",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181016",
    name: "Xã Tiến Dũng",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181017",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181018",
    name: "Xã Trí Yên",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181019",
    name: "Xã Tư Mại",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181020",
    name: "Xã Xuân Phú",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "181021",
    name: "Xã Yên Lư",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "190101",
    name: "Phường Đại Phúc",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190102",
    name: "Phường Đáp Cầu",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190103",
    name: "Phường Hạp Lĩnh",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190104",
    name: "Phường Khắc Niệm",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190105",
    name: "Phường Khúc Xuyên",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190106",
    name: "Phường Kinh Bắc",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190107",
    name: "Phường Ninh Xá",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190108",
    name: "Phường Phong Khê",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190109",
    name: "Phường Suối Hoa",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190110",
    name: "Phường Thị Cầu",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190111",
    name: "Phường Tiền An",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190112",
    name: "Phường Vạn An",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190113",
    name: "Phường Vân Dương",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190114",
    name: "Phường Vệ An",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190115",
    name: "Phường Võ Cường",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190116",
    name: "Phường Vũ Ninh",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190117",
    name: "Xã Hòa Long",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190118",
    name: "Xã Kim Chân",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190119",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "1644",
  },
  {
    code: "190201",
    name: "Thị trấn Chờ",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190202",
    name: "Xã Đông Phong",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190203",
    name: "Xã Đông Thọ",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190204",
    name: "Xã Đông Tiến",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190205",
    name: "Xã Dũng Liệt",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190206",
    name: "Xã Hòa Tiến",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190207",
    name: "Xã Long Châu",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190208",
    name: "Xã Tam Đa",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190209",
    name: "Xã Tam Giang",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190210",
    name: "Xã Thụy Hòa",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190211",
    name: "Xã Trung Nghĩa",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190212",
    name: "Xã Văn Môn",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190213",
    name: "Xã Yên Phụ",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190214",
    name: "Xã Yên Trung",
    is_higher_price: false,
    district_code: "1768",
  },
  {
    code: "190301",
    name: "Thị trấn Phố Mới",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190302",
    name: "Xã Bằng An",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190303",
    name: "Xã Bồng Lai",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190304",
    name: "Xã Cách Bi",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190305",
    name: "Xã Châu Phong",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190306",
    name: "Xã Chi Lăng",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190307",
    name: "Xã Đại Xuân",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190308",
    name: "Xã Đào Viên",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190309",
    name: "Xã Đức Long",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190310",
    name: "Xã Hán Quảng",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190311",
    name: "Xã Mộ Đạo",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190312",
    name: "Xã Ngọc Xá",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190313",
    name: "Xã Nhân Hòa",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190314",
    name: "Xã Phù Lãng",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190315",
    name: "Xã Phù Lương",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190316",
    name: "Xã Phương Liễu",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190317",
    name: "Xã Phượng Mao",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190318",
    name: "Xã Quế Tân",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190319",
    name: "Xã Việt Hùng",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190320",
    name: "Xã Việt Thống",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190321",
    name: "Xã Yên Giả",
    is_higher_price: false,
    district_code: "1728",
  },
  {
    code: "190401",
    name: "Thị trấn Lim",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190402",
    name: "Xã Cảnh Hưng",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190403",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190404",
    name: "Xã Hiên Vân",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190405",
    name: "Xã Hoàn Sơn",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190406",
    name: "Xã Lạc Vệ",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190407",
    name: "Xã Liên Bão",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190408",
    name: "Xã Minh Đạo",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190409",
    name: "Xã Nội Duệ",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190410",
    name: "Xã Phật Tích",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190411",
    name: "Xã Phú Lâm",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190412",
    name: "Xã Tân Chi",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190413",
    name: "Xã Tri Phương",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190414",
    name: "Xã Việt Đoàn",
    is_higher_price: false,
    district_code: "1729",
  },
  {
    code: "190501",
    name: "Phường Châu Khê",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190502",
    name: "Phường Đình Bảng",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190503",
    name: "Phường Đồng Kỵ",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190504",
    name: "Phường Đông Ngàn",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190505",
    name: "Phường Đồng Nguyên",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190506",
    name: "Phường Tân Hồng",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190507",
    name: "Phường Trang Hạ",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190508",
    name: "Xã Hương Mạc",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190509",
    name: "Xã Phù Chẩn",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190510",
    name: "Xã Phù Khê",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190511",
    name: "Xã Tam Sơn",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190512",
    name: "Xã Tương Giang",
    is_higher_price: false,
    district_code: "1730",
  },
  {
    code: "190601",
    name: "Thị trấn Hồ",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190602",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190603",
    name: "Xã Đại Đồng Thành",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190604",
    name: "Xã Đình Tổ",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190605",
    name: "Xã Gia Đông",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190606",
    name: "Xã Hà Mãn",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190607",
    name: "Xã Hoài Thượng",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190608",
    name: "Xã Mão Điền",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190609",
    name: "Xã Nghĩa Đạo",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190610",
    name: "Xã Ngũ Thái",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190611",
    name: "Xã Nguyệt Đức",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190612",
    name: "Xã Ninh Xá",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190613",
    name: "Xã Song Hồ",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190614",
    name: "Xã Song Liễu",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190615",
    name: "Xã Thanh Khương",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190616",
    name: "Xã Trạm Lộ",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190617",
    name: "Xã Trí Quả",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190618",
    name: "Xã Xuân Lâm",
    is_higher_price: false,
    district_code: "1767",
  },
  {
    code: "190701",
    name: "Thị trấn Gia Bình",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190702",
    name: "Xã Bình Dương",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190703",
    name: "Xã Cao Đức",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190704",
    name: "Xã Đại Bái",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190705",
    name: "Xã Đại Lai",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190706",
    name: "Xã Đông Cứu",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190707",
    name: "Xã Giang Sơn",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190708",
    name: "Xã Lãng Ngâm",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190709",
    name: "Xã Nhân Thắng",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190710",
    name: "Xã Quỳnh Phú",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190711",
    name: "Xã Song Giang",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190712",
    name: "Xã Thái Bảo",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190713",
    name: "Xã Vạn Ninh",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190714",
    name: "Xã Xuân Lai",
    is_higher_price: false,
    district_code: "1766",
  },
  {
    code: "190801",
    name: "Thị trấn Thứa",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190802",
    name: "Xã An Thịnh",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190803",
    name: "Xã Bình Định",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190804",
    name: "Xã Lai Hạ",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190805",
    name: "Xã Lâm Thao",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190806",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190807",
    name: "Xã Mỹ Hương",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190808",
    name: "Xã Phú Hòa",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190809",
    name: "Xã Phú Lương",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190810",
    name: "Xã Quảng Phú",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190811",
    name: "Xã Tân Lãng",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190812",
    name: "Xã Trung Chính",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190813",
    name: "Xã Trung Kênh",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "190814",
    name: "Xã Trừng Xá",
    is_higher_price: false,
    district_code: "1969",
  },
  {
    code: "1A0052",
    name: "Phường đặc biệt",
    is_higher_price: false,
    district_code: "3442",
  },
  {
    code: "1A0101",
    name: "Phường Cống Vị",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0102",
    name: "Phường Điện Biên",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0103",
    name: "Phường Đội Cấn",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0104",
    name: "Phường Giảng Võ",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0105",
    name: "Phường Kim Mã",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0106",
    name: "Phường Liễu Giai",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0107",
    name: "Phường Ngọc Hà",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0108",
    name: "Phường Ngọc Khánh",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0109",
    name: "Phường Nguyễn Trung Trực",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0110",
    name: "Phường Phúc Xá",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0111",
    name: "Phường Quán Thánh",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0112",
    name: "Phường Thành Công",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0113",
    name: "Phường Trúc Bạch",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0114",
    name: "Phường Vĩnh Phúc",
    is_higher_price: false,
    district_code: "1484",
  },
  {
    code: "1A0201",
    name: "Phường Chương Dương Độ",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0202",
    name: "Phường Cửa Đông",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0203",
    name: "Phường Cửa Nam",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0204",
    name: "Phường Đồng Xuân",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0205",
    name: "Phường Hàng Bạc",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0206",
    name: "Phường Hàng Bài",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0207",
    name: "Phường Hàng Bồ",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0208",
    name: "Phường Hàng Bông",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0209",
    name: "Phường Hàng Buồm",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0210",
    name: "Phường Hàng Đào",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0211",
    name: "Phường Hàng Gai",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0212",
    name: "Phường Hàng Mã",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0213",
    name: "Phường Hàng Trống",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0214",
    name: "Phường Lý Thái Tổ",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0215",
    name: "Phường Phan Chu Trinh",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0216",
    name: "Phường Phúc Tân",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0217",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0218",
    name: "Phường Tràng Tiền",
    is_higher_price: false,
    district_code: "1489",
  },
  {
    code: "1A0301",
    name: "Phường Bạch Đằng",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0302",
    name: "Phường Bách Khoa",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0303",
    name: "Phường Bạch Mai",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0304",
    name: "Phường Bùi Thị Xuân",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0305",
    name: "Phường Cầu Dền",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0306",
    name: "Phường Đống Mác",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0307",
    name: "Phường Đồng Nhân",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0308",
    name: "Phường Đồng Tâm",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0309",
    name: "Phường Lê Đại Hành",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0310",
    name: "Phường Minh Khai",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0311",
    name: "Phường Ngô Thì Nhậm",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0312",
    name: "Phường Nguyễn Du",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0313",
    name: "Phường Phạm Đình Hổ",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0314",
    name: "Phường Phố Huế",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0315",
    name: "Phường Quỳnh Lôi",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0316",
    name: "Phường Quỳnh Mai",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0317",
    name: "Phường Thanh Lương",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0318",
    name: "Phường Thanh Nhàn",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0319",
    name: "Phường Trương Định",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0320",
    name: "Phường Vĩnh Tuy",
    is_higher_price: false,
    district_code: "1488",
  },
  {
    code: "1A0401",
    name: "Phường Cát Linh",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0402",
    name: "Phường Hàng Bột",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0403",
    name: "Phường Khâm Thiên",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0404",
    name: "Phường Khương Thượng",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0405",
    name: "Phường Kim Liên",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0406",
    name: "Phường Láng Hạ",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0407",
    name: "Phường Láng Thượng",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0408",
    name: "Phường Nam Đồng",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0409",
    name: "Phường Ngã Tư Sở",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0410",
    name: "Phường Ô Chợ Dừa",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0411",
    name: "Phường Phương Liên",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0412",
    name: "Phường Phương Mai",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0413",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0414",
    name: "Phường Quốc Tử Giám",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0415",
    name: "Phường Thịnh Quang",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0416",
    name: "Phường Thổ Quan",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0417",
    name: "Phường Trung Liệt",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0418",
    name: "Phường Trung Phụng",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0419",
    name: "Phường Trung Tự",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0420",
    name: "Phường Văn Chương",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0421",
    name: "Phường Văn Miếu",
    is_higher_price: false,
    district_code: "1486",
  },
  {
    code: "1A0501",
    name: "Phường Bưởi",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0502",
    name: "Phường Nhật Tân",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0503",
    name: "Phường Phú Thượng",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0504",
    name: "Phường Quảng An",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0505",
    name: "Phường Thụy Khuê",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0506",
    name: "Phường Tứ Liên",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0507",
    name: "Phường Xuân La",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0508",
    name: "Phường Yên Phụ",
    is_higher_price: false,
    district_code: "1492",
  },
  {
    code: "1A0601",
    name: "Phường Dịch Vọng",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0602",
    name: "Phường Dịch Vọng Hậu",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0603",
    name: "Phường Mai Dịch",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0604",
    name: "Phường Nghĩa Đô",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0605",
    name: "Phường Nghĩa Tân",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0606",
    name: "Phường Quan Hoa",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0607",
    name: "Phường Trung Hoà",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0608",
    name: "Phường Yên Hoà",
    is_higher_price: false,
    district_code: "1485",
  },
  {
    code: "1A0701",
    name: "Phường Hạ Đình",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0702",
    name: "Phường Khương Đình",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0703",
    name: "Phường Khương Mai",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0704",
    name: "Phường Khương Trung",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0705",
    name: "Phường Kim Giang",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0706",
    name: "Phường Nhân Chính",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0707",
    name: "Phường Phương Liệt",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0708",
    name: "Phường Thanh Xuân Bắc",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0709",
    name: "Phường Thanh Xuân Nam",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0710",
    name: "Phường Thanh Xuân Trung",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0711",
    name: "Phường Thượng Đình",
    is_higher_price: false,
    district_code: "1493",
  },
  {
    code: "1A0801",
    name: "Phường Đại Kim",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0802",
    name: "Phường Định Công",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0803",
    name: "Phường Giáp Bát",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0804",
    name: "Phường Hoàng Liệt",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0805",
    name: "Phường Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0806",
    name: "Phường Lĩnh Nam",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0807",
    name: "Phường Mai Động",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0808",
    name: "Phường Tân Mai",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0809",
    name: "Phường Thanh Trì",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0810",
    name: "Phường Thịnh Liệt",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0811",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0812",
    name: "Phường Tương Mai",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0813",
    name: "Phường Vĩnh Hưng",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0814",
    name: "Phường Yên Sở",
    is_higher_price: false,
    district_code: "1490",
  },
  {
    code: "1A0901",
    name: "Phường Bồ Đề",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0902",
    name: "Phường Cự Khối",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0903",
    name: "Phường Đức Giang",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0904",
    name: "Phường Gia Thụy",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0905",
    name: "Phường Giang Biên",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0906",
    name: "Phường Long Biên",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0907",
    name: "Phường Ngọc Lâm",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0908",
    name: "Phường Ngọc Thụy",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0909",
    name: "Phường Phúc Đồng",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0910",
    name: "Phường Phúc Lợi",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0911",
    name: "Phường Sài Đồng",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0912",
    name: "Phường Thạch Bàn",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0913",
    name: "Phường Thượng Thanh",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A0914",
    name: "Phường Việt Hưng",
    is_higher_price: false,
    district_code: "1491",
  },
  {
    code: "1A1101",
    name: "Thị trấn Văn Điển",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1102",
    name: "Xã Đại áng",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1103",
    name: "Xã Đông Mỹ",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1104",
    name: "Xã Duyên Hà",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1105",
    name: "Xã Hữu Hoà",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1106",
    name: "Xã Liên Ninh",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1107",
    name: "Xã Ngọc Hồi",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1108",
    name: "Xã Ngũ Hiệp",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1109",
    name: "Xã Tả Thanh Oai",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1110",
    name: "Xã Tam Hiệp",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1111",
    name: "Xã Tân Triều",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1112",
    name: "Xã Thanh Liệt",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1113",
    name: "Xã Tứ Hiệp",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1114",
    name: "Xã Vạn Phúc",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1115",
    name: "Xã Vĩnh Quỳnh",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1116",
    name: "Xã Yên Mỹ",
    is_higher_price: false,
    district_code: "1710",
  },
  {
    code: "1A1201",
    name: "Thị trấn Trâu Quỳ",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1202",
    name: "Thị trấn Yên Viên",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1203",
    name: "Xã Bát Tràng",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1204",
    name: "Xã Cổ Bi",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1205",
    name: "Xã Đa Tốn",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1206",
    name: "Xã Đặng Xá",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1207",
    name: "Xã Đình Xuyên",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1208",
    name: "Xã Đông Dư",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1209",
    name: "Xã Dương Hà",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1210",
    name: "Xã Dương Quang",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1211",
    name: "Xã Dương Xá",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1212",
    name: "Xã Kiêu Kỵ",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1213",
    name: "Xã Kim Lan",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1214",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1215",
    name: "Xã Lệ Chi",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1216",
    name: "Xã Ninh Hiệp",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1217",
    name: "Xã Phù Đổng",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1218",
    name: "Xã Phú Thị",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1219",
    name: "Xã Trung Mầu",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1220",
    name: "Xã Văn Đức",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1221",
    name: "Xã Yên Thường",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1222",
    name: "Xã Yên Viên",
    is_higher_price: false,
    district_code: "1703",
  },
  {
    code: "1A1301",
    name: "Thị trấn Đông Anh",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1302",
    name: "Xã Bắc Hồng",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1303",
    name: "Xã Cổ Loa",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1304",
    name: "Xã Đại Mạch",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1305",
    name: "Xã Đông Hội",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1306",
    name: "Xã Dục Tú",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1307",
    name: "Xã Hải Bối",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1308",
    name: "Xã Kim Chung",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1309",
    name: "Xã Kim Nỗ",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1310",
    name: "Xã Liên Hà",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1311",
    name: "Xã Mai Lâm",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1312",
    name: "Xã Nam Hồng",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1313",
    name: "Xã Nguyên Khê",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1314",
    name: "Xã Tầm Xá",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1315",
    name: "Xã Thuỵ Lâm",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1316",
    name: "Xã Tiên Dương",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1317",
    name: "Xã Uy Nỗ",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1318",
    name: "Xã Vân Hà",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1319",
    name: "Xã Vân Nội",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1320",
    name: "Xã Việt Hùng",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1321",
    name: "Xã Vĩnh Ngọc",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1322",
    name: "Xã Võng La",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1323",
    name: "Xã Xuân Canh",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1324",
    name: "Xã Xuân Nộn",
    is_higher_price: false,
    district_code: "1582",
  },
  {
    code: "1A1401",
    name: "Thị trấn Sóc Sơn",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1402",
    name: "Xã Bắc Phú",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1403",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1404",
    name: "Xã Đông Xuân",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1405",
    name: "Xã Đức Hoà",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1406",
    name: "Xã Hiền Ninh",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1407",
    name: "Xã Hồng Kỳ",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1408",
    name: "Xã Kim Lũ",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1409",
    name: "Xã Mai Đình",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1410",
    name: "Xã Minh Phú",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1411",
    name: "Xã Minh Trí",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1412",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1413",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1414",
    name: "Xã Phù Linh",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1415",
    name: "Xã Phù Lỗ",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1416",
    name: "Xã Phú Minh",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1417",
    name: "Xã Quang Tiến",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1418",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1419",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1420",
    name: "Xã Tân Minh",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1421",
    name: "Xã Thanh Xuân",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1422",
    name: "Xã Tiên Dược",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1423",
    name: "Xã Trung Giã",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1424",
    name: "Xã Việt Long",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1425",
    name: "Xã Xuân Giang",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1A1426",
    name: "Xã Xuân Thu",
    is_higher_price: false,
    district_code: "1583",
  },
  {
    code: "1B1501",
    name: "Phường Biên Giang",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1502",
    name: "Phường Đồng Mai",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1503",
    name: "Phường Dương Nội",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1504",
    name: "Phường Hà Cầu",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1505",
    name: "Phường Kiến Hưng",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1506",
    name: "Phường La Khê",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1507",
    name: "Phường Mộ Lao",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1508",
    name: "Phường Nguyễn Trãi",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1509",
    name: "Phường Phú La",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1510",
    name: "Phường Phú Lãm",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1511",
    name: "Phường Phú Lương",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1512",
    name: "Phường Phúc La",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1513",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1514",
    name: "Phường Vạn Phúc",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1515",
    name: "Phường Văn Quán",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1516",
    name: "Phường Yên Nghĩa",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1517",
    name: "Phường Yết Kiêu",
    is_higher_price: false,
    district_code: "1542",
  },
  {
    code: "1B1601",
    name: "Phường Lê Lợi",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1602",
    name: "Phường Ngô Quyền",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1603",
    name: "Phường Phú Thịnh",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1604",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1605",
    name: "Phường Sơn Lộc",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1606",
    name: "Phường Trung Hưng",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1607",
    name: "Phường Trung Sơn Trầm",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1608",
    name: "Phường Viên Sơn",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1609",
    name: "Phường Xuân Khanh",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1610",
    name: "Xã Cổ Đông",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1611",
    name: "Xã Đường Lâm",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1612",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1613",
    name: "Xã Sơn Đông",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1614",
    name: "Xã Thanh Mỹ",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1615",
    name: "Xã Xuân Sơn",
    is_higher_price: false,
    district_code: "1711",
  },
  {
    code: "1B1701",
    name: "Thị trấn Tây Đằng",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1702",
    name: "Xã Ba Trại",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1703",
    name: "Xã Ba Vì",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1704",
    name: "Xã Cẩm Lĩnh",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1705",
    name: "Xã Cam Thượng",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1706",
    name: "Xã Châu Sơn",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1707",
    name: "Xã Chu Minh",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1708",
    name: "Xã Cổ Đô",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1709",
    name: "Xã Đông Quang",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1710",
    name: "Xã Đồng Thái",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1711",
    name: "Xã Khánh Thượng",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1712",
    name: "Xã Minh Châu",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1713",
    name: "Xã Minh Quang",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1714",
    name: "Xã Phong Vân",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1715",
    name: "Xã Phú Châu",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1716",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1717",
    name: "Xã Phú Đông",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1718",
    name: "Xã Phú Phương",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1719",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1720",
    name: "Xã Sơn Đà",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1721",
    name: "Xã Tản Hồng",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1722",
    name: "Xã Tản Lĩnh",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1723",
    name: "Xã Thái Hòa",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1724",
    name: "Xã Thuần Mỹ",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1725",
    name: "Xã Thụy An",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1726",
    name: "Xã Tiên Phong",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1727",
    name: "Xã Tòng Bạt",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1728",
    name: "Xã Vân Hòa",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1729",
    name: "Xã Vạn Thắng",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1730",
    name: "Xã Vật Lại",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1731",
    name: "Xã Yên Bài",
    is_higher_price: false,
    district_code: "1803",
  },
  {
    code: "1B1801",
    name: "Thị trấn Phúc Thọ",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1802",
    name: "Xã Cẩm Đình",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1803",
    name: "Xã Hát Môn",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1804",
    name: "Xã Hiệp Thuận",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1805",
    name: "Xã Liên Hiệp",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1806",
    name: "Xã Long Xuyên",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1807",
    name: "Xã Ngọc Tảo",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1808",
    name: "Xã Phúc Hòa",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1809",
    name: "Xã Phụng Thượng",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1810",
    name: "Xã Phương Độ",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1811",
    name: "Xã Sen Chiểu",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1812",
    name: "Xã Tam Hiệp",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1813",
    name: "Xã Tam Thuấn",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1814",
    name: "Xã Thanh Đa",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1815",
    name: "Xã Thọ Lộc",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1816",
    name: "Xã Thượng Cốc",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1817",
    name: "Xã Tích Giang",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1818",
    name: "Xã Trạch Mỹ Lộc",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1819",
    name: "Xã Vân Hà",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1820",
    name: "Xã Vân Nam",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1821",
    name: "Xã Vân Phúc",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1822",
    name: "Xã Võng Xuyên",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1823",
    name: "Xã Xuân Phú",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "1B1901",
    name: "Thị trấn Liên Quan",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1902",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1903",
    name: "Xã Bình Yên",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1904",
    name: "Xã Cẩm Yên",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1905",
    name: "Xã Cần Kiệm",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1906",
    name: "Xã Canh Nậu",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1907",
    name: "Xã Chàng Sơn",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1908",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1909",
    name: "Xã Dị Nậu",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1910",
    name: "Xã Đồng Trúc",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1911",
    name: "Xã Hạ Bằng",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1912",
    name: "Xã Hương Ngải",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1913",
    name: "Xã Hữu Bằng",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1914",
    name: "Xã Kim Quan",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1915",
    name: "Xã Lại Thượng",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1916",
    name: "Xã Phú Kim",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1917",
    name: "Xã Phùng Xá",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1918",
    name: "Xã Tân Xã",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1919",
    name: "Xã Thạch Hoà",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1920",
    name: "Xã Thạch Xá",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1921",
    name: "Xã Tiến Xuân",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1922",
    name: "Xã Yên Bình",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B1923",
    name: "Xã Yên Trung",
    is_higher_price: false,
    district_code: "1808",
  },
  {
    code: "1B2001",
    name: "Thị trấn Quốc Oai",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2002",
    name: "Xã Cấn Hữu",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2003",
    name: "Xã Cộng Hòa",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2004",
    name: "Xã Đại Thành",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2005",
    name: "Xã Đồng Quang",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2006",
    name: "Xã Đông Xuân",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2007",
    name: "Xã Đông Yên",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2008",
    name: "Xã Hòa Thạch",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2009",
    name: "Xã Liệp Tuyết",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2010",
    name: "Xã Nghĩa Hương",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2011",
    name: "Xã Ngọc Liệp",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2012",
    name: "Xã Ngọc Mỹ",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2013",
    name: "Xã Phú Cát",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2014",
    name: "Xã Phú Mãn",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2015",
    name: "Xã Phượng Cách",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2016",
    name: "Xã Sài Sơn",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2017",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2018",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2019",
    name: "Xã Thạch Thán",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2020",
    name: "Xã Tuyết Nghĩa",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2021",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "2004",
  },
  {
    code: "1B2101",
    name: "Thị trấn Chúc Sơn",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2102",
    name: "Thị trấn Xuân Mai",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2103",
    name: "Xã Đại Yên",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2104",
    name: "Xã Đồng Lạc",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2105",
    name: "Xã Đồng Phú",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2106",
    name: "Xã Đông Phương Yên",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2107",
    name: "Xã Đông Sơn",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2108",
    name: "Xã Hòa Chính",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2109",
    name: "Xã Hoàng Diệu",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2110",
    name: "Xã Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2111",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2112",
    name: "Xã Hợp Đồng",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2113",
    name: "Xã Hữu Văn",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2114",
    name: "Xã Lam Điền",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2115",
    name: "Xã Mỹ Lương",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2116",
    name: "Xã Nam Phương Tiến",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2117",
    name: "Xã Ngọc Hòa",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2118",
    name: "Xã Phú Nam An",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2119",
    name: "Xã Phú Nghĩa",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2120",
    name: "Xã Phụng Châu",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2121",
    name: "Xã Quảng Bị",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2122",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2123",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2124",
    name: "Xã Thượng Vực",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2125",
    name: "Xã Thụy Hương",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2126",
    name: "Xã Thủy Xuân Tiên",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2127",
    name: "Xã Tiên Phương",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2128",
    name: "Xã Tốt Động",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2129",
    name: "Xã Trần Phú",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2130",
    name: "Xã Trung Hòa",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2131",
    name: "Xã Trường Yên",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2132",
    name: "Xã Văn Võ",
    is_higher_price: false,
    district_code: "1915",
  },
  {
    code: "1B2201",
    name: "Thị trấn Phùng",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2202",
    name: "Xã Đan Phượng",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2203",
    name: "Xã Đồng Tháp",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2204",
    name: "Xã Hạ Mỗ",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2205",
    name: "Xã Hồng Hà",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2206",
    name: "Xã Liên Hà",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2207",
    name: "Xã Liên Hồng",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2208",
    name: "Xã Liên Trung",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2209",
    name: "Xã Phương Đình",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2210",
    name: "Xã Song Phượng",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2211",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2212",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2213",
    name: "Xã Thọ An",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2214",
    name: "Xã Thọ Xuân",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2215",
    name: "Xã Thượng Mỗ",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2216",
    name: "Xã Trung Châu",
    is_higher_price: false,
    district_code: "1804",
  },
  {
    code: "1B2301",
    name: "Thị trấn Trạm Trôi",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2302",
    name: "Xã An Khánh",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2303",
    name: "Xã An Thượng",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2304",
    name: "Xã Cát Quế",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2305",
    name: "Xã Đắc Sở",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2306",
    name: "Xã Di Trạch",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2307",
    name: "Xã Đông La",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2308",
    name: "Xã Đức Giang",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2309",
    name: "Xã Đức Thượng",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2310",
    name: "Xã Dương Liễu",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2311",
    name: "Xã Kim Chung",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2312",
    name: "Xã La Phù",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2313",
    name: "Xã Lại Yên",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2314",
    name: "Xã Minh Khai",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2315",
    name: "Xã Sơn Đồng",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2316",
    name: "Xã Song Phương",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2317",
    name: "Xã Tiền Yên",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2318",
    name: "Xã Vân Canh",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2319",
    name: "Xã Vân Côn",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2320",
    name: "Xã Yên Sở",
    is_higher_price: false,
    district_code: "1805",
  },
  {
    code: "1B2401",
    name: "Thị trấn Kim Bài",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2402",
    name: "Xã Bích Hòa",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2403",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2404",
    name: "Xã Cao Dương",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2405",
    name: "Xã Cao Viên",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2406",
    name: "Xã Cự Khê",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2407",
    name: "Xã Dân Hòa",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2408",
    name: "Xã Đỗ Động",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2409",
    name: "Xã Hồng Dương",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2410",
    name: "Xã Kim An",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2411",
    name: "Xã Kim Thư",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2412",
    name: "Xã Liên Châu",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2413",
    name: "Xã Mỹ Hưng",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2414",
    name: "Xã Phương Trung",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2415",
    name: "Xã Tam Hưng",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2416",
    name: "Xã Tân Ước",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2417",
    name: "Xã Thanh Cao",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2418",
    name: "Xã Thanh Mai",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2419",
    name: "Xã Thanh Thùy",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2420",
    name: "Xã Thanh Văn",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2421",
    name: "Xã Xuân Dương",
    is_higher_price: false,
    district_code: "1809",
  },
  {
    code: "1B2501",
    name: "Thị trấn Đại Nghĩa",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2502",
    name: "Xã An Mỹ",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2503",
    name: "Xã An Phú",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2504",
    name: "Xã An Tiến",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2505",
    name: "Xã Bột Xuyên",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2506",
    name: "Xã Đại Hưng",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2507",
    name: "Xã Đốc Tín",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2508",
    name: "Xã Đồng Tâm",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2509",
    name: "Xã Hồng Sơn",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2510",
    name: "Xã Hợp Thanh",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2511",
    name: "Xã Hợp Tiến",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2512",
    name: "Xã Hùng Tiến",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2513",
    name: "Xã Hương Sơn",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2514",
    name: "Xã Lê Thanh",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2515",
    name: "Xã Mỹ Thành",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2516",
    name: "Xã Phù Lưu Tế",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2517",
    name: "Xã Phúc Lâm",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2518",
    name: "Xã Phùng Xá",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2519",
    name: "Xã Thượng Lâm",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2520",
    name: "Xã Tuy Lai",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2521",
    name: "Xã Vạn Kim",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2522",
    name: "Xã Xuy Xá",
    is_higher_price: false,
    district_code: "1806",
  },
  {
    code: "1B2601",
    name: "Thị trấn Vân Đình",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2602",
    name: "Xã Cao Thành",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2603",
    name: "Xã Đại Cường",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2604",
    name: "Xã Đại Hùng",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2605",
    name: "Xã Đội Bình",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2606",
    name: "Xã Đông Lỗ",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2607",
    name: "Xã Đồng Tân",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2608",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2609",
    name: "Xã Hòa Lâm",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2610",
    name: "Xã Hòa Nam",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2611",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2612",
    name: "Xã Hoa Sơn",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2613",
    name: "Xã Hòa Xá",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2614",
    name: "Xã Hồng Quang",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2615",
    name: "Xã Kim Đường",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2616",
    name: "Xã Liên Bạt",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2617",
    name: "Xã Lưu Hoàng",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2618",
    name: "Xã Minh Đức",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2619",
    name: "Xã Phù Lưu",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2620",
    name: "Xã Phương Tú",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2621",
    name: "Xã Quảng Phú Cầu",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2622",
    name: "Xã Sơn Công",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2623",
    name: "Xã Tảo Dương Văn",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2624",
    name: "Xã Trầm Lộng",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2625",
    name: "Xã Trung Tú",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2626",
    name: "Xã Trường Thịnh",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2627",
    name: "Xã Vạn Thái",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2628",
    name: "Xã Viên An",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2629",
    name: "Xã Viên Nội",
    is_higher_price: false,
    district_code: "1810",
  },
  {
    code: "1B2701",
    name: "Thị trấn Thường Tín",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2702",
    name: "Xã Chương Dương",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2703",
    name: "Xã Dũng Tiến",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2704",
    name: "Xã Duyên Thái",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2705",
    name: "Xã Hà Hồi",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2706",
    name: "Xã Hiền Giang",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2707",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2708",
    name: "Xã Hồng Vân",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2709",
    name: "Xã Khánh Hà",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2710",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2711",
    name: "Xã Liên Phương",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2712",
    name: "Xã Minh Cường",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2713",
    name: "Xã Nghiêm Xuyên",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2714",
    name: "Xã Nguyễn Trãi",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2715",
    name: "Xã Nhị Khê",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2716",
    name: "Xã Ninh Sở",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2717",
    name: "Xã Quất Động",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2718",
    name: "Xã Tân Minh",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2719",
    name: "Xã Thắng Lợi",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2720",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2721",
    name: "Xã Thư Phú",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2722",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2723",
    name: "Xã Tô Hiệu",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2724",
    name: "Xã Tự Nhiên",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2725",
    name: "Xã Văn Bình",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2726",
    name: "Xã Vạn Điểm",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2727",
    name: "Xã Văn Phú",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2728",
    name: "Xã Vân Tảo",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2729",
    name: "Xã Văn Tự",
    is_higher_price: false,
    district_code: "3303",
  },
  {
    code: "1B2801",
    name: "Thị trấn Phú Minh",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2802",
    name: "Thị trấn Phú Xuyên",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2803",
    name: "Xã Bạch Hạ",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2804",
    name: "Xã Châu Can",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2805",
    name: "Xã Chuyên Mỹ",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2806",
    name: "Xã Đại Thắng",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2807",
    name: "Xã Đại Xuyên",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2808",
    name: "Xã Hoàng Long",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2809",
    name: "Xã Hồng Minh",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2810",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2811",
    name: "Xã Khai Thái",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2812",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2813",
    name: "Xã Nam Phong",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2814",
    name: "Xã Nam Triều",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2815",
    name: "Xã Phú Túc",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2816",
    name: "Xã Phú Yên",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2817",
    name: "Xã Phúc Tiến",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2818",
    name: "Xã Phượng Dực",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2819",
    name: "Xã Quang Lãng",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2820",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2821",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2822",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2823",
    name: "Xã Thụy Phú",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2824",
    name: "Xã Tri Thủy",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2825",
    name: "Xã Tri Trung",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2826",
    name: "Xã Văn Hoàng",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2827",
    name: "Xã Văn Nhân",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2828",
    name: "Xã Vân Từ",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "1B2901",
    name: "Thị trấn Chi Đông",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2902",
    name: "Thị trấn Quang Minh",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2903",
    name: "Xã Chu Phan",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2904",
    name: "Xã Đại Thịnh",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2905",
    name: "Xã Hoàng Kim",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2906",
    name: "Xã Kim Hoa",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2907",
    name: "Xã Liên Mạc",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2908",
    name: "Xã Mê Linh",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2909",
    name: "Xã Tam Đồng",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2910",
    name: "Xã Thạch Đà",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2911",
    name: "Xã Thanh Lâm",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2912",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2913",
    name: "Xã Tiến Thắng",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2914",
    name: "Xã Tiến Thịnh",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2915",
    name: "Xã Tráng Việt",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2916",
    name: "Xã Tự Lập",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2917",
    name: "Xã Văn Khê",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "1B2918",
    name: "Xã Vạn Yên",
    is_higher_price: false,
    district_code: "1581",
  },
  {
    code: "20101",
    name: "Phường Bến Nghé",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20102",
    name: "Phường Bến Thành",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20103",
    name: "Phường Cầu Kho",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20104",
    name: "Phường Cầu Ông Lãnh",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20105",
    name: "Phường Cô Giang",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20106",
    name: "Phường Đa Kao",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20107",
    name: "Phường Nguyễn Cư Trinh",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20108",
    name: "Phường Nguyễn Thái Bình",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20109",
    name: "Phường Phạm Ngũ Lão",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20110",
    name: "Phường Tân Định",
    is_higher_price: false,
    district_code: "1442",
  },
  {
    code: "20201",
    name: "Phường An Khánh",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20202",
    name: "Phường An Lợi Đông",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20203",
    name: "Phường An Phú",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20204",
    name: "Phường Bình An",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20205",
    name: "Phường Bình Khánh",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20206",
    name: "Phường Bình Trưng Đông",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20207",
    name: "Phường Bình Trưng Tây",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20208",
    name: "Phường Cát Lái",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20209",
    name: "Phường Thạnh Mỹ Lợi",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20210",
    name: "Phường Thảo Điền",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20211",
    name: "Phường Thủ Thiêm",
    is_higher_price: false,
    district_code: "1443",
  },
  {
    code: "20301",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20302",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20303",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20304",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20305",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20306",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20307",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20308",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20309",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20310",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20311",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20312",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20313",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20314",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1444",
  },
  {
    code: "20401",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20402",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20403",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20404",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20405",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20406",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20407",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20408",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20409",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20410",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20411",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20412",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20413",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20414",
    name: "Phường 16",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20415",
    name: "Phường 18",
    is_higher_price: false,
    district_code: "1446",
  },
  {
    code: "20501",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20502",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20503",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20504",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20505",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20506",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20507",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20508",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20509",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20510",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20511",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20512",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20513",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20514",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20515",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1447",
  },
  {
    code: "20601",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20602",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20603",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20604",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20605",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20606",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20607",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20608",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20609",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20610",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20611",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20612",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20613",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20614",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1448",
  },
  {
    code: "20701",
    name: "Phường Bình Thuận",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20702",
    name: "Phường Phú Mỹ",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20703",
    name: "Phường Phú Thuận",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20704",
    name: "Phường Tân Hưng",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20705",
    name: "Phường Tân Kiểng",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20706",
    name: "Phường Tân Phong",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20707",
    name: "Phường Tân Phú",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20708",
    name: "Phường Tân Quy",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20709",
    name: "Phường Tân Thuận Đông",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20710",
    name: "Phường Tân Thuận Tây",
    is_higher_price: false,
    district_code: "1449",
  },
  {
    code: "20801",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20802",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20803",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20804",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20805",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20806",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20807",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20808",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20809",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20810",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20811",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20812",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20813",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20814",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20815",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20816",
    name: "Phường 16",
    is_higher_price: false,
    district_code: "1450",
  },
  {
    code: "20901",
    name: "Phường Hiệp Phú",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20902",
    name: "Phường Long Bình",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20903",
    name: "Phường Long Phước",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20904",
    name: "Phường Long Thạnh Mỹ",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20905",
    name: "Phường Long Trường",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20906",
    name: "Phường Phú Hữu",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20907",
    name: "Phường Phước Bình",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20908",
    name: "Phường Phước Long A",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20909",
    name: "Phường Phước Long B",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20910",
    name: "Phường Tân Phú",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20911",
    name: "Phường Tăng Nhơn Phú A",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20912",
    name: "Phường Tăng Nhơn Phú B",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "20913",
    name: "Phường Trường Thạnh",
    is_higher_price: false,
    district_code: "1451",
  },
  {
    code: "21001",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21002",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21003",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21004",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21005",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21006",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21007",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21008",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21009",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21010",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "210101",
    name: "Phường Ái Quốc",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210102",
    name: "Phường Bình Hàn",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210103",
    name: "Phường Cẩm Thượng",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210104",
    name: "Phường Hải Tân",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210105",
    name: "Phường Lê Thanh Nghị",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210106",
    name: "Phường Ngọc Châu",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210107",
    name: "Phường Nguyễn Trãi",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210108",
    name: "Phường Nhị Châu",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210109",
    name: "Phường Phạm Ngũ Lão",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "21011",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "210110",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210111",
    name: "Phường Tân Bình",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210112",
    name: "Phường Thạch Khôi",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210113",
    name: "Phường Thanh Bình",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210114",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210115",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210116",
    name: "Phường Tứ Minh",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210117",
    name: "Phường Việt Hoà",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210118",
    name: "Xã An Châu",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210119",
    name: "Xã Nam Đồng",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "21012",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "210120",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "210121",
    name: "Xã Thượng Đạt",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "21013",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21014",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "21015",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1452",
  },
  {
    code: "210201",
    name: "Phường Bến Tắm",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210202",
    name: "Phường Chí Minh",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210203",
    name: "Phường Cộng Hoà",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210204",
    name: "Phường Hoàng Tân",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210205",
    name: "Phường Phả Lại",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210206",
    name: "Phường Sao Đỏ",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210207",
    name: "Phường Thái Học",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210208",
    name: "Phường Văn An",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210209",
    name: "Xã An Lạc",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210210",
    name: "Xã Bắc An",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210211",
    name: "Xã Cổ Thành",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210212",
    name: "Xã Đồng Lạc",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210213",
    name: "Xã Hoàng Hoa Thám",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210214",
    name: "Xã Hoàng Tiến",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210215",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210216",
    name: "Xã Kênh Giang",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210217",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210218",
    name: "Xã Nhân Huệ",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210219",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210220",
    name: "Xã Văn Đức",
    is_higher_price: false,
    district_code: "2056",
  },
  {
    code: "210301",
    name: "Thị trấn Nam Sách",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210302",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210303",
    name: "Xã An Lâm",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210304",
    name: "Xã An Sơn",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210305",
    name: "Xã Cộng Hòa",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210306",
    name: "Xã Đồng Lạc",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210307",
    name: "Xã Hiệp Cát",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210308",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210309",
    name: "Xã Hợp Tiến",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210310",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210311",
    name: "Xã Nam Chính",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210312",
    name: "Xã Nam Hồng",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210313",
    name: "Xã Nam Hưng",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210314",
    name: "Xã Nam Tân",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210315",
    name: "Xã Nam Trung",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210316",
    name: "Xã Phú Điền",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210317",
    name: "Xã Quốc Tuấn",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210318",
    name: "Xã Thái Tân",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210319",
    name: "Xã Thanh Quang",
    is_higher_price: false,
    district_code: "1727",
  },
  {
    code: "210401",
    name: "Thị trấn Kinh Môn",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210402",
    name: "Thị trấn Minh Tân",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210403",
    name: "Thị trấn Phú Thứ",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210404",
    name: "Xã An Phụ",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210405",
    name: "Xã An Sinh",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210406",
    name: "Xã Bạch Đằng",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210407",
    name: "Xã Duy Tân",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210408",
    name: "Xã Hiến Thành",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210409",
    name: "Xã Hiệp An",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210410",
    name: "Xã Hiệp Hòa",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210411",
    name: "Xã Hiệp Sơn",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210412",
    name: "Xã Hoành Sơn",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210413",
    name: "Xã Lạc Long",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210414",
    name: "Xã Lê Ninh",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210415",
    name: "Xã Long Xuyên",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210416",
    name: "Xã Minh Hòa",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210417",
    name: "Xã Phạm Mệnh",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210418",
    name: "Xã Phúc Thành B",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210419",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210420",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210421",
    name: "Xã Thái Sơn",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210422",
    name: "Xã Thái Thịnh",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210423",
    name: "Xã Thăng Long",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210424",
    name: "Xã Thất Hùng",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210425",
    name: "Xã Thượng Quận",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "210501",
    name: "Thị trấn Gia Lộc",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210502",
    name: "Xã Đoàn Thượng",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210503",
    name: "Xã Đồng Quang",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210504",
    name: "Xã Đức Xương",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210505",
    name: "Xã Gia Hòa",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210506",
    name: "Xã Gia Khánh",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210507",
    name: "Xã Gia Lương",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210508",
    name: "Xã Gia Tân",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210509",
    name: "Xã Gia Xuyên",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210510",
    name: "Xã Hoàng Diệu",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210511",
    name: "Xã Hồng Hưng",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210512",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210513",
    name: "Xã Liên Hồng",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210514",
    name: "Xã Nhật Tân",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210515",
    name: "Xã Phạm Trấn",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210516",
    name: "Xã Phương Hưng",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210517",
    name: "Xã Quang Minh",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210518",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210519",
    name: "Xã Thống Kênh",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210520",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210521",
    name: "Xã Toàn Thắng",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210522",
    name: "Xã Trùng Khánh",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210523",
    name: "Xã Yết Kiêu",
    is_higher_price: false,
    district_code: "1934",
  },
  {
    code: "210601",
    name: "Thị trấn Tứ Kỳ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210602",
    name: "Xã An Thanh",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210603",
    name: "Xã Bình Lăng",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210604",
    name: "Xã Cộng Lạc",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210605",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210606",
    name: "Xã Đại Hợp",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210607",
    name: "Xã Dân Chủ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210608",
    name: "Xã Đông Kỳ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210609",
    name: "Xã Hà Kỳ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210610",
    name: "Xã Hà Thanh",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210611",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210612",
    name: "Xã Kỳ Sơn",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210613",
    name: "Xã Minh Đức",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210614",
    name: "Xã Ngọc Kỳ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210615",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210616",
    name: "Xã Nguyên Giáp",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210617",
    name: "Xã Phượng Kỳ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210618",
    name: "Xã Quang Khải",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210619",
    name: "Xã Quảng Nghiệp",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210620",
    name: "Xã Quang Phục",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210621",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210622",
    name: "Xã Tái Sơn",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210623",
    name: "Xã Tân Kỳ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210624",
    name: "Xã Tây Kỳ",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210625",
    name: "Xã Tiên Động",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210626",
    name: "Xã Tứ Xuyên",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210627",
    name: "Xã Văn Tố",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "210701",
    name: "Thị trấn Thanh Miện",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210702",
    name: "Xã Cao Thắng",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210703",
    name: "Xã Chi Lăng Bắc",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210704",
    name: "Xã Chi Lăng Nam",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210705",
    name: "Xã Diên Hồng",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210706",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210707",
    name: "Xã Đoàn Tùng",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210708",
    name: "Xã Hồng Quang",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210709",
    name: "Xã Hùng Sơn",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210710",
    name: "Xã Lam Sơn",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210711",
    name: "Xã Lê Hồng",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210712",
    name: "Xã Ngô Quyền",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210713",
    name: "Xã Ngũ Hùng",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210714",
    name: "Xã Phạm Kha",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210715",
    name: "Xã Tân Trào",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210716",
    name: "Xã Thanh Giang",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210717",
    name: "Xã Thanh Tùng",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210718",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210719",
    name: "Xã Tứ Cường",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "210801",
    name: "Thị trấn Ninh Giang",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210802",
    name: "Xã An Đức",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210803",
    name: "Xã Đồng Tâm",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210804",
    name: "Xã Đông Xuyên",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210805",
    name: "Xã Hiệp Lực",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210806",
    name: "Xã Hoàng Hanh",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210807",
    name: "Xã Hồng Dụ",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210808",
    name: "Xã Hồng Đức",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210809",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210810",
    name: "Xã Hồng Phúc",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210811",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210812",
    name: "Xã Hưng Long",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210813",
    name: "Xã Hưng Thái",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210814",
    name: "Xã Kiến Quốc",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210815",
    name: "Xã Nghĩa An",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210816",
    name: "Xã Ninh Hải",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210817",
    name: "Xã Ninh Hòa",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210818",
    name: "Xã Ninh Thành",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210819",
    name: "Xã Quang Hưng",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210820",
    name: "Xã Quyết Thắng",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210821",
    name: "Xã Tân Hương",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210822",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210823",
    name: "Xã Tân Quang",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210824",
    name: "Xã Ứng Hoè",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210825",
    name: "Xã Văn Giang",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210826",
    name: "Xã Văn Hội",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210827",
    name: "Xã Vạn Phúc",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210828",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "3238",
  },
  {
    code: "210901",
    name: "Thị trấn Cẩm Giàng",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210902",
    name: "Thị trấn Lai Cách",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210903",
    name: "Xã Cẩm Điền",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210904",
    name: "Xã Cẩm Định",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210905",
    name: "Xã Cẩm Đoài",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210906",
    name: "Xã Cẩm Đông",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210907",
    name: "Xã Cẩm Hoàng",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210908",
    name: "Xã Cẩm Hưng",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210909",
    name: "Xã Cẩm Phúc",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210910",
    name: "Xã Cẩm Sơn",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210911",
    name: "Xã Cẩm Văn",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210912",
    name: "Xã Cẩm Vũ",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210913",
    name: "Xã Cao An",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210914",
    name: "Xã Đức Chính",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210915",
    name: "Xã Kim Giang",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210916",
    name: "Xã Lương Điền",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210917",
    name: "Xã Ngọc Liên",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210918",
    name: "Xã Tân Trường",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "210919",
    name: "Xã Thạch Lỗi",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "211001",
    name: "Thị trấn Thanh Hà",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211002",
    name: "Xã An Lương",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211003",
    name: "Xã Cẩm Chế",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211004",
    name: "Xã Hồng Lạc",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211005",
    name: "Xã Hợp Đức",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211006",
    name: "Xã Liên Mạc",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211007",
    name: "Xã Phượng Hoàng",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211008",
    name: "Xã Quyết Thắng",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211009",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "21101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "211010",
    name: "Xã Tân Việt",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211011",
    name: "Xã Thanh An",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211012",
    name: "Xã Thanh Bính",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211013",
    name: "Xã Thanh Cường",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211014",
    name: "Xã Thanh Hải",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211015",
    name: "Xã Thanh Hồng",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211016",
    name: "Xã Thanh Khê",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211017",
    name: "Xã Thanh Lang",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211018",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211019",
    name: "Xã Thanh Thủy",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "21102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "211020",
    name: "Xã Thanh Xá",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211021",
    name: "Xã Thanh Xuân",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211022",
    name: "Xã Tiền Tiến",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211023",
    name: "Xã Trường Thành",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211024",
    name: "Xã Việt Hồng",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "211025",
    name: "Xã Vĩnh Lập",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "21103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21106",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21107",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21108",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21109",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21110",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "211101",
    name: "Thị trấn Phú Thái",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211102",
    name: "Xã Bình Dân",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211103",
    name: "Xã Cẩm La",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211104",
    name: "Xã Cổ Dũng",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211105",
    name: "Xã Cộng Hòa",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211106",
    name: "Xã Đại Đức",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211107",
    name: "Xã Đồng Gia",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211108",
    name: "Xã Kim Anh",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211109",
    name: "Xã Kim Đính",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "21111",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "211110",
    name: "Xã Kim Khê",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211111",
    name: "Xã Kim Lương",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211112",
    name: "Xã Kim Tân",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211113",
    name: "Xã Kim Xuyên",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211114",
    name: "Xã Lai Vu",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211115",
    name: "Xã Liên Hòa",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211116",
    name: "Xã Ngũ Phúc",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211117",
    name: "Xã Phúc Thành A",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211118",
    name: "Xã Tam Kỳ",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211119",
    name: "Xã Thượng Vũ",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "21112",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "211120",
    name: "Xã Tuấn Hưng",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "211121",
    name: "Xã Việt Hưng",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "21113",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21114",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21115",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "21116",
    name: "Phường 16",
    is_higher_price: false,
    district_code: "1453",
  },
  {
    code: "211201",
    name: "Thị trấn Kẻ Sặt",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211202",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211203",
    name: "Xã Bình Xuyên",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211204",
    name: "Xã Cổ Bi",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211205",
    name: "Xã Hồng Khê",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211206",
    name: "Xã Hùng Thắng",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211207",
    name: "Xã Hưng Thịnh",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211208",
    name: "Xã Long Xuyên",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211209",
    name: "Xã Nhân Quyền",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211210",
    name: "Xã Tân Hồng",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211211",
    name: "Xã Tân Việt",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211212",
    name: "Xã Thái Dương",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211213",
    name: "Xã Thái Hòa",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211214",
    name: "Xã Thái Học",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211215",
    name: "Xã Thúc Kháng",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211216",
    name: "Xã Tráng Liệt",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211217",
    name: "Xã Vĩnh Hồng",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "211218",
    name: "Xã Vĩnh Tuy",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "21201",
    name: "Phường An Phú Đông",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21202",
    name: "Phường Đông Hưng Thuận",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21203",
    name: "Phường Hiệp Thành",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21204",
    name: "Phường Tân Chánh Hiệp",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21205",
    name: "Phường Tân Hưng Thuận",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21206",
    name: "Phường Tân Thới Hiệp",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21207",
    name: "Phường Tân Thới Nhất",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21208",
    name: "Phường Thạnh Lộc",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21209",
    name: "Phường Thạnh Xuân",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21210",
    name: "Phường Thới An",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21211",
    name: "Phường Trung Mỹ Tây",
    is_higher_price: false,
    district_code: "1454",
  },
  {
    code: "21301",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21302",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21303",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21304",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21305",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21306",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21307",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21308",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21309",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21310",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21311",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21312",
    name: "Phường 16",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21313",
    name: "Phường 17",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21314",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21315",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21316",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1461",
  },
  {
    code: "21401",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21402",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21403",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21404",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21405",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21406",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21407",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21408",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21409",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21410",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21411",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21412",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21413",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21414",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21415",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1455",
  },
  {
    code: "21501",
    name: "Phường Hiệp Tân",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21502",
    name: "Phường Hòa Thạnh",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21503",
    name: "Phường Phú Thạnh",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21504",
    name: "Phường Phú Thọ Hòa",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21505",
    name: "Phường Phú Trung",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21506",
    name: "Phường Sơn Kỳ",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21507",
    name: "Phường Tân Quý",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21508",
    name: "Phường Tân Sơn Nhì",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21509",
    name: "Phường Tân Thành",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21510",
    name: "Phường Tân Thới Hòa",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21511",
    name: "Phường Tây Thạnh",
    is_higher_price: false,
    district_code: "1456",
  },
  {
    code: "21601",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21602",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21603",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21604",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21605",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21606",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21607",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21608",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21609",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21610",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21611",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21612",
    name: "Phường 17",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21613",
    name: "Phường 19",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21614",
    name: "Phường 21",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21615",
    name: "Phường 22",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21616",
    name: "Phường 24",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21617",
    name: "Phường 25",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21618",
    name: "Phường 26",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21619",
    name: "Phường 27",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21620",
    name: "Phường 28",
    is_higher_price: false,
    district_code: "1462",
  },
  {
    code: "21701",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21702",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21703",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21704",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21705",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21706",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21707",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21708",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21709",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21710",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21711",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21712",
    name: "Phường 13",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21713",
    name: "Phường 14",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21714",
    name: "Phường 15",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21715",
    name: "Phường 17",
    is_higher_price: false,
    district_code: "1457",
  },
  {
    code: "21801",
    name: "Phường Bình Chiểu",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21802",
    name: "Phường Bình Thọ",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21803",
    name: "Phường Hiệp Bình Chánh",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21804",
    name: "Phường Hiệp Bình Phước",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21805",
    name: "Phường Linh Chiểu",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21806",
    name: "Phường Linh Đông",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21807",
    name: "Phường Linh Tây",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21808",
    name: "Phường Linh Trung",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21809",
    name: "Phường Linh Xuân",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21810",
    name: "Phường Tam Bình",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21811",
    name: "Phường Tam Phú",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21812",
    name: "Phường Trường Thọ",
    is_higher_price: false,
    district_code: "1463",
  },
  {
    code: "21901",
    name: "Phường  An Lạc",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21902",
    name: "Phường An Lạc A",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21903",
    name: "Phường Bình Hưng Hòa",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21904",
    name: "Phường Bình Hưng Hoà A",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21905",
    name: "Phường Bình Hưng Hoà B",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21906",
    name: "Phường Bình Trị Đông",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21907",
    name: "Phường Bình Trị Đông A",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21908",
    name: "Phường Bình Trị Đông B",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21909",
    name: "Phường Tân Tạo",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "21910",
    name: "Phường Tân Tạo A",
    is_higher_price: false,
    district_code: "1458",
  },
  {
    code: "22001",
    name: "Thị trấn Tân Túc",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22002",
    name: "Xã An Phú Tây",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22003",
    name: "Xã Bình Chánh",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22004",
    name: "Xã Bình Hưng",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22005",
    name: "Xã Bình Lợi",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22006",
    name: "Xã Đa Phước",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22007",
    name: "Xã Hưng Long",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22008",
    name: "Xã Lê Minh Xuân",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22009",
    name: "Xã Phạm Văn Hai",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22010",
    name: "Xã Phong Phú",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "220101",
    name: "Phường An Tảo",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220102",
    name: "Phường Hiến Nam",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220103",
    name: "Phường Hồng Châu",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220104",
    name: "Phường Lam Sơn",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220105",
    name: "Phường Lê Lợi",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220106",
    name: "Phường Minh Khai",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220107",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220108",
    name: "Xã Bảo Khê",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220109",
    name: "Xã Hoàng Hanh",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "22011",
    name: "Xã Quy Đức",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "220110",
    name: "Xã Hồng Nam",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220111",
    name: "Xã Hùng Cường",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220112",
    name: "Xã Liên Phương",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220113",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220114",
    name: "Xã Phương Chiểu",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220115",
    name: "Xã Quảng Châu",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220116",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "220117",
    name: "Xã Trung Nghĩa",
    is_higher_price: false,
    district_code: "1680",
  },
  {
    code: "22012",
    name: "Xã Tân Kiên",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22013",
    name: "Xã Tân Nhựt",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22014",
    name: "Xã Tân Quý Tây",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22015",
    name: "Xã Vĩnh Lộc A",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "22016",
    name: "Xã Vĩnh Lộc B",
    is_higher_price: false,
    district_code: "1533",
  },
  {
    code: "220201",
    name: "Thị trấn Lương Bằng",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220202",
    name: "Xã Chính Nghĩa",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220203",
    name: "Xã Đồng Thanh",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220204",
    name: "Xã Đức Hợp",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220205",
    name: "Xã Hiệp Cường",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220206",
    name: "Xã Hùng An",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220207",
    name: "Xã Mai Động",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220208",
    name: "Xã Nghĩa Dân",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220209",
    name: "Xã Ngọc Thanh",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220210",
    name: "Xã Nhân La",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220211",
    name: "Xã Phạm Ngũ Lão",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220212",
    name: "Xã Phú Thịnh",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220213",
    name: "Xã Song Mai",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220214",
    name: "Xã Thọ Vinh",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220215",
    name: "Xã Toàn Thắng",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220216",
    name: "Xã Vĩnh Xá",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220217",
    name: "Xã Vũ Xá",
    is_higher_price: false,
    district_code: "1717",
  },
  {
    code: "220250",
    name: "Phường đặc biệt",
    is_higher_price: false,
    district_code: "1580",
  },
  {
    code: "2202b51",
    name: "Phường đặc biệt DC",
    is_higher_price: false,
    district_code: "3448",
  },
  {
    code: "220301",
    name: "Thị trấn Ân Thi",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220302",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220303",
    name: "Xã Bãi Sậy",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220304",
    name: "Xã Cẩm Ninh",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220305",
    name: "Xã Đa Lộc",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220306",
    name: "Xã Đặng Lễ",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220307",
    name: "Xã Đào Dương",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220308",
    name: "Xã Hạ Lễ",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220309",
    name: "Xã Hồ Tùng Mậu",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220310",
    name: "Xã Hoàng Hoa Thám",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220311",
    name: "Xã Hồng Quang",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220312",
    name: "Xã Hồng Vân",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220313",
    name: "Xã Nguyễn Trãi",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220314",
    name: "Xã Phù Ủng",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220315",
    name: "Xã Quảng Lãng",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220316",
    name: "Xã Quang Vinh",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220317",
    name: "Xã Tân Phúc",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220318",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220319",
    name: "Xã Vân Du",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220320",
    name: "Xã Văn Nhuệ",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220321",
    name: "Xã Xuân Trúc",
    is_higher_price: false,
    district_code: "1825",
  },
  {
    code: "220401",
    name: "Thị trấn Khoái Châu",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220402",
    name: "Xã An Vĩ",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220403",
    name: "Xã Bình Kiều",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220404",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220405",
    name: "Xã Chí Tân",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220406",
    name: "Xã Dạ Trạch",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220407",
    name: "Xã Đại Hưng",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220408",
    name: "Xã Đại Tập",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220409",
    name: "Xã Dân Tiến",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220410",
    name: "Xã Đông Kết",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220411",
    name: "Xã Đông Ninh",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220412",
    name: "Xã Đông Tảo",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220413",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220414",
    name: "Xã Hàm Tử",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220415",
    name: "Xã Hồng Tiến",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220416",
    name: "Xã Liên Khê",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220417",
    name: "Xã Nhuế Dương",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220418",
    name: "Xã Ông Đình",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220419",
    name: "Xã Phùng Hưng",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220420",
    name: "Xã Tân Châu",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220421",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220422",
    name: "Xã Thành Công",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220423",
    name: "Xã Thuần Hưng",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220424",
    name: "Xã Tứ Dân",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220425",
    name: "Xã Việt Hòa",
    is_higher_price: false,
    district_code: "1826",
  },
  {
    code: "220501",
    name: "Thị trấn Yên Mỹ",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220502",
    name: "Xã Đồng Than",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220503",
    name: "Xã Giai Phạm",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220504",
    name: "Xã Hoàn Long",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220505",
    name: "Xã Liêu Xá",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220506",
    name: "Xã Lý Thường Kiệt",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220507",
    name: "Xã Minh Châu",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220508",
    name: "Xã Nghĩa Hiệp",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220509",
    name: "Xã Ngọc Long",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220510",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220511",
    name: "Xã Tân Việt",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220512",
    name: "Xã Thanh Long",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220513",
    name: "Xã Trung Hòa",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220514",
    name: "Xã Trung Hưng",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220515",
    name: "Xã Việt Cường",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220516",
    name: "Xã Yên Hòa",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220517",
    name: "Xã Yên Phú",
    is_higher_price: false,
    district_code: "1828",
  },
  {
    code: "220601",
    name: "Thị trấn Vương",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220602",
    name: "Xã An Viên",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220603",
    name: "Xã Cương Chính",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220604",
    name: "Xã Dị Chế",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220605",
    name: "Xã Đức Thắng",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220606",
    name: "Xã Hải Triều",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220607",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220608",
    name: "Xã Lệ Xá",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220609",
    name: "Xã Minh Phương",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220610",
    name: "Xã Ngô Quyền",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220611",
    name: "Xã Nhật Tân",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220612",
    name: "Xã Thiện Phiến",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220613",
    name: "Xã Thủ Sỹ",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220614",
    name: "Xã Thụy Lôi",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220615",
    name: "Xã Trung Dũng",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "220701",
    name: "Thị trấn Trần Cao",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220702",
    name: "Xã Đình Cao",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220703",
    name: "Xã Đoàn Đào",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220704",
    name: "Xã Minh Hoàng",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220705",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220706",
    name: "Xã Minh Tiến",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220707",
    name: "Xã Nguyên Hòa",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220708",
    name: "Xã Nhật Quang",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220709",
    name: "Xã Phan Sào Nam",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220710",
    name: "Xã Quang Hưng",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220711",
    name: "Xã Tam Đa",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220712",
    name: "Xã Tiền Tiến",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220713",
    name: "Xã Tống Phan",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220714",
    name: "Xã Tống Trân",
    is_higher_price: false,
    district_code: "2194",
  },
  {
    code: "220801",
    name: "Thị trấn Bần Yên Nhân",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220802",
    name: "Xã Bạch Sam",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220803",
    name: "Xã Cẩm Xá",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220804",
    name: "Xã Dị Sử",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220805",
    name: "Xã Dương Quang",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220806",
    name: "Xã Hòa Phong",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220807",
    name: "Xã Hưng Long",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220808",
    name: "Xã Minh Đức",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220809",
    name: "Xã Ngọc Lâm",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220810",
    name: "Xã Nhân Hòa",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220811",
    name: "Xã Phan Đình Phùng",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220812",
    name: "Xã Phùng Chí Kiên",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220813",
    name: "Xã Xuân Dục",
    is_higher_price: false,
    district_code: "1827",
  },
  {
    code: "220901",
    name: "Thị trấn Như Quỳnh",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220902",
    name: "Xã Chỉ Đạo",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220903",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220904",
    name: "Xã Đình Dù",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220905",
    name: "Xã Lạc Đạo",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220906",
    name: "Xã Lạc Hồng",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220907",
    name: "Xã Lương Tài",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220908",
    name: "Xã Minh Hải",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220909",
    name: "Xã Tân Quang",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220910",
    name: "Xã Trưng Trắc",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "220911",
    name: "Xã Việt Hưng",
    is_higher_price: false,
    district_code: "2046",
  },
  {
    code: "221001",
    name: "Thị trấn Văn Giang",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221002",
    name: "Xã Cửu Cao",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221003",
    name: "Xã Liên Nghĩa",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221004",
    name: "Xã Long Hưng",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221005",
    name: "Xã Mễ Sở",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221006",
    name: "Xã Nghĩa Trụ",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221007",
    name: "Xã Phụng Công",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221008",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221009",
    name: "Xã Thắng Lợi",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "22101",
    name: "Thị trấn Củ Chi",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "221010",
    name: "Xã Vĩnh Khúc",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "221011",
    name: "Xã Xuân Quan",
    is_higher_price: false,
    district_code: "2045",
  },
  {
    code: "22102",
    name: "Xã An Nhơn Tây",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22103",
    name: "Xã An Phú",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22104",
    name: "Xã Bình Mỹ",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22105",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22106",
    name: "Xã Nhuận Đức",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22107",
    name: "Xã Phạm Văn Cội",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22108",
    name: "Xã Phú Hòa Đông",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22109",
    name: "Xã Phú Mỹ Hưng",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22110",
    name: "Xã Phước Hiệp",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22111",
    name: "Xã Phước Thạnh",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22112",
    name: "Xã Phước Vĩnh An",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22113",
    name: "Xã Tân An Hội",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22114",
    name: "Xã Tân Phú Trung",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22115",
    name: "Xã Tân Thạnh Đông",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22116",
    name: "Xã Tân Thạnh Tây",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22117",
    name: "Xã Tân Thông Hội",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22118",
    name: "Xã Thái Mỹ",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22119",
    name: "Xã Trung An",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22120",
    name: "Xã Trung Lập Hạ",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22121",
    name: "Xã Trung Lập Thượng",
    is_higher_price: false,
    district_code: "1460",
  },
  {
    code: "22201",
    name: "Thị trấn Hóc Môn",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22202",
    name: "Xã Bà Điểm",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22203",
    name: "Xã Đông Thạnh",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22204",
    name: "Xã Nhị Bình",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22205",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22206",
    name: "Xã Tân Thới Nhì",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22207",
    name: "Xã Tân Xuân",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22208",
    name: "Xã Thới Tam Thôn",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22209",
    name: "Xã Trung Chánh",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22210",
    name: "Xã Xuân Thới Đông",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22211",
    name: "Xã Xuân Thới Sơn",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22212",
    name: "Xã Xuân Thới Thượng",
    is_higher_price: false,
    district_code: "1459",
  },
  {
    code: "22301",
    name: "Thị trấn Nhà Bè",
    is_higher_price: false,
    district_code: "1534",
  },
  {
    code: "22302",
    name: "Xã Hiệp Phước",
    is_higher_price: false,
    district_code: "1534",
  },
  {
    code: "22303",
    name: "Xã Long Thới",
    is_higher_price: false,
    district_code: "1534",
  },
  {
    code: "22304",
    name: "Xã Nhơn Đức",
    is_higher_price: false,
    district_code: "1534",
  },
  {
    code: "22305",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1534",
  },
  {
    code: "22306",
    name: "Xã Phước Kiển",
    is_higher_price: false,
    district_code: "1534",
  },
  {
    code: "22307",
    name: "Xã Phước Lộc",
    is_higher_price: false,
    district_code: "1534",
  },
  {
    code: "22401",
    name: "Thị trấn Cần Thạnh",
    is_higher_price: false,
    district_code: "2090",
  },
  {
    code: "22402",
    name: "Xã An Thới Đông",
    is_higher_price: false,
    district_code: "2090",
  },
  {
    code: "22403",
    name: "Xã Bình Khánh",
    is_higher_price: false,
    district_code: "2090",
  },
  {
    code: "22404",
    name: "Xã Long Hòa",
    is_higher_price: false,
    district_code: "2090",
  },
  {
    code: "22405",
    name: "Xã Lý Nhơn",
    is_higher_price: false,
    district_code: "2090",
  },
  {
    code: "22406",
    name: "Xã Tam Thôn Hiệp",
    is_higher_price: false,
    district_code: "2090",
  },
  {
    code: "22407",
    name: "Xã Thạnh An",
    is_higher_price: false,
    district_code: "2090",
  },
  {
    code: "230101",
    name: "Phường Chăm Mát",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230102",
    name: "Phường Đồng Tiến",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230103",
    name: "Phường Hữu Nghị",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230104",
    name: "Phường Phương Lâm",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230105",
    name: "Phường Tân Hòa",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230106",
    name: "Phường Tân Thịnh",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230107",
    name: "Phường Thái Bình",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230108",
    name: "Phường Thịnh Lang",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230109",
    name: "Xã Dân Chủ",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230110",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230111",
    name: "Xã Sủ Ngòi",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230112",
    name: "Xã Thái Thịnh",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230113",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230114",
    name: "Xã Trung Minh",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230115",
    name: "Xã Yên Mông",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "230201",
    name: "Thị trấn Đà Bắc",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230202",
    name: "Xã Cao Sơn",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230203",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230204",
    name: "Xã Đồng Chum",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230205",
    name: "Xã Đồng Nghê",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230206",
    name: "Xã Đồng Ruộng",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230207",
    name: "Xã Giáp Đắt",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230208",
    name: "Xã Hào Lý",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230209",
    name: "Xã Hiền Lương",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230210",
    name: "Xã Mường Chiềng",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230211",
    name: "Xã Mường Tuổng",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230212",
    name: "Xã Suối Nánh",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230213",
    name: "Xã Tân Minh",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230214",
    name: "Xã Tân Pheo",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230215",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230216",
    name: "Xã Toàn Sơn",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230217",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230218",
    name: "Xã Tu Lý",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230219",
    name: "Xã Vầy Nưa",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230220",
    name: "Xã Yên Hòa",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "230301",
    name: "Thị trấn Mai Châu",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230302",
    name: "Xã Ba Khan",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230303",
    name: "Xã Bao La",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230304",
    name: "Xã Chiềng Châu",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230305",
    name: "Xã Cun Pheo",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230306",
    name: "Xã Đồng Bảng",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230307",
    name: "Xã Hang Kia",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230308",
    name: "Xã Mai Hạ",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230309",
    name: "Xã Mai Hịch",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230310",
    name: "Xã Nà Mèo",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230311",
    name: "Xã Nà Phòn",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230312",
    name: "Xã Nong Luông",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230313",
    name: "Xã Pà Cò",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230314",
    name: "Xã Phúc Sạn",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230315",
    name: "Xã Piềng Vế",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230316",
    name: "Xã Pù Pin",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230317",
    name: "Xã Săm Khóe",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230318",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230319",
    name: "Xã Tân Mai",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230320",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230321",
    name: "Xã Thung Khe",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230322",
    name: "Xã Tòng Đậu",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230323",
    name: "Xã Vạn Mai",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "230401",
    name: "Thị trấn Mường Khến",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230402",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230403",
    name: "Xã Địch Giáo",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230404",
    name: "Xã Do Nhân",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230405",
    name: "Xã Đông Lai",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230406",
    name: "Xã Gia Mô",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230407",
    name: "Xã Lỗ Sơn",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230408",
    name: "Xã Lũng Vân",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230409",
    name: "Xã Mãn Đức",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230410",
    name: "Xã Mỹ Hòa",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230411",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230412",
    name: "Xã Ngổ Luông",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230413",
    name: "Xã Ngọc Mỹ",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230414",
    name: "Xã Ngòi Hoa",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230415",
    name: "Xã Phong Phú",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230416",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230417",
    name: "Xã Phú Vinh",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230418",
    name: "Xã Quy Hậu",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230419",
    name: "Xã Quy Mỹ",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230420",
    name: "Xã Quyết Chiến",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230421",
    name: "Xã Thanh Hối",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230422",
    name: "Xã Trung Hòa",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230423",
    name: "Xã Tử Nê",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230424",
    name: "Xã Tuân Lộ",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "230501",
    name: "Thị trấn Vụ Bản",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230502",
    name: "Xã Ân Nghĩa",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230503",
    name: "Xã Bình Cảng",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230504",
    name: "Xã Bình Chân",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230505",
    name: "Xã Bình Hẻm",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230506",
    name: "Xã Chí Đạo",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230507",
    name: "Xã Chí Thiện",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230508",
    name: "Xã Định Cư",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230509",
    name: "Xã Hương Nhượng",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230510",
    name: "Xã Liên Vũ",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230511",
    name: "Xã Miền Đồi",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230512",
    name: "Xã Mỹ Thành",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230513",
    name: "Xã Ngọc Lâu",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230514",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230515",
    name: "Xã Nhân Nghĩa",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230516",
    name: "Xã Phú Lương",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230517",
    name: "Xã Phúc Tuy",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230518",
    name: "Xã Quý Hòa",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230519",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230520",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230521",
    name: "Xã Thượng Cốc",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230522",
    name: "Xã Tự Do",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230523",
    name: "Xã Tuân Đạo",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230524",
    name: "Xã Văn Nghĩa",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230525",
    name: "Xã Văn Sơn",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230526",
    name: "Xã Vũ Lâm",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230527",
    name: "Xã Xuất Hóa",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230528",
    name: "Xã Yên Nghiệp",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230529",
    name: "Xã Yên Phú",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "230601",
    name: "Thị trấn Kỳ Sơn",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230602",
    name: "Xã Dân Hạ",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230603",
    name: "Xã Dân Hòa",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230604",
    name: "Xã Độc Lập",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230605",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230606",
    name: "Xã Hợp Thịnh",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230607",
    name: "Xã Mông Hóa",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230608",
    name: "Xã Phú Minh",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230609",
    name: "Xã Phúc Tiến",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230610",
    name: "Xã Yên Quang",
    is_higher_price: false,
    district_code: "1955",
  },
  {
    code: "230701",
    name: "Thị trấn Lương Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230702",
    name: "Xã Cao Dương",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230703",
    name: "Xã Cao Răm",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230704",
    name: "Xã Cao Thắng",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230705",
    name: "Xã Cư Yên",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230706",
    name: "Xã Hòa Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230707",
    name: "Xã Hợp Châu",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230708",
    name: "Xã Hợp Hòa",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230709",
    name: "Xã Hợp Thanh",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230710",
    name: "Xã Lâm Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230711",
    name: "Xã Liên Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230712",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230713",
    name: "Xã Nhuận Trạch",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230714",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230715",
    name: "Xã Tân Vinh",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230716",
    name: "Xã Thành Lập",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230717",
    name: "Xã Thanh Lương",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230718",
    name: "Xã Tiến Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230719",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230720",
    name: "Xã Trường Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "230801",
    name: "Thị trấn Bo",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230802",
    name: "Xã  Bắc Sơn",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230803",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230804",
    name: "Xã Cuối Hạ",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230805",
    name: "Xã Đông Bắc",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230806",
    name: "Xã Đú Sáng",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230807",
    name: "Xã Hạ Bì",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230808",
    name: "Xã Hợp Đồng",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230809",
    name: "Xã Hợp Kim",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230810",
    name: "Xã Hùng Tiến",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230811",
    name: "Xã Kim Bình",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230812",
    name: "Xã Kim Bôi",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230813",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230814",
    name: "Xã Kim Tiến",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230815",
    name: "Xã Kim Truy",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230816",
    name: "Xã Lập Chiệng",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230817",
    name: "Xã Mi Hòa",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230818",
    name: "Xã Nam Thượng",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230819",
    name: "Xã Nật Sơn",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230820",
    name: "Xã Nuông Dăm",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230821",
    name: "Xã Sào Báy",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230822",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230823",
    name: "Xã Thượng Bì",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230824",
    name: "Xã Thượng Tiến",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230825",
    name: "Xã Trung Bì",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230826",
    name: "Xã Tú Sơn",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230827",
    name: "Xã Vĩnh Đồng",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230828",
    name: "Xã Vĩnh Tiến",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "230901",
    name: "Thị trấn Chi Nê",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230902",
    name: "Thị trấn Thanh Hà",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230903",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230904",
    name: "Xã An Lạc",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230905",
    name: "Xã Cố Nghĩa",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230906",
    name: "Xã Đồng Môn",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230907",
    name: "Xã Đồng Tâm",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230908",
    name: "Xã Hưng Thi",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230909",
    name: "Xã Khoan Dụ",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230910",
    name: "Xã Lạc Long",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230911",
    name: "Xã Liên Hòa",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230912",
    name: "Xã Phú Lão",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230913",
    name: "Xã Phú Thành",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230914",
    name: "Xã Thanh Nông",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "230915",
    name: "Xã Yên Bồng",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "231001",
    name: "Thị trấn Hàng Trạm",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231002",
    name: "Xã Bảo Hiệu",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231003",
    name: "Xã Đa Phúc",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231004",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231005",
    name: "Xã Hữu Lợi",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231006",
    name: "Xã Lạc Hưng",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231007",
    name: "Xã Lạc Lương",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231008",
    name: "Xã Lạc Sỹ",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231009",
    name: "Xã Lạc Thịnh",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231010",
    name: "Xã Ngọc Lương",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231011",
    name: "Xã Phú Lai",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231012",
    name: "Xã Yên Lạc",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231013",
    name: "Xã Yên Trị",
    is_higher_price: false,
    district_code: "2270",
  },
  {
    code: "231101",
    name: "Thị trấn Cao Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231102",
    name: "Xã Bắc Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231103",
    name: "Xã Bình Thanh",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231104",
    name: "Xã Đông Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231105",
    name: "Xã Dũng Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231106",
    name: "Xã Nam Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231107",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231108",
    name: "Xã Tây Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231109",
    name: "Xã Thu Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231110",
    name: "Xã Thung Nai",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231111",
    name: "Xã Xuân Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231112",
    name: "Xã Yên Lập",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "231113",
    name: "Xã Yên Thượng",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "240101",
    name: "Phường Châu Sơn",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240102",
    name: "Phường Hai Bà Trưng",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240103",
    name: "Phường Lam Hà",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240104",
    name: "Phường Lê Hồng Phong",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240105",
    name: "Phường Liêm Chính",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240106",
    name: "Phường Lương Khánh Thiện",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240107",
    name: "Phường Minh Khai",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240108",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240109",
    name: "Phường Thanh Châu",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240110",
    name: "Phường Thanh Tuyền",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240111",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240112",
    name: "Xã Đinh Xá",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240113",
    name: "Xã Kim Bình",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240114",
    name: "Xã Liêm Chung",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240115",
    name: "Xã Liêm Tiết",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240116",
    name: "Xã Liêm Tuyền",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240117",
    name: "Xã Phù Vân",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240118",
    name: "Xã Tiên Hải",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240119",
    name: "Xã Tiên Hiệp",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240120",
    name: "Xã Tiên Tân",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240121",
    name: "Xã Trịnh Xá",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "240201",
    name: "Thị trấn Đồng Văn",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240202",
    name: "Thị trấn Hòa Mạc",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240203",
    name: "Xã Bạch Thượng",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240204",
    name: "Xã Châu Giang",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240205",
    name: "Xã Châu Sơn",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240206",
    name: "Xã Chuyên Ngoại",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240207",
    name: "Xã Đọi Sơn",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240208",
    name: "Xã Duy Hải",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240209",
    name: "Xã Duy Minh",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240210",
    name: "Xã Hoàng Đông",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240211",
    name: "Xã Mộc Bắc",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240212",
    name: "Xã Mộc Nam",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240213",
    name: "Xã Tiên Ngoại",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240214",
    name: "Xã Tiên Nội",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240215",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240216",
    name: "Xã Trác Văn",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240217",
    name: "Xã Yên Bắc",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240218",
    name: "Xã Yên Nam",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "240301",
    name: "Thị trấn Ba Sao",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240302",
    name: "Thị trấn Quế",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240303",
    name: "Xã Đại Cương",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240304",
    name: "Xã Đồng Hóa",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240305",
    name: "Xã Hoàng Tây",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240306",
    name: "Xã Khả Phong",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240307",
    name: "Xã Lê Hồ",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240308",
    name: "Xã Liên Sơn",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240309",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240310",
    name: "Xã Nguyễn Úy",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240311",
    name: "Xã Nhật Tân",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240312",
    name: "Xã Nhật Tựu",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240313",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240314",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240315",
    name: "Xã Thi Sơn",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240316",
    name: "Xã Thụy Lôi",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240317",
    name: "Xã Tượng Lĩnh",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240318",
    name: "Xã Văn Xá",
    is_higher_price: false,
    district_code: "1952",
  },
  {
    code: "240401",
    name: "Thị trấn Vĩnh Trụ",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240402",
    name: "Xã Bắc Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240403",
    name: "Xã Chân Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240404",
    name: "Xã Chính Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240405",
    name: "Xã Công Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240406",
    name: "Xã Đạo Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240407",
    name: "Xã Đồng Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240408",
    name: "Xã Đức Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240409",
    name: "Xã Hòa Hậu",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240410",
    name: "Xã Hợp Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240411",
    name: "Xã Nguyên Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240412",
    name: "Xã Nhân Bình",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240413",
    name: "Xã Nhân Chính",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240414",
    name: "Xã Nhân Đạo",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240415",
    name: "Xã Nhân Hưng",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240416",
    name: "Xã Nhân Khang",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240417",
    name: "Xã Nhân Mỹ",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240418",
    name: "Xã Nhân Nghĩa",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240419",
    name: "Xã Nhân Thịnh",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240420",
    name: "Xã Phú Phúc",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240421",
    name: "Xã Tiến Thắng",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240422",
    name: "Xã Văn Lý",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240423",
    name: "Xã Xuân Khê",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "240501",
    name: "Thị trấn Kiện Khê",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240502",
    name: "Xã Liêm Cần",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240503",
    name: "Xã Liêm Phong",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240504",
    name: "Xã Liêm Sơn",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240505",
    name: "Xã Liêm Thuận",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240506",
    name: "Xã Liêm Túc",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240507",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240508",
    name: "Xã Thanh Hà",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240509",
    name: "Xã Thanh Hải",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240510",
    name: "Xã Thanh Hương",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240511",
    name: "Xã Thanh Lưu",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240512",
    name: "Xã Thanh Nghị",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240513",
    name: "Xã Thanh Nguyên",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240514",
    name: "Xã Thanh Phong",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240515",
    name: "Xã Thanh Tâm",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240516",
    name: "Xã Thanh Tân",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240517",
    name: "Xã Thanh Thủy",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "240601",
    name: "Thị trấn Bình Mỹ",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240602",
    name: "Xã An Đổ",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240603",
    name: "Xã An Lão",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240604",
    name: "Xã An Mỹ",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240605",
    name: "Xã An Ninh",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240606",
    name: "Xã An Nội",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240607",
    name: "Xã Bình Nghĩa",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240608",
    name: "Xã Bồ Đề",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240609",
    name: "Xã Bối Cầu",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240610",
    name: "Xã Đồn Xá",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240611",
    name: "Xã Đồng Du",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240612",
    name: "Xã Hưng Công",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240613",
    name: "Xã La Sơn",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240614",
    name: "Xã Mỹ Thọ",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240615",
    name: "Xã Ngọc Lũ",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240616",
    name: "Xã Tiêu Động",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240617",
    name: "Xã Tràng An",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240618",
    name: "Xã Trung Lương",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "240619",
    name: "Xã Vũ Bản",
    is_higher_price: false,
    district_code: "1897",
  },
  {
    code: "250101",
    name: "Phường Bà Triệu",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250102",
    name: "Phường Cửa Bắc",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250103",
    name: "Phường Cửa Nam",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250104",
    name: "Phường Hạ Long",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250105",
    name: "Phường Lộc Hạ",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250106",
    name: "Phường Lộc Vượng",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250107",
    name: "Phường Năng Tĩnh",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250108",
    name: "Phường Ngô Quyền",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250109",
    name: "Phường Nguyễn Du",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250110",
    name: "Phường Phan Đình Phùng",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250111",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250112",
    name: "Phường Thống Nhất",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250113",
    name: "Phường Trần Đăng Ninh",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250114",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250115",
    name: "Phường Trần Quang Khải",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250116",
    name: "Phường Trần Tế Xương",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250117",
    name: "Phường Trường Thi",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250118",
    name: "Phường Văn Miếu",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250119",
    name: "Phường Vị Hoàng",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250120",
    name: "Phường Vị Xuyên",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250121",
    name: "Xã Lộc An",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250122",
    name: "Xã Lộc Hòa",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250123",
    name: "Xã Mỹ Xá",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250124",
    name: "Xã Nam Phong",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250125",
    name: "Xã Nam Vân",
    is_higher_price: false,
    district_code: "1613",
  },
  {
    code: "250201",
    name: "Thị trấn Mỹ Lộc",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250202",
    name: "Xã Mỹ Hà",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250203",
    name: "Xã Mỹ Hưng",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250204",
    name: "Xã Mỹ Phúc",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250205",
    name: "Xã Mỹ Tân",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250206",
    name: "Xã Mỹ Thắng",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250207",
    name: "Xã Mỹ Thành",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250208",
    name: "Xã Mỹ Thịnh",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250209",
    name: "Xã Mỹ Thuận",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250210",
    name: "Xã Mỹ Tiến",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250211",
    name: "Xã Mỹ Trung",
    is_higher_price: false,
    district_code: "1981",
  },
  {
    code: "250301",
    name: "Thị trấn Xuân Trường",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250302",
    name: "Xã Thọ Nghiệp",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250303",
    name: "Xã Xuân Bắc",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250304",
    name: "Xã Xuân Châu",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250305",
    name: "Xã Xuân Đài",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250306",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250307",
    name: "Xã Xuân Hồng",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250308",
    name: "Xã Xuân Kiên",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250309",
    name: "Xã Xuân Ngọc",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250310",
    name: "Xã Xuân Ninh",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250311",
    name: "Xã Xuân Phong",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250312",
    name: "Xã Xuân Phú",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250313",
    name: "Xã Xuân Phương",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250314",
    name: "Xã Xuân Tân",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250315",
    name: "Xã Xuân Thành",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250316",
    name: "Xã Xuân Thượng",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250317",
    name: "Xã Xuân Thủy",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250318",
    name: "Xã Xuân Tiến",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250319",
    name: "Xã Xuân Trung",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250320",
    name: "Xã Xuân Vinh",
    is_higher_price: false,
    district_code: "3323",
  },
  {
    code: "250401",
    name: "Thị trấn Ngô Đồng",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250402",
    name: "Thị trấn Quất Lâm",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250403",
    name: "Xã Bạch Long",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250404",
    name: "Xã Bình Hòa",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250405",
    name: "Xã Giao An",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250406",
    name: "Xã Giao Châu",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250407",
    name: "Xã Giao Hà",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250408",
    name: "Xã Giao Hải",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250409",
    name: "Xã Giao Hương",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250410",
    name: "Xã Giao Lạc",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250411",
    name: "Xã Giao Long",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250412",
    name: "Xã Giao Nhân",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250413",
    name: "Xã Giao Phong",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250414",
    name: "Xã Giao Tân",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250415",
    name: "Xã Giao Thanh",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250416",
    name: "Xã Giao Thiện",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250417",
    name: "Xã Giao Thịnh",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250418",
    name: "Xã Giao Tiến",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250419",
    name: "Xã Giao Xuân",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250420",
    name: "Xã Giao Yến",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250421",
    name: "Xã Hoành Sơn",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250422",
    name: "Xã Hồng Thuận",
    is_higher_price: false,
    district_code: "3193",
  },
  {
    code: "250501",
    name: "Thị trấn Lâm",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250502",
    name: "Xã Yên Bằng",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250503",
    name: "Xã Yên Bình",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250504",
    name: "Xã Yên Chính",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250505",
    name: "Xã Yên Cường",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250506",
    name: "Xã Yên Đồng",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250507",
    name: "Xã Yên Dương",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250508",
    name: "Xã Yên Hồng",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250509",
    name: "Xã Yên Hưng",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250510",
    name: "Xã Yên Khang",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250511",
    name: "Xã Yên Khánh",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250512",
    name: "Xã Yên Lộc",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250513",
    name: "Xã Yên Lợi",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250514",
    name: "Xã Yên Lương",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250515",
    name: "Xã Yên Minh",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250516",
    name: "Xã Yên Mỹ",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250517",
    name: "Xã Yên Nghĩa",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250518",
    name: "Xã Yên Nhân",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250519",
    name: "Xã Yên Ninh",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250520",
    name: "Xã Yên Phong",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250521",
    name: "Xã Yên Phú",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250522",
    name: "Xã Yên Phúc",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250523",
    name: "Xã Yên Phương",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250524",
    name: "Xã Yên Quang",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250525",
    name: "Xã Yên Tân",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250526",
    name: "Xã Yên Thắng",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250527",
    name: "Xã Yên Thành",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250528",
    name: "Xã Yên Thọ",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250529",
    name: "Xã Yên Tiến",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250530",
    name: "Xã Yên Trị",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250531",
    name: "Xã Yên Trung",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250532",
    name: "Xã Yên Xá",
    is_higher_price: false,
    district_code: "1841",
  },
  {
    code: "250601",
    name: "Thị trấn Gôi",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250602",
    name: "Xã Cộng Hòa",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250603",
    name: "Xã Đại An",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250604",
    name: "Xã Đại Thắng",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250605",
    name: "Xã Hiển Khánh",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250606",
    name: "Xã Hợp Hưng",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250607",
    name: "Xã Kim Thái",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250608",
    name: "Xã Liên Bảo",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250609",
    name: "Xã Liên Minh",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250610",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250611",
    name: "Xã Minh Thuận",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250612",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250613",
    name: "Xã Tam Thanh",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250614",
    name: "Xã Tân Khánh",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250615",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250616",
    name: "Xã Thành Lợi",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250617",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250618",
    name: "Xã Vĩnh Hào",
    is_higher_price: false,
    district_code: "3319",
  },
  {
    code: "250701",
    name: "Thị trấn Nam Giang",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250702",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250703",
    name: "Xã Điền Xá",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250704",
    name: "Xã Đồng Sơn",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250705",
    name: "Xã Hồng Quang",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250706",
    name: "Xã Nam Cường",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250707",
    name: "Xã Nam Dương",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250708",
    name: "Xã Nam Hải",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250709",
    name: "Xã Nam Hoa",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250710",
    name: "Xã Nam Hồng",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250711",
    name: "Xã Nam Hùng",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250712",
    name: "Xã Nam Lợi",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250713",
    name: "Xã Nam Mỹ",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250714",
    name: "Xã Nam Thái",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250715",
    name: "Xã Nam Thắng",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250716",
    name: "Xã Nam Thanh",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250717",
    name: "Xã Nam Tiến",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250718",
    name: "Xã Nam Toàn",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250719",
    name: "Xã Nghĩa An",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250720",
    name: "Xã Tân Thịnh",
    is_higher_price: false,
    district_code: "1983",
  },
  {
    code: "250801",
    name: "Thị trấn Cát Thành",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250802",
    name: "Thị trấn Cổ Lễ",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250803",
    name: "Xã Liêm Hải",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250804",
    name: "Xã Phương Định",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250805",
    name: "Xã Trực Chính",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250806",
    name: "Xã Trực Cường",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250807",
    name: "Xã Trực Đại",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250808",
    name: "Xã Trực Đạo",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250809",
    name: "Xã Trực Hùng",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250810",
    name: "Xã Trực Hưng",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250811",
    name: "Xã Trực Khang",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250812",
    name: "Xã Trực Mỹ",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250813",
    name: "Xã Trực Nội",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250814",
    name: "Xã Trực Phú",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250815",
    name: "Xã Trực Thái",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250816",
    name: "Xã Trực Thắng",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250817",
    name: "Xã Trực Thanh",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250818",
    name: "Xã Trực Thuận",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250819",
    name: "Xã Trực Tuấn",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250820",
    name: "Xã Trung Đông",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250821",
    name: "Xã Việt Hùng",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "250901",
    name: "Thị trấn Liễu Đề",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250902",
    name: "Thị trấn Quỹ Nhất",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250903",
    name: "Thị trấn Rạng Đông",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250904",
    name: "Xã Hoàng Nam",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250905",
    name: "Xã Nam Điền",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250906",
    name: "Xã Nghĩa Bình",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250907",
    name: "Xã Nghĩa Châu",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250908",
    name: "Xã Nghĩa Đồng",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250909",
    name: "Xã Nghĩa Hải",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250910",
    name: "Xã Nghĩa Hồng",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250911",
    name: "Xã Nghĩa Hùng",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250912",
    name: "Xã Nghĩa Lạc",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250913",
    name: "Xã Nghĩa Lâm",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250914",
    name: "Xã Nghĩa Lợi",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250915",
    name: "Xã Nghĩa Minh",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250916",
    name: "Xã Nghĩa Phong",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250917",
    name: "Xã Nghĩa Phú",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250918",
    name: "Xã Nghĩa Phúc",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250919",
    name: "Xã Nghĩa Sơn",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250920",
    name: "Xã Nghĩa Tân",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250921",
    name: "Xã Nghĩa Thái",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250922",
    name: "Xã Nghĩa Thắng",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250923",
    name: "Xã Nghĩa Thành",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250924",
    name: "Xã Nghĩa Thịnh",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "250925",
    name: "Xã Nghĩa Trung",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "251001",
    name: "Thị trấn Cồn",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251002",
    name: "Thị trấn Thịnh Long",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251003",
    name: "Thị trấn Yên Định",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251004",
    name: "Xã Hải An",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251005",
    name: "Xã Hải Anh",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251006",
    name: "Xã Hải Bắc",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251007",
    name: "Xã Hải Châu",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251008",
    name: "Xã Hải Chính",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251009",
    name: "Xã Hải Cường",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251010",
    name: "Xã Hải Đông",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251011",
    name: "Xã Hải Đường",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251012",
    name: "Xã Hải Giang",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251013",
    name: "Xã Hải Hà",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251014",
    name: "Xã Hải Hòa",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251015",
    name: "Xã Hải Hưng",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251016",
    name: "Xã Hải Lộc",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251017",
    name: "Xã Hải Long",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251018",
    name: "Xã Hải Lý",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251019",
    name: "Xã Hải Minh",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251020",
    name: "Xã Hải Nam",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251021",
    name: "Xã Hải Ninh",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251022",
    name: "Xã Hải Phong",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251023",
    name: "Xã Hải Phú",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251024",
    name: "Xã Hải Phúc",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251025",
    name: "Xã Hải Phương",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251026",
    name: "Xã Hải Quang",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251027",
    name: "Xã Hải Sơn",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251028",
    name: "Xã Hải Tân",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251029",
    name: "Xã Hải Tây",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251030",
    name: "Xã Hải Thanh",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251031",
    name: "Xã Hải Toàn",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251032",
    name: "Xã Hải Triều",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251033",
    name: "Xã Hải Trung",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251034",
    name: "Xã Hải Vân",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "251035",
    name: "Xã Hải Xuân",
    is_higher_price: false,
    district_code: "1840",
  },
  {
    code: "260101",
    name: "Phường Bồ Xuyên",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260102",
    name: "Phường Đề Thám",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260103",
    name: "Phường Hoàng Diệu",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260104",
    name: "Phường Kỳ Bá",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260105",
    name: "Phường Lê Hồng Phong",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260106",
    name: "Phường Phú Khánh",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260107",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260108",
    name: "Phường Tiền Phong",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260109",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260110",
    name: "Phường Trần Lãm",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260111",
    name: "Xã Đông Hòa",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260112",
    name: "Xã Đông Mỹ",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260113",
    name: "Xã Đông Thọ",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260114",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260115",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260116",
    name: "Xã Vũ Chính",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260117",
    name: "Xã Vũ Đông",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260118",
    name: "Xã Vũ Lạc",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260119",
    name: "Xã Vũ Phúc",
    is_higher_price: false,
    district_code: "1599",
  },
  {
    code: "260201",
    name: "Thị trấn An Bài",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260202",
    name: "Thị trấn Quỳnh Côi",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260203",
    name: "Xã An Ấp",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260204",
    name: "Xã An Cầu",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260205",
    name: "Xã An Đồng",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260206",
    name: "Xã An Dục",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260207",
    name: "Xã An Hiệp",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260208",
    name: "Xã An Khê",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260209",
    name: "Xã An Lễ",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260210",
    name: "Xã An Mỹ",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260211",
    name: "Xã An Ninh",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260212",
    name: "Xã An Quí",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260213",
    name: "Xã An Thái",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260214",
    name: "Xã An Thanh",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260215",
    name: "Xã An Tràng",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260216",
    name: "Xã An Vinh",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260217",
    name: "Xã An Vũ",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260218",
    name: "Xã Đông Hải",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260219",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260220",
    name: "Xã Quỳnh Bảo",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260221",
    name: "Xã Quỳnh Châu",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260222",
    name: "Xã Quỳnh Giao",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260223",
    name: "Xã Quỳnh Hải",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260224",
    name: "Xã Quỳnh Hoa",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260225",
    name: "Xã Quỳnh Hoàng",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260226",
    name: "Xã Quỳnh Hội",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260227",
    name: "Xã Quỳnh Hồng",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260228",
    name: "Xã Quỳnh Hưng",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260229",
    name: "Xã Quỳnh Khê",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260230",
    name: "Xã Quỳnh Lâm",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260231",
    name: "Xã Quỳnh Minh",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260232",
    name: "Xã Quỳnh Mỹ",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260233",
    name: "Xã Quỳnh Ngọc",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260234",
    name: "Xã Quỳnh Nguyên",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260235",
    name: "Xã Quỳnh Sơn",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260236",
    name: "Xã Quỳnh Thọ",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260237",
    name: "Xã Quỳnh Trang",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260238",
    name: "Xã Quỳnh Xá",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "260301",
    name: "Thị trấn Hưng Hà",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260302",
    name: "Thị trấn Hưng Nhân",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260303",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260304",
    name: "Xã Canh Tân",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260305",
    name: "Xã Chí Hòa",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260306",
    name: "Xã Chi Lăng",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260307",
    name: "Xã Cộng Hòa",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260308",
    name: "Xã Dân Chủ",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260309",
    name: "Xã Điệp Nông",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260310",
    name: "Xã Đoan Hùng",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260311",
    name: "Xã Độc Lập",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260312",
    name: "Xã Đông Đô",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260313",
    name: "Xã Duyên Hải",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260314",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260315",
    name: "Xã Hòa Tiến",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260316",
    name: "Xã Hồng An",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260317",
    name: "Xã Hồng Lĩnh",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260318",
    name: "Xã Hồng Minh",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260319",
    name: "Xã Hùng Dũng",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260320",
    name: "Xã Kim Chung",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260321",
    name: "Xã Liên Hiệp",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260322",
    name: "Xã Minh Hòa",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260323",
    name: "Xã Minh Khai",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260324",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260325",
    name: "Xã Phúc Khánh",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260326",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260327",
    name: "Xã Tân Lễ",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260328",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260329",
    name: "Xã Tây Đô",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260330",
    name: "Xã Thái Hưng",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260331",
    name: "Xã Thái Phương",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260332",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260333",
    name: "Xã Tiến Đức",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260334",
    name: "Xã Văn Cẩm",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260335",
    name: "Xã Văn Lang",
    is_higher_price: false,
    district_code: "1867",
  },
  {
    code: "260401",
    name: "Thị trấn Đông Hưng",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260402",
    name: "Xã An Châu",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260403",
    name: "Xã Bạch Đằng",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260404",
    name: "Xã Chương Dương",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260405",
    name: "Xã Đô Lương",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260406",
    name: "Xã Đông Á",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260407",
    name: "Xã Đông Các",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260408",
    name: "Xã Đông Cường",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260409",
    name: "Xã Đông Động",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260410",
    name: "Xã Đông Dương",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260411",
    name: "Xã Đông Giang",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260412",
    name: "Xã Đông Hà",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260413",
    name: "Xã Đông Hoàng",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260414",
    name: "Xã Đông Hợp",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260415",
    name: "Xã Đông Huy",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260416",
    name: "Xã Đông Kinh",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260417",
    name: "Xã Đông La",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260418",
    name: "Xã Đông Lĩnh",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260419",
    name: "Xã Đông Phong",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260420",
    name: "Xã Đồng Phú",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260421",
    name: "Xã Đông Phương",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260422",
    name: "Xã Đông Quang",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260423",
    name: "Xã Đông Sơn",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260424",
    name: "Xã Đông Tân",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260425",
    name: "Xã Đông Vinh",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260426",
    name: "Xã Đông Xá",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260427",
    name: "Xã Đông Xuân",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260428",
    name: "Xã Hoa Lư",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260429",
    name: "Xã Hoa Nam",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260430",
    name: "Xã Hồng Châu",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260431",
    name: "Xã Hồng Giang",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260432",
    name: "Xã Hồng Việt",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260433",
    name: "Xã Hợp Tiến",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260434",
    name: "Xã Liên Giang",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260435",
    name: "Xã Lô Giang",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260436",
    name: "Xã Mê Linh",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260437",
    name: "Xã Minh Châu",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260438",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260439",
    name: "Xã Nguyên Xá",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260440",
    name: "Xã Phong Châu",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260441",
    name: "Xã Phú Châu",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260442",
    name: "Xã Phú Lương",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260443",
    name: "Xã Thăng Long",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260444",
    name: "Xã Trọng Quan",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "260501",
    name: "Thị trấn Vũ Thư",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260502",
    name: "Xã Bách Thuận",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260503",
    name: "Xã Đồng Thanh",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260504",
    name: "Xã Dũng Nghĩa",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260505",
    name: "Xã Duy Nhất",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260506",
    name: "Xã Hiệp Hòa",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260507",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260508",
    name: "Xã Hồng Lý",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260509",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260510",
    name: "Xã Minh Khai",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260511",
    name: "Xã Minh Lãng",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260512",
    name: "Xã Minh Quang",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260513",
    name: "Xã Nguyên Xá",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260514",
    name: "Xã Phúc Thành",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260515",
    name: "Xã Song An",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260516",
    name: "Xã Song Lãng",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260517",
    name: "Xã Tam Quang",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260518",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260519",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260520",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260521",
    name: "Xã Trung An",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260522",
    name: "Xã Tự Tân",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260523",
    name: "Xã Việt Hùng",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260524",
    name: "Xã Việt Thuận",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260525",
    name: "Xã Vũ Đoài",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260526",
    name: "Xã Vũ Hội",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260527",
    name: "Xã Vũ Tiến",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260528",
    name: "Xã Vũ Vân",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260529",
    name: "Xã Vũ Vinh",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260530",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "1716",
  },
  {
    code: "260601",
    name: "Thị trấn Thanh Nê",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260602",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260603",
    name: "Xã An Bồi",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260604",
    name: "Xã Bình Định",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260605",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260606",
    name: "Xã Bình Nguyên",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260607",
    name: "Xã Bình Thanh",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260608",
    name: "Xã Đình Phùng",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260609",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260610",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260611",
    name: "Xã Hồng Tiến",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260612",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260613",
    name: "Xã Minh Hưng",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260614",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260615",
    name: "Xã Nam Bình",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260616",
    name: "Xã Nam Cao",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260617",
    name: "Xã Quang Bình",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260618",
    name: "Xã Quang Hưng",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260619",
    name: "Xã Quang Lịch",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260620",
    name: "Xã Quang Minh",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260621",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260622",
    name: "Xã Quốc Tuấn",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260623",
    name: "Xã Quyết Tiến",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260624",
    name: "Xã Thanh Tân",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260625",
    name: "Xã Thượng Hiền",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260626",
    name: "Xã Trà Giang",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260627",
    name: "Xã Vũ An",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260628",
    name: "Xã Vũ Bình",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260629",
    name: "Xã Vũ Công",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260630",
    name: "Xã Vũ Hòa",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260631",
    name: "Xã Vũ Lễ",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260632",
    name: "Xã Vũ Ninh",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260633",
    name: "Xã Vũ Quí",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260634",
    name: "Xã Vũ Sơn",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260635",
    name: "Xã Vũ Tây",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260636",
    name: "Xã Vũ Thắng",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260637",
    name: "Xã Vũ Trung",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "260701",
    name: "Thị trấn Tiền Hải",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260702",
    name: "Xã An Ninh",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260703",
    name: "Xã Bắc Hải",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260704",
    name: "Xã Đông Cơ",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260705",
    name: "Xã Đông Hải",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260706",
    name: "Xã Đông Hoàng",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260707",
    name: "Xã Đông Lâm",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260708",
    name: "Xã Đông Long",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260709",
    name: "Xã Đông Minh",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260710",
    name: "Xã Đông Phong",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260711",
    name: "Xã Đông Quí",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260712",
    name: "Xã Đông Trà",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260713",
    name: "Xã Đông Trung",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260714",
    name: "Xã Đông Xuyên",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260715",
    name: "Xã Nam Chính",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260716",
    name: "Xã Nam Cường",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260717",
    name: "Xã Nam Hà",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260718",
    name: "Xã Nam Hải",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260719",
    name: "Xã Nam Hồng",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260720",
    name: "Xã Nam Hưng",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260721",
    name: "Xã Nam Phú",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260722",
    name: "Xã Nam Thắng",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260723",
    name: "Xã Nam Thanh",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260724",
    name: "Xã Nam Thịnh",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260725",
    name: "Xã Nam Trung",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260726",
    name: "Xã Phương Công",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260727",
    name: "Xã Tây An",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260728",
    name: "Xã Tây Giang",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260729",
    name: "Xã Tây Lương",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260730",
    name: "Xã Tây Ninh",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260731",
    name: "Xã Tây Phong",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260732",
    name: "Xã Tây Sơn",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260733",
    name: "Xã Tây Tiến",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260734",
    name: "Xã Vân Trường",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260735",
    name: "Xã Vũ Lăng",
    is_higher_price: false,
    district_code: "3281",
  },
  {
    code: "260801",
    name: "Thị trấn Diêm Điền",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260802",
    name: "Xã  Mỹ Lộc",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260803",
    name: "Xã Hồng Quỳnh",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260804",
    name: "Xã Thái An",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260805",
    name: "Xã Thái Đô",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260806",
    name: "Xã Thái Dương",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260807",
    name: "Xã Thái Giang",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260808",
    name: "Xã Thái Hà",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260809",
    name: "Xã Thái Hòa",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260810",
    name: "Xã Thái Học",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260811",
    name: "Xã Thái Hồng",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260812",
    name: "Xã Thái Hưng",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260813",
    name: "Xã Thái Nguyên",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260814",
    name: "Xã Thái Phúc",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260815",
    name: "Xã Thái Sơn",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260816",
    name: "Xã Thái Tân",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260817",
    name: "Xã Thái Thành",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260818",
    name: "Xã Thái Thịnh",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260819",
    name: "Xã Thái Thọ",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260820",
    name: "Xã Thái Thuần",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260821",
    name: "Xã Thái Thượng",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260822",
    name: "Xã Thái Thủy",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260823",
    name: "Xã Thái Xuyên",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260824",
    name: "Xã Thụy An",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260825",
    name: "Xã Thụy Bình",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260826",
    name: "Xã Thụy Chính",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260827",
    name: "Xã Thụy Dân",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260828",
    name: "Xã Thụy Dũng",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260829",
    name: "Xã Thụy Dương",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260830",
    name: "Xã Thụy Duyên",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260831",
    name: "Xã Thụy Hà",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260832",
    name: "Xã Thụy Hải",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260833",
    name: "Xã Thụy Hồng",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260834",
    name: "Xã Thụy Hưng",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260835",
    name: "Xã Thụy Liên",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260836",
    name: "Xã Thụy Lương",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260837",
    name: "Xã Thụy Ninh",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260838",
    name: "Xã Thụy Phong",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260839",
    name: "Xã Thụy Phúc",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260840",
    name: "Xã Thụy Quỳnh",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260841",
    name: "Xã Thụy Sơn",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260842",
    name: "Xã Thụy Tân",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260843",
    name: "Xã Thụy Thanh",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260844",
    name: "Xã Thụy Trình",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260845",
    name: "Xã Thụy Trường",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260846",
    name: "Xã Thụy Văn",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260847",
    name: "Xã Thụy Việt",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "260848",
    name: "Xã Thụy Xuân",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "270101",
    name: "Phường Bích Đào",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270102",
    name: "Phường Đông Thành",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270103",
    name: "Phường Nam Bình",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270104",
    name: "Phường Nam Thành",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270105",
    name: "Phường Ninh Khánh",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270106",
    name: "Phường Ninh Phong",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270107",
    name: "Phường Ninh Sơn",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270108",
    name: "Phường Phúc Thành",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270109",
    name: "Phường Tân Thành",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270110",
    name: "Phường Thanh Bình",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270111",
    name: "Phường Vân Giang",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270112",
    name: "Xã Ninh Nhất",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270113",
    name: "Xã Ninh Phúc",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270114",
    name: "Xã Ninh Tiến",
    is_higher_price: false,
    district_code: "1615",
  },
  {
    code: "270201",
    name: "Phường Bắc Sơn",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270202",
    name: "Phường Nam Sơn",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270203",
    name: "Phường Tân Bình",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270204",
    name: "Phường Tây Sơn",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270205",
    name: "Phường Trung Sơn",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270206",
    name: "Phường Yên Bình",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270207",
    name: "Xã Đông Sơn",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270208",
    name: "Xã Quang Sơn",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270209",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "1713",
  },
  {
    code: "270301",
    name: "Thị trấn Nho Quan",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270302",
    name: "Xã Cúc Phương",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270303",
    name: "Xã Đồng Phong",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270304",
    name: "Xã Đức Long",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270305",
    name: "Xã Gia Lâm",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270306",
    name: "Xã Gia Sơn",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270307",
    name: "Xã Gia Thủy",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270308",
    name: "Xã Gia Tường",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270309",
    name: "Xã Kỳ Phú",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270310",
    name: "Xã Lạc Vân",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270311",
    name: "Xã Lạng Phong",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270312",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270313",
    name: "Xã Phú Long",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270314",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270315",
    name: "Xã Quảng Lạc",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270316",
    name: "Xã Quỳnh Lưu",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270317",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270318",
    name: "Xã Sơn Lai",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270319",
    name: "Xã Sơn Thành",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270320",
    name: "Xã Thạch Bình",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270321",
    name: "Xã Thanh Lạc",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270322",
    name: "Xã Thượng Hòa",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270323",
    name: "Xã Văn Phong",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270324",
    name: "Xã Văn Phú",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270325",
    name: "Xã Văn Phương",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270326",
    name: "Xã Xích Thổ",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270327",
    name: "Xã Yên Quang",
    is_higher_price: false,
    district_code: "3247",
  },
  {
    code: "270401",
    name: "Thị trấn Me",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270402",
    name: "Xã Gia Hòa",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270403",
    name: "Xã Gia Hưng",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270404",
    name: "Xã Gia Lạc",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270405",
    name: "Xã Gia Lập",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270406",
    name: "Xã Gia Minh",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270407",
    name: "Xã Gia Phong",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270408",
    name: "Xã Gia Phú",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270409",
    name: "Xã Gia Phương",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270410",
    name: "Xã Gia Sinh",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270411",
    name: "Xã Gia Tân",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270412",
    name: "Xã Gia Thắng",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270413",
    name: "Xã Gia Thanh",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270414",
    name: "Xã Gia Thịnh",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270415",
    name: "Xã Gia Tiến",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270416",
    name: "Xã Gia Trấn",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270417",
    name: "Xã Gia Trung",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270418",
    name: "Xã Gia Vân",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270419",
    name: "Xã Gia Vượng",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270420",
    name: "Xã Gia Xuân",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270421",
    name: "Xã Liên Sơn",
    is_higher_price: false,
    district_code: "3191",
  },
  {
    code: "270501",
    name: "Thị trấn Thiên Tôn",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270502",
    name: "Xã Ninh An",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270503",
    name: "Xã Ninh Giang",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270504",
    name: "Xã Ninh Hải",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270505",
    name: "Xã Ninh Hòa",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270506",
    name: "Xã Ninh Khang",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270507",
    name: "Xã Ninh Mỹ",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270508",
    name: "Xã Ninh Thắng",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270509",
    name: "Xã Ninh Vân",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270510",
    name: "Xã Ninh Xuân",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270511",
    name: "Xã Trường Yên",
    is_higher_price: false,
    district_code: "1944",
  },
  {
    code: "270601",
    name: "Thị trấn Yên Thịnh",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270602",
    name: "Xã Khánh Dương",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270603",
    name: "Xã Khánh Thịnh",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270604",
    name: "Xã Khánh Thượng",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270605",
    name: "Xã Mai Sơn",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270606",
    name: "Xã Yên Đồng",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270607",
    name: "Xã Yên Hòa",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270608",
    name: "Xã Yên Hưng",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270609",
    name: "Xã Yên Lâm",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270610",
    name: "Xã Yên Mạc",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270611",
    name: "Xã Yên Mỹ",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270612",
    name: "Xã Yên Nhân",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270613",
    name: "Xã Yên Phong",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270614",
    name: "Xã Yên Thái",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270615",
    name: "Xã Yên Thắng",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270616",
    name: "Xã Yên Thành",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270617",
    name: "Xã Yên Từ",
    is_higher_price: false,
    district_code: "3327",
  },
  {
    code: "270701",
    name: "Thị trấn Bình Minh",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270702",
    name: "Thị trấn Phát Diệm",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270703",
    name: "Xã Ân Hòa",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270704",
    name: "Xã Chất Bình",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270705",
    name: "Xã Chính Tâm",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270706",
    name: "Xã Cồn Thoi",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270707",
    name: "Xã Định Hóa",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270708",
    name: "Xã Đồng Hướng",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270709",
    name: "Xã Hồi Ninh",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270710",
    name: "Xã Hùng Tiến",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270711",
    name: "Xã Kim Chính",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270712",
    name: "Xã Kim Định",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270713",
    name: "Xã Kim Đông",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270714",
    name: "Xã Kim Hải",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270715",
    name: "Xã Kim Mỹ",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270716",
    name: "Xã Kim Tân",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270717",
    name: "Xã Kim Trung",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270718",
    name: "Xã Lai Thành",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270719",
    name: "Xã Lưu Phương",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270720",
    name: "Xã Như Hòa",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270721",
    name: "Xã Quang Thiện",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270722",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270723",
    name: "Xã Thượng Kiệm",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270724",
    name: "Xã Văn Hải",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270725",
    name: "Xã Xuân Thiện",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270726",
    name: "Xã Yên Lộc",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270727",
    name: "Xã Yên Mật",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "270801",
    name: "Thị trấn Yên Ninh",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270802",
    name: "Xã Khánh An",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270803",
    name: "Xã Khánh Công",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270804",
    name: "Xã Khánh Cư",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270805",
    name: "Xã Khánh Cường",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270806",
    name: "Xã Khánh Hải",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270807",
    name: "Xã Khánh Hòa",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270808",
    name: "Xã Khánh Hội",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270809",
    name: "Xã Khánh Hồng",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270810",
    name: "Xã Khánh Lợi",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270811",
    name: "Xã Khánh Mậu",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270812",
    name: "Xã Khánh Nhạc",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270813",
    name: "Xã Khánh Phú",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270814",
    name: "Xã Khánh Thành",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270815",
    name: "Xã Khánh Thiện",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270816",
    name: "Xã Khánh Thủy",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270817",
    name: "Xã Khánh Tiên",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270818",
    name: "Xã Khánh Trung",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "270819",
    name: "Xã Khánh Vân",
    is_higher_price: false,
    district_code: "1714",
  },
  {
    code: "280101",
    name: "Phường An Hoạch",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280102",
    name: "Phường Ba Đình",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280103",
    name: "Phường Điện Biên",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280104",
    name: "Phường Đông Cương",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280105",
    name: "Phường Đông Hải",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280106",
    name: "Phường Đông Hương",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280107",
    name: "Phường Đông Sơn",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280108",
    name: "Phường Đông Thọ",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280109",
    name: "Phường Đông Vệ",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280110",
    name: "Phường Hàm Rồng",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280111",
    name: "Phường Lam Sơn",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280112",
    name: "Phường Nam Ngạn",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280113",
    name: "Phường Ngọc Trạo",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280114",
    name: "Phường Phú Sơn",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280115",
    name: "Phường Quảng Hưng",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280116",
    name: "Phường Quảng Thắng",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280117",
    name: "Phường Quảng Thành",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280118",
    name: "Phường Tân Sơn",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280119",
    name: "Phường Tào Xuyên",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280120",
    name: "Phường Trường Thi",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280121",
    name: "Xã Đông Hưng",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280122",
    name: "Xã Đông Lĩnh",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280123",
    name: "Xã Đông Tân",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280124",
    name: "Xã Đông Vinh",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280125",
    name: "Xã Hoằng Anh",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280126",
    name: "Xã Hoằng Đại",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280127",
    name: "Xã Hoằng Long",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280128",
    name: "Xã Hoằng Lý",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280129",
    name: "Xã Hoằng Quang",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280130",
    name: "Xã Quảng Cát",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280131",
    name: "Xã Quảng Đông",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280132",
    name: "Xã Quảng Phú",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280133",
    name: "Xã Quảng Tâm",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280134",
    name: "Xã Quảng Thịnh",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280135",
    name: "Xã Thiệu Dương",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280136",
    name: "Xã Thiệu Khánh",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280137",
    name: "Xã Thiệu Vân",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "280201",
    name: "Phường Ba Đình",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280202",
    name: "Phường Bắc Sơn",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280203",
    name: "Phường Đông Sơn",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280204",
    name: "Phường Lam Sơn",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280205",
    name: "Phường Ngọc Trạo",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280206",
    name: "Phường Phú Sơn",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280207",
    name: "Xã Hà Lan",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280208",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "1876",
  },
  {
    code: "280301",
    name: "Phường Bắc Sơn",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280302",
    name: "Phường Quảng Tiến",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280303",
    name: "Phường Trung Sơn",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280304",
    name: "Phường Trường Sơn",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280305",
    name: "Phường Quảng Châu",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280306",
    name: "Phường Quảng Cư",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280307",
    name: "Xã Quảng Đại",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280308",
    name: "Xã Quảng Hùng",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280309",
    name: "Xã Quảng Minh",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280310",
    name: "Xã Quảng Thọ",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280311",
    name: "Phường Quảng Vinh",
    is_higher_price: false,
    district_code: "1712",
  },
  {
    code: "280401",
    name: "Thị trấn Quan Hóa",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280402",
    name: "Xã Hiền Chung",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280403",
    name: "Xã Hiền Kiệt",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280404",
    name: "Xã Hồi Xuân",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280405",
    name: "Xã Nam Động",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280406",
    name: "Xã Nam Tiến",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280407",
    name: "Xã Nam Xuân",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280408",
    name: "Xã Phú Lệ",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280409",
    name: "Xã Phú Nghiêm",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280410",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280411",
    name: "Xã Phú Thanh",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280412",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280413",
    name: "Xã Thành Sơn",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280414",
    name: "Xã Thanh Xuân",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280415",
    name: "Xã Thiên Phủ",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280416",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280417",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280418",
    name: "Xã Xuân Phú",
    is_higher_price: false,
    district_code: "1879",
  },
  {
    code: "280501",
    name: "Thị trấn Quan Sơn",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280502",
    name: "Xã Mường Mìn",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280503",
    name: "Xã Na Mèo",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280504",
    name: "Xã Sơn Điện",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280505",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280506",
    name: "Xã Sơn Lư",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280507",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280508",
    name: "Xã Tam Lư",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280509",
    name: "Xã Tam Thanh",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280510",
    name: "Xã Trung Hạ",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280511",
    name: "Xã Trung Thượng",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280512",
    name: "Xã Trung Tiến",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280513",
    name: "Xã Trung Xuân",
    is_higher_price: false,
    district_code: "2000",
  },
  {
    code: "280601",
    name: "Thị trấn Mường Lát",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280602",
    name: "Xã Mường Chanh",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280603",
    name: "Xã Mường Lý",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280604",
    name: "Xã Nhi Sơn",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280605",
    name: "Xã Pù Nhi",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280606",
    name: "Xã Quang Chiểu",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280607",
    name: "Xã Tam Chung",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280608",
    name: "Xã Tén Tằn",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280609",
    name: "Xã Trung Lý",
    is_higher_price: false,
    district_code: "1878",
  },
  {
    code: "280701",
    name: "Thị trấn Cành Nàng",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280702",
    name: "Xã Ái Thượng",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280703",
    name: "Xã Ban Công",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280704",
    name: "Xã Cổ Lũng",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280705",
    name: "Xã Điền Hạ",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280706",
    name: "Xã Điền Lư",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280707",
    name: "Xã Điền Quang",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280708",
    name: "Xã Điền Thượng",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280709",
    name: "Xã Điền Trung",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280710",
    name: "Xã Hạ Trung",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280711",
    name: "Xã Kỳ Tân",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280712",
    name: "Xã Lâm Sa",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280713",
    name: "Xã Lũng Cao",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280714",
    name: "Xã Lũng Niêm",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280715",
    name: "Xã Lương Ngoại",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280716",
    name: "Xã Lương Nội",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280717",
    name: "Xã Lương Trung",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280718",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280719",
    name: "Xã Thành Lâm",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280720",
    name: "Xã Thành Sơn",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280721",
    name: "Xã Thiết Kế",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280722",
    name: "Xã Thiết Ống",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280723",
    name: "Xã Văn Nho",
    is_higher_price: false,
    district_code: "2070",
  },
  {
    code: "280801",
    name: "Thị trấn Thường Xuân",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280802",
    name: "Xã Bát Mọt",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280803",
    name: "Xã Luận Khê",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280804",
    name: "Xã Luận Thành",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280805",
    name: "Xã Lương Sơn",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280806",
    name: "Xã Ngọc Phụng",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280807",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280808",
    name: "Xã Thọ Thanh",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280809",
    name: "Xã Vạn Xuân",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280810",
    name: "Xã Xuân Cẩm",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280811",
    name: "Xã Xuân Cao",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280812",
    name: "Xã Xuân Chinh",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280813",
    name: "Xã Xuân Dương",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280814",
    name: "Xã Xuân Lẹ",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280815",
    name: "Xã Xuân Lộc",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280816",
    name: "Xã Xuân Thắng",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280817",
    name: "Xã Yên Nhân",
    is_higher_price: false,
    district_code: "1872",
  },
  {
    code: "280901",
    name: "Thị trấn Yên Cát",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280902",
    name: "Xã Bãi Trành",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280903",
    name: "Xã Bình Lương",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280904",
    name: "Xã Cát Tân",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280905",
    name: "Xã Cát Vân",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280906",
    name: "Xã Hóa Quỳ",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280907",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280908",
    name: "Xã Thanh Hòa",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280909",
    name: "Xã Thanh Lâm",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280910",
    name: "Xã Thanh Phong",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280911",
    name: "Xã Thanh Quân",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280912",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280913",
    name: "Xã Thanh Xuân",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280914",
    name: "Xã Thượng Ninh",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280915",
    name: "Xã Xuân Bình",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280916",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280917",
    name: "Xã Xuân Quỳ",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "280918",
    name: "Xã Yên Lễ",
    is_higher_price: false,
    district_code: "1871",
  },
  {
    code: "281001",
    name: "Thị trấn Bến Sung",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281002",
    name: "Xã Cán Khê",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281003",
    name: "Xã Hải Long",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281004",
    name: "Xã Hải Vân",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281005",
    name: "Xã Mậu Lâm",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281006",
    name: "Xã Phú Nhuận",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281007",
    name: "Xã Phúc Đường",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281008",
    name: "Xã Phượng Nghi",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281009",
    name: "Xã Thanh Kỳ",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281010",
    name: "Xã Thanh Tân",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281011",
    name: "Xã Xuân Du",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281012",
    name: "Xã Xuân Khang",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281013",
    name: "Xã Xuân Phúc",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281014",
    name: "Xã Xuân Thái",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281015",
    name: "Xã Xuân Thọ",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281016",
    name: "Xã Yên Lạc",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281017",
    name: "Xã Yên Thọ",
    is_higher_price: false,
    district_code: "2190",
  },
  {
    code: "281101",
    name: "Thị trấn Lang Chánh",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281102",
    name: "Xã Đồng Lương",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281103",
    name: "Xã Giao An",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281104",
    name: "Xã Giao Thiện",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281105",
    name: "Xã Lâm Phú",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281106",
    name: "Xã Quang Hiến",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281107",
    name: "Xã Tam Văn",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281108",
    name: "Xã Tân Phúc",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281109",
    name: "Xã Trí Nang",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281110",
    name: "Xã Yên Khương",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281111",
    name: "Xã Yên Thắng",
    is_higher_price: false,
    district_code: "3216",
  },
  {
    code: "281201",
    name: "Thị Trấn Ngọc Lặc",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281202",
    name: "Xã Cao Ngọc",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281203",
    name: "Xã Cao Thịnh",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281204",
    name: "Xã Đồng Thịnh",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281205",
    name: "Xã Kiên Thọ",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281206",
    name: "Xã Lam Sơn",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281207",
    name: "Xã Lộc Thịnh",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281208",
    name: "Xã Minh Sơn",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281209",
    name: "Xã Minh Tiến",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281210",
    name: "Xã Mỹ Tân",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281211",
    name: "Xã Ngọc Khê",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281212",
    name: "Xã Ngọc Liên",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281213",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281214",
    name: "Xã Ngọc Trung",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281215",
    name: "Xã Nguyệt Ấn",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281216",
    name: "Xã Phúc Thịnh",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281217",
    name: "Xã Phùng Giáo",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281218",
    name: "Xã Phùng Minh",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281219",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281220",
    name: "Xã Thạch Lập",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281221",
    name: "Xã Thúy Sơn",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281222",
    name: "Xã Vân Âm",
    is_higher_price: false,
    district_code: "1874",
  },
  {
    code: "281301",
    name: "Thị trấn Kim Tân",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281302",
    name: "Thị trấn Vân Du",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281303",
    name: "Xã Ngọc Trạo",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281304",
    name: "Xã Thạch Bình",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281305",
    name: "Xã Thạch Cẩm",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281306",
    name: "Xã Thạch Định",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281307",
    name: "Xã Thạch Đồng",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281308",
    name: "Xã Thạch Lâm",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281309",
    name: "Xã Thạch Long",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281310",
    name: "Xã Thạch Quảng",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281311",
    name: "Xã Thạch Sơn",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281312",
    name: "Xã Thạch Tân",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281313",
    name: "Xã Thạch Tượng",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281314",
    name: "Xã Thành An",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281315",
    name: "Xã Thành Công",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281316",
    name: "Xã Thành Hưng",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281317",
    name: "Xã Thành Kim",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281318",
    name: "Xã Thành Long",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281319",
    name: "Xã Thành Minh",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281320",
    name: "Xã Thành Mỹ",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281321",
    name: "Xã Thành Tâm",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281322",
    name: "Xã Thành Tân",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281323",
    name: "Xã Thành Thọ",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281324",
    name: "Xã Thành Tiến",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281325",
    name: "Xã Thành Trực",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281326",
    name: "Xã Thành Vân",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281327",
    name: "Xã Thành Vinh",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281328",
    name: "Xã Thành Yên",
    is_higher_price: false,
    district_code: "1880",
  },
  {
    code: "281401",
    name: "Thị trấn Cẩm Thủy",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281402",
    name: "Xã Cẩm Bình",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281403",
    name: "Xã Cẩm Châu",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281404",
    name: "Xã Cẩm Giang",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281405",
    name: "Xã Cẩm Liên",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281406",
    name: "Xã Cẩm Long",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281407",
    name: "Xã Cẩm Lương",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281408",
    name: "Xã Cẩm Ngọc",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281409",
    name: "Xã Cẩm Phong",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281410",
    name: "Xã Cẩm Phú",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281411",
    name: "Xã Cẩm Quý",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281412",
    name: "Xã Cẩm Sơn",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281413",
    name: "Xã Cẩm Tâm",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281414",
    name: "Xã Cẩm Tân",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281415",
    name: "Xã Cẩm Thạch",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281416",
    name: "Xã Cẩm Thành",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281417",
    name: "Xã Cẩm Tú",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281418",
    name: "Xã Cẩm Vân",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281419",
    name: "Xã Cẩm Yên",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281420",
    name: "Xã Phúc Do",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "281501",
    name: "Thị trấn Lam Sơn",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281502",
    name: "Thị trấn Sao Vàng",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281503",
    name: "Thị trấn Thọ Xuân",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281504",
    name: "Xã Bắc Lương",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281505",
    name: "Xã Hạnh Phúc",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281506",
    name: "Xã Nam Giang",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281507",
    name: "Xã Phú Yên",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281508",
    name: "Xã Quảng Phú",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281509",
    name: "Xã Tây Hồ",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281510",
    name: "Xã Thọ Diên",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281511",
    name: "Xã Thọ Hải",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281512",
    name: "Xã Thọ Lâm",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281513",
    name: "Xã Thọ Lập",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281514",
    name: "Xã Thọ Lộc",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281515",
    name: "Xã Thọ Minh",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281516",
    name: "Xã Thọ Nguyên",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281517",
    name: "Xã Thọ Thắng",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281518",
    name: "Xã Thọ Trường",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281519",
    name: "Xã Thọ Xương",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281520",
    name: "Xã Xuân Bái",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281521",
    name: "Xã Xuân Châu",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281522",
    name: "Xã Xuân Giang",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281523",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281524",
    name: "Xã Xuân Hưng",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281525",
    name: "Xã Xuân Khánh",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281526",
    name: "Xã Xuân Lai",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281527",
    name: "Xã Xuân Lam",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281528",
    name: "Xã Xuân Lập",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281529",
    name: "Xã Xuân Minh",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281530",
    name: "Xã Xuân Phong",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281531",
    name: "Xã Xuân Phú",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281532",
    name: "Xã Xuân Quang",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281533",
    name: "Xã Xuân Sơn",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281534",
    name: "Xã Xuân Tân",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281535",
    name: "Xã Xuân Thắng",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281536",
    name: "Xã Xuân Thành",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281537",
    name: "Xã Xuân Thiên",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281538",
    name: "Xã Xuân Tín",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281539",
    name: "Xã Xuân Trường",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281540",
    name: "Xã Xuân Vinh",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281541",
    name: "Xã Xuân Yên",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "281601",
    name: "Thị trấn Vĩnh Lộc",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281602",
    name: "Xã Vĩnh An",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281603",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281604",
    name: "Xã Vĩnh Hùng",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281605",
    name: "Xã Vĩnh Hưng",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281606",
    name: "Xã Vĩnh Khang",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281607",
    name: "Xã Vĩnh Long",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281608",
    name: "Xã Vĩnh Minh",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281609",
    name: "Xã Vĩnh Ninh",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281610",
    name: "Xã Vĩnh Phúc",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281611",
    name: "Xã Vĩnh Quang",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281612",
    name: "Xã Vĩnh Tân",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281613",
    name: "Xã Vĩnh Thành",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281614",
    name: "Xã Vĩnh Thịnh",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281615",
    name: "Xã Vĩnh Tiến",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281616",
    name: "Xã Vĩnh Yên",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "281701",
    name: "Thị trấn Vạn Hà",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281702",
    name: "Xã Thiệu Châu",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281703",
    name: "Xã Thiệu Chính",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281704",
    name: "Xã Thiệu Công",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281705",
    name: "Xã Thiệu Đô",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281706",
    name: "Xã Thiệu Duy",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281707",
    name: "Xã Thiệu Giang",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281708",
    name: "Xã Thiệu Giao",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281709",
    name: "Xã Thiệu Hòa",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281710",
    name: "Xã Thiệu Hợp",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281711",
    name: "Xã Thiệu Long",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281712",
    name: "Xã Thiệu Lý",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281713",
    name: "Xã Thiệu Minh",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281714",
    name: "Xã Thiệu Ngọc",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281715",
    name: "Xã Thiệu Nguyên",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281716",
    name: "Xã Thiệu Phú",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281717",
    name: "Xã Thiệu Phúc",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281718",
    name: "Xã Thiệu Quang",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281719",
    name: "Xã Thiệu Tâm",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281720",
    name: "Xã Thiệu Tân",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281721",
    name: "Xã Thiệu Thành",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281722",
    name: "Xã Thiệu Thịnh",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281723",
    name: "Xã Thiệu Tiến",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281724",
    name: "Xã Thiệu Toán",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281725",
    name: "Xã Thiệu Trung",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281726",
    name: "Xã Thiệu Vận",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281727",
    name: "Xã Thiệu Viên",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281728",
    name: "Xã Thiệu Vũ",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "281801",
    name: "Thị trấn Triệu Sơn",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281802",
    name: "Xã An Nông",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281803",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281804",
    name: "Xã Dân Lực",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281805",
    name: "Xã Dân Lý",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281806",
    name: "Xã Dân Quyền",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281807",
    name: "Xã Đồng Lợi",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281808",
    name: "Xã Đồng Thắng",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281809",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281810",
    name: "Xã Hợp Lý",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281811",
    name: "Xã Hợp Thắng",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281812",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281813",
    name: "Xã Hợp Tiến",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281814",
    name: "Xã Khuyến Nông",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281815",
    name: "Xã Minh Châu",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281816",
    name: "Xã Minh Dân",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281817",
    name: "Xã Minh Sơn",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281818",
    name: "Xã Nông Trường",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281819",
    name: "Xã Tân Ninh",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281820",
    name: "Xã Thái Hòa",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281821",
    name: "Xã Thọ Bình",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281822",
    name: "Xã Thọ Cường",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281823",
    name: "Xã Thọ Dân",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281824",
    name: "Xã Thọ Ngọc",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281825",
    name: "Xã Thọ Phú",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281826",
    name: "Xã Thọ Sơn",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281827",
    name: "Xã Thọ Tân",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281828",
    name: "Xã Thọ Thế",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281829",
    name: "Xã Thọ Tiến",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281830",
    name: "Xã Thọ Vực",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281831",
    name: "Xã Tiến Nông",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281832",
    name: "Xã Triệu Thành",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281833",
    name: "Xã Văn Sơn",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281834",
    name: "Xã Xuân Lộc",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281835",
    name: "Xã Xuân Thịnh",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281836",
    name: "Xã Xuân Thọ",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "281901",
    name: "Thị trấn Nông Cống",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281902",
    name: "Xã Công Bình",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281903",
    name: "Xã Công Chính",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281904",
    name: "Xã Công Liêm",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281905",
    name: "Xã Hoàng Giang",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281906",
    name: "Xã Hoàng Sơn",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281907",
    name: "Xã Minh Khôi",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281908",
    name: "Xã Minh Nghĩa",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281909",
    name: "Xã Tân Khang",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281910",
    name: "Xã Tân Phúc",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281911",
    name: "Xã Tân Thọ",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281912",
    name: "Xã Tế Lợi",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281913",
    name: "Xã Tế Nông",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281914",
    name: "Xã Tế Tân",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281915",
    name: "Xã Tế Thắng",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281916",
    name: "Xã Thăng Bình",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281917",
    name: "Xã Thăng Long",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281918",
    name: "Xã Thăng Thọ",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281919",
    name: "Xã Trung Chính",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281920",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281921",
    name: "Xã Trung Ý",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281922",
    name: "Xã Trường Giang",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281923",
    name: "Xã Trường Minh",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281924",
    name: "Xã Trường Sơn",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281925",
    name: "Xã Trường Trung",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281926",
    name: "Xã Tượng Lĩnh",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281927",
    name: "Xã Tượng Sơn",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281928",
    name: "Xã Tượng Văn",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281929",
    name: "Xã Vạn Hòa",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281930",
    name: "Xã Vạn Thắng",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281931",
    name: "Xã Vạn Thiện",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "281932",
    name: "Xã Yên Mỹ",
    is_higher_price: false,
    district_code: "2181",
  },
  {
    code: "282001",
    name: "Thị trấn Rừng Thông",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282002",
    name: "Xã Đông Anh",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282003",
    name: "Xã Đông Hòa",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282004",
    name: "Xã Đông Hoàng",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282005",
    name: "Xã Đông Khê",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282006",
    name: "Xã Đông Minh",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282007",
    name: "Xã Đông Nam",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282008",
    name: "Xã Đông Ninh",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282009",
    name: "Xã Đông Phú",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282010",
    name: "Xã Đông Quang",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282011",
    name: "Xã Đông Thanh",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282012",
    name: "Xã Đông Thịnh",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282013",
    name: "Xã Đông Tiến",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282014",
    name: "Xã Đông Văn",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282015",
    name: "Xã Đông Yên",
    is_higher_price: false,
    district_code: "1927",
  },
  {
    code: "282101",
    name: "Thị trấn Hà Trung",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282102",
    name: "Xã Hà Bắc",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282103",
    name: "Xã Hà Bình",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282104",
    name: "Xã Hà Châu",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282105",
    name: "Xã Hà Đông",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282106",
    name: "Xã Hà Dương",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282107",
    name: "Xã Hà Giang",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282108",
    name: "Xã Hà Hải",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282109",
    name: "Xã Hà Lai",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282110",
    name: "Xã Hà Lâm",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282111",
    name: "Xã Hà Lĩnh",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282112",
    name: "Xã Hà Long",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282113",
    name: "Xã Hà Ngọc",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282114",
    name: "Xã Hà Ninh",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282115",
    name: "Xã Hà Phong",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282116",
    name: "Xã Hà Phú",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282117",
    name: "Xã Hà Sơn",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282118",
    name: "Xã Hà Tân",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282119",
    name: "Xã Hà Thái",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282120",
    name: "Xã Hà Thanh",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282121",
    name: "Xã Hà Tiến",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282122",
    name: "Xã Hà Toại",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282123",
    name: "Xã Hà Vân",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282124",
    name: "Xã Hà Vinh",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282125",
    name: "Xã Hà Yên",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "282201",
    name: "Thị trấn Hoằng Hóa",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282202",
    name: "Xã Hoằng Cát",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282203",
    name: "Xã Hoằng Châu",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282204",
    name: "Xã Hoằng Đạo",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282205",
    name: "Xã Hoằng Đạt",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282206",
    name: "Xã Hoằng Đông",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282207",
    name: "Xã Hoằng Đồng",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282208",
    name: "Xã Hoằng Đức",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282209",
    name: "Xã Hoằng Giang",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282210",
    name: "Xã Hoằng Hà",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282211",
    name: "Xã Hoằng Hải",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282212",
    name: "Xã Hoằng Hợp",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282213",
    name: "Xã Hoằng Khánh",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282214",
    name: "Xã Hoằng Khê",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282215",
    name: "Xã Hoằng Kim",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282216",
    name: "Xã Hoằng Lộc",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282217",
    name: "Xã Hoằng Lương",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282218",
    name: "Xã Hoằng Lưu",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282219",
    name: "Xã Hoằng Minh",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282220",
    name: "Xã Hoằng Ngọc",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282221",
    name: "Xã Hoằng Phong",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282222",
    name: "Xã Hoằng Phú",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282223",
    name: "Xã Hoằng Phụ",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282224",
    name: "Xã Hoằng Phúc",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282225",
    name: "Xã Hoằng Phượng",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282226",
    name: "Xã Hoằng Quý",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282227",
    name: "Xã Hoằng Quỳ",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282228",
    name: "Xã Hoằng Sơn",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282229",
    name: "Xã Hoằng Tân",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282230",
    name: "Xã Hoằng Thái",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282231",
    name: "Xã Hoằng Thắng",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282232",
    name: "Xã Hoằng Thanh",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282233",
    name: "Xã Hoằng Thành",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282234",
    name: "Xã Hoằng Thịnh",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282235",
    name: "Xã Hoằng Tiến",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282236",
    name: "Xã Hoằng Trạch",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282237",
    name: "Xã Hoằng Trinh",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282238",
    name: "Xã Hoằng Trung",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282239",
    name: "Xã Hoằng Trường",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282240",
    name: "Xã Hoằng Vinh",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282241",
    name: "Xã Hoằng Xuân",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282242",
    name: "Xã Hoằng Xuyên",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282243",
    name: "Xã Hoằng Yến",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "282301",
    name: "Thị trấn Nga Sơn",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282302",
    name: "Xã Ba Đình",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282303",
    name: "Xã Nga An",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282304",
    name: "Xã Nga Bạch",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282305",
    name: "Xã Nga Điền",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282306",
    name: "Xã Nga Giáp",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282307",
    name: "Xã Nga Hải",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282308",
    name: "Xã Nga Hưng",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282309",
    name: "Xã Nga Liên",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282310",
    name: "Xã Nga Lĩnh",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282311",
    name: "Xã Nga Mỹ",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282312",
    name: "Xã Nga Nhân",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282313",
    name: "Xã Nga Phú",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282314",
    name: "Xã Nga Tân",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282315",
    name: "Xã Nga Thạch",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282316",
    name: "Xã Nga Thái",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282317",
    name: "Xã Nga Thắng",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282318",
    name: "Xã Nga Thanh",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282319",
    name: "Xã Nga Thành",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282320",
    name: "Xã Nga Thiện",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282321",
    name: "Xã Nga Thủy",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282322",
    name: "Xã Nga Tiến",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282323",
    name: "Xã Nga Trung",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282324",
    name: "Xã Nga Trường",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282325",
    name: "Xã Nga Văn",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282326",
    name: "Xã Nga Vịnh",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282327",
    name: "Xã Nga Yên",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "282401",
    name: "Thị trấn Hậu Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282402",
    name: "Xã Cầu Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282403",
    name: "Xã Châu Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282404",
    name: "Xã Đa Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282405",
    name: "Xã Đại Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282406",
    name: "Xã Đồng Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282407",
    name: "Xã Hải Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282408",
    name: "Xã Hoa Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282409",
    name: "Xã Hòa Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282410",
    name: "Xã Hưng Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282411",
    name: "Xã Liên Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282412",
    name: "Xã Lộc Sơn",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282413",
    name: "Xã Lộc Tân",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282414",
    name: "Xã Minh Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282415",
    name: "Xã Mỹ Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282416",
    name: "Xã Ngư Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282417",
    name: "Xã Phong Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282418",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282419",
    name: "Xã Quang Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282420",
    name: "Xã Thành Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282421",
    name: "Xã Thịnh Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282422",
    name: "Xã Thuần Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282423",
    name: "Xã Tiến Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282424",
    name: "Xã Triệu Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282425",
    name: "Xã Tuy Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282426",
    name: "Xã Văn Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282427",
    name: "Xã Xuân Lộc",
    is_higher_price: false,
    district_code: "1942",
  },
  {
    code: "282501",
    name: "Thị trấn Quảng Xương",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282502",
    name: "Xã Quảng Bình",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282503",
    name: "Xã Quảng Chính",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282504",
    name: "Xã Quảng Định",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282505",
    name: "Xã Quảng Đức",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282506",
    name: "Xã Quảng Giao",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282507",
    name: "Xã Quảng Hải",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282508",
    name: "Xã Quảng Hòa",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282509",
    name: "Xã Quảng Hợp",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282510",
    name: "Xã Quảng Khê",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282511",
    name: "Xã Quảng Lĩnh",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282512",
    name: "Xã Quảng Lộc",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282513",
    name: "Xã Quảng Lợi",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282514",
    name: "Xã Quảng Long",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282515",
    name: "Xã Quảng Lưu",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282516",
    name: "Xã Quảng Ngọc",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282517",
    name: "Xã Quảng Nham",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282518",
    name: "Xã Quảng Nhân",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282519",
    name: "Xã Quảng Ninh",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282520",
    name: "Xã Quảng Phong",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282521",
    name: "Xã Quảng Phúc",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282522",
    name: "Xã Quảng Tân",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282523",
    name: "Xã Quảng Thạch",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282524",
    name: "Xã Quảng Thái",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282525",
    name: "Xã Quảng Trạch",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282526",
    name: "Xã Quảng Trung",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282527",
    name: "Xã Quảng Trường",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282528",
    name: "Xã Quảng Văn",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282529",
    name: "Xã Quảng Vọng",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282530",
    name: "Xã Quảng Yên",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "282601",
    name: "Thị trấn Tĩnh Gia",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282602",
    name: "Xã Anh Sơn",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282603",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282604",
    name: "Xã Các Sơn",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282605",
    name: "Xã Định Hải",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282606",
    name: "Xã Hải An",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282607",
    name: "Xã Hải Bình",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282608",
    name: "Xã Hải Châu",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282609",
    name: "Xã Hải Hà",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282610",
    name: "Xã Hải Hòa",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282611",
    name: "Xã Hải Lĩnh",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282612",
    name: "Xã Hải Nhân",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282613",
    name: "Xã Hải Ninh",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282614",
    name: "Xã Hải Thanh",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282615",
    name: "Xã Hải Thượng",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282616",
    name: "Xã Hải Yến",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282617",
    name: "Xã Hùng Sơn",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282618",
    name: "Xã Mai Lâm",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282619",
    name: "Xã Nghi Sơn",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282620",
    name: "Xã Ngọc Lĩnh",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282621",
    name: "Xã Nguyên Bình",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282622",
    name: "Xã Ninh Hải",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282623",
    name: "Xã Phú Lâm",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282624",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282625",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282626",
    name: "Xã Tân Trường",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282627",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282628",
    name: "Xã Thanh Thủy",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282629",
    name: "Xã Tĩnh Hải",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282630",
    name: "Xã Triêu Dương",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282631",
    name: "Xã Trúc Lâm",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282632",
    name: "Xã Trường Lâm",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282633",
    name: "Xã Tùng Lâm",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282634",
    name: "Xã Xuân Lâm",
    is_higher_price: false,
    district_code: "1870",
  },
  {
    code: "282701",
    name: "Thị trấn Quán Lào",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282702",
    name: "Thị trấn Thống Nhất",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282703",
    name: "Xã Định Bình",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282704",
    name: "Xã Định Công",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282705",
    name: "Xã Định Hải",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282706",
    name: "Xã Định Hòa",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282707",
    name: "Xã Định Hưng",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282708",
    name: "Xã Định Liên",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282709",
    name: "Xã Định Long",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282710",
    name: "Xã Định Tân",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282711",
    name: "Xã Định Tăng",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282712",
    name: "Xã Định Thành",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282713",
    name: "Xã Định Tiến",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282714",
    name: "Xã Định Tường",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282715",
    name: "Xã Quí Lộc",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282716",
    name: "Xã Yên Bái",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282717",
    name: "Xã Yên Giang",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282718",
    name: "Xã Yên Hùng",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282719",
    name: "Xã Yên Lạc",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282720",
    name: "Xã Yên Lâm",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282721",
    name: "Xã Yên Ninh",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282722",
    name: "Xã Yên Phong",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282723",
    name: "Xã Yên Phú",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282724",
    name: "Xã Yên Tâm",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282725",
    name: "Xã Yên Thái",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282726",
    name: "Xã Yên Thịnh",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282727",
    name: "Xã Yên Thọ",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282728",
    name: "Xã Yên Trung",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "282729",
    name: "Xã Yên Trường",
    is_higher_price: false,
    district_code: "1875",
  },
  {
    code: "290101",
    name: "Phường Bến Thủy",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290102",
    name: "Phường Cửa Nam",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290103",
    name: "Phường Đội Cung",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290104",
    name: "Phường Đông Vĩnh",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290105",
    name: "Phường Hà Huy Tập",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290106",
    name: "Phường Hồng Sơn",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290107",
    name: "Phường Hưng Bình",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290108",
    name: "Phường Hưng Dũng",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290109",
    name: "Phường Hưng Phúc",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290110",
    name: "Phường Lê Lợi",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290111",
    name: "Phường Lê Mao",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290112",
    name: "Phường Quán Bàu",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290113",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290114",
    name: "Phường Trung Đô",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290115",
    name: "Phường Trường Thi",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290116",
    name: "Phường Vinh Tân",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290117",
    name: "Xã Hưng Chính",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290118",
    name: "Xã Hưng Đông",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290119",
    name: "Xã Hưng Hòa",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290120",
    name: "Xã Hưng Lộc",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290121",
    name: "Xã Nghi Ân",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290122",
    name: "Xã Nghi Đức",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290123",
    name: "Xã Nghi Kim",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290124",
    name: "Xã Nghi Liên",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290125",
    name: "Xã Nghi Phú",
    is_higher_price: false,
    district_code: "1617",
  },
  {
    code: "290201",
    name: "Phường Nghi Hải",
    is_higher_price: false,
    district_code: "1842",
  },
  {
    code: "290202",
    name: "Phường Nghi Hòa",
    is_higher_price: false,
    district_code: "1842",
  },
  {
    code: "290203",
    name: "Phường Nghi Hương",
    is_higher_price: false,
    district_code: "1842",
  },
  {
    code: "290204",
    name: "Phường Nghi Tân",
    is_higher_price: false,
    district_code: "1842",
  },
  {
    code: "290205",
    name: "Phường Nghi Thu",
    is_higher_price: false,
    district_code: "1842",
  },
  {
    code: "290206",
    name: "Phường Nghi Thuỷ",
    is_higher_price: false,
    district_code: "1842",
  },
  {
    code: "290207",
    name: "Phường Thu Thuỷ",
    is_higher_price: false,
    district_code: "1842",
  },
  {
    code: "290301",
    name: "Thị trấn Tân Lạc",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290302",
    name: "Xã Châu Bính",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290303",
    name: "Xã Châu Bình",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290304",
    name: "Xã Châu Hạnh",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290305",
    name: "Xã Châu Hoàn",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290306",
    name: "Xã Châu Hội",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290307",
    name: "Xã Châu Nga",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290308",
    name: "Xã Châu Phong",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290309",
    name: "Xã Châu Thắng",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290310",
    name: "Xã Châu Thuận",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290311",
    name: "Xã Châu Tiến",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290312",
    name: "Xã Diên Lãm",
    is_higher_price: false,
    district_code: "3261",
  },
  {
    code: "290401",
    name: "Thị trấn Quỳ Hợp",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290402",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290403",
    name: "Xã Châu Cường",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290404",
    name: "Xã Châu Đình",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290405",
    name: "Xã Châu Hồng",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290406",
    name: "Xã Châu Lộc",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290407",
    name: "Xã Châu Lý",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290408",
    name: "Xã Châu Quang",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290409",
    name: "Xã Châu Thái",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290410",
    name: "Xã Châu Thành",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290411",
    name: "Xã Châu Tiến",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290412",
    name: "Xã Đồng Hợp",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290413",
    name: "Xã Hạ Sơn",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290414",
    name: "Xã Liên Hợp",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290415",
    name: "Xã Minh Hợp",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290416",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290417",
    name: "Xã Nghĩa Xuân",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290418",
    name: "Xã Tam Hợp",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290419",
    name: "Xã Thọ Hợp",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290420",
    name: "Xã Văn Lợi",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290421",
    name: "Xã Yên Hợp",
    is_higher_price: false,
    district_code: "1852",
  },
  {
    code: "290501",
    name: "Thị trấn Nghĩa Đàn",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290502",
    name: "Xã Nghĩa An",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290503",
    name: "Xã Nghĩa Bình",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290504",
    name: "Xã Nghĩa Đức",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290505",
    name: "Xã Nghĩa Hiếu",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290506",
    name: "Xã Nghĩa Hội",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290507",
    name: "Xã Nghĩa Hồng",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290508",
    name: "Xã Nghĩa Hưng",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290509",
    name: "Xã Nghĩa Khánh",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290510",
    name: "Xã Nghĩa Lạc",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290511",
    name: "Xã Nghĩa Lâm",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290512",
    name: "Xã Nghĩa Liên",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290513",
    name: "Xã Nghĩa Lộc",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290514",
    name: "Xã Nghĩa Lợi",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290515",
    name: "Xã Nghĩa Long",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290516",
    name: "Xã Nghĩa Mai",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290517",
    name: "Xã Nghĩa Minh",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290518",
    name: "Xã Nghĩa Phú",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290519",
    name: "Xã Nghĩa Sơn",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290520",
    name: "Xã Nghĩa Tân",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290521",
    name: "Xã Nghĩa Thắng",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290522",
    name: "Xã Nghĩa Thịnh",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290523",
    name: "Xã Nghĩa Thọ",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290524",
    name: "Xã Nghĩa Trung",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290525",
    name: "Xã Nghĩa Yên",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "290601",
    name: "Thị trấn Cầu Giát",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290602",
    name: "Xã An Hòa",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290603",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290604",
    name: "Xã Quỳnh Bá",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290605",
    name: "Xã Quỳnh Bảng",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290606",
    name: "Xã Quỳnh Châu",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290607",
    name: "Xã Quỳnh Diện",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290608",
    name: "Xã Quỳnh Đôi",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290609",
    name: "Xã Quỳnh Giang",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290610",
    name: "Xã Quỳnh Hậu",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290611",
    name: "Xã Quỳnh Hoa",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290612",
    name: "Xã Quỳnh Hồng",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290613",
    name: "Xã Quỳnh Hưng",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290614",
    name: "Xã Quỳnh Lâm",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290615",
    name: "Xã Quỳnh Long",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290616",
    name: "Xã Quỳnh Lương",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290617",
    name: "Xã Quỳnh Minh",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290618",
    name: "Xã Quỳnh Mỹ",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290619",
    name: "Xã Quỳnh Nghĩa",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290620",
    name: "Xã Quỳnh Ngọc",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290621",
    name: "Xã Quỳnh Tam",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290622",
    name: "Xã Quỳnh Tân",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290623",
    name: "Xã Quỳnh Thạch",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290624",
    name: "Xã Quỳnh Thắng",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290625",
    name: "Xã Quỳnh Thanh",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290626",
    name: "Xã Quỳnh Thọ",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290627",
    name: "Xã Quỳnh Thuận",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290628",
    name: "Xã Quỳnh Văn",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290629",
    name: "Xã Quỳnh Yên",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290630",
    name: "Xã Sơn Hải",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290631",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290632",
    name: "Xã Tân Thắng",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "290633",
    name: "Xã Tiến Thủy",
    is_higher_price: false,
    district_code: "1848",
  },
  {
    code: "29071",
    name: "Thị trấn Mường Xén",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290710",
    name: "Xã Keng Đu",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290711",
    name: "Xã Mường Ải",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290712",
    name: "Xã Mường Lống",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290713",
    name: "Xã Mường Típ",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290714",
    name: "Xã Mỹ Lý",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290715",
    name: "Xã Na Loi",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290716",
    name: "Xã Na Ngoi",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290717",
    name: "Xã Nậm Càn",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290718",
    name: "Xã Nậm Cắn",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290719",
    name: "Xã Phà Đánh",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29072",
    name: "Xã Bắc Lý",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290720",
    name: "Xã Tà Cạ",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290721",
    name: "Xã Tây Sơn",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29073",
    name: "Xã Bảo Nam",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29074",
    name: "Xã Bảo Thắng",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29075",
    name: "Xã Chiêu Lưu",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29076",
    name: "Xã Đoọc Mạy",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29077",
    name: "Xã Huồi Tụ",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29078",
    name: "Xã Hữu Kiệm",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "29079",
    name: "Xã Hữu Lập",
    is_higher_price: false,
    district_code: "3211",
  },
  {
    code: "290801",
    name: "Thị trấn Hòa Bình",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290802",
    name: "Xã Hữu Khuông",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290803",
    name: "Xã Lưỡng Minh",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290804",
    name: "Xã Lưu Kiền",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290805",
    name: "Xã Mai Sơn",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290806",
    name: "Xã Nga My",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290807",
    name: "Xã Nhôn Mai",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290808",
    name: "Xã Tam Đình",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290809",
    name: "Xã Tam Hợp",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290810",
    name: "Xã Tam Quang",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290811",
    name: "Xã Tam Thái",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290812",
    name: "Xã Thạch Giám",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290813",
    name: "Xã Xá Lượng",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290814",
    name: "Xã Xiêng My",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290815",
    name: "Xã Yên Hòa",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290816",
    name: "Xã Yên Na",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290817",
    name: "Xã Yên Thắng",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290818",
    name: "Xã Yên Tĩnh",
    is_higher_price: false,
    district_code: "3288",
  },
  {
    code: "290901",
    name: "Thị trấn Con Cuông",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290902",
    name: "Xã Bình Chuẩn",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290903",
    name: "Xã Bồng Khê",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290904",
    name: "Xã Cam Lâm",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290905",
    name: "Xã Châu Khê",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290906",
    name: "Xã Chi Khê",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290907",
    name: "Xã Đôn Phục",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290908",
    name: "Xã Lạng Khê",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290909",
    name: "Xã Lục Dạ",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290910",
    name: "Xã Mậu Đức",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290911",
    name: "Xã Môn Sơn",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290912",
    name: "Xã Thạch Ngàn",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "290913",
    name: "Xã Yên Khê",
    is_higher_price: false,
    district_code: "1853",
  },
  {
    code: "291001",
    name: "Thị trấn Tân Kỳ",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291002",
    name: "Xã Đồng Văn",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291003",
    name: "Xã Giai Xuân",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291004",
    name: "Xã Hương Sơn",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291005",
    name: "Xã Kỳ Sơn",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291006",
    name: "Xã Kỳ Tân",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291007",
    name: "Xã Nghĩa Bình",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291008",
    name: "Xã Nghĩa Đồng",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291009",
    name: "Xã Nghĩa Dũng",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291010",
    name: "Xã Nghĩa Hành",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291011",
    name: "Xã Nghĩa Hoàn",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291012",
    name: "Xã Nghĩa Hợp",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291013",
    name: "Xã Nghĩa Phúc",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291014",
    name: "Xã Nghĩa Thái",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291015",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291016",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291017",
    name: "Xã Tân Hợp",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291018",
    name: "Xã Tân Hương",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291019",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291020",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291021",
    name: "Xã Tân Xuân",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291022",
    name: "Xã Tiên Kỳ",
    is_higher_price: false,
    district_code: "1845",
  },
  {
    code: "291101",
    name: "Thị trấn Yên Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291102",
    name: "Xã Bắc Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291103",
    name: "Xã Bảo Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291104",
    name: "Xã Công Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291105",
    name: "Xã Đại Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291106",
    name: "Xã Đô Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291107",
    name: "Xã Đồng Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291108",
    name: "Xã Đức Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291109",
    name: "Xã Hậu Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291110",
    name: "Xã Hoa Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291111",
    name: "Xã Hồng Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291112",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291113",
    name: "Xã Hùng Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291114",
    name: "Xã Khánh Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291115",
    name: "Xã Kim Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291116",
    name: "Xã Lăng Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291117",
    name: "Xã Liên Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291118",
    name: "Xã Long Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291119",
    name: "Xã Lý Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291120",
    name: "Xã Mã Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291121",
    name: "Xã Minh Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291122",
    name: "Xã Mỹ Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291123",
    name: "Xã Nam Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291124",
    name: "Xã Nhân Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291125",
    name: "Xã Phú Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291126",
    name: "Xã Phúc Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291127",
    name: "Xã Quang Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291128",
    name: "Xã Sơn Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291129",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291130",
    name: "Xã Tăng Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291131",
    name: "Xã Tây Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291132",
    name: "Xã Thịnh Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291133",
    name: "Xã Thọ Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291134",
    name: "Xã Tiến Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291135",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291136",
    name: "Xã Văn Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291137",
    name: "Xã Viên Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291138",
    name: "Xã Vĩnh Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291139",
    name: "Xã Xuân Thành",
    is_higher_price: false,
    district_code: "1846",
  },
  {
    code: "291201",
    name: "Thị trấn Diễn Châu",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291202",
    name: "Xã Diễn An",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291203",
    name: "Xã Diễn Bích",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291204",
    name: "Xã Diễn Bình",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291205",
    name: "Xã Diễn Cát",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291206",
    name: "Xã Diễn Đoài",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291207",
    name: "Xã Diễn Đồng",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291208",
    name: "Xã Diễn Hải",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291209",
    name: "Xã Diễn Hạnh",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291210",
    name: "Xã Diễn Hoa",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291211",
    name: "Xã Diễn Hoàng",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291212",
    name: "Xã Diễn Hồng",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291213",
    name: "Xã Diễn Hùng",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291214",
    name: "Xã Diễn Kim",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291215",
    name: "Xã Diễn Kỷ",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291216",
    name: "Xã Diễn Lâm",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291217",
    name: "Xã Diễn Liên",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291218",
    name: "Xã Diễn Lộc",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291219",
    name: "Xã Diễn Lợi",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291220",
    name: "Xã Diễn Minh",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291221",
    name: "Xã Diễn Mỹ",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291222",
    name: "Xã Diễn Ngọc",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291223",
    name: "Xã Diễn Nguyên",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291224",
    name: "Xã Diễn Phong",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291225",
    name: "Xã Diễn Phú",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291226",
    name: "Xã Diễn Phúc",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291227",
    name: "Xã Diễn Quảng",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291228",
    name: "Xã Diễn Tân",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291229",
    name: "Xã Diễn Thái",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291230",
    name: "Xã Diễn Thắng",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291231",
    name: "Xã Diễn Thành",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291232",
    name: "Xã Diễn Tháp",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291233",
    name: "Xã Diễn Thịnh",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291234",
    name: "Xã Diễn Thọ",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291235",
    name: "Xã Diễn Trung",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291236",
    name: "Xã Diễn Trường",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291237",
    name: "Xã Diễn Vạn",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291238",
    name: "Xã Diễn Xuân",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291239",
    name: "Xã Diễn Yên",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "291301",
    name: "Thị trấn Anh Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291302",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291303",
    name: "Xã Cẩm Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291304",
    name: "Xã Cao Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291305",
    name: "Xã Đỉnh Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291306",
    name: "Xã Đức Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291307",
    name: "Xã Hoa Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291308",
    name: "Xã Hội Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291309",
    name: "Xã Hùng Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291310",
    name: "Xã Khai Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291311",
    name: "Xã Lạng Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291312",
    name: "Xã Lĩnh Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291313",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291314",
    name: "Xã Phúc Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291315",
    name: "Xã Tam Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291316",
    name: "Xã Tào Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291317",
    name: "Xã Thạch Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291318",
    name: "Xã Thành Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291319",
    name: "Xã Thọ Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291320",
    name: "Xã Tường Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291321",
    name: "Xã Vĩnh Sơn",
    is_higher_price: false,
    district_code: "1844",
  },
  {
    code: "291401",
    name: "Thị trấn Đô Lương",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291402",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291403",
    name: "Xã Bài Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291404",
    name: "Xã Bồi Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291405",
    name: "Xã Đà Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291406",
    name: "Xã Đại Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291407",
    name: "Xã Đặng Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291408",
    name: "Xã Đông Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291409",
    name: "Xã Giang Sơn Đông",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291410",
    name: "Xã Giang Sơn Tây",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291411",
    name: "Xã Hiến Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291412",
    name: "Xã Hòa Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291413",
    name: "Xã Hồng Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291414",
    name: "Xã Lạc Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291415",
    name: "Xã Lam Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291416",
    name: "Xã Lưu Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291417",
    name: "Xã Minh Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291418",
    name: "Xã Mỹ Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291419",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291420",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291421",
    name: "Xã Nhân Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291422",
    name: "Xã Quang Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291423",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291424",
    name: "Xã Thái Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291425",
    name: "Xã Thịnh Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291426",
    name: "Xã Thuận Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291427",
    name: "Xã Thượng Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291428",
    name: "Xã Tràng Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291429",
    name: "Xã Trù Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291430",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291431",
    name: "Xã Văn Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291432",
    name: "Xã Xuân Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291433",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "1843",
  },
  {
    code: "291501",
    name: "Thị trấn Thanh Chương",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291502",
    name: "Xã Cát Văn",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291503",
    name: "Xã Đồng Văn",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291504",
    name: "Xã Hạnh Lâm",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291505",
    name: "Xã Ngọc Lâm",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291506",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291507",
    name: "Xã Phong Thịnh",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291508",
    name: "Xã Thanh An",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291509",
    name: "Xã Thanh Chi",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291510",
    name: "Xã Thanh Đồng",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291511",
    name: "Xã Thanh Đức",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291512",
    name: "Xã Thanh Dương",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291513",
    name: "Xã Thanh Giang",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291514",
    name: "Xã Thanh Hà",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291515",
    name: "Xã Thanh Hòa",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291516",
    name: "Xã Thanh Hưng",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291517",
    name: "Xã Thanh Hương",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291518",
    name: "Xã Thanh Khai",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291519",
    name: "Xã Thanh Khê",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291520",
    name: "Xã Thanh Lâm",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291521",
    name: "Xã Thanh Liên",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291522",
    name: "Xã Thanh Lĩnh",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291523",
    name: "Xã Thanh Long",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291524",
    name: "Xã Thanh Lương",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291525",
    name: "Xã Thanh Mai",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291526",
    name: "Xã Thanh Mỹ",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291527",
    name: "Xã Thanh Ngọc",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291528",
    name: "Xã Thanh Nho",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291529",
    name: "Xã Thanh Phong",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291530",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291531",
    name: "Xã Thanh Thịnh",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291532",
    name: "Xã Thanh Thủy",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291533",
    name: "Xã Thanh Tiên",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291534",
    name: "Xã Thanh Tùng",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291535",
    name: "Xã Thanh Tường",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291536",
    name: "Xã Thanh Văn",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291537",
    name: "Xã Thanh Xuân",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291538",
    name: "Xã Thanh Yên",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291539",
    name: "Xã Võ Liệt",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291540",
    name: "Xã Xuân Tường",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "291601",
    name: "Thị trấn Quán Hành",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291602",
    name: "Xã Nghi Công Bắc",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291603",
    name: "Xã Nghi Công Nam",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291604",
    name: "Xã Nghi Diên",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291605",
    name: "Xã Nghi Đồng",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291606",
    name: "Xã Nghi Hoa",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291607",
    name: "Xã Nghi Hợp",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291608",
    name: "Xã Nghi Hưng",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291609",
    name: "Xã Nghi Khánh",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291610",
    name: "Xã Nghi Kiều",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291611",
    name: "Xã Nghi Lâm",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291612",
    name: "Xã Nghi Long",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291613",
    name: "Xã Nghi Mỹ",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291614",
    name: "Xã Nghi Phong",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291615",
    name: "Xã Nghi Phương",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291616",
    name: "Xã Nghi Quang",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291617",
    name: "Xã Nghi Thạch",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291618",
    name: "Xã Nghi Thái",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291619",
    name: "Xã Nghi Thiết",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291620",
    name: "Xã Nghi Thịnh",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291621",
    name: "Xã Nghi Thuận",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291622",
    name: "Xã Nghi Tiến",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291623",
    name: "Xã Nghi Trung",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291624",
    name: "Xã Nghi Trường",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291625",
    name: "Xã Nghi Văn",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291626",
    name: "Xã Nghi Vạn",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291627",
    name: "Xã Nghi Xá",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291628",
    name: "Xã Nghi Xuân",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291629",
    name: "Xã Nghi Yên",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291630",
    name: "Xã Phúc Thọ",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "291701",
    name: "Thị trấn Nam Đàn",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291702",
    name: "Xã Hồng Long",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291703",
    name: "Xã Hùng Tiến",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291704",
    name: "Xã Khánh Sơn",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291705",
    name: "Xã Kim Liên",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291706",
    name: "Xã Nam Anh",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291707",
    name: "Xã Nam Cát",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291708",
    name: "Xã Nam Cường",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291709",
    name: "Xã Nam Giang",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291710",
    name: "Xã Nam Hưng",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291711",
    name: "Xã Nam Kim",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291712",
    name: "Xã Nam Lĩnh",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291713",
    name: "Xã Nam Lộc",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291714",
    name: "Xã Nam Nghĩa",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291715",
    name: "Xã Nam Phúc",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291716",
    name: "Xã Nam Tân",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291717",
    name: "Xã Nam Thái",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291718",
    name: "Xã Nam Thanh",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291719",
    name: "Xã Nam Thượng",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291720",
    name: "Xã Nam Trung",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291721",
    name: "Xã Nam Xuân",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291722",
    name: "Xã Vân Diên",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291723",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291724",
    name: "Xã Xuân Lâm",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "291801",
    name: "Thị trấn Hưng Nguyên",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291802",
    name: "Xã Hưng Châu",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291803",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291804",
    name: "Xã Hưng Khánh",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291805",
    name: "Xã Hưng Lam",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291806",
    name: "Xã Hưng Lĩnh",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291807",
    name: "Xã Hưng Lợi",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291808",
    name: "Xã Hưng Long",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291809",
    name: "Xã Hưng Mỹ",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291810",
    name: "Xã Hưng Nhân",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291811",
    name: "Xã Hưng Phú",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291812",
    name: "Xã Hưng Phúc",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291813",
    name: "Xã Hưng Tân",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291814",
    name: "Xã Hưng Tây",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291815",
    name: "Xã Hưng Thắng",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291816",
    name: "Xã Hưng Thịnh",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291817",
    name: "Xã Hưng Thông",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291818",
    name: "Xã Hưng Tiến",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291819",
    name: "Xã Hưng Trung",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291820",
    name: "Xã Hưng Xá",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291821",
    name: "Xã Hưng Xuân",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291822",
    name: "Xã Hưng Yên",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291823",
    name: "Xã Hưng Yên Bắc",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "291901",
    name: "Thị trấn Kim Sơn",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291902",
    name: "Xã Căm Muộn",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291903",
    name: "Xã Châu Kim",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291904",
    name: "Xã Châu Thôn",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291905",
    name: "Xã Đồng Văn",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291906",
    name: "Xã Hạnh Dịch",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291907",
    name: "Xã Mường Nọc",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291908",
    name: "Xã Nậm Giải",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291909",
    name: "Xã Nậm Nhoóng",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291910",
    name: "Xã Quang Phong",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291911",
    name: "Xã Quế Sơn",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291912",
    name: "Xã Thông Thụ",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291913",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "291914",
    name: "Xã Tri Lễ",
    is_higher_price: false,
    district_code: "3260",
  },
  {
    code: "292001",
    name: "Phường Hoà Hiếu",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292002",
    name: "Phường Long Sơn",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292003",
    name: "Phường Quang Phong",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292004",
    name: "Phường Quang Tiến",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292005",
    name: "Xã Đông Hiếu",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292006",
    name: "Xã Nghĩa Hòa",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292007",
    name: "Xã Nghĩa Mỹ",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292008",
    name: "Xã Nghĩa Thuận",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292009",
    name: "Xã Nghĩa Tiến",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292010",
    name: "Xã Tây Hiếu",
    is_higher_price: false,
    district_code: "1850",
  },
  {
    code: "292101",
    name: "Phường Mai Hùng",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292102",
    name: "Phường Quỳnh Dị",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292103",
    name: "Phường Quỳnh Phương",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292104",
    name: "Phường Quỳnh Thiện",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292105",
    name: "Phường Quỳnh Xuân",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292106",
    name: "Xã Quỳnh Lập",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292107",
    name: "Xã Quỳnh Liên",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292108",
    name: "Xã Quỳnh Lộc",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292109",
    name: "Xã Quỳnh Trang",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "292110",
    name: "Xã Quỳnh Vinh",
    is_higher_price: false,
    district_code: "1849",
  },
  {
    code: "300101",
    name: "Phường Bắc Hà",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300102",
    name: "Phường Đại Nài",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300103",
    name: "Phường Hà Huy Tập",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300104",
    name: "Phường Nam Hà",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300105",
    name: "Phường Nguyễn Du",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300106",
    name: "Phường Tân Giang",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300107",
    name: "Phường Thạch Linh",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300108",
    name: "Phường Thạch Quý",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300109",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300110",
    name: "Phường Văn Yên",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300111",
    name: "Xã Thạch Bình",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300112",
    name: "Xã Thạch Đồng",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300113",
    name: "Xã Thạch Hạ",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300114",
    name: "Xã Thạch Hưng",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300115",
    name: "Xã Thạch Môn",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300116",
    name: "Xã Thạch Trung",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "300201",
    name: "Phường Bắc Hồng",
    is_higher_price: false,
    district_code: "1814",
  },
  {
    code: "300202",
    name: "Phường Đậu Liêu",
    is_higher_price: false,
    district_code: "1814",
  },
  {
    code: "300203",
    name: "Phường Đức Thuận",
    is_higher_price: false,
    district_code: "1814",
  },
  {
    code: "300204",
    name: "Phường Nam Hồng",
    is_higher_price: false,
    district_code: "1814",
  },
  {
    code: "300205",
    name: "Phường Trung Lương",
    is_higher_price: false,
    district_code: "1814",
  },
  {
    code: "300206",
    name: "Xã Thuận Lộc",
    is_higher_price: false,
    district_code: "1814",
  },
  {
    code: "300301",
    name: "Thị trấn  Tây Sơn",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300302",
    name: "Thị trấn Phố Châu",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300303",
    name: "Xã Sơn An",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300304",
    name: "Xã Sơn Bằng",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300305",
    name: "Xã Sơn Bình",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300306",
    name: "Xã Sơn Châu",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300307",
    name: "Xã Sơn Diệm",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300308",
    name: "Xã Sơn Giang",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300309",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300310",
    name: "Xã Sơn Hàm",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300311",
    name: "Xã Sơn Hòa",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300312",
    name: "Xã Sơn Hồng",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300313",
    name: "Xã Sơn Kim 1",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300314",
    name: "Xã Sơn Kim 2",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300315",
    name: "Xã Sơn Lâm",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300316",
    name: "Xã Sơn Lễ",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300317",
    name: "Xã Sơn Lĩnh",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300318",
    name: "Xã Sơn Long",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300319",
    name: "Xã Sơn Mai",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300320",
    name: "Xã Sơn Mỹ",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300321",
    name: "Xã Sơn Ninh",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300322",
    name: "Xã Sơn Phú",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300323",
    name: "Xã Sơn Phúc",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300324",
    name: "Xã Sơn Quang",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300325",
    name: "Xã Sơn Tân",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300326",
    name: "Xã Sơn Tây",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300327",
    name: "Xã Sơn Thịnh",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300328",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300329",
    name: "Xã Sơn Tiến",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300330",
    name: "Xã Sơn Trà",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300331",
    name: "Xã Sơn Trung",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300332",
    name: "Xã Sơn Trường",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "300401",
    name: "Thị trấn Đức Thọ",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300402",
    name: "Xã Bùi Xá",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300403",
    name: "Xã Đức An",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300404",
    name: "Xã Đức Châu",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300405",
    name: "Xã Đức Đồng",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300406",
    name: "Xã Đức Dũng",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300407",
    name: "Xã Đức Hòa",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300408",
    name: "Xã Đức La",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300409",
    name: "Xã Đức Lạc",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300410",
    name: "Xã Đức Lâm",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300411",
    name: "Xã Đức Lạng",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300412",
    name: "Xã Đức Lập",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300413",
    name: "Xã Đức Long",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300414",
    name: "Xã Đức Nhân",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300415",
    name: "Xã Đức Quang",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300416",
    name: "Xã Đức Thanh",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300417",
    name: "Xã Đức Thịnh",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300418",
    name: "Xã Đức Thủy",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300419",
    name: "Xã Đức Tùng",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300420",
    name: "Xã Đức Vĩnh",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300421",
    name: "Xã Đức Yên",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300422",
    name: "Xã Liên Minh",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300423",
    name: "Xã Tân Hương",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300424",
    name: "Xã Thái Yên",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300425",
    name: "Xã Trung Lễ",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300426",
    name: "Xã Trường Sơn",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300427",
    name: "Xã Tùng Ảnh",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300428",
    name: "Xã Yên Hồ",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "300501",
    name: "Thị trấn Nghi Xuân",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300502",
    name: "Thị trấn Xuân An",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300503",
    name: "Xã Cỗ Đạm",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300504",
    name: "Xã Cương Gián",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300505",
    name: "Xã Tiên Điền",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300506",
    name: "Xã Xuân Đan",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300507",
    name: "Xã Xuân Giang",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300508",
    name: "Xã Xuân Hải",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300509",
    name: "Xã Xuân Hội",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300510",
    name: "Xã Xuân Hồng",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300511",
    name: "Xã Xuân Lam",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300512",
    name: "Xã Xuân Liên",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300513",
    name: "Xã Xuân Lĩnh",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300514",
    name: "Xã Xuân Mỹ",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300515",
    name: "Xã Xuân Phổ",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300516",
    name: "Xã Xuân Thành",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300517",
    name: "Xã Xuân Trường",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300518",
    name: "Xã Xuân Viên",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300519",
    name: "Xã Xuân Yên",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "300601",
    name: "Thị trấn Nghèn",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300602",
    name: "Xã Đồng Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300603",
    name: "Xã Gia Hanh",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300604",
    name: "Xã Khánh Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300605",
    name: "Xã Kim Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300606",
    name: "Xã Mỹ Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300607",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300608",
    name: "Xã Quang Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300609",
    name: "Xã Sơn Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300610",
    name: "Xã Song Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300611",
    name: "Xã Thanh Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300612",
    name: "Xã Thiên Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300613",
    name: "Xã Thuần Thiện",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300614",
    name: "Xã Thượng Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300615",
    name: "Xã Thường Nga",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300616",
    name: "Xã Tiến Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300617",
    name: "Xã Trung Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300618",
    name: "Xã Trường Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300619",
    name: "Xã Tùng Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300620",
    name: "Xã Vĩnh Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300621",
    name: "Xã Vượng Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300622",
    name: "Xã Xuân Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300623",
    name: "Xã Yên Lộc",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "300701",
    name: "Thị trấn Hương Khê",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300702",
    name: "Xã Gia Phố",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300703",
    name: "Xã Hà Linh",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300704",
    name: "Xã Hòa Hải",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300705",
    name: "Xã Hương Bình",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300706",
    name: "Xã Hương Đô",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300707",
    name: "Xã Hương Giang",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300708",
    name: "Xã Hương Lâm",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300709",
    name: "Xã Hương Liên",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300710",
    name: "Xã Hương Long",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300711",
    name: "Xã Hương Thủy",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300712",
    name: "Xã Hương Trà",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300713",
    name: "Xã Hương Trạch",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300714",
    name: "Xã Hương Vĩnh",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300715",
    name: "Xã Hương Xuân",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300716",
    name: "Xã Lộc Yên",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300717",
    name: "Xã Phú Gia",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300718",
    name: "Xã Phú Phong",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300719",
    name: "Xã Phúc Đồng",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300720",
    name: "Xã Phúc Trạch",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300721",
    name: "Xã Phương Điền",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300722",
    name: "Xã Phương Mỹ",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "300801",
    name: "Thị trấn Thạch Hà",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300802",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300803",
    name: "Xã Nam Hương",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300804",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300805",
    name: "Xã Phù Việt",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300806",
    name: "Xã Thạch Bàn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300807",
    name: "Xã Thạch Đài",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300808",
    name: "Xã Thạch Điền",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300809",
    name: "Xã Thạch Đỉnh",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300810",
    name: "Xã Thạch Hải",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300811",
    name: "Xã Thạch Hội",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300812",
    name: "Xã Thạch Hương",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300813",
    name: "Xã Thạch Kênh",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300814",
    name: "Xã Thạch Khê",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300815",
    name: "Xã Thạch Lạc",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300816",
    name: "Xã Thạch Lâm",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300817",
    name: "Xã Thạch Liên",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300818",
    name: "Xã Thạch Long",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300819",
    name: "Xã Thạch Lưu",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300820",
    name: "Xã Thạch Ngọc",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300821",
    name: "Xã Thạch Sơn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300822",
    name: "Xã Thạch Tân",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300823",
    name: "Xã Thạch Thắng",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300824",
    name: "Xã Thạch Thanh",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300825",
    name: "Xã Thạch Tiến",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300826",
    name: "Xã Thạch Trị",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300827",
    name: "Xã Thạch Văn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300828",
    name: "Xã Thạch Vĩnh",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300829",
    name: "Xã Thạch Xuân",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300830",
    name: "Xã Tượng Sơn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300831",
    name: "Xã Việt Xuyên",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "300901",
    name: "Thị trấn Cẩm Xuyên",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300902",
    name: "Thị trấn Thiên Cầm",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300903",
    name: "Xã Cẩm Bình",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300904",
    name: "Xã Cẩm Duệ",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300905",
    name: "Xã Cẩm Dương",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300906",
    name: "Xã Cẩm Hà",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300907",
    name: "Xã Cẩm Hòa",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300908",
    name: "Xã Cẩm Hưng",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300909",
    name: "Xã Cẩm Huy",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300910",
    name: "Xã Cẩm Lạc",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300911",
    name: "Xã Cẩm Lĩnh",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300912",
    name: "Xã Cẩm Lộc",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300913",
    name: "Xã Cẩm Minh",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300914",
    name: "Xã Cẩm Mỹ",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300915",
    name: "Xã Cẩm Nam",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300916",
    name: "Xã Cẩm Nhượng",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300917",
    name: "Xã Cẩm Phúc",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300918",
    name: "Xã Cẩm Quan",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300919",
    name: "Xã Cẩm Quang",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300920",
    name: "Xã Cẩm Sơn",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300921",
    name: "Xã Cẩm Thạch",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300922",
    name: "Xã Cẩm Thăng",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300923",
    name: "Xã Cẩm Thành",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300924",
    name: "Xã Cẩm Thịnh",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300925",
    name: "Xã Cẩm Trung",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300926",
    name: "Xã Cẩm Vĩnh",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "300927",
    name: "Xã Cẩm Yên",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "301001",
    name: "Xã Kỳ Bắc",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301002",
    name: "Xã Kỳ Châu",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301003",
    name: "Xã Kỳ Đồng",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301004",
    name: "Xã Kỳ Giang",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301005",
    name: "Xã Kỳ Hải",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301006",
    name: "Xã Kỳ Hợp",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301007",
    name: "Xã Kỳ Khang",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301008",
    name: "Xã Kỳ Lạc",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301009",
    name: "Xã Kỳ Lâm",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "30101",
    name: "Phường Hạ Lý",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "301010",
    name: "Xã Kỳ Phong",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301011",
    name: "Xã Kỳ Phú",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301012",
    name: "Xã Kỳ Sơn",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301013",
    name: "Xã Kỳ Tân",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301014",
    name: "Xã Kỳ Tây",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301015",
    name: "Xã Kỳ Thọ",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301016",
    name: "Xã Kỳ Thư",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301017",
    name: "Xã Kỳ Thượng",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301018",
    name: "Xã Kỳ Tiến",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301019",
    name: "Xã Kỳ Trung",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "30102",
    name: "Phường Hoàng Văn Thụ",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "301020",
    name: "Xã Kỳ Văn",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "301021",
    name: "Xã Kỳ Xuân",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "30103",
    name: "Phường Hùng Vương",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "30104",
    name: "Phường Minh Khai",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "30105",
    name: "Phường Phạm Hồng Thái",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "30106",
    name: "Phường Phan Bội Châu",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "30107",
    name: "Phường Quán Toan",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "30108",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "30109",
    name: "Phường Sở Dầu",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "30110",
    name: "Phường Thượng Lý",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "301101",
    name: "Thị trấn Vũ Quang",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301102",
    name: "Xã Ân Phú",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301103",
    name: "Xã Đức Bồng",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301104",
    name: "Xã Đức Giang",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301105",
    name: "Xã Đức Hương",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301106",
    name: "Xã Đức Liên",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301107",
    name: "Xã Đức Lĩnh",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301108",
    name: "Xã Hương Điền",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301109",
    name: "Xã Hương Minh",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "30111",
    name: "Phường Trại Chuối",
    is_higher_price: false,
    district_code: "1589",
  },
  {
    code: "301110",
    name: "Xã Hương Quang",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301111",
    name: "Xã Hương Thọ",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301112",
    name: "Xã Sơn Thọ",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "301201",
    name: "Xã An Lộc",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301202",
    name: "Xã Bình Lộc",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301203",
    name: "Xã Hộ Độ",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301204",
    name: "Xã Hồng Lộc",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301205",
    name: "Xã Ích Hậu",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301206",
    name: "Xã Mai Phụ",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301207",
    name: "Xã Phù Lưu",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301208",
    name: "Xã Tân Lộc",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301209",
    name: "Xã Thạch Bằng",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301210",
    name: "Xã Thạch Châu",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301211",
    name: "Xã Thạch Kim",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301212",
    name: "Xã Thạch Mỹ",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301213",
    name: "Xã Thịnh Lộc",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "301301",
    name: "Phường Kỳ Liên",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301302",
    name: "Phường Kỳ Long",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301303",
    name: "Phường Kỳ Phương",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301304",
    name: "Phường Kỳ Thịnh",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301305",
    name: "Phường Kỳ Trinh",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301306",
    name: "Phường Sông Trí",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301307",
    name: "Xã Kỳ Hà",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301308",
    name: "Xã Kỳ Hoa",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301309",
    name: "Xã Kỳ Hưng",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301310",
    name: "Xã Kỳ Lợi",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301311",
    name: "Xã Kỳ Nam",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "301312",
    name: "Xã Kỳ Ninh",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "30201",
    name: "Phường An Biên",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30202",
    name: "Phường An Dương",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30203",
    name: "Phường Cát Dài",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30204",
    name: "Phường Đông Hải",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30205",
    name: "Phường Dư Hàng",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30206",
    name: "Phường Dư Hàng Kênh",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30207",
    name: "Phường Hàng Kênh",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30208",
    name: "Phường Hồ Nam",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30209",
    name: "Phường Kênh Dương",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30210",
    name: "Phường Lam Sơn",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30211",
    name: "Phường Nghĩa Xá",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30212",
    name: "Phường Niệm Nghĩa",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30213",
    name: "Phường Trại Cau",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30214",
    name: "Phường Trần Nguyên Hãn",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30215",
    name: "Phường Vĩnh Niệm",
    is_higher_price: false,
    district_code: "1588",
  },
  {
    code: "30301",
    name: "Phường Cầu Đất",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30302",
    name: "Phường Cầu Tre",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30303",
    name: "Phường Đằng Giang",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30304",
    name: "Phường Đông Khê",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30305",
    name: "Phường Đổng Quốc Bình",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30306",
    name: "Phường Gia Viên",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30307",
    name: "Phường Lạc Viên",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30308",
    name: "Phường Lạch Tray",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30309",
    name: "Phường Lê Lợi",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30310",
    name: "Phường Lương Khánh Thiện",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30311",
    name: "Phường Máy Chai",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30312",
    name: "Phường Máy Tơ",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30313",
    name: "Phường Vạn Mỹ",
    is_higher_price: false,
    district_code: "1587",
  },
  {
    code: "30401",
    name: "Phường Bắc Sơn",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30402",
    name: "Phường Đồng Hoà",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30403",
    name: "Phường Lãm Hà",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30404",
    name: "Phường Nam Sơn",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30405",
    name: "Phường Ngọc Sơn",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30406",
    name: "Phường Phù Liễn",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30407",
    name: "Phường Quán Trữ",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30408",
    name: "Phường Trần Thành Ngọ",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30409",
    name: "Phường Tràng Minh",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30410",
    name: "Phường Văn Đẩu",
    is_higher_price: false,
    district_code: "1590",
  },
  {
    code: "30501",
    name: "Phường Cát Bi",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30502",
    name: "Phường Đằng Hải",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30503",
    name: "Phường Đằng Lâm",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30504",
    name: "Phường Đông Hải 1",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30505",
    name: "Phường Đông Hải 2",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30506",
    name: "Phường Nam Hải",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30507",
    name: "Phường Thành Tô",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30508",
    name: "Phường Tràng Cát",
    is_higher_price: false,
    district_code: "1591",
  },
  {
    code: "30601",
    name: "Phường Bàng La",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "30602",
    name: "Phường Hợp Đức",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "30603",
    name: "Phường Minh Đức",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "30604",
    name: "Phường Ngọc Hải",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "30605",
    name: "Phường Ngọc Xuyên",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "30606",
    name: "Phường Vạn Hương",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "30607",
    name: "Phường Vạn Sơn",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "30801",
    name: "Thị trấn Núi Đối",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30802",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30803",
    name: "Xã Đại Hà",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30804",
    name: "Xã Đại Hợp",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30805",
    name: "Xã Đoàn Xá",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30806",
    name: "Xã Đông Phương",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30807",
    name: "Xã Du Lễ",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30808",
    name: "Xã Hữu Bằng",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30809",
    name: "Xã Kiến Quốc",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30810",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30811",
    name: "Xã Ngũ Đoan",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30812",
    name: "Xã Ngũ Phúc",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30813",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30814",
    name: "Xã Tân Trào",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30815",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30816",
    name: "Xã Thuận Thiên",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30817",
    name: "Xã Thuỵ Hương",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30818",
    name: "Xã Tú Sơn",
    is_higher_price: false,
    district_code: "3203",
  },
  {
    code: "30901",
    name: "Thị trấn Minh Đức",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30902",
    name: "Thị trấn Núi Đèo",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30903",
    name: "Xã An Lư",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30904",
    name: "Xã An Sơn",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30905",
    name: "Xã Cao Nhân",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30906",
    name: "Xã Chính Mỹ",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30907",
    name: "Xã Đông Sơn",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30908",
    name: "Xã Dương Quan",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30909",
    name: "Xã Gia Đức",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30910",
    name: "Xã Gia Minh",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30911",
    name: "Xã Hoà Bình",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30912",
    name: "Xã Hoa Động",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30913",
    name: "Xã Hoàng Động",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30914",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30915",
    name: "Xã Kênh Giang",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30916",
    name: "Xã Kiền Bái",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30917",
    name: "Xã Kỳ Sơn",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30918",
    name: "Xã Lại Xuân",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30919",
    name: "Xã Lâm Động",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30920",
    name: "Xã Lập Lễ",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30921",
    name: "Xã Liên Khê",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30922",
    name: "Xã Lưu Kiếm",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30923",
    name: "Xã Lưu Kỳ",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30924",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30925",
    name: "Xã Mỹ Đồng",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30926",
    name: "Xã Ngũ Lão",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30927",
    name: "Xã Phả Lễ",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30928",
    name: "Xã Phù Ninh",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30929",
    name: "Xã Phục Lễ",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30930",
    name: "Xã Quảng Thanh",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30931",
    name: "Xã Tam Hưng",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30932",
    name: "Xã Tân Dương",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30933",
    name: "Xã Thiên Hương",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30934",
    name: "Xã Thuỷ Đường",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30935",
    name: "Xã Thuỷ Sơn",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30936",
    name: "Xã Thuỷ Triều",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "30937",
    name: "Xã Trung Hà",
    is_higher_price: false,
    district_code: "1726",
  },
  {
    code: "31001",
    name: "Thị trấn An Dương",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31002",
    name: "Xã An Đồng",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31003",
    name: "Xã An Hoà",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31004",
    name: "Xã An Hồng",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31005",
    name: "Xã An Hưng",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31006",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31007",
    name: "Xã Đại Bản",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31008",
    name: "Xã Đặng Cương",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31009",
    name: "Xã Đồng Thái",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31010",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "310101",
    name: "Phường Bắc Lý",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310102",
    name: "Phường Bắc Nghĩa",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310103",
    name: "Phường Đồng Mỹ",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310104",
    name: "Phường Đồng Phú",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310105",
    name: "Phường Đồng Sơn",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310106",
    name: "Phường Đức Ninh Đông",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310107",
    name: "Phường Hải Đình",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310108",
    name: "Phường Hải Thành",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310109",
    name: "Phường Nam Lý",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "31011",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "310110",
    name: "Phường Phú Hải",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310111",
    name: "Xã Bảo Ninh",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310112",
    name: "Xã Đức Ninh",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310113",
    name: "Xã Lộc Ninh",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310114",
    name: "Xã Nghĩa Ninh",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310115",
    name: "Xã Quang Phú",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "310116",
    name: "Xã Thuận Đức",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "31012",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31013",
    name: "Xã Lê Thiện",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31014",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31015",
    name: "Xã Quốc Tuấn",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "31016",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1819",
  },
  {
    code: "310201",
    name: "Thị trấn Đồng Lê",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310202",
    name: "Xã Cao Quảng",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310203",
    name: "Xã Châu Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310204",
    name: "Xã Đồng Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310205",
    name: "Xã Đức Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310206",
    name: "Xã Hương Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310207",
    name: "Xã Kim Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310208",
    name: "Xã Lâm Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310209",
    name: "Xã Lê Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310210",
    name: "Xã Mai Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310211",
    name: "Xã Nam Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310212",
    name: "Xã Ngư Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310213",
    name: "Xã Phong Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310214",
    name: "Xã Sơn Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310215",
    name: "Xã Thạch Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310216",
    name: "Xã Thanh Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310217",
    name: "Xã Thanh Thạch",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310218",
    name: "Xã Thuận Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310219",
    name: "Xã Tiến Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310220",
    name: "Xã Văn Hóa",
    is_higher_price: false,
    district_code: "3286",
  },
  {
    code: "310301",
    name: "Thị trấn Quy Đạt",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310302",
    name: "Xã Dân Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310303",
    name: "Xã Hóa Hợp",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310304",
    name: "Xã Hóa Phúc",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310305",
    name: "Xã Hóa Sơn",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310306",
    name: "Xã Hóa Thanh",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310307",
    name: "Xã Hóa Tiến",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310308",
    name: "Xã Hồng Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310309",
    name: "Xã Minh Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310310",
    name: "Xã Quy Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310311",
    name: "Xã Tân Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310312",
    name: "Xã Thượng Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310313",
    name: "Xã Trọng Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310314",
    name: "Xã Trung Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310315",
    name: "Xã Xuân Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310316",
    name: "Xã Yên Hóa",
    is_higher_price: false,
    district_code: "3224",
  },
  {
    code: "310401",
    name: "Xã Cảnh Dương",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310402",
    name: "Xã Cảnh Hóa",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310403",
    name: "Xã Phù Hóa",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310404",
    name: "Xã Quảng Châu",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310405",
    name: "Xã Quảng Đông",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310406",
    name: "Xã Quảng Hợp",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310407",
    name: "Xã Quảng Hưng",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310408",
    name: "Xã Quảng Kim",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310409",
    name: "Xã Quảng Liên",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310410",
    name: "Xã Quảng Lưu",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310411",
    name: "Xã Quảng Phú",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310412",
    name: "Xã Quảng Phương",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310413",
    name: "Xã Quảng Thạch",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310414",
    name: "Xã Quảng Thanh",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310415",
    name: "Xã Quảng Tiến",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310416",
    name: "Xã Quảng Trường",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310417",
    name: "Xã Quảng Tùng",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310418",
    name: "Xã Quảng Xuân",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "310501",
    name: "Thị trấn Hoàn Lão",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310502",
    name: "Thị trấn NT Việt Trung",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310503",
    name: "Xã Bắc Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310504",
    name: "Xã Cự Nẫm",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310505",
    name: "Xã Đại Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310506",
    name: "Xã Đồng Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310507",
    name: "Xã Đức Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310508",
    name: "Xã Hạ Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310509",
    name: "Xã Hải Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310510",
    name: "Xã Hòa Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310511",
    name: "Xã Hoàn Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310512",
    name: "Xã Hưng Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310513",
    name: "Xã Lâm Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310514",
    name: "Xã Liên Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310515",
    name: "Xã Lý Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310516",
    name: "Xã Mỹ Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310517",
    name: "Xã Nam Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310518",
    name: "Xã Nhân Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310519",
    name: "Xã Phú Định",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310520",
    name: "Xã Phú Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310521",
    name: "Xã Phúc Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310522",
    name: "Xã Sơn Lộc",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310523",
    name: "Xã Sơn Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310524",
    name: "Xã Tân Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310525",
    name: "Xã Tây Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310526",
    name: "Xã Thanh Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310527",
    name: "Xã Thượng Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310528",
    name: "Xã Trung Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310529",
    name: "Xã Vạn Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310530",
    name: "Xã Xuân Trạch",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "310601",
    name: "Thị trấn Quán Hàu",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310602",
    name: "Xã An Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310603",
    name: "Xã Duy Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310604",
    name: "Xã Gia Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310605",
    name: "Xã Hải Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310606",
    name: "Xã Hàm Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310607",
    name: "Xã Hiền Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310608",
    name: "Xã Lương Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310609",
    name: "Xã Tân Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310610",
    name: "Xã Trường Sơn",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310611",
    name: "Xã Trường Xuân",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310612",
    name: "Xã Vạn Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310613",
    name: "Xã Vĩnh Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310614",
    name: "Xã Võ Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310615",
    name: "Xã Xuân Ninh",
    is_higher_price: false,
    district_code: "2002",
  },
  {
    code: "310701",
    name: "Thị trấn Kiến Giang",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310702",
    name: "Thị trấn NT Lệ Ninh",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310703",
    name: "Xã An Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310704",
    name: "Xã Cam Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310705",
    name: "Xã Dương Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310706",
    name: "Xã Hoa Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310707",
    name: "Xã Hồng Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310708",
    name: "Xã Hưng Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310709",
    name: "Xã Kim Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310710",
    name: "Xã Lâm Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310711",
    name: "Xã Liên Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310712",
    name: "Xã Lộc Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310713",
    name: "Xã Mai Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310714",
    name: "Xã Mỹ Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310715",
    name: "Xã Ngân Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310716",
    name: "Xã Ngư Thủy Bắc",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310717",
    name: "Xã Ngư Thủy Nam",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310718",
    name: "Xã Ngư Thủy Trung",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310719",
    name: "Xã Phong Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310720",
    name: "Xã Phú Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310721",
    name: "Xã Sen Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310722",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310723",
    name: "Xã Tân Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310724",
    name: "Xã Thái Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310725",
    name: "Xã Thanh Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310726",
    name: "Xã Trường Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310727",
    name: "Xã Văn Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310728",
    name: "Xã Xuân Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "310801",
    name: "Phường Ba Đồn",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310802",
    name: "Phường Quảng Long",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310803",
    name: "Phường Quảng Phong",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310804",
    name: "Phường Quảng Phúc",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310805",
    name: "Phường Quảng Thọ",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310806",
    name: "Phường Quảng Thuận",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310807",
    name: "Xã Quảng Hải",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310808",
    name: "Xã Quảng Hòa",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310809",
    name: "Xã Quảng Lộc",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310810",
    name: "Xã Quảng Minh",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310811",
    name: "Xã Quảng Sơn",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310812",
    name: "Xã Quảng Tân",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310813",
    name: "Xã Quảng Thủy",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310814",
    name: "Xã Quảng Tiên",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310815",
    name: "Xã Quảng Trung",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "310816",
    name: "Xã Quảng Văn",
    is_higher_price: false,
    district_code: "1859",
  },
  {
    code: "31101",
    name: "Thị trấn Tiên Lãng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31102",
    name: "Xã Bắc Hưng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31103",
    name: "Xã Bạch Đằng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31104",
    name: "Xã Cấp Tiến",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31105",
    name: "Xã Đại Thắng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31106",
    name: "Xã Đoàn Lập",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31107",
    name: "Xã Đông Hưng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31108",
    name: "Xã Hùng Thắng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31109",
    name: "Xã Khởi Nghĩa",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31110",
    name: "Xã Kiến Thiết",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31111",
    name: "Xã Nam Hưng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31112",
    name: "Xã Quang Phục",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31113",
    name: "Xã Quyết Tiến",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31114",
    name: "Xã Tây Hưng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31115",
    name: "Xã Tiên Cường",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31116",
    name: "Xã Tiên Hưng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31117",
    name: "Xã Tiên Minh",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31118",
    name: "Xã Tiên Thắng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31119",
    name: "Xã Tiên Thanh",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31120",
    name: "Xã Tiên Tiến",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31121",
    name: "Xã Toàn Thắng",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31122",
    name: "Xã Tự Cường",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31123",
    name: "Xã Vinh Quang",
    is_higher_price: false,
    district_code: "1821",
  },
  {
    code: "31201",
    name: "Thị trấn Vĩnh Bảo",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31202",
    name: "Xã An Hoà",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31203",
    name: "Xã Cao Minh",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31204",
    name: "Xã Cổ Am",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31205",
    name: "Xã Cộng Hiền",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31206",
    name: "Xã Đồng Minh",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31207",
    name: "Xã Dũng Tiến",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31208",
    name: "Xã Giang Biên",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31209",
    name: "Xã Hiệp Hoà",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31210",
    name: "Xã Hoà Bình",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31211",
    name: "Xã Hưng Nhân",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31212",
    name: "Xã Hùng Tiến",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31213",
    name: "Xã Liên Am",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31214",
    name: "Xã Lý Học",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31215",
    name: "Xã Nhân Hoà",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31216",
    name: "Xã Tam Cường",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31217",
    name: "Xã Tam Đa",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31218",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31219",
    name: "Xã Tân Liên",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31220",
    name: "Xã Thắng Thuỷ",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31221",
    name: "Xã Thanh Lương",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31222",
    name: "Xã Tiền Phong",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31223",
    name: "Xã Trấn Dương",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31224",
    name: "Xã Trung Lập",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31225",
    name: "Xã Việt Tiến",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31226",
    name: "Xã Vĩnh An",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31227",
    name: "Xã Vĩnh Long",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31228",
    name: "Xã Vĩnh Phong",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31229",
    name: "Xã Vinh Quang",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31230",
    name: "Xã Vĩnh Tiến",
    is_higher_price: false,
    district_code: "1822",
  },
  {
    code: "31301",
    name: "Thị trấn Cát Bà",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31302",
    name: "Thị trấn Cát Hải",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31303",
    name: "Xã Đồng Bài",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31304",
    name: "Xã Gia Luận",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31305",
    name: "Xã Hiền Hào",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31306",
    name: "Xã Hoàng Châu",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31307",
    name: "Xã Nghĩa Lộ",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31308",
    name: "Xã Phù Long",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31309",
    name: "Xã Trân Châu",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31310",
    name: "Xã Văn Phong",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31311",
    name: "Xã Việt Hải",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31312",
    name: "Xã Xuân Đám",
    is_higher_price: false,
    district_code: "2108",
  },
  {
    code: "31501",
    name: "Phường Anh Dũng",
    is_higher_price: false,
    district_code: "1706",
  },
  {
    code: "31502",
    name: "Phường Đa Phúc",
    is_higher_price: false,
    district_code: "1706",
  },
  {
    code: "31503",
    name: "Phường Hải Thành",
    is_higher_price: false,
    district_code: "1706",
  },
  {
    code: "31504",
    name: "Phường Hoà Nghĩa",
    is_higher_price: false,
    district_code: "1706",
  },
  {
    code: "31505",
    name: "Phường Hưng Đạo",
    is_higher_price: false,
    district_code: "1706",
  },
  {
    code: "31506",
    name: "Phường Tân Thành",
    is_higher_price: false,
    district_code: "1706",
  },
  {
    code: "320101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320106",
    name: "Phường Đông Giang",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320107",
    name: "Phường Đông Lễ",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320108",
    name: "Phường Đông Lương",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320109",
    name: "Phường Đông Thanh",
    is_higher_price: false,
    district_code: "1620",
  },
  {
    code: "320201",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1621",
  },
  {
    code: "320202",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1621",
  },
  {
    code: "320203",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1621",
  },
  {
    code: "320204",
    name: "Phường An Đôn",
    is_higher_price: false,
    district_code: "1621",
  },
  {
    code: "320205",
    name: "Xã Hải Lệ",
    is_higher_price: false,
    district_code: "1621",
  },
  {
    code: "320301",
    name: "Thị trấn Bến Quan",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320302",
    name: "Thị trấn Cửa Tùng",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320303",
    name: "Thị trấn Hồ Xá",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320304",
    name: "Xã Vĩnh Chấp",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320305",
    name: "Xã Vĩnh Giang",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320306",
    name: "Xã Vĩnh Hà",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320307",
    name: "Xã Vĩnh Hiền",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320308",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320309",
    name: "Xã Vĩnh Khê",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320310",
    name: "Xã Vĩnh Kim",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320311",
    name: "Xã Vĩnh Lâm",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320312",
    name: "Xã Vĩnh Long",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320313",
    name: "Xã Vĩnh Nam",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320314",
    name: "Xã Vĩnh Ô",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320315",
    name: "Xã Vĩnh Sơn",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320316",
    name: "Xã Vĩnh Tân",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320317",
    name: "Xã Vĩnh Thạch",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320318",
    name: "Xã Vĩnh Thái",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320319",
    name: "Xã Vĩnh Thành",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320320",
    name: "Xã Vĩnh Thủy",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320321",
    name: "Xã Vĩnh Trung",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320322",
    name: "Xã Vĩnh Tú",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "320401",
    name: "Thị trấn Cửa Việt",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320402",
    name: "Thị trấn Gio Linh",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320403",
    name: "Xã Gio An",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320404",
    name: "Xã Gio Bình",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320405",
    name: "Xã Gio Châu",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320406",
    name: "Xã Gio Hải",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320407",
    name: "Xã Gio Hòa",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320408",
    name: "Xã Gio Mai",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320409",
    name: "Xã Gio Mỹ",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320410",
    name: "Xã Gio Phong",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320411",
    name: "Xã Gio Quang",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320412",
    name: "Xã Gio Sơn",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320413",
    name: "Xã Gio Thành",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320414",
    name: "Xã Gio Việt",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320415",
    name: "Xã Hải Thái",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320416",
    name: "Xã Linh Hải",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320417",
    name: "Xã Linh Thượng",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320418",
    name: "Xã Trung Giang",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320419",
    name: "Xã Trung Hải",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320420",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320421",
    name: "Xã Vĩnh Trường",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "320501",
    name: "Thị trấn Cam Lộ",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320502",
    name: "Xã Cam An",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320503",
    name: "Xã Cam Chính",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320504",
    name: "Xã Cam Hiếu",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320505",
    name: "Xã Cam Nghĩa",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320506",
    name: "Xã Cam Thanh",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320507",
    name: "Xã Cam Thành",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320508",
    name: "Xã Cam Thủy",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320509",
    name: "Xã Cam Tuyền",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "320601",
    name: "Thị Trấn Ái Tử",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320602",
    name: "Xã Triệu Ái",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320603",
    name: "Xã Triệu An",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320604",
    name: "Xã Triệu Đại",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320605",
    name: "Xã Triệu Độ",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320606",
    name: "Xã Triệu Đông",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320607",
    name: "Xã Triệu Giang",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320608",
    name: "Xã Triệu Hòa",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320609",
    name: "Xã Triệu Lăng",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320610",
    name: "Xã Triệu Long",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320611",
    name: "Xã Triệu Phước",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320612",
    name: "Xã Triệu Sơn",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320613",
    name: "Xã Triệu Tài",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320614",
    name: "Xã Triệu Thành",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320615",
    name: "Xã Triệu Thuận",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320616",
    name: "Xã Triệu Thượng",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320617",
    name: "Xã Triệu Trạch",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320618",
    name: "Xã Triệu Trung",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320619",
    name: "Xã Triệu Vân",
    is_higher_price: false,
    district_code: "2040",
  },
  {
    code: "320701",
    name: "Thị trấn Hải Lăng",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320702",
    name: "Xã Hải An",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320703",
    name: "Xã Hải Ba",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320704",
    name: "Xã Hải Chánh",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320705",
    name: "Xã Hải Dương",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320706",
    name: "Xã Hải Hòa",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320707",
    name: "Xã Hải Khê",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320708",
    name: "Xã Hải Lâm",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320709",
    name: "Xã Hải Phú",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320710",
    name: "Xã Hải Quế",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320711",
    name: "Xã Hải Quy",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320712",
    name: "Xã Hải Sơn",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320713",
    name: "Xã Hải Tân",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320714",
    name: "Xã Hải Thành",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320715",
    name: "Xã Hải Thiện",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320716",
    name: "Xã Hải Thọ",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320717",
    name: "Xã Hải Thượng",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320718",
    name: "Xã Hải Trường",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320719",
    name: "Xã Hải Vĩnh",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320720",
    name: "Xã Hải Xuân",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "320801",
    name: "Thị trấn Khe Sanh",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320802",
    name: "Thị trấn Lao Bảo",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320803",
    name: "Xã  A Dơi",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320804",
    name: "Xã A Túc",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320805",
    name: "Xã A Xing",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320806",
    name: "Xã Ba Tầng",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320807",
    name: "Xã Húc",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320808",
    name: "Xã Hướng Lập",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320809",
    name: "Xã Hướng Linh",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320810",
    name: "Xã Hướng Lộc",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320811",
    name: "Xã Hướng Phùng",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320812",
    name: "Xã Hướng Sơn",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320813",
    name: "Xã Hướng Tân",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320814",
    name: "Xã Hướng Việt",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320815",
    name: "Xã Tân Hợp",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320816",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320817",
    name: "Xã Tân Liên",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320818",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320819",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320820",
    name: "Xã Thanh",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320821",
    name: "Xã Thuận",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320822",
    name: "Xã Xy",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "320901",
    name: "Thị trấn Krông Klang",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320902",
    name: "Xã A Bung",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320903",
    name: "Xã A Ngo",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320904",
    name: "Xã A Vao",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320905",
    name: "Xã Ba Lòng",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320906",
    name: "Xã Ba Nang",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320907",
    name: "Xã Đa Krông",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320908",
    name: "Xã Hải Phúc",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320909",
    name: "Xã Húc Nghì",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320910",
    name: "Xã Hướng Hiệp",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320911",
    name: "Xã Mò Ó",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320912",
    name: "Xã Tà Long",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320913",
    name: "Xã Tà Rụt",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "320914",
    name: "Xã Triệu Nguyên",
    is_higher_price: false,
    district_code: "2105",
  },
  {
    code: "330101",
    name: "Phường An Cựu",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330102",
    name: "Phường An Đông",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330103",
    name: "Phường An Hòa",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330104",
    name: "Phường An Tây",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330105",
    name: "Phường Hương Long",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330106",
    name: "Phường Hương Sơ",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330107",
    name: "Phường Kim Long",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330108",
    name: "Phường Phú Bình",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330109",
    name: "Phường Phú Cát",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330110",
    name: "Phường Phú Hậu",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330111",
    name: "Phường Phú Hiệp",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330112",
    name: "Phường Phú Hòa",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330113",
    name: "Phường Phú Hội",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330114",
    name: "Phường Phú Nhuận",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330115",
    name: "Phường Phú Thuận",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330116",
    name: "Phường Phước Vĩnh",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330117",
    name: "Phường Phường Đúc",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330118",
    name: "Phường Tây Lộc",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330119",
    name: "Phường Thuận Hòa",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330120",
    name: "Phường Thuận Lộc",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330121",
    name: "Phường Thuận Thành",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330122",
    name: "Phường Thuỷ Biều",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330123",
    name: "Phường Thuỷ Xuân",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330124",
    name: "Phường Trường An",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330125",
    name: "Phường Vĩ Dạ",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330126",
    name: "Phường Vĩnh Ninh",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "330127",
    name: "Phường Xuân Phú",
    is_higher_price: false,
    district_code: "1585",
  },
  {
    code: "33021",
    name: "Thị trấn Phong Điền",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330210",
    name: "Xã Phong Hải",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330211",
    name: "Xã Phong Hiền",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330212",
    name: "Xã Phong Hòa",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330213",
    name: "Xã Phong Mỹ",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330214",
    name: "Xã Phong Sơn",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330215",
    name: "Xã Phong Thu",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330216",
    name: "Xã Phong Xuân",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33022",
    name: "Xã Điền Hải",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33023",
    name: "Xã Điền Hòa",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33024",
    name: "Xã Điền Hương",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33025",
    name: "Xã Điền Lộc",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33026",
    name: "Xã Điền Môn",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33027",
    name: "Xã Phong An",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33028",
    name: "Xã Phong Bình",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "33029",
    name: "Xã Phong Chương",
    is_higher_price: false,
    district_code: "2193",
  },
  {
    code: "330301",
    name: "Thị trấn Sịa",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330302",
    name: "Xã Quảng An",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330303",
    name: "Xã Quảng Công",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330304",
    name: "Xã Quảng Lợi",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330305",
    name: "Xã Quảng Ngạn",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330306",
    name: "Xã Quảng Phú",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330307",
    name: "Xã Quảng Phước",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330308",
    name: "Xã Quảng Thái",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330309",
    name: "Xã Quảng Thành",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330310",
    name: "Xã Quảng Thọ",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330311",
    name: "Xã Quảng Vinh",
    is_higher_price: false,
    district_code: "3257",
  },
  {
    code: "330401",
    name: "Phường Hương An",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330402",
    name: "Phường Hương Chữ",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330403",
    name: "Phường Hương Hồ",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330404",
    name: "Phường Hương Vân",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330405",
    name: "Phường Hương Văn",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330406",
    name: "Phường Hương Xuân",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330407",
    name: "Phường Tứ Hạ",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330408",
    name: "Xã Bình Điền",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330409",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330410",
    name: "Xã Hải Dương",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330411",
    name: "Xã Hồng Tiến",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330412",
    name: "Xã Hương Bình",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330413",
    name: "Xã Hương Phong",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330414",
    name: "Xã Hương Thọ",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330415",
    name: "Xã Hương Toàn",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330416",
    name: "Xã Hương Vinh",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "330501",
    name: "Thị trấn Phú Đa",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330502",
    name: "Thị trấn Thuận An",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330503",
    name: "Xã Phú An",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330504",
    name: "Xã Phú Diên",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330505",
    name: "Xã Phú Dương",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330506",
    name: "Xã Phú Hải",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330507",
    name: "Xã Phú Hồ",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330508",
    name: "Xã Phú Lương",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330509",
    name: "Xã Phú Mậu",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330510",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330511",
    name: "Xã Phú Thanh",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330512",
    name: "Xã Phú Thuận",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330513",
    name: "Xã Phú Thượng",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330514",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330515",
    name: "Xã Vinh An",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330516",
    name: "Xã Vinh Hà",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330517",
    name: "Xã Vinh Phú",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330518",
    name: "Xã Vinh Thái",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330519",
    name: "Xã Vinh Thanh",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330520",
    name: "Xã Vinh Xuân",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "330601",
    name: "Phường Phú Bài",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330602",
    name: "Phường Thủy Châu",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330603",
    name: "Phường Thủy Dương",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330604",
    name: "Phường Thủy Lương",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330605",
    name: "Phường Thủy Phương",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330606",
    name: "Xã Dương Hòa",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330607",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330608",
    name: "Xã Thủy Bằng",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330609",
    name: "Xã Thủy Phù",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330610",
    name: "Xã Thủy Tân",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330611",
    name: "Xã Thủy Thanh",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330612",
    name: "Xã Thủy Vân",
    is_higher_price: false,
    district_code: "1698",
  },
  {
    code: "330701",
    name: "Thị trấn Lăng Cô",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330702",
    name: "Thị trấn Phú Lộc",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330703",
    name: "Xã Lộc An",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330704",
    name: "Xã Lộc Bình",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330705",
    name: "Xã Lộc Bổn",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330706",
    name: "Xã Lộc Điền",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330707",
    name: "Xã Lộc Hòa",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330708",
    name: "Xã Lộc Sơn",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330709",
    name: "Xã Lộc Thủy",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330710",
    name: "Xã Lộc Tiến",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330711",
    name: "Xã Lộc Trì",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330712",
    name: "Xã Lộc Vĩnh",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330713",
    name: "Xã Vinh Giang",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330714",
    name: "Xã Vinh Hải",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330715",
    name: "Xã Vinh Hiền",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330716",
    name: "Xã Vinh Hưng",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330717",
    name: "Xã Vinh Mỹ",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330718",
    name: "Xã Xuân Lộc",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "330801",
    name: "Thị trấn Khe Tre",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330802",
    name: "Xã Hương Giang",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330803",
    name: "Xã Hương Hòa",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330804",
    name: "Xã Hương Hữu",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330805",
    name: "Xã Hương Lộc",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330806",
    name: "Xã Hương Phú",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330807",
    name: "Xã Hương Sơn",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330808",
    name: "Xã Thượng Lộ",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330809",
    name: "Xã Thượng Long",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330810",
    name: "Xã Thượng Nhật",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330811",
    name: "Xã Thượng Quảng",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "330901",
    name: "Thị trấn A Lưới",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330902",
    name: "Xã A Đớt",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330903",
    name: "Xã A Ngo",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330904",
    name: "Xã A Roằng",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330905",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330906",
    name: "Xã Đông Sơn",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330907",
    name: "Xã Hồng Bắc",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330908",
    name: "Xã Hồng Hạ",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330909",
    name: "Xã Hồng Kim",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330910",
    name: "Xã Hồng Quảng",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330911",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330912",
    name: "Xã Hồng Thượng",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330913",
    name: "Xã Hồng Thủy",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330914",
    name: "Xã Hồng Trung",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330915",
    name: "Xã Hồng Vân",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330916",
    name: "Xã Hương Lâm",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330917",
    name: "Xã Hương Nguyên",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330918",
    name: "Xã Hương Phong",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330919",
    name: "Xã Nhâm",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330920",
    name: "Xã Phú Vinh",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "330921",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "340101",
    name: "Phường An Mỹ",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340102",
    name: "Phường An Phú",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340103",
    name: "Phường An Sơn",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340104",
    name: "Phường An Xuân",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340105",
    name: "Phường Hòa Hương",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340106",
    name: "Phường Hoà Thuận",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340107",
    name: "Phường Phước Hòa",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340108",
    name: "Phường Tân Thạnh",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340109",
    name: "Phường Trường Xuân",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340110",
    name: "Xã Tam Ngọc",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340111",
    name: "Xã Tam Phú",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340112",
    name: "Xã Tam Thăng",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340113",
    name: "Xã Tam Thanh",
    is_higher_price: false,
    district_code: "1631",
  },
  {
    code: "340201",
    name: "Phường Cẩm An",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340202",
    name: "Phường Cẩm Châu",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340203",
    name: "Phường Cẩm Nam",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340204",
    name: "Phường Cẩm Phô",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340205",
    name: "Phường Cửa Đại",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340206",
    name: "Phường Minh An",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340207",
    name: "Phường Sơn Phong",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340208",
    name: "Phường Tân An",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340209",
    name: "Phường Thanh Hà",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340210",
    name: "Xã Cẩm Hà",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340211",
    name: "Xã Cẩm Kim",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340212",
    name: "Xã Cẩm Thanh",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340213",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "1632",
  },
  {
    code: "340301",
    name: "Thị trấn Nam Phước",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340302",
    name: "Xã Duy Châu",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340303",
    name: "Xã Duy Hải",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340304",
    name: "Xã Duy Hòa",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340305",
    name: "Xã Duy Nghĩa",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340306",
    name: "Xã Duy Phú",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340307",
    name: "Xã Duy Phước",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340308",
    name: "Xã Duy Sơn",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340309",
    name: "Xã Duy Tân",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340310",
    name: "Xã Duy Thành",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340311",
    name: "Xã Duy Thu",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340312",
    name: "Xã Duy Trinh",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340313",
    name: "Xã Duy Trung",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340314",
    name: "Xã Duy Vinh",
    is_higher_price: false,
    district_code: "1735",
  },
  {
    code: "340401",
    name: "Phường Điện An",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340402",
    name: "Phường Điện Dương",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340403",
    name: "Phường Điện Nam Bắc",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340404",
    name: "Phường Điện Nam Đông",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340405",
    name: "Phường Điện Nam Trung",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340406",
    name: "Phường Điện Ngọc",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340407",
    name: "Phường Vĩnh Điện",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340408",
    name: "Xã Điện Hòa",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340409",
    name: "Xã Điện Hồng",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340410",
    name: "Xã Điện Minh",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340411",
    name: "Xã Điện Phong",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340412",
    name: "Xã Điện Phước",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340413",
    name: "Xã Điện Phương",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340414",
    name: "Xã Điện Quang",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340415",
    name: "Xã Điện Thắng Bắc",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340416",
    name: "Xã Điện Thắng Nam",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340417",
    name: "Xã Điện Thắng Trung",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340418",
    name: "Xã Điện Thọ",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340419",
    name: "Xã Điện Tiến",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340420",
    name: "Xã Điện Trung",
    is_higher_price: false,
    district_code: "1736",
  },
  {
    code: "340501",
    name: "Thị Trấn Ái Nghĩa",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340502",
    name: "Xã Đại An",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340503",
    name: "Xã Đại Chánh",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340504",
    name: "Xã Đại Cường",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340505",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340506",
    name: "Xã Đại Hiệp",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340507",
    name: "Xã Đại Hòa",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340508",
    name: "Xã Đại Hồng",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340509",
    name: "Xã Đại Hưng",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340510",
    name: "Xã Đại Lãnh",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340511",
    name: "Xã Đại Minh",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340512",
    name: "Xã Đại Nghĩa",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340513",
    name: "Xã Đại Phong",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340514",
    name: "Xã Đại Quang",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340515",
    name: "Xã Đại Sơn",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340516",
    name: "Xã Đại Tân",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340517",
    name: "Xã Đại Thắng",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340518",
    name: "Xã Đại Thạnh",
    is_higher_price: false,
    district_code: "1917",
  },
  {
    code: "340601",
    name: "Thị trấn Đông Phú",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340602",
    name: "Xã Hương An",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340603",
    name: "Xã Phú Thọ",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340604",
    name: "Xã Quế An",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340605",
    name: "Xã Quế Châu",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340606",
    name: "Xã Quế Cường",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340607",
    name: "Xã Quế Hiệp",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340608",
    name: "Xã Quế Long",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340609",
    name: "Xã Quế Minh",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340610",
    name: "Xã Quế Phong",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340611",
    name: "Xã Quế Phú",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340612",
    name: "Xã Quế Thuận",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340613",
    name: "Xã Quế Xuân 1",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340614",
    name: "Xã Quế Xuân 2",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "340701",
    name: "Thị trấn Tân An",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340702",
    name: "Xã Bình Lâm",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340703",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340704",
    name: "Xã Hiệp Hòa",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340705",
    name: "Xã Hiệp Thuận",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340706",
    name: "Xã Phước Gia",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340707",
    name: "Xã Phước Trà",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340708",
    name: "Xã Quế Bình",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340709",
    name: "Xã Quế Lưu",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340710",
    name: "Xã Quế Thọ",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340711",
    name: "Xã Sông Trà",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340712",
    name: "Xã Thăng Phước",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "340801",
    name: "Thị trấn Hà Lam",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340802",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340803",
    name: "Xã Bình Chánh",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340804",
    name: "Xã Bình Đào",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340805",
    name: "Xã Bình Định Bắc",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340806",
    name: "Xã Bình Định Nam",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340807",
    name: "Xã Bình Dương",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340808",
    name: "Xã Bình Giang",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340809",
    name: "Xã Bình Hải",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340810",
    name: "Xã Bình Lãnh",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340811",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340812",
    name: "Xã Bình Nam",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340813",
    name: "Xã Bình Nguyên",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340814",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340815",
    name: "Xã Bình Phục",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340816",
    name: "Xã Bình Quế",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340817",
    name: "Xã Bình Quý",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340818",
    name: "Xã Bình Sa",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340819",
    name: "Xã Bình Trị",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340820",
    name: "Xã Bình Triều",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340821",
    name: "Xã Bình Trung",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340822",
    name: "Xã Bình Tú",
    is_higher_price: false,
    district_code: "2239",
  },
  {
    code: "340901",
    name: "Thị trấn Núi Thành",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340902",
    name: "Xã Tam Anh Bắc",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340903",
    name: "Xã Tam Anh Nam",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340904",
    name: "Xã Tam Giang",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340905",
    name: "Xã Tam Hải",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340906",
    name: "Xã Tam Hiệp",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340907",
    name: "Xã Tam Hòa",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340908",
    name: "Xã Tam Mỹ Đông",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340909",
    name: "Xã Tam Mỹ Tây",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340910",
    name: "Xã Tam Nghĩa",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340911",
    name: "Xã Tam Quang",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340912",
    name: "Xã Tam Sơn",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340913",
    name: "Xã Tam Thạnh",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340914",
    name: "Xã Tam Tiến",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340915",
    name: "Xã Tam Trà",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340916",
    name: "Xã Tam Xuân I",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "340917",
    name: "Xã Tam Xuân II",
    is_higher_price: false,
    district_code: "1987",
  },
  {
    code: "341001",
    name: "Thị trấn Tiên Kỳ",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341002",
    name: "Xã Tiên An",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341003",
    name: "Xã Tiên Cẩm",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341004",
    name: "Xã Tiên Cảnh",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341005",
    name: "Xã Tiên Châu",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341006",
    name: "Xã Tiên Hà",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341007",
    name: "Xã Tiên Hiệp",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341008",
    name: "Xã Tiên Lãnh",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341009",
    name: "Xã Tiên Lập",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341010",
    name: "Xã Tiên Lộc",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341011",
    name: "Xã Tiên Mỹ",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341012",
    name: "Xã Tiên Ngọc",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341013",
    name: "Xã Tiên Phong",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341014",
    name: "Xã Tiên Sơn",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341015",
    name: "Xã Tiên Thọ",
    is_higher_price: false,
    district_code: "2224",
  },
  {
    code: "341101",
    name: "Thị trấn Trà My",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341102",
    name: "Xã Trà Bui",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341103",
    name: "Xã Trà Đốc",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341104",
    name: "Xã Trà Đông",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341105",
    name: "Xã Trà Dương",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341106",
    name: "Xã Trà Giác",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341107",
    name: "Xã Trà Giang",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341108",
    name: "Xã Trà Giáp",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341109",
    name: "Xã Trà Ka",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341110",
    name: "Xã Trà Kót",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341111",
    name: "Xã Trà Nú",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341112",
    name: "Xã Trà Sơn",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341113",
    name: "Xã Trà Tân",
    is_higher_price: false,
    district_code: "2078",
  },
  {
    code: "341201",
    name: "Thị trấn P Rao",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341202",
    name: "Xã  Tư",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341203",
    name: "Xã A Rooi",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341204",
    name: "Xã A Ting",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341205",
    name: "Xã Ba",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341206",
    name: "Xã Jơ Ngây",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341207",
    name: "Xã Ka Dăng",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341208",
    name: "Xã Mà Cooi",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341209",
    name: "Xã Sông Kôn",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341210",
    name: "Xã Tà Lu",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341211",
    name: "Xã Za Hung",
    is_higher_price: false,
    district_code: "2125",
  },
  {
    code: "341301",
    name: "Thị trấn Thạnh Mỹ",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341302",
    name: "Xã Cà Dy",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341303",
    name: "Xã Chà Vàl",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341304",
    name: "Xã Chơ Chun",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341305",
    name: "Xã Đắc Pre",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341306",
    name: "Xã Đắc Pring",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341307",
    name: "Xã Đắc Tôi",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341308",
    name: "Xã La Dêê",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341309",
    name: "Xã Laêê",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341310",
    name: "Xã Tà Bhinh",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341311",
    name: "Xã Tà Pơơ",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341312",
    name: "Xã Zuôich",
    is_higher_price: false,
    district_code: "2177",
  },
  {
    code: "341401",
    name: "Thị trấn Khâm Đức",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341402",
    name: "Xã Phước Chánh",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341403",
    name: "Xã Phước Công",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341404",
    name: "Xã Phước Đức",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341405",
    name: "Xã Phước Hiệp",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341406",
    name: "Xã Phước Hoà",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341407",
    name: "Xã Phước Kim",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341408",
    name: "Xã Phước Lộc",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341409",
    name: "Xã Phước Mỹ",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341410",
    name: "Xã Phước Năng",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341411",
    name: "Xã Phước Thành",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341412",
    name: "Xã Phước Xuân",
    is_higher_price: false,
    district_code: "2198",
  },
  {
    code: "341501",
    name: "Xã Trà Cang",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341502",
    name: "Xã Trà Don",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341503",
    name: "Xã Trà Dơn",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341504",
    name: "Xã Trà Leng",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341505",
    name: "Xã Trà Linh",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341506",
    name: "Xã Trà Mai",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341507",
    name: "Xã Trà Nam",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341508",
    name: "Xã Trà Tập",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341509",
    name: "Xã Trà Vân",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341510",
    name: "Xã Trà Vinh",
    is_higher_price: false,
    district_code: "2178",
  },
  {
    code: "341601",
    name: "Xã A Nông",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341602",
    name: "Xã A Tiêng",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341603",
    name: "Xã A Vương",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341604",
    name: "Xã A Xan",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341605",
    name: "Xã Bha Lê",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341606",
    name: "Xã Ch'ơm",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341607",
    name: "Xã Dang",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341608",
    name: "Xã Ga Ri",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341609",
    name: "Xã Lăng",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341610",
    name: "Xã Tr'Hy",
    is_higher_price: false,
    district_code: "2219",
  },
  {
    code: "341701",
    name: "Thị trấn Phú Thịnh",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341702",
    name: "Xã Tam An",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341703",
    name: "Xã Tam Đại",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341704",
    name: "Xã Tam Dân",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341705",
    name: "Xã Tam Đàn",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341706",
    name: "Xã Tam Lãnh",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341707",
    name: "Xã Tam Lộc",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341708",
    name: "Xã Tam Phước",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341709",
    name: "Xã Tam Thái",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341710",
    name: "Xã Tam Thành",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341711",
    name: "Xã Tam Vinh",
    is_higher_price: false,
    district_code: "1995",
  },
  {
    code: "341801",
    name: "Xã Phước Ninh",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "341802",
    name: "Xã Quế Lâm",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "341803",
    name: "Xã Quế Lộc",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "341804",
    name: "Xã Quế Ninh",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "341805",
    name: "Xã Quế Phước",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "341806",
    name: "Xã Quế Trung",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "341807",
    name: "Xã Sơn Viên",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "350101",
    name: "Phường Chánh Lộ",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350102",
    name: "Phường Lê Hồng Phong",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350103",
    name: "Phường Nghĩa Chánh",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350104",
    name: "Phường Nghĩa Lộ",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350105",
    name: "Phường Nguyễn Nghiêm",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350106",
    name: "Phường Quảng Phú",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350107",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350108",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350109",
    name: "Phường Trương Quang Trọng",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350110",
    name: "Xã Nghĩa An",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350111",
    name: "Xã Nghĩa Dõng",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350112",
    name: "Xã Nghĩa Dũng",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350113",
    name: "Xã Nghĩa Hà",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350114",
    name: "Xã Nghĩa Phú",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350115",
    name: "Xã Tịnh An",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350116",
    name: "Xã Tịnh Ấn Đông",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350117",
    name: "Xã Tịnh Ấn Tây",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350118",
    name: "Xã Tịnh Châu",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350119",
    name: "Xã Tịnh Hòa",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350120",
    name: "Xã Tịnh Khê",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350121",
    name: "Xã Tịnh Kỳ",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350122",
    name: "Xã Tịnh Long",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350123",
    name: "Xã Tịnh Thiện",
    is_higher_price: false,
    district_code: "1630",
  },
  {
    code: "350201",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "2114",
  },
  {
    code: "350202",
    name: "Xã An Hải",
    is_higher_price: false,
    district_code: "2114",
  },
  {
    code: "350203",
    name: "Xã An Vĩnh",
    is_higher_price: false,
    district_code: "2114",
  },
  {
    code: "350301",
    name: "Thị Trấn Châu Ổ",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350302",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350303",
    name: "Xã Bình Chánh",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350304",
    name: "Xã Bình Châu",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350305",
    name: "Xã Bình Chương",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350306",
    name: "Xã Bình Đông",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350307",
    name: "Xã Bình Dương",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350308",
    name: "Xã Bình Hải",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350309",
    name: "Xã Bình Hiệp",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350310",
    name: "Xã Bình Hòa",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350311",
    name: "Xã Bình Khương",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350312",
    name: "Xã Bình Long",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350313",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350314",
    name: "Xã Bình Mỹ",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350315",
    name: "Xã Bình Nguyên",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350316",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350317",
    name: "Xã Bình Phước",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350318",
    name: "Xã Bình Tân",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350319",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350320",
    name: "Xã Bình Thanh Đông",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350321",
    name: "Xã Bình Thanh Tây",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350322",
    name: "Xã Bình Thới",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350323",
    name: "Xã Bình Thuận",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350324",
    name: "Xã Bình Trị",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350325",
    name: "Xã Bình Trung",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "350401",
    name: "Thị trấn Trà Xuân",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350402",
    name: "Xã Trà Bình",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350403",
    name: "Xã Trà Bùi",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350404",
    name: "Xã Trà Giang",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350405",
    name: "Xã Trà Hiệp",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350406",
    name: "Xã Trà Lâm",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350407",
    name: "Xã Trà Phú",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350408",
    name: "Xã Trà Sơn",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350409",
    name: "Xã Trà Tân",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350410",
    name: "Xã Trà Thủy",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "350501",
    name: "Xã Tịnh Bắc",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350502",
    name: "Xã Tịnh Bình",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350503",
    name: "Xã Tịnh Đông",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350504",
    name: "Xã Tịnh Giang",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350505",
    name: "Xã Tịnh Hà",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350506",
    name: "Xã Tịnh Hiệp",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350507",
    name: "Xã Tịnh Minh",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350508",
    name: "Xã Tịnh Phong",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350509",
    name: "Xã Tịnh Sơn",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350510",
    name: "Xã Tịnh Thọ",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350511",
    name: "Xã Tịnh Trà",
    is_higher_price: false,
    district_code: "1737",
  },
  {
    code: "350601",
    name: "Thị trấn Di Lăng",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350602",
    name: "Xã Sơn Ba",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350603",
    name: "Xã Sơn Bao",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350604",
    name: "Xã Sơn Cao",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350605",
    name: "Xã Sơn Giang",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350606",
    name: "Xã Sơn Hạ",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350607",
    name: "Xã Sơn Hải",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350608",
    name: "Xã Sơn Kỳ",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350609",
    name: "Xã Sơn Linh",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350610",
    name: "Xã Sơn Nham",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350611",
    name: "Xã Sơn Thành",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350612",
    name: "Xã Sơn Thượng",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350613",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350614",
    name: "Xã Sơn Trung",
    is_higher_price: false,
    district_code: "2210",
  },
  {
    code: "350701",
    name: "Thị trấn La Hà",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350702",
    name: "Thị trấn Sông Vệ",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350703",
    name: "Xã Nghĩa Điền",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350704",
    name: "Xã Nghĩa Hiệp",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350705",
    name: "Xã Nghĩa Hòa",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350706",
    name: "Xã Nghĩa Kỳ",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350707",
    name: "Xã Nghĩa Lâm",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350708",
    name: "Xã Nghĩa Mỹ",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350709",
    name: "Xã Nghĩa Phương",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350710",
    name: "Xã Nghĩa Sơn",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350711",
    name: "Xã Nghĩa Thắng",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350712",
    name: "Xã Nghĩa Thọ",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350713",
    name: "Xã Nghĩa Thuận",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350714",
    name: "Xã Nghĩa Thương",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350715",
    name: "Xã Nghĩa Trung",
    is_higher_price: false,
    district_code: "1738",
  },
  {
    code: "350801",
    name: "Thị trấn Chợ Chùa",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350802",
    name: "Xã Hành Đức",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350803",
    name: "Xã Hành Dũng",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350804",
    name: "Xã Hành Minh",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350805",
    name: "Xã Hành Nhân",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350806",
    name: "Xã Hành Phước",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350807",
    name: "Xã Hành Thiện",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350808",
    name: "Xã Hành Thịnh",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350809",
    name: "Xã Hành Thuận",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350810",
    name: "Xã Hành Tín  Đông",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350811",
    name: "Xã Hành Tín Tây",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350812",
    name: "Xã Hành Trung",
    is_higher_price: false,
    district_code: "1988",
  },
  {
    code: "350901",
    name: "Xã Long Hiệp",
    is_higher_price: false,
    district_code: "2167",
  },
  {
    code: "350902",
    name: "Xã Long Mai",
    is_higher_price: false,
    district_code: "2167",
  },
  {
    code: "350903",
    name: "Xã Long Môn",
    is_higher_price: false,
    district_code: "2167",
  },
  {
    code: "350904",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "2167",
  },
  {
    code: "350905",
    name: "Xã Thanh An",
    is_higher_price: false,
    district_code: "2167",
  },
  {
    code: "351001",
    name: "Thị trấn Mộ Đức",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351002",
    name: "Xã Đức Chánh",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351003",
    name: "Xã Đức Hiệp",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351004",
    name: "Xã Đức Hòa",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351005",
    name: "Xã Đức Lân",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351006",
    name: "Xã Đức Lợi",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351007",
    name: "Xã Đức Minh",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351008",
    name: "Xã Đức Nhuận",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351009",
    name: "Xã Đức Phong",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351010",
    name: "Xã Đức Phú",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351011",
    name: "Xã Đức Tân",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351012",
    name: "Xã Đức Thắng",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351013",
    name: "Xã Đức Thạnh",
    is_higher_price: false,
    district_code: "3226",
  },
  {
    code: "351101",
    name: "Thị trấn Đức Phổ",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351102",
    name: "Xã Phổ An",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351103",
    name: "Xã Phổ Châu",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351104",
    name: "Xã Phổ Cường",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351105",
    name: "Xã Phổ Hòa",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351106",
    name: "Xã Phổ Khánh",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351107",
    name: "Xã Phổ Minh",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351108",
    name: "Xã Phổ Nhơn",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351109",
    name: "Xã Phổ Ninh",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351110",
    name: "Xã Phổ Phong",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351111",
    name: "Xã Phổ Quang",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351112",
    name: "Xã Phổ Thạnh",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351113",
    name: "Xã Phổ Thuận",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351114",
    name: "Xã Phổ Văn",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351115",
    name: "Xã Phổ Vinh",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "351201",
    name: "Thị trấn Ba Tơ",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351202",
    name: "Xã Ba Bích",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351203",
    name: "Xã Ba Chùa",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351204",
    name: "Xã Ba Cung",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351205",
    name: "Xã Ba Điền",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351206",
    name: "Xã Ba Dinh",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351207",
    name: "Xã Ba Động",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351208",
    name: "Xã Ba Giang",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351209",
    name: "Xã Ba Khâm",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351210",
    name: "Xã Ba Lế",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351211",
    name: "Xã Ba Liên",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351212",
    name: "Xã Ba Nam",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351213",
    name: "Xã Ba Ngạc",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351214",
    name: "Xã Ba Thành",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351215",
    name: "Xã Ba Tiêu",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351216",
    name: "Xã Ba Tô",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351217",
    name: "Xã Ba Trang",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351218",
    name: "Xã Ba Vì",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351219",
    name: "Xã Ba Vinh",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351220",
    name: "Xã Ba Xa",
    is_higher_price: false,
    district_code: "3127",
  },
  {
    code: "351301",
    name: "Xã Sơn Bua",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351302",
    name: "Xã Sơn Dung",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351303",
    name: "Xã Sơn Lập",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351304",
    name: "Xã Sơn Liên",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351305",
    name: "Xã Sơn Long",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351306",
    name: "Xã Sơn Màu",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351307",
    name: "Xã Sơn Mùa",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351308",
    name: "Xã Sơn Tân",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351309",
    name: "Xã Sơn Tinh",
    is_higher_price: false,
    district_code: "3270",
  },
  {
    code: "351401",
    name: "Xã Trà Khê",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351402",
    name: "Xã Trà Lãnh",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351403",
    name: "Xã Trà Nham",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351404",
    name: "Xã Trà Phong",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351405",
    name: "Xã Trà Quân",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351406",
    name: "Xã Trà Thanh",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351407",
    name: "Xã Trà Thọ",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351408",
    name: "Xã Trà Trung",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "351409",
    name: "Xã Trà Xinh",
    is_higher_price: false,
    district_code: "2222",
  },
  {
    code: "360101",
    name: "Phường Duy Tân",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360102",
    name: "Phường Lê Lợi",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360103",
    name: "Phường Ngô Mây",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360104",
    name: "Phường Nguyễn Trãi",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360105",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360106",
    name: "Phường Quyết Thắng",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360107",
    name: "Phường Thắng Lợi",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360108",
    name: "Phường Thống Nhất",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360109",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360110",
    name: "Phường Trường Chinh",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360111",
    name: "Xã Chư Hreng",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360112",
    name: "Xã Đắk Blà",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360113",
    name: "Xã Đắk Cấm",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360114",
    name: "Xã Đăk Năng",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360115",
    name: "Xã Đắk Rơ Wa",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360116",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360117",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360118",
    name: "Xã Ia Chim",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360119",
    name: "Xã Kroong",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360120",
    name: "Xã Ngọk Bay",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360121",
    name: "Xã Vinh Quang",
    is_higher_price: false,
    district_code: "1660",
  },
  {
    code: "360201",
    name: "Thị trấn Đắk Glei",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360202",
    name: "Xã Đắk Choong",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360203",
    name: "Xã Đắk KRoong",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360204",
    name: "Xã Đắk Long",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360205",
    name: "Xã Đắk Man",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360206",
    name: "Xã Đắk Môn",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360207",
    name: "Xã Đắk Nhoong",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360208",
    name: "Xã Đắk Pék",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360209",
    name: "Xã Đắk Plô",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360210",
    name: "Xã Mường Hoong",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360211",
    name: "Xã Ngọc Linh",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360212",
    name: "Xã Xốp",
    is_higher_price: false,
    district_code: "1921",
  },
  {
    code: "360301",
    name: "Thị trấn Plei Cần",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360302",
    name: "Xã Đắk Ang",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360303",
    name: "Xã Đắk Dục",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360304",
    name: "Xã Đắk Kan",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360305",
    name: "Xã Đắk Nông",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360306",
    name: "Xã Đắk Xú",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360307",
    name: "Xã Pờ Y",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360308",
    name: "Xã Sa Loong",
    is_higher_price: false,
    district_code: "2187",
  },
  {
    code: "360401",
    name: "Thị trấn Đắk Tô",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360402",
    name: "Xã Đắk Rơ Nga",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360403",
    name: "Xã Đắk Trăm",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360404",
    name: "Xã Diên Bình",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360405",
    name: "Xã Kon Đào",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360406",
    name: "Xã Ngọk Tụ",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360407",
    name: "Xã Pô Kô",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360408",
    name: "Xã Tân Cảnh",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360409",
    name: "Xã Văn Lem",
    is_higher_price: false,
    district_code: "2121",
  },
  {
    code: "360501",
    name: "Thị trấn Sa Thầy",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360502",
    name: "Xã Hơ Moong",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360503",
    name: "Xã Mô Rai",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360504",
    name: "Xã Rơ Kơi",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360505",
    name: "Xã Sa Bình",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360506",
    name: "Xã Sa Nghĩa",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360507",
    name: "Xã Sa Nhơn",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360508",
    name: "Xã Sa Sơn",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360509",
    name: "Xã Ya ly",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360510",
    name: "Xã Ya Tăng",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360511",
    name: "Xã Ya Xiêr",
    is_higher_price: false,
    district_code: "2205",
  },
  {
    code: "360601",
    name: "Xã Đắk Long",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360602",
    name: "Xã Đắk Nên",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360603",
    name: "Xã Đắk Ring",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360604",
    name: "Xã Đắk Tăng",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360605",
    name: "Xã Hiếu",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360606",
    name: "Xã Măng Buk",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360607",
    name: "Xã Măng Cành",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360608",
    name: "Xã Ngok Tem",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360609",
    name: "Xã Pờ Ê",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "360701",
    name: "Thị trấn Đắk Hà",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360702",
    name: "Xã Đắk HRing",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360703",
    name: "Xã Đắk La",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360704",
    name: "Xã Đăk Long",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360705",
    name: "Xã Đắk Mar",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360706",
    name: "Xã Đăk Ngọk",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360707",
    name: "Xã Đắk PXi",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360708",
    name: "Xã Đắk Ui",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360709",
    name: "Xã Hà Mòn",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360710",
    name: "Xã Ngok Réo",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360711",
    name: "Xã Ngok Wang",
    is_higher_price: false,
    district_code: "1835",
  },
  {
    code: "360801",
    name: "Thị trấn Đắk Rve",
    is_higher_price: false,
    district_code: "2148",
  },
  {
    code: "360802",
    name: "Xã Đắk Kôi",
    is_higher_price: false,
    district_code: "2148",
  },
  {
    code: "360803",
    name: "Xã Đắk Pne",
    is_higher_price: false,
    district_code: "2148",
  },
  {
    code: "360804",
    name: "Xã Đắk Ruồng",
    is_higher_price: false,
    district_code: "2148",
  },
  {
    code: "360805",
    name: "Xã Đắk Tơ Lung",
    is_higher_price: false,
    district_code: "2148",
  },
  {
    code: "360806",
    name: "Xã Đắk Tờ Re",
    is_higher_price: false,
    district_code: "2148",
  },
  {
    code: "360807",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "2148",
  },
  {
    code: "360901",
    name: "Xã Đắk Hà",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360902",
    name: "Xã Đắk Na",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360903",
    name: "Xã Đắk Rơ Ông",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360904",
    name: "Xã Đắk Sao",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360905",
    name: "Xã Đắk Tơ Kan",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360906",
    name: "Xã Măng Ri",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360907",
    name: "Xã Ngọk Lây",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360908",
    name: "Xã Ngok Yêu",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360909",
    name: "Xã Tê Xăng",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360910",
    name: "Xã Tu Mơ Rông",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "360911",
    name: "Xã Văn Xuôi",
    is_higher_price: false,
    district_code: "2225",
  },
  {
    code: "361001",
    name: "Xã Ia Dal",
    is_higher_price: false,
    district_code: "3446",
  },
  {
    code: "361002",
    name: "Xã Ia Dom",
    is_higher_price: false,
    district_code: "3446",
  },
  {
    code: "361003",
    name: "Xã Ia Tơi",
    is_higher_price: false,
    district_code: "3446",
  },
  {
    code: "370101",
    name: "Phường Bùi Thị Xuân",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370102",
    name: "Phường Đống Đa",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370103",
    name: "Phường Ghềnh Ráng",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370104",
    name: "Phường Hải Cảng",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370105",
    name: "Phường Lê Hồng Phong",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370106",
    name: "Phường Lê Lợi",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370107",
    name: "Phường Lý Thường Kiệt",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370108",
    name: "Phường Ngô Mây",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370109",
    name: "Phường Nguyễn Văn Cừ",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370110",
    name: "Phường Nhơn Bình",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370111",
    name: "Phường Nhơn Phú",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370112",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370113",
    name: "Phường Thị Nại",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370114",
    name: "Phường Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370115",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370116",
    name: "Phường Trần Quang Diệu",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370117",
    name: "Xã Nhơn Châu",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370118",
    name: "Xã Nhơn Hải",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370119",
    name: "Xã Nhơn Hội",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370120",
    name: "Xã Nhơn Lý",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370121",
    name: "Xã Phước Mỹ",
    is_higher_price: false,
    district_code: "1662",
  },
  {
    code: "370201",
    name: "Thị trấn An Lão",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370202",
    name: "Xã An Dũng",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370203",
    name: "Xã An Hòa",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370204",
    name: "Xã An Hưng",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370205",
    name: "Xã An Nghĩa",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370206",
    name: "Xã An Quang",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370207",
    name: "Xã An Tân",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370208",
    name: "Xã An Toàn",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370209",
    name: "Xã An Trung",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370210",
    name: "Xã An Vinh",
    is_higher_price: false,
    district_code: "1886",
  },
  {
    code: "370301",
    name: "Thị trấn Tăng Bạt Hổ",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370302",
    name: "Xã Ân Đức",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370303",
    name: "Xã Ân Hảo Đông",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370304",
    name: "Xã Ân Hảo Tây",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370305",
    name: "Xã Ân Hữu",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370306",
    name: "Xã Ân Mỹ",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370307",
    name: "Xã Ân Nghĩa",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370308",
    name: "Xã Ân Phong",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370309",
    name: "Xã Ân Sơn",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370310",
    name: "Xã Ân Thạnh",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370311",
    name: "Xã Ân Tín",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370312",
    name: "Xã Ân Tường Đông",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370313",
    name: "Xã Ân Tường Tây",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370314",
    name: "Xã Bok Tới",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370315",
    name: "Xã Dak Mang",
    is_higher_price: false,
    district_code: "2140",
  },
  {
    code: "370401",
    name: "Thị trấn  Tam Quan",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370402",
    name: "Thị trấn Bồng Sơn",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370403",
    name: "Xã Hoài Châu",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370404",
    name: "Xã Hoài Châu Bắc",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370405",
    name: "Xã Hoài Đức",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370406",
    name: "Xã Hoài Hải",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370407",
    name: "Xã Hoài Hảo",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370408",
    name: "Xã Hoài Hương",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370409",
    name: "Xã Hoài Mỹ",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370410",
    name: "Xã Hoài Phú",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370411",
    name: "Xã Hoài Sơn",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370412",
    name: "Xã Hoài Tân",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370413",
    name: "Xã Hoài Thanh",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370414",
    name: "Xã Hoài Thanh Tây",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370415",
    name: "Xã Hoài Xuân",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370416",
    name: "Xã Tam Quan Bắc",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370417",
    name: "Xã Tam Quan Nam",
    is_higher_price: false,
    district_code: "1771",
  },
  {
    code: "370501",
    name: "Thị trấn Bình Dương",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370502",
    name: "Thị trấn Phù Mỹ",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370503",
    name: "Xã Mỹ An",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370504",
    name: "Xã Mỹ Cát",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370505",
    name: "Xã Mỹ Chánh",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370506",
    name: "Xã Mỹ Chánh Tây",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370507",
    name: "Xã Mỹ Châu",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370508",
    name: "Xã Mỹ Đức",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370509",
    name: "Xã Mỹ Hiệp",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370510",
    name: "Xã Mỹ Hòa",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370511",
    name: "Xã Mỹ Lộc",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370512",
    name: "Xã Mỹ Lợi",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370513",
    name: "Xã Mỹ Phong",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370514",
    name: "Xã Mỹ Quang",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370515",
    name: "Xã Mỹ Tài",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370516",
    name: "Xã Mỹ Thắng",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370517",
    name: "Xã Mỹ Thành",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370518",
    name: "Xã Mỹ Thọ",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370519",
    name: "Xã Mỹ Trinh",
    is_higher_price: false,
    district_code: "3254",
  },
  {
    code: "370601",
    name: "Thị trấn Ngô Mây",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370602",
    name: "Xã Cát Chánh",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370603",
    name: "Xã Cát Hải",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370604",
    name: "Xã Cát Hanh",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370605",
    name: "Xã Cát Hiệp",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370606",
    name: "Xã Cát Hưng",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370607",
    name: "Xã Cát Khánh",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370608",
    name: "Xã Cát Lâm",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370609",
    name: "Xã Cát Minh",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370610",
    name: "Xã Cát Nhơn",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370611",
    name: "Xã Cát Sơn",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370612",
    name: "Xã Cát Tài",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370613",
    name: "Xã Cát Tân",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370614",
    name: "Xã Cát Thắng",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370615",
    name: "Xã Cát Thành",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370616",
    name: "Xã Cát Tiến",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370617",
    name: "Xã Cát Trinh",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370618",
    name: "Xã Cát Tường",
    is_higher_price: false,
    district_code: "1770",
  },
  {
    code: "370701",
    name: "Thị trấn Vĩnh Thạnh",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370702",
    name: "Xã Vĩnh Hảo",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370703",
    name: "Xã Vĩnh Hiệp",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370704",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370705",
    name: "Xã Vĩnh Kim",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370706",
    name: "Xã Vĩnh Quang",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370707",
    name: "Xã Vĩnh Sơn",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370708",
    name: "Xã Vĩnh Thịnh",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370709",
    name: "Xã Vĩnh Thuận",
    is_higher_price: false,
    district_code: "2258",
  },
  {
    code: "370801",
    name: "Thị trấn Phú Phong",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370802",
    name: "Xã Bình Hòa",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370803",
    name: "Xã Bình Nghi",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370804",
    name: "Xã Bình Tân",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370805",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370806",
    name: "Xã Bình Thuận",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370807",
    name: "Xã Bình Tường",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370808",
    name: "Xã Tây An",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370809",
    name: "Xã Tây Bình",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370810",
    name: "Xã Tây Giang",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370811",
    name: "Xã Tây Phú",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370812",
    name: "Xã Tây Thuận",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370813",
    name: "Xã Tây Vinh",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370814",
    name: "Xã Tây Xuân",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370815",
    name: "Xã Vĩnh An",
    is_higher_price: false,
    district_code: "3279",
  },
  {
    code: "370901",
    name: "Thị trấn Vân Canh",
    is_higher_price: false,
    district_code: "3312",
  },
  {
    code: "370902",
    name: "Xã Canh Hiển",
    is_higher_price: false,
    district_code: "3312",
  },
  {
    code: "370903",
    name: "Xã Canh Hiệp",
    is_higher_price: false,
    district_code: "3312",
  },
  {
    code: "370904",
    name: "Xã Canh Hòa",
    is_higher_price: false,
    district_code: "3312",
  },
  {
    code: "370905",
    name: "Xã Canh Liên",
    is_higher_price: false,
    district_code: "3312",
  },
  {
    code: "370906",
    name: "Xã Canh Thuận",
    is_higher_price: false,
    district_code: "3312",
  },
  {
    code: "370907",
    name: "Xã Canh Vinh",
    is_higher_price: false,
    district_code: "3312",
  },
  {
    code: "371001",
    name: "Phường Bình Định",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371002",
    name: "Phường Đập Đá",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371003",
    name: "Phường Nhơn Hoà",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371004",
    name: "Phường Nhơn Hưng",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371005",
    name: "Phường Nhơn Thành",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371006",
    name: "Xã Nhơn An",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371007",
    name: "Xã Nhơn Hạnh",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371008",
    name: "Xã Nhơn Hậu",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371009",
    name: "Xã Nhơn Khánh",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371010",
    name: "Xã Nhơn Lộc",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371011",
    name: "Xã Nhơn Mỹ",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371012",
    name: "Xã Nhơn Phong",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371013",
    name: "Xã Nhơn Phúc",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371014",
    name: "Xã Nhơn Tân",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371015",
    name: "Xã Nhơn Thọ",
    is_higher_price: false,
    district_code: "1769",
  },
  {
    code: "371101",
    name: "Thị trấn Diêu Trì",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371102",
    name: "Thị trấn Tuy Phước",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371103",
    name: "Xã Phước An",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371104",
    name: "Xã Phước Hiệp",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371105",
    name: "Xã Phước Hòa",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371106",
    name: "Xã Phước Hưng",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371107",
    name: "Xã Phước Lộc",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371108",
    name: "Xã Phước Nghĩa",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371109",
    name: "Xã Phước Quang",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371110",
    name: "Xã Phước Sơn",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371111",
    name: "Xã Phước Thắng",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371112",
    name: "Xã Phước Thành",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "371113",
    name: "Xã Phước Thuận",
    is_higher_price: false,
    district_code: "2023",
  },
  {
    code: "380101",
    name: "Phường Chi Lăng",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380102",
    name: "Phường Diên Hồng",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380103",
    name: "Phường Đống Đa",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380104",
    name: "Phường Hoa Lư",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380105",
    name: "Phường Hội Phú",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380106",
    name: "Phường Hội Thương",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380107",
    name: "Phường Ia Kring",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380108",
    name: "Phường Phù Đổng",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380109",
    name: "Phường Tây Sơn",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380110",
    name: "Phường Thắng Lợi",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380111",
    name: "Phường Thống Nhất",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380112",
    name: "Phường Trà Bá",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380113",
    name: "Phường Yên Đỗ",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380114",
    name: "Phường Yên Thế",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380115",
    name: "Xã An Phú",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380116",
    name: "Xã Biển Hồ",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380117",
    name: "Xã Chư Á",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380118",
    name: "Xã Chư HDrông",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380119",
    name: "Xã Diên Phú",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380120",
    name: "Xã Gào",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380121",
    name: "Xã Ia Kênh",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380122",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380123",
    name: "Xã Trà Đa",
    is_higher_price: false,
    district_code: "1546",
  },
  {
    code: "380201",
    name: "Thị trấn Ia Ly",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380202",
    name: "Thị trấn Phú Hòa",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380203",
    name: "Xã Chư Đăng Ya",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380204",
    name: "Xã Chư Jôr",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380205",
    name: "Xã Đăk Tơ Ver",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380206",
    name: "Xã Hà Tây",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380207",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380208",
    name: "Xã Ia Ka",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380209",
    name: "Xã Ia Khươl",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380210",
    name: "Xã Ia Kreng",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380211",
    name: "Xã Ia Mơ Nông",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380212",
    name: "Xã Ia Nhin",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380213",
    name: "Xã Ia Phí",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380214",
    name: "Xã Nghĩa Hòa",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380215",
    name: "Xã Nghĩa Hưng",
    is_higher_price: false,
    district_code: "1801",
  },
  {
    code: "380301",
    name: "Thị trấn Kon Dơng",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380302",
    name: "Xã Ayun",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380303",
    name: "Xã Đăk Djrăng",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380304",
    name: "Xã Đak Jơ Ta",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380305",
    name: "Xã Đak Ta Ley",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380306",
    name: "Xã Đăk Trôi",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380307",
    name: "Xã Đăk Yă",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380308",
    name: "Xã Đê Ar",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380309",
    name: "Xã Hà Ra",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380310",
    name: "Xã Kon Chiêng",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380311",
    name: "Xã Kon Thụp",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380312",
    name: "Xã Lơ Pang",
    is_higher_price: false,
    district_code: "2165",
  },
  {
    code: "380401",
    name: "Thị trấn KBang",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380402",
    name: "Xã Đăk HLơ",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380403",
    name: "Xã Đăk Roong",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380404",
    name: "Xã Đak SMar",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380405",
    name: "Xã Đông",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380406",
    name: "Xã Kon Pne",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380407",
    name: "Xã Kông Lơng Khơng",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380408",
    name: "Xã Kông Pla",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380409",
    name: "Xã KRong",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380410",
    name: "Xã Lơ Ku",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380411",
    name: "Xã Nghĩa An",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380412",
    name: "Xã Sơ Pai",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380413",
    name: "Xã Sơn Lang",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380414",
    name: "Xã Tơ Tung",
    is_higher_price: false,
    district_code: "2144",
  },
  {
    code: "380501",
    name: "Phường An Bình",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380502",
    name: "Phường An Phú",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380503",
    name: "Phường An Phước",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380504",
    name: "Phường An Tân",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380505",
    name: "Phường Ngô Mây",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380506",
    name: "Phường Tây Sơn",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380507",
    name: "Xã Cửu An",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380508",
    name: "Xã Song An",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380509",
    name: "Xã Thành An",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380510",
    name: "Xã Tú An",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380511",
    name: "Xã Xuân An",
    is_higher_price: false,
    district_code: "1800",
  },
  {
    code: "380601",
    name: "Thị trấn Kông Chro",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380602",
    name: "Xã An Trung",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380603",
    name: "Xã Chơ Long",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380604",
    name: "Xã Chư Krêy",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380605",
    name: "Xã Đắk Kơ Ning",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380606",
    name: "Xã Đăk Pling",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380607",
    name: "Xã Đăk Pơ Pho",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380608",
    name: "Xã Đăk Song",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380609",
    name: "Xã Đăk Tơ Pang",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380610",
    name: "Xã Kông Yang",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380611",
    name: "Xã SRó",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380612",
    name: "Xã Ya Ma",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380613",
    name: "Xã Yang Nam",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380614",
    name: "Xã Yang Trung",
    is_higher_price: false,
    district_code: "2149",
  },
  {
    code: "380701",
    name: "Thị trấn Chư Ty",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380702",
    name: "Xã Ia Din",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380703",
    name: "Xã Ia Dơk",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380704",
    name: "Xã Ia Dom",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380705",
    name: "Xã Ia Kla",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380706",
    name: "Xã Ia Krêl",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380707",
    name: "Xã Ia Kriêng",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380708",
    name: "Xã Ia Lang",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380709",
    name: "Xã Ia Nan",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380710",
    name: "Xã Ia Pnôn",
    is_higher_price: false,
    district_code: "1794",
  },
  {
    code: "380801",
    name: "Thị trấn Chư Prông",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380802",
    name: "Xã Bàu Cạn",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380803",
    name: "Xã Bình Giáo",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380804",
    name: "Xã Ia Bang",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380805",
    name: "Xã Ia Băng",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380806",
    name: "Xã Ia Boòng",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380807",
    name: "Xã Ia Drăng",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380808",
    name: "Xã Ia Ga",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380809",
    name: "Xã Ia Kly",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380810",
    name: "Xã Ia Lâu",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380811",
    name: "Xã Ia Me",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380812",
    name: "Xã Ia Mơ",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380813",
    name: "Xã Ia O",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380814",
    name: "Xã Ia Phìn",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380815",
    name: "Xã Ia Pia",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380816",
    name: "Xã Ia Piơr",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380817",
    name: "Xã Ia Púch",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380818",
    name: "Xã Ia Tôr",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380819",
    name: "Xã Ia Vê",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380820",
    name: "Xã Thăng Hưng",
    is_higher_price: false,
    district_code: "1795",
  },
  {
    code: "380901",
    name: "Thị trấn Chư Sê",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380902",
    name: "Xã AL Bá",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380903",
    name: "Xã AYun",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380904",
    name: "Xã Bar Măih",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380905",
    name: "Xã Bờ Ngoong",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380906",
    name: "Xã Chư Pơng",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380907",
    name: "Xã Dun",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380908",
    name: "Xã H Bông",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380909",
    name: "Xã Ia Blang",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380910",
    name: "Xã Ia Glai",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380911",
    name: "Xã Ia HLốp",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380912",
    name: "Xã Ia Ko",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380913",
    name: "Xã Ia Pal",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380914",
    name: "Xã Ia Tiêm",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "380915",
    name: "Xã Kông HTok",
    is_higher_price: false,
    district_code: "1796",
  },
  {
    code: "381001",
    name: "Phường Cheo Reo",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381002",
    name: "Phường Đoàn Kết",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381003",
    name: "Phường Hòa Bình",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381004",
    name: "Phường Sông Bờ",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381005",
    name: "Xã Chư Băh",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381006",
    name: "Xã Ia RBol",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381007",
    name: "Xã Ia RTô",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381008",
    name: "Xã Ia Sao",
    is_higher_price: false,
    district_code: "1798",
  },
  {
    code: "381101",
    name: "Thị trấn Phú Túc",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381102",
    name: "Xã Chư Drăng",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381103",
    name: "Xã Chư Gu",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381104",
    name: "Xã Chư Ngọc",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381105",
    name: "Xã Chư Rcăm",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381106",
    name: "Xã Đất Bằng",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381107",
    name: "Xã Ia HDreh",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381108",
    name: "Xã Ia Mláh",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381109",
    name: "Xã Ia RMok",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381110",
    name: "Xã Ia RSai",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381111",
    name: "Xã Ia RSươm",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381112",
    name: "Xã Krông Năng",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381113",
    name: "Xã Phú Cần",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381114",
    name: "Xã Uar",
    is_higher_price: false,
    district_code: "2152",
  },
  {
    code: "381201",
    name: "Thị trấn Ia Kha",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381202",
    name: "Xã Ia Bă",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381203",
    name: "Xã Ia Chia",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381204",
    name: "Xã Ia Dêr",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381205",
    name: "Xã Ia Grăng",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381206",
    name: "Xã Ia Hrung",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381207",
    name: "Xã Ia Khai",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381208",
    name: "Xã Ia KRai",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381209",
    name: "Xã Ia O",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381210",
    name: "Xã Ia Pếch",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381211",
    name: "Xã Ia Sao",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381212",
    name: "Xã Ia Tô",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381213",
    name: "Xã Ia Yok",
    is_higher_price: false,
    district_code: "1793",
  },
  {
    code: "381301",
    name: "Thị trấn Đăk Đoa",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381302",
    name: "Xã A Dơk",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381303",
    name: "Xã Đăk Krong",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381304",
    name: "Xã Đăk Sơmei",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381305",
    name: "Xã Glar",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381306",
    name: "Xã H' Neng",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381307",
    name: "Xã Hà Bầu",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381308",
    name: "Xã Hà Đông",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381309",
    name: "Xã Hải Yang",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381310",
    name: "Xã HNol",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381311",
    name: "Xã Ia Băng",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381312",
    name: "Xã Ia Pết",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381313",
    name: "Xã K' Dang",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381314",
    name: "Xã Kon Gang",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381315",
    name: "Xã Nam Yang",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381316",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381317",
    name: "Xã Trang",
    is_higher_price: false,
    district_code: "2118",
  },
  {
    code: "381401",
    name: "Xã Chư Mố",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381402",
    name: "Xã Chư Răng",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381403",
    name: "Xã Ia Broăi",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381404",
    name: "Xã Ia KDăm",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381405",
    name: "Xã Ia Ma Rơn",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381406",
    name: "Xã Ia Trok",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381407",
    name: "Xã Ia Tul",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381408",
    name: "Xã Kim Tân",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381409",
    name: "Xã Pờ Tó",
    is_higher_price: false,
    district_code: "1799",
  },
  {
    code: "381501",
    name: "Thị trấn Đak Pơ",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381502",
    name: "Xã An Thành",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381503",
    name: "Xã Cư An",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381504",
    name: "Xã Hà Tam",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381505",
    name: "Xã Phú An",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381506",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381507",
    name: "Xã Ya Hội",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381508",
    name: "Xã Yang Bắc",
    is_higher_price: false,
    district_code: "2119",
  },
  {
    code: "381601",
    name: "Thị trấn Phú Thiện",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381602",
    name: "Xã Ayun Hạ",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381603",
    name: "Xã Chrôh Pơnan",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381604",
    name: "Xã Chư A Thai",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381605",
    name: "Xã Ia Ake",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381606",
    name: "Xã Ia Hiao",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381607",
    name: "Xã Ia Peng",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381608",
    name: "Xã Ia Piar",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381609",
    name: "Xã Ia Sol",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381610",
    name: "Xã Ia Yeng",
    is_higher_price: false,
    district_code: "1797",
  },
  {
    code: "381701",
    name: "Thị trấn Nhơn Hoà",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381702",
    name: "Xã Chư Don",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381703",
    name: "Xã Ia BLứ",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381704",
    name: "Xã Ia Dreng",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381705",
    name: "Xã Ia Hla",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381706",
    name: "Xã Ia Hrú",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381707",
    name: "Xã Ia Le",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381708",
    name: "Xã Ia Phang",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "381709",
    name: "Xã Ia Rong",
    is_higher_price: false,
    district_code: "2101",
  },
  {
    code: "390101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390106",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390107",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390108",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390109",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390110",
    name: "Phường Phú Đông",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390111",
    name: "Phường Phú Lâm",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390112",
    name: "Phường Phú Thạnh",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390113",
    name: "Xã An Phú",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390114",
    name: "Xã Bình Kiến",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390115",
    name: "Xã Bình Ngọc",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390116",
    name: "Xã Hòa Kiến",
    is_higher_price: false,
    district_code: "1663",
  },
  {
    code: "390201",
    name: "Thị trấn La Hai",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390202",
    name: "Xã Đa Lộc",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390203",
    name: "Xã Phú Mỡ",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390204",
    name: "Xã Xuân Lãnh",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390205",
    name: "Xã Xuân Long",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390206",
    name: "Xã Xuân Phước",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390207",
    name: "Xã Xuân Quang 1",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390208",
    name: "Xã Xuân Quang 2",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390209",
    name: "Xã Xuân Quang 3",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390210",
    name: "Xã Xuân Sơn Bắc",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390211",
    name: "Xã Xuân Sơn Nam",
    is_higher_price: false,
    district_code: "3186",
  },
  {
    code: "390301",
    name: "Phường Xuân Đài",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390302",
    name: "Phường Xuân Phú",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390303",
    name: "Phường Xuân Thành",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390304",
    name: "Phường Xuân Yên",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390305",
    name: "Xã Xuân Bình",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390306",
    name: "Xã Xuân Cảnh",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390307",
    name: "Xã Xuân Hải",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390308",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390309",
    name: "Xã Xuân Lâm",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390310",
    name: "Xã Xuân Lộc",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390311",
    name: "Xã Xuân Phương",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390312",
    name: "Xã Xuân Thịnh",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390313",
    name: "Xã Xuân Thọ 1",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390314",
    name: "Xã Xuân Thọ 2",
    is_higher_price: false,
    district_code: "1856",
  },
  {
    code: "390401",
    name: "Thị trấn Chí Thạnh",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390402",
    name: "Xã An Chấn",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390403",
    name: "Xã An Cư",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390404",
    name: "Xã An Dân",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390405",
    name: "Xã An Định",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390406",
    name: "Xã An Hải",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390407",
    name: "Xã An Hiệp",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390408",
    name: "Xã An Hòa",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390409",
    name: "Xã An Lĩnh",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390410",
    name: "Xã An Mỹ",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390411",
    name: "Xã An Nghiệp",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390412",
    name: "Xã An Ninh Đông",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390413",
    name: "Xã An Ninh Tây",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390414",
    name: "Xã An Thạch",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390415",
    name: "Xã An Thọ",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390416",
    name: "Xã An Xuân",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "390501",
    name: "Thị trấn Củng Sơn",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390502",
    name: "Xã Cà Lúi",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390503",
    name: "Xã Eachà Rang",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390504",
    name: "Xã Krông Pa",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390505",
    name: "Xã Phước Tân",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390506",
    name: "Xã Sơn Định",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390507",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390508",
    name: "Xã Sơn Hội",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390509",
    name: "Xã Sơn Long",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390510",
    name: "Xã Sơn Nguyên",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390511",
    name: "Xã Sơn Phước",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390512",
    name: "Xã Sơn Xuân",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390513",
    name: "Xã Suối Bạc",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390514",
    name: "Xã Suối Trai",
    is_higher_price: false,
    district_code: "2211",
  },
  {
    code: "390601",
    name: "Thị trấn Hai Riêng",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390602",
    name: "Xã Đức Bình Đông",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390603",
    name: "Xã Đức Bình Tây",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390604",
    name: "Xã Ea Bá",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390605",
    name: "Xã Ea Lâm",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390606",
    name: "Xã EaBar",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390607",
    name: "Xã EaBia",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390608",
    name: "Xã Ealy",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390609",
    name: "Xã EaTrol",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390610",
    name: "Xã Sơn Giang",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390611",
    name: "Xã Sông Hinh",
    is_higher_price: false,
    district_code: "2206",
  },
  {
    code: "390701",
    name: "Thị trấn Hoà Hiệp Trung",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390702",
    name: "Thị trấn Hoà Vinh",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390703",
    name: "Xã Hòa Hiệp Bắc",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390704",
    name: "Xã Hòa Hiệp Nam",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390705",
    name: "Xã Hòa Tâm",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390706",
    name: "Xã Hòa Tân Đông",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390707",
    name: "Xã Hòa Thành",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390708",
    name: "Xã Hòa Xuân Đông",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390709",
    name: "Xã Hòa Xuân Nam",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390710",
    name: "Xã Hòa Xuân Tây",
    is_higher_price: false,
    district_code: "3184",
  },
  {
    code: "390801",
    name: "Thị Trấn Phú Hoà",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390802",
    name: "Xã Hòa An",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390803",
    name: "Xã Hòa Định Đông",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390804",
    name: "Xã Hòa Định Tây",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390805",
    name: "Xã Hòa Hội",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390806",
    name: "Xã Hòa Quang Bắc",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390807",
    name: "Xã Hòa Quang Nam",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390808",
    name: "Xã Hòa Thắng",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390809",
    name: "Xã Hòa Trị",
    is_higher_price: false,
    district_code: "1993",
  },
  {
    code: "390901",
    name: "Thị trấn Phú Thứ",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390902",
    name: "Xã Hòa Bình 1",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390903",
    name: "Xã Hòa Đồng",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390904",
    name: "Xã Hòa Mỹ Đông",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390905",
    name: "Xã Hòa Mỹ Tây",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390906",
    name: "Xã Hòa Phong",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390907",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390908",
    name: "Xã Hòa Tân Tây",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390909",
    name: "Xã Hòa Thịnh",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390910",
    name: "Xã Sơn Thành Đông",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "390911",
    name: "Xã Sơn Thành Tây",
    is_higher_price: false,
    district_code: "3278",
  },
  {
    code: "400101",
    name: "Phường Ea Tam",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400102",
    name: "Phường Khánh Xuân",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400103",
    name: "Phường Tân An",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400104",
    name: "Phường Tân Hòa",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400105",
    name: "Phường Tân Lập",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400106",
    name: "Phường Tân Lợi",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400107",
    name: "Phường Tân Thành",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400108",
    name: "Phường Tân Tiến",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400109",
    name: "Phường Thắng Lợi",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400110",
    name: "Phường Thành Công",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400111",
    name: "Phường Thành Nhất",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400112",
    name: "Phường Thống Nhất",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400113",
    name: "Phường Tự An",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400114",
    name: "Xã Cư ÊBur",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400115",
    name: "Xã Ea Kao",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400116",
    name: "Xã Ea Tu",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400117",
    name: "Xã Hòa Khánh",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400118",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400119",
    name: "Xã Hòa Thắng",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400120",
    name: "Xã Hòa Thuận",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400121",
    name: "Xã Hòa Xuân",
    is_higher_price: false,
    district_code: "1552",
  },
  {
    code: "400201",
    name: "Thị trấn Ea Drăng",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400202",
    name: "Xã Cư A Mung",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400203",
    name: "Xã Cư Mốt",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400204",
    name: "Xã Dlê Yang",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400205",
    name: "Xã Ea Hiao",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400206",
    name: "Xã Ea H'leo",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400207",
    name: "Xã Ea Khal",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400208",
    name: "Xã Ea Nam",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400209",
    name: "Xã Ea Ral",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400210",
    name: "Xã Ea Sol",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400211",
    name: "Xã Ea Tir",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400212",
    name: "Xã Ea Wy",
    is_higher_price: false,
    district_code: "1786",
  },
  {
    code: "400301",
    name: "Xã Chư KBô",
    is_higher_price: false,
    district_code: "2150",
  },
  {
    code: "400302",
    name: "Xã Cư Né",
    is_higher_price: false,
    district_code: "2150",
  },
  {
    code: "400303",
    name: "Xã Cư Pơng",
    is_higher_price: false,
    district_code: "2150",
  },
  {
    code: "400304",
    name: "Xã Ea Ngai",
    is_higher_price: false,
    district_code: "2150",
  },
  {
    code: "400305",
    name: "Xã Ea Sin",
    is_higher_price: false,
    district_code: "2150",
  },
  {
    code: "400306",
    name: "Xã Pơng Drang",
    is_higher_price: false,
    district_code: "2150",
  },
  {
    code: "400307",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "2150",
  },
  {
    code: "400401",
    name: "Thị trấn Krông Năng",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400402",
    name: "Xã Cư Klông",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400403",
    name: "Xã ĐLiê Ya",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400404",
    name: "Xã Ea Dăh",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400405",
    name: "Xã Ea Hồ",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400406",
    name: "Xã Ea Puk",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400407",
    name: "Xã Ea Tam",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400408",
    name: "Xã Ea Tân",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400409",
    name: "Xã Ea Tóh",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400410",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400411",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400412",
    name: "Xã Tam Giang",
    is_higher_price: false,
    district_code: "1787",
  },
  {
    code: "400501",
    name: "Thị trấn Ea Súp",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400502",
    name: "Xã Cư KBang",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400503",
    name: "Xã Cư M'Lan",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400504",
    name: "Xã Ea Bung",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400505",
    name: "Xã Ea Lê",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400506",
    name: "Xã Ea Rốk",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400507",
    name: "Xã Ia JLơi",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400508",
    name: "Xã Ia Lốp",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400509",
    name: "Xã Ia RVê",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400510",
    name: "Xã Ya Tờ Mốt",
    is_higher_price: false,
    district_code: "2131",
  },
  {
    code: "400601",
    name: "Thị trấn Ea Pốk",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400602",
    name: "Thị trấn Quảng Phú",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400603",
    name: "Xã Cư Dliê M'nông",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400604",
    name: "Xã Cư M'gar",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400605",
    name: "Xã Cư Suê",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400606",
    name: "Xã Cuor Đăng",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400607",
    name: "Xã Ea D'Rơng",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400608",
    name: "Xã Ea H'đinh",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400609",
    name: "Xã Ea Kiết",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400610",
    name: "Xã Ea KPam",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400611",
    name: "Xã Ea Kuêh",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400612",
    name: "Xã Ea M'DRóh",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400613",
    name: "Xã Ea M'nang",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400614",
    name: "Xã Ea Tar",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400615",
    name: "Xã Ea Tul",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400616",
    name: "Xã Quảng Hiệp",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400617",
    name: "Xã Quảng Tiến",
    is_higher_price: false,
    district_code: "1785",
  },
  {
    code: "400701",
    name: "Thị trấn Phước An",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400702",
    name: "Xã Ea Hiu",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400703",
    name: "Xã Ea Kênh",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400704",
    name: "Xã Ea Kly",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400705",
    name: "Xã Ea KNuec",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400706",
    name: "Xã Ea Kuăng",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400707",
    name: "Xã Ea Phê",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400708",
    name: "Xã Ea Uy",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400709",
    name: "Xã Ea Yiêng",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400710",
    name: "Xã Ea Yông",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400711",
    name: "Xã Hòa An",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400712",
    name: "Xã Hòa Đông",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400713",
    name: "Xã Hòa Tiến",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400714",
    name: "Xã KRông Búk",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400715",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400716",
    name: "Xã Vụ Bổn",
    is_higher_price: false,
    district_code: "1954",
  },
  {
    code: "400801",
    name: "Thị trấn Ea Kar",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400802",
    name: "Thị trấn Ea Knốp",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400803",
    name: "Xã Cư Bông",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400804",
    name: "Xã Cư ELang",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400805",
    name: "Xã Cư Huê",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400806",
    name: "Xã Cư Jang",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400807",
    name: "Xã Cư Ni",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400808",
    name: "Xã Cư Prông",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400809",
    name: "Xã Ea Đar",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400810",
    name: "Xã Ea Kmút",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400811",
    name: "Xã Ea Ô",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400812",
    name: "Xã Ea Păl",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400813",
    name: "Xã Ea Sar",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400814",
    name: "Xã Ea Sô",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400815",
    name: "Xã Ea Tih",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400816",
    name: "Xã Xuân Phú",
    is_higher_price: false,
    district_code: "1931",
  },
  {
    code: "400901",
    name: "Thị trấn M'Đrắk",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400902",
    name: "Xã Cư K Róa",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400903",
    name: "Xã Cư M'ta",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400904",
    name: "Xã Cư Prao",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400905",
    name: "Xã Cư San",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400906",
    name: "Xã Ea H'MLay",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400907",
    name: "Xã Ea Lai",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400908",
    name: "Xã Ea M' Doal",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400909",
    name: "Xã Ea Pil",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400910",
    name: "Xã Ea Riêng",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400911",
    name: "Xã Ea Trang",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400912",
    name: "Xã Krông Á",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "400913",
    name: "Xã Krông Jing",
    is_higher_price: false,
    district_code: "3418",
  },
  {
    code: "401001",
    name: "Thị trấn Buôn Trấp",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "401002",
    name: "Xã Băng A Drênh",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "401003",
    name: "Xã Bình Hòa",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "401004",
    name: "Xã Dray Sáp",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "401005",
    name: "Xã Dur KMăl",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "401006",
    name: "Xã Ea Bông",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "401007",
    name: "Xã Ea Na",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "401008",
    name: "Xã Quảng Điền",
    is_higher_price: false,
    district_code: "1884",
  },
  {
    code: "40101",
    name: "Phường Bình Hiên",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40102",
    name: "Phường Bình Thuận",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40103",
    name: "Phường Hải Châu  I",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40104",
    name: "Phường Hải Châu II",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40105",
    name: "Phường Hòa Cường Bắc",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40106",
    name: "Phường Hòa Cường Nam",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40107",
    name: "Phường Hòa Thuận Đông",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40108",
    name: "Phường Hòa Thuận Tây",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40109",
    name: "Phường Nam Dương",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40110",
    name: "Phường Phước Ninh",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "401101",
    name: "Thị trấn Krông Kmar",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401102",
    name: "Xã Cư Drăm",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401103",
    name: "Xã Cư KTy",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401104",
    name: "Xã Cư Pui",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401105",
    name: "Xã Dang Kang",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401106",
    name: "Xã Ea Trul",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401107",
    name: "Xã Hòa Lễ",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401108",
    name: "Xã Hòa Phong",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401109",
    name: "Xã Hòa Sơn",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "40111",
    name: "Phường Thạch Thang",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "401110",
    name: "Xã Hòa Tân",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401111",
    name: "Xã Hòa Thành",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401112",
    name: "Xã Khuê Ngọc Điền",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401113",
    name: "Xã Yang Mao",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "401114",
    name: "Xã Yang Reh",
    is_higher_price: false,
    district_code: "1789",
  },
  {
    code: "40112",
    name: "Phường Thanh Bình",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "40113",
    name: "Phường Thuận Phước",
    is_higher_price: false,
    district_code: "1526",
  },
  {
    code: "401201",
    name: "Thị trấn Liên Sơn",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401202",
    name: "Xã Bông Krang",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401203",
    name: "Xã Buôn Tría",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401204",
    name: "Xã Buôn Triết",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401205",
    name: "Xã Đắk Liêng",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401206",
    name: "Xã Đắk Nuê",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401207",
    name: "Xã Đắk Phơi",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401208",
    name: "Xã Ea R'Bin",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401209",
    name: "Xã Krông Nô",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401210",
    name: "Xã Nam Ka",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401211",
    name: "Xã Yang Tao",
    is_higher_price: false,
    district_code: "3217",
  },
  {
    code: "401301",
    name: "Xã Cuôr KNia",
    is_higher_price: false,
    district_code: "1784",
  },
  {
    code: "401302",
    name: "Xã Ea Bar",
    is_higher_price: false,
    district_code: "1784",
  },
  {
    code: "401303",
    name: "Xã Ea Huar",
    is_higher_price: false,
    district_code: "1784",
  },
  {
    code: "401304",
    name: "Xã Ea Nuôl",
    is_higher_price: false,
    district_code: "1784",
  },
  {
    code: "401305",
    name: "Xã Ea Wer",
    is_higher_price: false,
    district_code: "1784",
  },
  {
    code: "401306",
    name: "Xã Krông Na",
    is_higher_price: false,
    district_code: "1784",
  },
  {
    code: "401307",
    name: "Xã Tân Hoà",
    is_higher_price: false,
    district_code: "1784",
  },
  {
    code: "401401",
    name: "Xã Cư Ê Wi",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401402",
    name: "Xã Dray Bhăng",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401403",
    name: "Xã Ea BHốk",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401404",
    name: "Xã Ea Hu",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401405",
    name: "Xã Ea Ktur",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401406",
    name: "Xã Ea Ning",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401407",
    name: "Xã Ea Tiêu",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401408",
    name: "Xã Hòa Hiệp",
    is_higher_price: false,
    district_code: "3153",
  },
  {
    code: "401501",
    name: "Phường An Bình",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401502",
    name: "Phường An Lạc",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401503",
    name: "Phường Bình Tân",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401504",
    name: "Phường Đạt Hiếu",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401505",
    name: "Phường Đoàn Kết",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401506",
    name: "Phường Thiện An",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401507",
    name: "Phường Thống Nhất",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401508",
    name: "Xã Bình Thuận",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401509",
    name: "Xã Cư Bao",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401510",
    name: "Xã Ea Blang",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401511",
    name: "Xã Ea Drông",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "401512",
    name: "Xã Ea Siên",
    is_higher_price: false,
    district_code: "1788",
  },
  {
    code: "40201",
    name: "Phường An Khê",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40202",
    name: "Phường Chính Gián",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40203",
    name: "Phường Hòa Khê",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40204",
    name: "Phường Tam Thuận",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40205",
    name: "Phường Tân Chính",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40206",
    name: "Phường Thạc Gián",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40207",
    name: "Phường Thanh Khê Đông",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40208",
    name: "Phường Thanh Khê Tây",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40209",
    name: "Phường Vĩnh Trung",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40210",
    name: "Phường Xuân Hà",
    is_higher_price: false,
    district_code: "1527",
  },
  {
    code: "40301",
    name: "Phường An Hải Bắc",
    is_higher_price: false,
    district_code: "1528",
  },
  {
    code: "40302",
    name: "Phường An Hải Đông",
    is_higher_price: false,
    district_code: "1528",
  },
  {
    code: "40303",
    name: "Phường An Hải Tây",
    is_higher_price: false,
    district_code: "1528",
  },
  {
    code: "40304",
    name: "Phường Mân Thái",
    is_higher_price: false,
    district_code: "1528",
  },
  {
    code: "40305",
    name: "Phường Nại Hiên Đông",
    is_higher_price: false,
    district_code: "1528",
  },
  {
    code: "40306",
    name: "Phường Phước Mỹ",
    is_higher_price: false,
    district_code: "1528",
  },
  {
    code: "40307",
    name: "Phường Thọ Quang",
    is_higher_price: false,
    district_code: "1528",
  },
  {
    code: "40401",
    name: "Phường Hoà Hải",
    is_higher_price: false,
    district_code: "1529",
  },
  {
    code: "40402",
    name: "Phường Hoà Quý",
    is_higher_price: false,
    district_code: "1529",
  },
  {
    code: "40403",
    name: "Phường Khuê Mỹ",
    is_higher_price: false,
    district_code: "1529",
  },
  {
    code: "40404",
    name: "Phường Mỹ An",
    is_higher_price: false,
    district_code: "1529",
  },
  {
    code: "40501",
    name: "Phường Hòa Hiệp Bắc",
    is_higher_price: false,
    district_code: "1530",
  },
  {
    code: "40502",
    name: "Phường Hòa Hiệp Nam",
    is_higher_price: false,
    district_code: "1530",
  },
  {
    code: "40503",
    name: "Phường Hòa Khánh Bắc",
    is_higher_price: false,
    district_code: "1530",
  },
  {
    code: "40504",
    name: "Phường Hòa Khánh Nam",
    is_higher_price: false,
    district_code: "1530",
  },
  {
    code: "40505",
    name: "Phường Hòa Minh",
    is_higher_price: false,
    district_code: "1530",
  },
  {
    code: "40601",
    name: "Xã Hòa Bắc",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40602",
    name: "Xã Hòa Châu",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40603",
    name: "Xã Hòa Khương",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40604",
    name: "Xã Hòa Liên",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40605",
    name: "Xã Hòa Nhơn",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40606",
    name: "Xã Hòa Ninh",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40607",
    name: "Xã Hòa Phong",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40608",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40609",
    name: "Xã Hòa Phước",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40610",
    name: "Xã Hòa Sơn",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40611",
    name: "Xã Hòa Tiến",
    is_higher_price: false,
    district_code: "1687",
  },
  {
    code: "40701",
    name: "Phường Hòa An",
    is_higher_price: false,
    district_code: "1531",
  },
  {
    code: "40702",
    name: "Phường Hòa Phát",
    is_higher_price: false,
    district_code: "1531",
  },
  {
    code: "40703",
    name: "Phường Hòa Thọ Đông",
    is_higher_price: false,
    district_code: "1531",
  },
  {
    code: "40704",
    name: "Phường Hòa Thọ Tây",
    is_higher_price: false,
    district_code: "1531",
  },
  {
    code: "40705",
    name: "Phường Hòa Xuân",
    is_higher_price: false,
    district_code: "1531",
  },
  {
    code: "40706",
    name: "Phường Khuê Trung",
    is_higher_price: false,
    district_code: "1531",
  },
  {
    code: "40953",
    name: "Phường đặc biệt",
    is_higher_price: false,
    district_code: "3447",
  },
  {
    code: "410101",
    name: "Phường Lộc Thọ",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410102",
    name: "Phường Ngọc Hiệp",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410103",
    name: "Phường Phước Hải",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410104",
    name: "Phường Phước Hòa",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410105",
    name: "Phường Phước Long",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410106",
    name: "Phường Phước Tân",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410107",
    name: "Phường Phước Tiến",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410108",
    name: "Phường Phương Sài",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410109",
    name: "Phường Phương Sơn",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410110",
    name: "Phường Tân Lập",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410111",
    name: "Phường Vạn Thắng",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410112",
    name: "Phường Vạn Thạnh",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410113",
    name: "Phường Vĩnh Hải",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410114",
    name: "Phường Vĩnh Hòa",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410115",
    name: "Phường Vĩnh Nguyên",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410116",
    name: "Phường Vĩnh Phước",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410117",
    name: "Phường Vĩnh Thọ",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410118",
    name: "Phường Vĩnh Trường",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410119",
    name: "Phường Xương Huân",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410120",
    name: "Xã Phước Đồng",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410121",
    name: "Xã Vĩnh Hiệp",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410122",
    name: "Xã Vĩnh Lương",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410123",
    name: "Xã Vĩnh Ngọc",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410124",
    name: "Xã Vĩnh Phương",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410125",
    name: "Xã Vĩnh Thái",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410126",
    name: "Xã Vĩnh Thạnh",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410127",
    name: "Xã Vĩnh Trung",
    is_higher_price: false,
    district_code: "1548",
  },
  {
    code: "410201",
    name: "Thị trấn Vạn Giã",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410202",
    name: "Xã Đại Lãnh",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410203",
    name: "Xã Vạn Bình",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410204",
    name: "Xã Vạn Hưng",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410205",
    name: "Xã Vạn Khánh",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410206",
    name: "Xã Vạn Long",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410207",
    name: "Xã Vạn Lương",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410208",
    name: "Xã Vạn Phú",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410209",
    name: "Xã Vạn Phước",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410210",
    name: "Xã Vạn Thắng",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410211",
    name: "Xã Vạn Thạnh",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410212",
    name: "Xã Vạn Thọ",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410213",
    name: "Xã Xuân Sơn",
    is_higher_price: false,
    district_code: "1829",
  },
  {
    code: "410301",
    name: "Phường Ninh Đa",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410302",
    name: "Phường Ninh Diêm",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410303",
    name: "Phường Ninh Giang",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410304",
    name: "Phường Ninh Hà",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410305",
    name: "Phường Ninh Hải",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410306",
    name: "Phường Ninh Hiệp",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410307",
    name: "Phường Ninh Thủy",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410308",
    name: "Xã Ninh An",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410309",
    name: "Xã Ninh Bình",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410310",
    name: "Xã Ninh Đông",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410311",
    name: "Xã Ninh Hưng",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410312",
    name: "Xã Ninh Ích",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410313",
    name: "Xã Ninh Lộc",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410314",
    name: "Xã Ninh Phú",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410315",
    name: "Xã Ninh Phụng",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410316",
    name: "Xã Ninh Phước",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410317",
    name: "Xã Ninh Quang",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410318",
    name: "Xã Ninh Sim",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410319",
    name: "Xã Ninh Sơn",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410320",
    name: "Xã Ninh Tân",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410321",
    name: "Xã Ninh Tây",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410322",
    name: "Xã Ninh Thân",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410323",
    name: "Xã Ninh Thọ",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410324",
    name: "Xã Ninh Thượng",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410325",
    name: "Xã Ninh Trung",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410326",
    name: "Xã Ninh Vân",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410327",
    name: "Xã Ninh Xuân",
    is_higher_price: false,
    district_code: "2061",
  },
  {
    code: "410401",
    name: "Thị trấn Diên Khánh",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410402",
    name: "Xã Diên An",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410403",
    name: "Xã Diên Bình",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410404",
    name: "Xã Diên Điền",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410405",
    name: "Xã Diên Đồng",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410406",
    name: "Xã Diên Hòa",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410407",
    name: "Xã Diên Lạc",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410408",
    name: "Xã Diên Lâm",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410409",
    name: "Xã Diên Lộc",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410410",
    name: "Xã Diên Phú",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410411",
    name: "Xã Diên Phước",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410412",
    name: "Xã Diên Sơn",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410413",
    name: "Xã Diên Tân",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410414",
    name: "Xã Diên Thạnh",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410415",
    name: "Xã Diên Thọ",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410416",
    name: "Xã Diên Toàn",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410417",
    name: "Xã Diên Xuân",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410418",
    name: "Xã Suối Hiệp",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410419",
    name: "Xã Suối Tiên",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "410501",
    name: "Thị trấn Khánh Vĩnh",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410502",
    name: "Xã Cầu Bà",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410503",
    name: "Xã Giang Ly",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410504",
    name: "Xã Khánh Bình",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410505",
    name: "Xã Khánh Đông",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410506",
    name: "Xã Khánh Hiệp",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410507",
    name: "Xã Khánh Nam",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410508",
    name: "Xã Khánh Phú",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410509",
    name: "Xã Khánh Thành",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410510",
    name: "Xã Khánh Thượng",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410511",
    name: "Xã Khánh Trung",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410512",
    name: "Xã Liên Sang",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410513",
    name: "Xã Sơn Thái",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410514",
    name: "Xã Sông Cầu",
    is_higher_price: false,
    district_code: "3213",
  },
  {
    code: "410601",
    name: "Phường Ba Ngòi",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410602",
    name: "Phường Cam Linh",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410603",
    name: "Phường Cam Lộc",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410604",
    name: "Phường Cam Lợi",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410605",
    name: "Phường Cam Nghĩa",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410606",
    name: "Phường Cam Phú",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410607",
    name: "Phường Cam Phúc Bắc",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410608",
    name: "Phường Cam Phúc Nam",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410609",
    name: "Phường Cam Thuận",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410610",
    name: "Xã Cam Bình",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410611",
    name: "Xã Cam Lập",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410612",
    name: "Xã Cam Phước Đông",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410613",
    name: "Xã Cam Thành Nam",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410614",
    name: "Xã Cam Thịnh Đông",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410615",
    name: "Xã Cam Thịnh Tây",
    is_higher_price: false,
    district_code: "1664",
  },
  {
    code: "410701",
    name: "Thị trấn Tô Hạp",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410702",
    name: "Xã Ba Cụm Bắc",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410703",
    name: "Xã Ba Cụm Nam",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410704",
    name: "Xã Sơn Bình",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410705",
    name: "Xã Sơn Hiệp",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410706",
    name: "Xã Sơn Lâm",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410707",
    name: "Xã Sơn Trung",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410708",
    name: "Xã Thành Sơn",
    is_higher_price: false,
    district_code: "3212",
  },
  {
    code: "410801",
    name: "Thị trấn Trường Sa",
    is_higher_price: false,
    district_code: "2117",
  },
  {
    code: "410802",
    name: "Xã Sinh Tồn",
    is_higher_price: false,
    district_code: "2117",
  },
  {
    code: "410803",
    name: "Xã Song Tử Tây",
    is_higher_price: false,
    district_code: "2117",
  },
  {
    code: "410901",
    name: "Thị trấn Cam Đức",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410902",
    name: "Xã Cam An Bắc",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410903",
    name: "Xã Cam An Nam",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410904",
    name: "Xã Cam Hải Đông",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410905",
    name: "Xã Cam Hải Tây",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410906",
    name: "Xã Cam Hiệp Bắc",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410907",
    name: "Xã Cam Hiệp Nam",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410908",
    name: "Xã Cam Hòa",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410909",
    name: "Xã Cam Phước Tây",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410910",
    name: "Xã Cam Tân",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410911",
    name: "Xã Cam Thành Bắc",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410912",
    name: "Xã Sơn Tân",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410913",
    name: "Xã Suối Cát",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "410914",
    name: "Xã Suối Tân",
    is_higher_price: false,
    district_code: "1902",
  },
  {
    code: "420101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420102",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420103",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420104",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420105",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420106",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420107",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420108",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420109",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420110",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420111",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420112",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420113",
    name: "Xã Tà Nung",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420114",
    name: "Xã Trạm Hành",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420115",
    name: "Xã Xuân Thọ",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420116",
    name: "Xã Xuân Trường",
    is_higher_price: false,
    district_code: "1550",
  },
  {
    code: "420201",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420202",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420203",
    name: "Phường B'lao",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420204",
    name: "Phường Lộc Phát",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420205",
    name: "Phường Lộc Sơn",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420206",
    name: "Phường Lộc Tiến",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420207",
    name: "Xã Đại Lào",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420208",
    name: "Xã Đạm Bri",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420209",
    name: "Xã Lộc Châu",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420210",
    name: "Xã Lộc Nga",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420211",
    name: "Xã Lộc Thanh",
    is_higher_price: false,
    district_code: "1838",
  },
  {
    code: "420301",
    name: "Thị trấn Liên Nghĩa",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420302",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420303",
    name: "Xã Đà Loan",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420304",
    name: "Xã Đa Quyn",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420305",
    name: "Xã Hiệp An",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420306",
    name: "Xã Hiệp Thạnh",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420307",
    name: "Xã Liên Hiệp",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420308",
    name: "Xã Ninh Gia",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420309",
    name: "Xã Ninh Loan",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420310",
    name: "Xã N'Thol Hạ",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420311",
    name: "Xã Phú Hội",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420312",
    name: "Xã Tà Hine",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420313",
    name: "Xã Tà Năng",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420314",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420315",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1837",
  },
  {
    code: "420401",
    name: "Thị trấn Di Linh",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420402",
    name: "Xã Bảo Thuận",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420403",
    name: "Xã Đinh Lạc",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420404",
    name: "Xã Đinh Trang Hòa",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420405",
    name: "Xã Đinh Trang Thượng",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420406",
    name: "Xã Gia Bắc",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420407",
    name: "Xã Gia Hiệp",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420408",
    name: "Xã Gung Ré",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420409",
    name: "Xã Hòa Bắc",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420410",
    name: "Xã Hòa Nam",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420411",
    name: "Xã Hòa Ninh",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420412",
    name: "Xã Hòa Trung",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420413",
    name: "Xã Liên Đầm",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420414",
    name: "Xã Sơn Điền",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420415",
    name: "Xã Tam Bố",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420416",
    name: "Xã Tân Châu",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420417",
    name: "Xã Tân Lâm",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420418",
    name: "Xã Tân Nghĩa",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420419",
    name: "Xã Tân Thượng",
    is_higher_price: false,
    district_code: "3160",
  },
  {
    code: "420501",
    name: "Thị trấn D'Ran",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420502",
    name: "Thị trấn Thạnh Mỹ",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420503",
    name: "Xã Đạ Ròn",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420504",
    name: "Xã Ka Đô",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420505",
    name: "Xã Ka Đơn",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420506",
    name: "Xã Lạc Lâm",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420507",
    name: "Xã Lạc Xuân",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420508",
    name: "Xã Pró",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420509",
    name: "Xã Quảng Lập",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420510",
    name: "Xã Tu Tra",
    is_higher_price: false,
    district_code: "1836",
  },
  {
    code: "420601",
    name: "Thị trấn Lạc Dương",
    is_higher_price: false,
    district_code: "1956",
  },
  {
    code: "420602",
    name: "Xã Đạ Chais",
    is_higher_price: false,
    district_code: "1956",
  },
  {
    code: "420603",
    name: "Xã Đạ Nhim",
    is_higher_price: false,
    district_code: "1956",
  },
  {
    code: "420604",
    name: "Xã Đạ Sar",
    is_higher_price: false,
    district_code: "1956",
  },
  {
    code: "420605",
    name: "Xã Đưng KNớ",
    is_higher_price: false,
    district_code: "1956",
  },
  {
    code: "420606",
    name: "Xã Lát",
    is_higher_price: false,
    district_code: "1956",
  },
  {
    code: "420701",
    name: "Thị trấn Đạ M'ri",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420702",
    name: "Thị trấn Ma Đa Guôi",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420703",
    name: "Xã Đạ M'ri",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420704",
    name: "Xã Đạ Oai",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420705",
    name: "Xã Đạ Ploa",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420706",
    name: "Xã Đạ Tồn",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420707",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420708",
    name: "Xã Hà Lâm",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420709",
    name: "Xã Ma Đa Guôi",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420710",
    name: "Xã Phước Lộc",
    is_higher_price: false,
    district_code: "2104",
  },
  {
    code: "420801",
    name: "Thị trấn Đạ Tẻh",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420802",
    name: "Xã An Nhơn",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420803",
    name: "Xã Đạ Kho",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420804",
    name: "Xã Đạ Lây",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420805",
    name: "Xã Đạ Pal",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420806",
    name: "Xã Hà Đông",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420807",
    name: "Xã Hương Lâm",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420808",
    name: "Xã Mỹ Đức",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420809",
    name: "Xã Quảng Trị",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420810",
    name: "Xã Quốc Oai",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420811",
    name: "Xã Triệu Hải",
    is_higher_price: false,
    district_code: "2106",
  },
  {
    code: "420901",
    name: "Thị trấn Cát Tiên",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420902",
    name: "Xã Đồng Nai Thượng",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420903",
    name: "Xã Đức Phổ",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420904",
    name: "Xã Gia Viễn",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420905",
    name: "Xã Mỹ Lâm",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420906",
    name: "Xã Nam Ninh",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420907",
    name: "Xã Phước Cát 1",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420908",
    name: "Xã Phước Cát 2",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420909",
    name: "Xã Quảng Ngãi",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420910",
    name: "Xã Tiên Hoàng",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "420911",
    name: "Xã Tư Nghĩa",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "421001",
    name: "Thị trấn Đinh Văn",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421002",
    name: "Thị trấn Nam Ban",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421003",
    name: "Xã Đạ Đờn",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421004",
    name: "Xã Đan Phượng",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421005",
    name: "Xã Đông Thanh",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421006",
    name: "Xã Gia Lâm",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421007",
    name: "Xã Hoài Đức",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421008",
    name: "Xã Liên Hà",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421009",
    name: "Xã Mê Linh",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421010",
    name: "Xã Nam Hà",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421011",
    name: "Xã Phi Tô",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421012",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421013",
    name: "Xã Phúc Thọ",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421014",
    name: "Xã Tân Hà",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421015",
    name: "Xã Tân Thanh",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "421016",
    name: "Xã Tân Văn",
    is_higher_price: false,
    district_code: "1958",
  },
  {
    code: "42111",
    name: "Thị trấn Lộc Thắng",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "421201",
    name: "Xã Đạ K' Nàng",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "421202",
    name: "Xã Đạ Long",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "421203",
    name: "Xã Đạ M' Rong",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "421204",
    name: "Xã Đạ Rsal",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "421205",
    name: "Xã Đạ Tông",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "421206",
    name: "Xã Liêng Srônh",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "421207",
    name: "Xã Phi Liêng",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "421208",
    name: "Xã Rô Men",
    is_higher_price: false,
    district_code: "1919",
  },
  {
    code: "430101",
    name: "Phường Tân Bình",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430102",
    name: "Phường Tân Đồng",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430103",
    name: "Phường Tân Phú",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430104",
    name: "Phường Tân Thiện",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430105",
    name: "Phường Tân Xuân",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430106",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430107",
    name: "Xã Tiến Hưng",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430108",
    name: "Xã Tiến Thành",
    is_higher_price: false,
    district_code: "1625",
  },
  {
    code: "430201",
    name: "Thị trấn Tân Phú",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430202",
    name: "Xã Đồng Tâm",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430203",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430204",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430205",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430206",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430207",
    name: "Xã Tân Lợi",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430208",
    name: "Xã Tân Phước",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430209",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430210",
    name: "Xã Thuận Lợi",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430211",
    name: "Xã Thuận Phú",
    is_higher_price: false,
    district_code: "1722",
  },
  {
    code: "430301",
    name: "Thị trấn Chơn Thành",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430302",
    name: "Xã Minh Hưng",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430303",
    name: "Xã Minh Lập",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430304",
    name: "Xã Minh Long",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430305",
    name: "Xã Minh Thắng",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430306",
    name: "Xã Minh Thành",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430307",
    name: "Xã Nha Bích",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430308",
    name: "Xã Quang Minh",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430309",
    name: "Xã Thành Tâm",
    is_higher_price: false,
    district_code: "1772",
  },
  {
    code: "430401",
    name: "Phường An Lộc",
    is_higher_price: false,
    district_code: "1774",
  },
  {
    code: "430402",
    name: "Phường Hưng Chiến",
    is_higher_price: false,
    district_code: "1774",
  },
  {
    code: "430403",
    name: "Phường Phú Đức",
    is_higher_price: false,
    district_code: "1774",
  },
  {
    code: "430404",
    name: "Phường Phú Thịnh",
    is_higher_price: false,
    district_code: "1774",
  },
  {
    code: "430405",
    name: "Xã Thanh Lương",
    is_higher_price: false,
    district_code: "1774",
  },
  {
    code: "430406",
    name: "Xã Thanh Phú",
    is_higher_price: false,
    district_code: "1774",
  },
  {
    code: "430501",
    name: "Thị trấn Lộc Ninh",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430502",
    name: "Xã Lộc An",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430503",
    name: "Xã Lộc Điền",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430504",
    name: "Xã Lộc Hiệp",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430505",
    name: "Xã Lộc Hòa",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430506",
    name: "Xã Lộc Hưng",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430507",
    name: "Xã Lộc Khánh",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430508",
    name: "Xã Lộc Phú",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430509",
    name: "Xã Lộc Quang",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430510",
    name: "Xã Lộc Tấn",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430511",
    name: "Xã Lộc Thái",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430512",
    name: "Xã Lộc Thành",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430513",
    name: "Xã Lộc Thạnh",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430514",
    name: "Xã Lộc Thiện",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430515",
    name: "Xã Lộc Thịnh",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430516",
    name: "Xã Lộc Thuận",
    is_higher_price: false,
    district_code: "1964",
  },
  {
    code: "430601",
    name: "Thị trấn Thanh Bình",
    is_higher_price: false,
    district_code: "3140",
  },
  {
    code: "430602",
    name: "Xã Hưng Phước",
    is_higher_price: false,
    district_code: "3140",
  },
  {
    code: "430603",
    name: "Xã Phước Thiện",
    is_higher_price: false,
    district_code: "3140",
  },
  {
    code: "430604",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "3140",
  },
  {
    code: "430605",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "3140",
  },
  {
    code: "430606",
    name: "Xã Thanh Hòa",
    is_higher_price: false,
    district_code: "3140",
  },
  {
    code: "430607",
    name: "Xã Thiện Hưng",
    is_higher_price: false,
    district_code: "3140",
  },
  {
    code: "430701",
    name: "Phường Long Phước",
    is_higher_price: false,
    district_code: "1775",
  },
  {
    code: "430702",
    name: "Phường Long Thủy",
    is_higher_price: false,
    district_code: "1775",
  },
  {
    code: "430703",
    name: "Phường Phước Bình",
    is_higher_price: false,
    district_code: "1775",
  },
  {
    code: "430704",
    name: "Phường Sơn Giang",
    is_higher_price: false,
    district_code: "1775",
  },
  {
    code: "430705",
    name: "Phường Thác Mơ",
    is_higher_price: false,
    district_code: "1775",
  },
  {
    code: "430706",
    name: "Xã Long Giang",
    is_higher_price: false,
    district_code: "1775",
  },
  {
    code: "430707",
    name: "Xã Phước Tín",
    is_higher_price: false,
    district_code: "1775",
  },
  {
    code: "430801",
    name: "Thị trấn Đức Phong",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430802",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430803",
    name: "Xã Bom Bo",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430804",
    name: "Xã Đak Nhau",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430805",
    name: "Xã Đăng Hà",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430806",
    name: "Xã Đoàn Kết",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430807",
    name: "Xã Đồng Nai",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430808",
    name: "Xã Đức Liễu",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430809",
    name: "Xã Đường 10",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430810",
    name: "Xã Minh Hưng",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430811",
    name: "Xã Nghĩa Bình",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430812",
    name: "Xã Nghĩa Trung",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430813",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430814",
    name: "Xã Phước Sơn",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430815",
    name: "Xã Thọ Sơn",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430816",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "1899",
  },
  {
    code: "430901",
    name: "Xã An Khương",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430902",
    name: "Xã An Phú",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430903",
    name: "Xã Đồng Nơ",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430904",
    name: "Xã Minh Đức",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430905",
    name: "Xã Minh Tâm",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430906",
    name: "Xã Phước An",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430907",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430908",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430909",
    name: "Xã Tân Khai",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430910",
    name: "Xã Tân Lợi",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430911",
    name: "Xã Tân Quan",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430912",
    name: "Xã Thanh An",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "430913",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "1773",
  },
  {
    code: "431001",
    name: "Xã Bình Thắng",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431002",
    name: "Xã Bù Gia Mập",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431003",
    name: "Xã Đa Kia",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431004",
    name: "Xã Đak Ơ",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431005",
    name: "Xã Đức Hạnh",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431006",
    name: "Xã Phú Nghĩa",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431007",
    name: "Xã Phú Văn",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431008",
    name: "Xã Phước Minh",
    is_higher_price: false,
    district_code: "3141",
  },
  {
    code: "431101",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431102",
    name: "Xã Bình Tân",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431103",
    name: "Xã Bù Nho",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431104",
    name: "Xã Long Bình",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431105",
    name: "Xã Long Hà",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431106",
    name: "Xã Long Hưng",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431107",
    name: "Xã Long Tân",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431108",
    name: "Xã Phú Riềng",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431109",
    name: "Xã Phú Trung",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "431110",
    name: "Xã Phước Tân",
    is_higher_price: false,
    district_code: "3444",
  },
  {
    code: "440101",
    name: "Phường Chánh Mỹ",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440102",
    name: "Phường Chánh Nghĩa",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440103",
    name: "Phường Định Hoà",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440104",
    name: "Phường Hiệp An",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440105",
    name: "Phường Hiệp Thành",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440106",
    name: "Phường Hoà Phú",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440107",
    name: "Phường Phú Cường",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440108",
    name: "Phường Phú Hòa",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440109",
    name: "Phường Phú Lợi",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440110",
    name: "Phường Phú Mỹ",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440111",
    name: "Phường Phú Tân",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440112",
    name: "Phường Phú Thọ",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440113",
    name: "Phường Tân An",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440114",
    name: "Phường Tương Bình Hiệp",
    is_higher_price: false,
    district_code: "1538",
  },
  {
    code: "440201",
    name: "Phường Chánh Phú Hòa",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440202",
    name: "Phường Hòa Lợi",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440203",
    name: "Phường Mỹ Phước",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440204",
    name: "Phường Tân Định",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440205",
    name: "Phường Thới Hòa",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440206",
    name: "Xã An Điền",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440207",
    name: "Xã An Tây",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440208",
    name: "Xã Phú An",
    is_higher_price: false,
    district_code: "1696",
  },
  {
    code: "440301",
    name: "Phường Khánh Bình",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440302",
    name: "Phường Tân Hiệp",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440303",
    name: "Phường Tân Phước Khánh",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440304",
    name: "Phường Thái Hòa",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440305",
    name: "Phường Thạnh Phước",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440306",
    name: "Phường Uyên Hưng",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440307",
    name: "Xã Bạch Đằng",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440308",
    name: "Xã Hội Nghĩa",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440309",
    name: "Xã Phú Chánh",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440310",
    name: "Xã Tân Vĩnh Hiệp",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440311",
    name: "Xã Thạnh Hội",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440312",
    name: "Xã Vĩnh Tân",
    is_higher_price: false,
    district_code: "1695",
  },
  {
    code: "440401",
    name: "Phường An Phú",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440402",
    name: "Phường An Thạnh",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440403",
    name: "Phường Bình Chuẩn",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440404",
    name: "Phường Bình Hòa",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440405",
    name: "Phường Bình Nhâm",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440406",
    name: "Phường Hưng Định",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440407",
    name: "Phường Lái Thiêu",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440408",
    name: "Phường Thuận Giao",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440409",
    name: "Phường Vĩnh Phú",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440410",
    name: "Xã An Sơn",
    is_higher_price: false,
    district_code: "1541",
  },
  {
    code: "440501",
    name: "Phường An Bình",
    is_higher_price: false,
    district_code: "1540",
  },
  {
    code: "440502",
    name: "Phường Bình An",
    is_higher_price: false,
    district_code: "1540",
  },
  {
    code: "440503",
    name: "Phường Bình Thắng",
    is_higher_price: false,
    district_code: "1540",
  },
  {
    code: "440504",
    name: "Phường Dĩ An",
    is_higher_price: false,
    district_code: "1540",
  },
  {
    code: "440505",
    name: "Phường Đông Hòa",
    is_higher_price: false,
    district_code: "1540",
  },
  {
    code: "440506",
    name: "Phường Tân Bình",
    is_higher_price: false,
    district_code: "1540",
  },
  {
    code: "440507",
    name: "Phường Tân Đông Hiệp",
    is_higher_price: false,
    district_code: "1540",
  },
  {
    code: "440601",
    name: "Thị trấn Phước Vĩnh",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440602",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440603",
    name: "Xã An Linh",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440604",
    name: "Xã An Long",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440605",
    name: "Xã An Thái",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440606",
    name: "Xã Phước Hoà",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440607",
    name: "Xã Phước Sang",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440608",
    name: "Xã Tam Lập",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440609",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440610",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440611",
    name: "Xã Vĩnh Hoà",
    is_higher_price: false,
    district_code: "1992",
  },
  {
    code: "440701",
    name: "Thị trấn Dầu Tiếng",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440702",
    name: "Xã An Lập",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440703",
    name: "Xã Định An",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440704",
    name: "Xã Định Hiệp",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440705",
    name: "Xã Định Thành",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440706",
    name: "Xã Long Hoà",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440707",
    name: "Xã Long Tân",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440708",
    name: "Xã Minh Hoà",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440709",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440710",
    name: "Xã Minh Thạnh",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440711",
    name: "Xã Thanh An",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440712",
    name: "Xã Thanh Tuyền",
    is_higher_price: false,
    district_code: "1746",
  },
  {
    code: "440801",
    name: "Xã Cây Trường II",
    is_higher_price: false,
    district_code: "3132",
  },
  {
    code: "440802",
    name: "Xã Hưng Hòa",
    is_higher_price: false,
    district_code: "3132",
  },
  {
    code: "440803",
    name: "Xã Lai Hưng",
    is_higher_price: false,
    district_code: "3132",
  },
  {
    code: "440804",
    name: "Xã Lai Uyên",
    is_higher_price: false,
    district_code: "3132",
  },
  {
    code: "440805",
    name: "Xã Long Nguyên",
    is_higher_price: false,
    district_code: "3132",
  },
  {
    code: "440806",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "3132",
  },
  {
    code: "440807",
    name: "Xã Trừ Văn Thố",
    is_higher_price: false,
    district_code: "3132",
  },
  {
    code: "440901",
    name: "Xã Bình Mỹ",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440902",
    name: "Xã Đất Cuốc",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440903",
    name: "Xã Hiếu Liêm",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440904",
    name: "Xã Lạc An",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440905",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440906",
    name: "Xã Tân Định",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440907",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440908",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440909",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "440910",
    name: "Xã Thường Tân",
    is_higher_price: false,
    district_code: "3135",
  },
  {
    code: "450101",
    name: "Phường Bảo An",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450102",
    name: "Phường Đài Sơn",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450103",
    name: "Phường Đạo Long",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450104",
    name: "Phường Đô Vinh",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450105",
    name: "Phường Đông Hải",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450106",
    name: "Phường Kinh Dinh",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450107",
    name: "Phường Mỹ Bình",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450108",
    name: "Phường Mỹ Đông",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450109",
    name: "Phường Mỹ Hải",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450110",
    name: "Phường Mỹ Hương",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450111",
    name: "Phường Phủ Hà",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450112",
    name: "Phường Phước Mỹ",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450113",
    name: "Phường Tấn Tài",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450114",
    name: "Phường Thanh Sơn",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450115",
    name: "Phường Văn Hải",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450116",
    name: "Xã Thành Hải",
    is_higher_price: false,
    district_code: "1665",
  },
  {
    code: "450201",
    name: "Thị trấn Tân Sơn",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450202",
    name: "Xã Hòa Sơn",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450203",
    name: "Xã Lâm Sơn",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450204",
    name: "Xã Lương Sơn",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450205",
    name: "Xã Ma Nới",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450206",
    name: "Xã Mỹ Sơn",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450207",
    name: "Xã Nhơn Sơn",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450208",
    name: "Xã Quảng Sơn",
    is_higher_price: false,
    district_code: "1855",
  },
  {
    code: "450301",
    name: "Thị trấn Khánh Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450302",
    name: "Xã Hộ Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450303",
    name: "Xã Nhơn Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450304",
    name: "Xã Phương Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450305",
    name: "Xã Tân Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450306",
    name: "Xã Thanh Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450307",
    name: "Xã Tri Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450308",
    name: "Xã Vĩnh Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450309",
    name: "Xã Xuân Hải",
    is_higher_price: false,
    district_code: "1985",
  },
  {
    code: "450401",
    name: "Thị trấn Phước Dân",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450402",
    name: "Xã An Hải",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450403",
    name: "Xã Phước Hải",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450404",
    name: "Xã Phước Hậu",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450405",
    name: "Xã Phước Hữu",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450406",
    name: "Xã Phước Sơn",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450407",
    name: "Xã Phước Thái",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450408",
    name: "Xã Phước Thuận",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450409",
    name: "Xã Phước Vinh",
    is_higher_price: false,
    district_code: "1986",
  },
  {
    code: "450501",
    name: "Xã Phước Bình",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450502",
    name: "Xã Phước Chính",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450503",
    name: "Xã Phước Đại",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450504",
    name: "Xã Phước Hòa",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450505",
    name: "Xã Phước Tân",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450506",
    name: "Xã Phước Thắng",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450507",
    name: "Xã Phước Thành",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450508",
    name: "Xã Phước Tiến",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450509",
    name: "Xã Phước Trung",
    is_higher_price: false,
    district_code: "3129",
  },
  {
    code: "450601",
    name: "Xã Bắc Phong",
    is_higher_price: false,
    district_code: "3301",
  },
  {
    code: "450602",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "3301",
  },
  {
    code: "450603",
    name: "Xã Công Hải",
    is_higher_price: false,
    district_code: "3301",
  },
  {
    code: "450604",
    name: "Xã Lợi Hải",
    is_higher_price: false,
    district_code: "3301",
  },
  {
    code: "450605",
    name: "Xã Phước Chiến",
    is_higher_price: false,
    district_code: "3301",
  },
  {
    code: "450606",
    name: "Xã Phước Kháng",
    is_higher_price: false,
    district_code: "3301",
  },
  {
    code: "450701",
    name: "Xã Cà Ná",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "450702",
    name: "Xã Nhị Hà",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "450703",
    name: "Xã Phước Diêm",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "450704",
    name: "Xã Phước Dinh",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "450705",
    name: "Xã Phước Hà",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "450706",
    name: "Xã Phước Minh",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "450707",
    name: "Xã Phước Nam",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "450708",
    name: "Xã Phước Ninh",
    is_higher_price: false,
    district_code: "3302",
  },
  {
    code: "460101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460105",
    name: "Phường Hiệp Ninh",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460106",
    name: "Phường Ninh Sơn",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460107",
    name: "Phường Ninh Thạnh",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460108",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460109",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460110",
    name: "Xã Thạnh Tân",
    is_higher_price: false,
    district_code: "1626",
  },
  {
    code: "460201",
    name: "Thị trấn Tân Biên",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460202",
    name: "Xã Hòa Hiệp",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460203",
    name: "Xã Mỏ Công",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460204",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460205",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460206",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460207",
    name: "Xã Thạnh Bắc",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460208",
    name: "Xã Thạnh Bình",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460209",
    name: "Xã Thạnh Tây",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "460210",
    name: "Xã Trà Vong",
    is_higher_price: false,
    district_code: "1862",
  },
  {
    code: "46031",
    name: "Thị trấn Tân Châu",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "460310",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "460311",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "460312",
    name: "Xã Thạnh Đông",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46032",
    name: "Xã Suối Dây",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46033",
    name: "Xã Suối Ngô",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46034",
    name: "Xã Tân Đông",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46035",
    name: "Xã Tân Hà",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46036",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46037",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46038",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "46039",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1863",
  },
  {
    code: "460401",
    name: "Thị trấn Dương Minh Châu",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460402",
    name: "Xã Bàu Năng",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460403",
    name: "Xã Bến Củi",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460404",
    name: "Xã Cầu Khởi",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460405",
    name: "Xã Chà Là",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460406",
    name: "Xã Lộc Ninh",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460407",
    name: "Xã Phan",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460408",
    name: "Xã Phước Minh",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460409",
    name: "Xã Phước Ninh",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460410",
    name: "Xã Suối Đá",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "460411",
    name: "Xã Truông Mít",
    is_higher_price: false,
    district_code: "1864",
  },
  {
    code: "46051",
    name: "Thị trấn Châu Thành",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "460510",
    name: "Xã Ninh Điền",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "460511",
    name: "Xã Phước Vinh",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "460512",
    name: "Xã Thái Bình",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "460513",
    name: "Xã Thanh Điền",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "460514",
    name: "Xã Thành Long",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "460515",
    name: "Xã Trí Bình",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46052",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46053",
    name: "Xã An Cơ",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46054",
    name: "Xã Biên Giới",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46055",
    name: "Xã Đồng Khởi",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46056",
    name: "Xã Hảo Đước",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46057",
    name: "Xã Hòa Hội",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46058",
    name: "Xã Hòa Thạnh",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "46059",
    name: "Xã Long Vĩnh",
    is_higher_price: false,
    district_code: "1720",
  },
  {
    code: "460601",
    name: "Thị trấn Hòa Thành",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460602",
    name: "Xã Hiệp Tân",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460603",
    name: "Xã Long Thành Bắc",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460604",
    name: "Xã Long Thành Nam",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460605",
    name: "Xã Long Thành Trung",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460606",
    name: "Xã Trường Đông",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460607",
    name: "Xã Trường Hòa",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460608",
    name: "Xã Trường Tây",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "460701",
    name: "Thị trấn Bến Cầu",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460702",
    name: "Xã An Thạnh",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460703",
    name: "Xã Lợi Thuận",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460704",
    name: "Xã Long Chữ",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460705",
    name: "Xã Long Giang",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460706",
    name: "Xã Long Khánh",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460707",
    name: "Xã Long Phước",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460708",
    name: "Xã Long Thuận",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460709",
    name: "Xã Tiên Thuận",
    is_higher_price: false,
    district_code: "1865",
  },
  {
    code: "460801",
    name: "Thị trấn Gò Dầu",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460802",
    name: "Xã Bàu Đồn",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460803",
    name: "Xã Cẩm Giang",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460804",
    name: "Xã Hiệp Thạnh",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460805",
    name: "Xã Phước Đông",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460806",
    name: "Xã Phước Thạnh",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460807",
    name: "Xã Phước Trạch",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460808",
    name: "Xã Thạnh Đức",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460809",
    name: "Xã Thanh Phước",
    is_higher_price: false,
    district_code: "1866",
  },
  {
    code: "460901",
    name: "Thị trấn Trảng Bàng",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460902",
    name: "Xã An Hòa",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460903",
    name: "Xã An Tịnh",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460904",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460905",
    name: "Xã Đôn Thuận",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460906",
    name: "Xã Gia Bình",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460907",
    name: "Xã Gia Lộc",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460908",
    name: "Xã Hưng Thuận",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460909",
    name: "Xã Lộc Hưng",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460910",
    name: "Xã Phước Chỉ",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "460911",
    name: "Xã Phước Lưu",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "470101",
    name: "Phường Bình Hưng",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470102",
    name: "Phường Đức Long",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470103",
    name: "Phường Đức Nghĩa",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470104",
    name: "Phường Đức Thắng",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470105",
    name: "Phường Hàm Tiến",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470106",
    name: "Phường Hưng Long",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470107",
    name: "Phường Lạc Đạo",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470108",
    name: "Phường Mũi Né",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470109",
    name: "Phường Phú Hài",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470110",
    name: "Phường Phú Tài",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470111",
    name: "Phường Phú Thủy",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470112",
    name: "Phường Phú Trinh",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470113",
    name: "Phường Thanh Hải",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470114",
    name: "Phường Xuân An",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470115",
    name: "Xã Phong Nẫm",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470116",
    name: "Xã Thiện Nghiệp",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470117",
    name: "Xã Tiến Lợi",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470118",
    name: "Xã Tiến Thành",
    is_higher_price: false,
    district_code: "1666",
  },
  {
    code: "470201",
    name: "Thị trấn Liên Hương",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470202",
    name: "Thị trấn Phan Rí Cửa",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470203",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470204",
    name: "Xã Chí Công",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470205",
    name: "Xã Hòa Minh",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470206",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470207",
    name: "Xã Phan Dũng",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470208",
    name: "Xã Phong Phú",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470209",
    name: "Xã Phú Lạc",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470210",
    name: "Xã Phước Thể",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470211",
    name: "Xã Vĩnh Hảo",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470212",
    name: "Xã Vĩnh Tân",
    is_higher_price: false,
    district_code: "1781",
  },
  {
    code: "470301",
    name: "Thị trấn Chợ Lầu",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470302",
    name: "Thị trấn Lương Sơn",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470303",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470304",
    name: "Xã Bình Tân",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470305",
    name: "Xã Hải Ninh",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470306",
    name: "Xã Hòa Thắng",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470307",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470308",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470309",
    name: "Xã Phan Điền",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470310",
    name: "Xã Phan Hiệp",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470311",
    name: "Xã Phan Hòa",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470312",
    name: "Xã Phan Lâm",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470313",
    name: "Xã Phan Rí Thành",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470314",
    name: "Xã Phan Sơn",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470315",
    name: "Xã Phan Thanh",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470316",
    name: "Xã Phan Tiến",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470317",
    name: "Xã Sông Bình",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470318",
    name: "Xã Sông Lũy",
    is_higher_price: false,
    district_code: "1780",
  },
  {
    code: "470401",
    name: "Thị trấn Ma Lâm",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470402",
    name: "Thị trấn Phú Long",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470403",
    name: "Xã Đa Mi",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470404",
    name: "Xã Đông Giang",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470405",
    name: "Xã Đông Tiến",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470406",
    name: "Xã Hàm Chính",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470407",
    name: "Xã Hàm Đức",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470408",
    name: "Xã Hàm Hiệp",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470409",
    name: "Xã Hàm Liêm",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470410",
    name: "Xã Hàm Phú",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470411",
    name: "Xã Hàm Thắng",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470412",
    name: "Xã Hàm Trí",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470413",
    name: "Xã Hồng Liêm",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470414",
    name: "Xã Hồng Sơn",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470415",
    name: "Xã La Dạ",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470416",
    name: "Xã Thuận Hòa",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470417",
    name: "Xã Thuận Minh",
    is_higher_price: false,
    district_code: "1777",
  },
  {
    code: "470501",
    name: "Thị trấn Thuận Nam",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470502",
    name: "Xã Hàm Cần",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470503",
    name: "Xã Hàm Cường",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470504",
    name: "Xã Hàm Kiệm",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470505",
    name: "Xã Hàm Minh",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470506",
    name: "Xã Hàm Mỹ",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470507",
    name: "Xã Hàm Thạnh",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470508",
    name: "Xã Mương Mán",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470509",
    name: "Xã Mỹ Thạnh",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470510",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470511",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470512",
    name: "Xã Tân Thuận",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470513",
    name: "Xã Thuận Quí",
    is_higher_price: false,
    district_code: "1776",
  },
  {
    code: "470601",
    name: "Thị trấn Tân Minh",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470602",
    name: "Thị trấn Tân Nghĩa",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470603",
    name: "Xã Sơn Mỹ",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470604",
    name: "Xã Sông Phan",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470605",
    name: "Xã Tân Đức",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470606",
    name: "Xã Tân Hà",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470607",
    name: "Xã Tân Phúc",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470608",
    name: "Xã Tân Thắng",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470609",
    name: "Xã Tân Xuân",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470610",
    name: "Xã Thắng Hải",
    is_higher_price: false,
    district_code: "3196",
  },
  {
    code: "470701",
    name: "Thị trấn Đức Tài",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470702",
    name: "Thị trấn Võ Xu",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470703",
    name: "Xã Đa Kai",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470704",
    name: "Xã Đông Hà",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470705",
    name: "Xã Đức Chính",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470706",
    name: "Xã Đức Hạnh",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470707",
    name: "Xã Đức Tín",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470708",
    name: "Xã Mê Pu",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470709",
    name: "Xã Nam Chính",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470710",
    name: "Xã Sùng Nhơn",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470711",
    name: "Xã Tân Hà",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470712",
    name: "Xã Trà Tân",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470713",
    name: "Xã Vũ Hoà",
    is_higher_price: false,
    district_code: "1779",
  },
  {
    code: "470801",
    name: "Thị trấn Lạc Tánh",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470802",
    name: "Xã Bắc Ruộng",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470803",
    name: "Xã Đồng Kho",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470804",
    name: "Xã Đức Bình",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470805",
    name: "Xã Đức Phú",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470806",
    name: "Xã Đức Tân",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470807",
    name: "Xã Đức Thuận",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470808",
    name: "Xã Gia An",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470809",
    name: "Xã Gia Huynh",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470810",
    name: "Xã Huy Khiêm",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470811",
    name: "Xã La Ngâu",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470812",
    name: "Xã Măng Tố",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470813",
    name: "Xã Nghị Đức",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470814",
    name: "Xã Suối Kiết",
    is_higher_price: false,
    district_code: "2012",
  },
  {
    code: "470901",
    name: "Xã Long Hải",
    is_higher_price: false,
    district_code: "2116",
  },
  {
    code: "470902",
    name: "Xã Ngũ Phụng",
    is_higher_price: false,
    district_code: "2116",
  },
  {
    code: "470903",
    name: "Xã Tam Thanh",
    is_higher_price: false,
    district_code: "2116",
  },
  {
    code: "471001",
    name: "Phường Bình Tân",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471002",
    name: "Phường Phước Hội",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471003",
    name: "Phường Phước Lộc",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471004",
    name: "Phường Tân An",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471005",
    name: "Phường Tân Thiện",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471006",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471007",
    name: "Xã Tân Hải",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471008",
    name: "Xã Tân Phước",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "471009",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1778",
  },
  {
    code: "480101",
    name: "Phường An Bình",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480102",
    name: "Phường Bình Đa",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480103",
    name: "Phường Bửu Hòa",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480104",
    name: "Phường Bửu Long",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480105",
    name: "Phường Hố Nai",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480106",
    name: "Phường Hòa Bình",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480107",
    name: "Phường Long Bình",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480108",
    name: "Phường Long Bình Tân",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480109",
    name: "Phường Quang Vinh",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480110",
    name: "Phường Quyết Thắng",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480111",
    name: "Phường Tam Hiệp",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480112",
    name: "Phường Tam Hòa",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480113",
    name: "Phường Tân Biên",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480114",
    name: "Phường Tân Hiệp",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480115",
    name: "Phường Tân Hòa",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480116",
    name: "Phường Tân Mai",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480117",
    name: "Phường Tân Phong",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480118",
    name: "Phường Tân Tiến",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480119",
    name: "Phường Tân Vạn",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480120",
    name: "Phường Thanh Bình",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480121",
    name: "Phường Thống Nhất",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480122",
    name: "Phường Trảng Dài",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480123",
    name: "Phường Trung Dũng",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480124",
    name: "Xã An Hoà",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480125",
    name: "Xã Hiệp Hòa",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480126",
    name: "Xã Hóa An",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480127",
    name: "Xã Long Hưng",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480128",
    name: "Xã Phước Tân",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480129",
    name: "Xã Tam Phước",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480130",
    name: "Xã Tân Hạnh",
    is_higher_price: false,
    district_code: "1536",
  },
  {
    code: "480201",
    name: "Thị trấn Vĩnh An",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480202",
    name: "Xã Bình Hòa",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480203",
    name: "Xã Bình Lợi",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480204",
    name: "Xã Hiếu Liêm",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480205",
    name: "Xã Mã Đà",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480206",
    name: "Xã Phú Lý",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480207",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480208",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480209",
    name: "Xã Thạnh Phú",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480210",
    name: "Xã Thiện Tân",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480211",
    name: "Xã Trị An",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480212",
    name: "Xã Vĩnh Tân",
    is_higher_price: false,
    district_code: "2049",
  },
  {
    code: "480301",
    name: "Thị trấn Tân Phú",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480302",
    name: "Xã Dak Lua",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480303",
    name: "Xã Nam Cát Tiên",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480304",
    name: "Xã Núi Tượng",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480305",
    name: "Xã Phú An",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480306",
    name: "Xã Phú Bình",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480307",
    name: "Xã Phú Điền",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480308",
    name: "Xã Phú Lâm",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480309",
    name: "Xã Phú Lập",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480310",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480311",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480312",
    name: "Xã Phú Thanh",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480313",
    name: "Xã Phú Thịnh",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480314",
    name: "Xã Phú Trung",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480315",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480316",
    name: "Xã Tà Lài",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480317",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480318",
    name: "Xã Trà Cổ",
    is_higher_price: false,
    district_code: "1693",
  },
  {
    code: "480401",
    name: "Thị trấn Định Quán",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480402",
    name: "Xã Gia Canh",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480403",
    name: "Xã La Ngà",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480404",
    name: "Xã Ngọc Định",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480405",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480406",
    name: "Xã Phú Hòa",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480407",
    name: "Xã Phú Lợi",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480408",
    name: "Xã Phú Ngọc",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480409",
    name: "Xã Phú Tân",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480410",
    name: "Xã Phú Túc",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480411",
    name: "Xã Phú Vinh",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480412",
    name: "Xã Suối Nho",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480413",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480414",
    name: "Xã Túc Trưng",
    is_higher_price: false,
    district_code: "1700",
  },
  {
    code: "480501",
    name: "Xã Bàu Hàm 2",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480502",
    name: "Xã Gia Kiệm",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480503",
    name: "Xã Gia Tân 1",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480504",
    name: "Xã Gia Tân 2",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480505",
    name: "Xã Gia Tân 3",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480506",
    name: "Xã Hưng Lộc",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480507",
    name: "Xã Lộ 25",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480508",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480509",
    name: "Xã Xuân Thạnh",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480510",
    name: "Xã Xuân Thiện",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "480601",
    name: "Phường Phú Bình",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480602",
    name: "Phường Xuân An",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480603",
    name: "Phường Xuân Bình",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480604",
    name: "Phường Xuân Hoà",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480605",
    name: "Phường Xuân Thanh",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480606",
    name: "Phường Xuân Trung",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480607",
    name: "Xã Bảo Quang",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480608",
    name: "Xã Bảo Vinh",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480609",
    name: "Xã Bàu Sen",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480610",
    name: "Xã Bàu Trâm",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480611",
    name: "Xã Bình Lộc",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480612",
    name: "Xã Hàng Gòn",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480613",
    name: "Xã Suối Tre",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480614",
    name: "Xã Xuân Lập",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480615",
    name: "Xã Xuân Tân",
    is_higher_price: false,
    district_code: "1692",
  },
  {
    code: "480701",
    name: "Thị trấn Gia Ray",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480702",
    name: "Xã Bảo Hoà",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480703",
    name: "Xã Lang Minh",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480704",
    name: "Xã Suối Cao",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480705",
    name: "Xã Suối Cát",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480706",
    name: "Xã Xuân Bắc",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480707",
    name: "Xã Xuân Định",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480708",
    name: "Xã Xuân Hiệp",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480709",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480710",
    name: "Xã Xuân Hưng",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480711",
    name: "Xã Xuân Phú",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480712",
    name: "Xã Xuân Tâm",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480713",
    name: "Xã Xuân Thành",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480714",
    name: "Xã Xuân Thọ",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480715",
    name: "Xã Xuân Trường",
    is_higher_price: false,
    district_code: "1704",
  },
  {
    code: "480801",
    name: "Thị trấn Long Thành",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480802",
    name: "Xã An Phước",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480803",
    name: "Xã Bàu Cạn",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480804",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480805",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480806",
    name: "Xã Cẩm Đường",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480807",
    name: "Xã Lộc An",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480808",
    name: "Xã Long An",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480809",
    name: "Xã Long Đức",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480810",
    name: "Xã Long Phước",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480811",
    name: "Xã Phước Bình",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480812",
    name: "Xã Phước Thái",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480813",
    name: "Xã Suối Trầu",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480814",
    name: "Xã Tam An",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480815",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "1694",
  },
  {
    code: "480901",
    name: "Xã Đại Phước",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480902",
    name: "Xã Hiệp Phước",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480903",
    name: "Xã Long Tân",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480904",
    name: "Xã Long Thọ",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480905",
    name: "Xã Phú Đông",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480906",
    name: "Xã Phú Hội",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480907",
    name: "Xã Phú Hữu",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480908",
    name: "Xã Phú Thạnh",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480909",
    name: "Xã Phước An",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480910",
    name: "Xã Phước Khánh",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480911",
    name: "Xã Phước Thiền",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "480912",
    name: "Xã Vĩnh Thanh",
    is_higher_price: false,
    district_code: "1708",
  },
  {
    code: "481001",
    name: "Thị trấn Trảng Bom",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481002",
    name: "Xã An Viễn",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481003",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481004",
    name: "Xã Bàu Hàm",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481005",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481006",
    name: "Xã Cây Gáo",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481007",
    name: "Xã Đồi 61",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481008",
    name: "Xã Đông Hoà",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481009",
    name: "Xã Giang Điền",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481010",
    name: "Xã Hố Nai 3",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481011",
    name: "Xã Hưng Thịnh",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481012",
    name: "Xã Quảng Tiến",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481013",
    name: "Xã Sông Thao",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481014",
    name: "Xã Sông Trầu",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481015",
    name: "Xã Tây Hoà",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481016",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481017",
    name: "Xã Trung Hoà",
    is_higher_price: false,
    district_code: "1691",
  },
  {
    code: "481101",
    name: "Xã Bảo Bình",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481102",
    name: "Xã Lâm San",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481103",
    name: "Xã Long Giao",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481104",
    name: "Xã Nhân Nghĩa",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481105",
    name: "Xã Sông Nhạn",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481106",
    name: "Xã Sông Ray",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481107",
    name: "Xã Thừa Đức",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481108",
    name: "Xã Xuân Bảo",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481109",
    name: "Xã Xuân Đông",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481110",
    name: "Xã Xuân Đường",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481111",
    name: "Xã Xuân Mỹ",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481112",
    name: "Xã Xuân Quế",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "481113",
    name: "Xã Xuân Tây",
    is_higher_price: false,
    district_code: "1702",
  },
  {
    code: "490101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490106",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490107",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490108",
    name: "Phường Khánh Hậu",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490109",
    name: "Phường Tân Khánh",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490110",
    name: "Xã An Vĩnh Ngãi",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490111",
    name: "Xã Bình Tâm",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490112",
    name: "Xã Hướng Thọ Phú",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490113",
    name: "Xã Lợi Bình Nhơn",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490114",
    name: "Xã Nhơn Thạnh Trung",
    is_higher_price: false,
    district_code: "1554",
  },
  {
    code: "490201",
    name: "Thị trấn Vĩnh Hưng",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490202",
    name: "Xã Hưng Điền A",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490203",
    name: "Xã Khánh Hưng",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490204",
    name: "Xã Thái Bình Trung",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490205",
    name: "Xã Thái Trị",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490206",
    name: "Xã Tuyên Bình",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490207",
    name: "Xã Tuyên Bình Tây",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490208",
    name: "Xã Vĩnh Bình",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490209",
    name: "Xã Vĩnh Thuận",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490210",
    name: "Xã Vĩnh Trị",
    is_higher_price: false,
    district_code: "3315",
  },
  {
    code: "490301",
    name: "Xã Bình Hòa Đông",
    is_higher_price: false,
    district_code: "3227",
  },
  {
    code: "490302",
    name: "Xã Bình Hòa Tây",
    is_higher_price: false,
    district_code: "3227",
  },
  {
    code: "490303",
    name: "Xã Bình Hòa Trung",
    is_higher_price: false,
    district_code: "3227",
  },
  {
    code: "490304",
    name: "Xã Bình Phong Thạnh",
    is_higher_price: false,
    district_code: "3227",
  },
  {
    code: "490305",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "3227",
  },
  {
    code: "490306",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "3227",
  },
  {
    code: "490307",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "3227",
  },
  {
    code: "490401",
    name: "Thị trấn Tân Thạnh",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490402",
    name: "Xã Bắc Hòa",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490403",
    name: "Xã Hậu Thạnh Đông",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490404",
    name: "Xã Hậu Thạnh Tây",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490405",
    name: "Xã Kiến Bình",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490406",
    name: "Xã Nhơn Hoà",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490407",
    name: "Xã Nhơn Hòa Lập",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490408",
    name: "Xã Nhơn Ninh",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490409",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490410",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490411",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490412",
    name: "Xã Tân Ninh",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490413",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "3276",
  },
  {
    code: "490501",
    name: "Thị trấn Thạnh Hóa",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490502",
    name: "Xã Tân Đông",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490503",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490504",
    name: "Xã Tân Tây",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490505",
    name: "Xã Thạnh An",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490506",
    name: "Xã Thạnh Phú",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490507",
    name: "Xã Thạnh Phước",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490508",
    name: "Xã Thuận Bình",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490509",
    name: "Xã Thuận Nghĩa Hòa",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490510",
    name: "Xã Thủy Đông",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490511",
    name: "Xã Thủy Tây",
    is_higher_price: false,
    district_code: "3293",
  },
  {
    code: "490601",
    name: "Thị trấn Đông Thành",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490602",
    name: "Xã Bình Hòa Bắc",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490603",
    name: "Xã Bình Hòa Hưng",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490604",
    name: "Xã Bình Hòa Nam",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490605",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490606",
    name: "Xã Mỹ Bình",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490607",
    name: "Xã Mỹ Quý Đông",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490608",
    name: "Xã Mỹ Quý Tây",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490609",
    name: "Xã Mỹ Thạnh Bắc",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490610",
    name: "Xã Mỹ Thạnh Đông",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490611",
    name: "Xã Mỹ Thạnh Tây",
    is_higher_price: false,
    district_code: "2129",
  },
  {
    code: "490701",
    name: "Thị trấn Đức Hòa",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490702",
    name: "Thị trấn Hậu Nghĩa",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490703",
    name: "Thị trấn Hiệp Hòa",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490704",
    name: "Xã An Ninh Đông",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490705",
    name: "Xã An Ninh Tây",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490706",
    name: "Xã Đức Hòa Đông",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490707",
    name: "Xã Đức Hòa Hạ",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490708",
    name: "Xã Đức Hòa Thượng",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490709",
    name: "Xã Đức Lập Hạ",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490710",
    name: "Xã Đức Lập Thượng",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490711",
    name: "Xã Hiệp Hòa",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490712",
    name: "Xã Hòa Khánh Đông",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490713",
    name: "Xã Hòa Khánh Nam",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490714",
    name: "Xã Hòa Khánh Tây",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490715",
    name: "Xã Hựu Thạnh",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490716",
    name: "Xã Lộc Giang",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490717",
    name: "Xã Mỹ Hạnh Bắc",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490718",
    name: "Xã Mỹ Hạnh Nam",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490719",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490720",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1929",
  },
  {
    code: "490801",
    name: "Thị trấn Bến Lức",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490802",
    name: "Xã An Thạnh",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490803",
    name: "Xã Bình Đức",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490804",
    name: "Xã Long Hiệp",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490805",
    name: "Xã Lương Bình",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490806",
    name: "Xã Lương Hòa",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490807",
    name: "Xã Mỹ Yên",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490808",
    name: "Xã Nhựt Chánh",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490809",
    name: "Xã Phước Lợi",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490810",
    name: "Xã Tân Bửu",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490811",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490812",
    name: "Xã Thạnh Đức",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490813",
    name: "Xã Thạnh Hòa",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490814",
    name: "Xã Thạnh Lợi",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490815",
    name: "Xã Thanh Phú",
    is_higher_price: false,
    district_code: "1894",
  },
  {
    code: "490901",
    name: "Thị trấn Thủ Thừa",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490902",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490903",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490904",
    name: "Xã Long Thành",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490905",
    name: "Xã Long Thạnh",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490906",
    name: "Xã Long Thuận",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490907",
    name: "Xã Mỹ An",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490908",
    name: "Xã Mỹ Lạc",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490909",
    name: "Xã Mỹ Phú",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490910",
    name: "Xã Mỹ Thạnh",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490911",
    name: "Xã Nhị Thành",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490912",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "490913",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "49101",
    name: "Thị trấn Tầm Vu",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "491010",
    name: "Xã Thanh Phú Long",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "491011",
    name: "Xã Thanh Vĩnh Đông",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "491012",
    name: "Xã Thuận Mỹ",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "491013",
    name: "Xã Vĩnh Công",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49102",
    name: "Xã An Lục Long",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49103",
    name: "Xã Bình Quới",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49104",
    name: "Xã Dương Xuân Hội",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49105",
    name: "Xã Hiệp Thạnh",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49106",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49107",
    name: "Xã Long Trì",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49108",
    name: "Xã Phú Ngãi Trị",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "49109",
    name: "Xã Phước Tân Hưng",
    is_higher_price: false,
    district_code: "1909",
  },
  {
    code: "491101",
    name: "Thị trấn Tân Trụ",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491102",
    name: "Xã An Nhựt Tân",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491103",
    name: "Xã Bình Lãng",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491104",
    name: "Xã Bình Tịnh",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491105",
    name: "Xã Bình Trinh Đông",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491106",
    name: "Xã Đức Tân",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491107",
    name: "Xã Lạc Tấn",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491108",
    name: "Xã Mỹ Bình",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491109",
    name: "Xã Nhựt Ninh",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491110",
    name: "Xã Quê Mỹ Thạnh",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491111",
    name: "Xã Tân Phước Tây",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "491201",
    name: "Thị trấn Cần Đước",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491202",
    name: "Xã Long Cang",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491203",
    name: "Xã Long Định",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491204",
    name: "Xã Long Hòa",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491205",
    name: "Xã Long Hựu Đông",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491206",
    name: "Xã Long Hựu Tây",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491207",
    name: "Xã Long Khê",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491208",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491209",
    name: "Xã Long Trạch",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491210",
    name: "Xã Mỹ Lệ",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491211",
    name: "Xã Phước Đông",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491212",
    name: "Xã Phước Tuy",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491213",
    name: "Xã Phước Vân",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491214",
    name: "Xã Tân Ân",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491215",
    name: "Xã Tân Chánh",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491216",
    name: "Xã Tân Lân",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491217",
    name: "Xã Tân Trạch",
    is_higher_price: false,
    district_code: "1906",
  },
  {
    code: "491301",
    name: "Thị trấn Cần Giuộc",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491302",
    name: "Xã Đông Thạnh",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491303",
    name: "Xã Long An",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491304",
    name: "Xã Long Hậu",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491305",
    name: "Xã Long Phụng",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491306",
    name: "Xã Long Thượng",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491307",
    name: "Xã Mỹ Lộc",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491308",
    name: "Xã Phước Hậu",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491309",
    name: "Xã Phước Lại",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491310",
    name: "Xã Phước Lâm",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491311",
    name: "Xã Phước Lý",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491312",
    name: "Xã Phước Vĩnh Đông",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491313",
    name: "Xã Phước Vĩnh Tây",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491314",
    name: "Xã Tân Kim",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491315",
    name: "Xã Tân Tập",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491316",
    name: "Xã Thuận Thành",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491317",
    name: "Xã Trường Bình",
    is_higher_price: false,
    district_code: "1907",
  },
  {
    code: "491401",
    name: "Thị trấn Tân Hưng",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491402",
    name: "Xã Hưng Điền",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491403",
    name: "Xã Hưng Điền B",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491404",
    name: "Xã Hưng Hà",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491405",
    name: "Xã Hưng Thạnh",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491406",
    name: "Xã Thạnh Hưng",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491407",
    name: "Xã Vĩnh Bửu",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491408",
    name: "Xã Vĩnh Châu A",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491409",
    name: "Xã Vĩnh Châu B",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491410",
    name: "Xã Vĩnh Đại",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491411",
    name: "Xã Vĩnh Lợi",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491412",
    name: "Xã Vĩnh Thạnh",
    is_higher_price: false,
    district_code: "3273",
  },
  {
    code: "491501",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "491502",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "491503",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "491504",
    name: "Xã Bình Hiệp",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "491505",
    name: "Xã Bình Tân",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "491506",
    name: "Xã Thạnh Hưng",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "491507",
    name: "Xã Thạnh Trị",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "491508",
    name: "Xã Tuyên Thạnh",
    is_higher_price: false,
    district_code: "3329",
  },
  {
    code: "500101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500102",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500103",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500104",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500105",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500106",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500107",
    name: "Phường Hoà Thuận",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500108",
    name: "Phường Mỹ Phú",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500109",
    name: "Xã Hòa An",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500110",
    name: "Xã Mỹ Ngãi",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500111",
    name: "Xã Mỹ Tân",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500112",
    name: "Xã Mỹ Trà",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500113",
    name: "Xã Tân Thuận Đông",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500114",
    name: "Xã Tân Thuận Tây",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500115",
    name: "Xã Tịnh Thới",
    is_higher_price: false,
    district_code: "1564",
  },
  {
    code: "500201",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500202",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500203",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500204",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500205",
    name: "Phường An Hoà",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500206",
    name: "Phường Tân Quy Đông",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500207",
    name: "Xã Tân Khánh Đông",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500208",
    name: "Xã Tân Phú Đông",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500209",
    name: "Xã Tân Quy Tây",
    is_higher_price: false,
    district_code: "1668",
  },
  {
    code: "500301",
    name: "Thị trấn Sa Rài",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500302",
    name: "Xã An Phước",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500303",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500304",
    name: "Xã Tân Công Chí",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500305",
    name: "Xã Tân Hộ Cơ",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500306",
    name: "Xã Tân Phước",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500307",
    name: "Xã Tân Thành A",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500308",
    name: "Xã Tân Thành B",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500309",
    name: "Xã Thông Bình",
    is_higher_price: false,
    district_code: "2013",
  },
  {
    code: "500401",
    name: "Xã Long Khánh A",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500402",
    name: "Xã Long Khánh B",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500403",
    name: "Xã Long Thuận",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500404",
    name: "Xã Phú Thuận A",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500405",
    name: "Xã Phú Thuận B",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500406",
    name: "Xã Thường Lạc",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500407",
    name: "Xã Thường Phước 1",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500408",
    name: "Xã Thường Phước 2",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500409",
    name: "Xã Thường Thới Hậu A",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500410",
    name: "Xã Thường Thới Hậu B",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500411",
    name: "Xã Thường Thới Tiền",
    is_higher_price: false,
    district_code: "3200",
  },
  {
    code: "500501",
    name: "Thị trấn Tràm Chim",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500502",
    name: "Xã An Hòa",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500503",
    name: "Xã An Long",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500504",
    name: "Xã Hoà Bình",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500505",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500506",
    name: "Xã Phú Đức",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500507",
    name: "Xã Phú Hiệp",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500508",
    name: "Xã Phú Ninh",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500509",
    name: "Xã Phú Thành A",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500510",
    name: "Xã Phú Thành B",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500511",
    name: "Xã Phú Thọ",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500512",
    name: "Xã Tân Công Sính",
    is_higher_price: false,
    district_code: "2011",
  },
  {
    code: "500601",
    name: "Thị trấn Thanh Bình",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500602",
    name: "Xã An Phong",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500603",
    name: "Xã Bình Tấn",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500604",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500605",
    name: "Xã Phú Lợi",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500606",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500607",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500608",
    name: "Xã Tân Huề",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500609",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500610",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500611",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500612",
    name: "Xã Tân Quới",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500613",
    name: "Xã Tân Thạnh",
    is_higher_price: false,
    district_code: "2026",
  },
  {
    code: "500701",
    name: "Thị trấn Mỹ Thọ",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500702",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500703",
    name: "Xã Ba Sao",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500704",
    name: "Xã Bình Hàng Tây",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500705",
    name: "Xã Bình Hàng Trung",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500706",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500707",
    name: "Xã Gáo Giồng",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500708",
    name: "Xã Mỹ Hiệp",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500709",
    name: "Xã Mỹ Hội",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500710",
    name: "Xã Mỹ Long",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500711",
    name: "Xã Mỹ Thọ",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500712",
    name: "Xã Mỹ Xương",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500713",
    name: "Xã Nhị Mỹ",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500714",
    name: "Xã Phong Mỹ",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500715",
    name: "Xã Phương Thịnh",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500716",
    name: "Xã Phương Trà",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500717",
    name: "Xã Tân Hội Trung",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500718",
    name: "Xã Tân Nghĩa",
    is_higher_price: false,
    district_code: "1724",
  },
  {
    code: "500801",
    name: "Thị trấn Lấp Vò",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500802",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500803",
    name: "Xã Bình Thạnh Trung",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500804",
    name: "Xã Định An",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500805",
    name: "Xã Định Yên",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500806",
    name: "Xã Hội An Đông",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500807",
    name: "Xã Long Hưng A",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500808",
    name: "Xã Long Hưng B",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500809",
    name: "Xã Mỹ An Hưng A",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500810",
    name: "Xã Mỹ An Hưng B",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500811",
    name: "Xã Tân  Khánh Trung",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500812",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500813",
    name: "Xã Vĩnh Thạnh",
    is_higher_price: false,
    district_code: "1961",
  },
  {
    code: "500901",
    name: "Thị trấn Mỹ An",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500902",
    name: "Xã Đốc Binh Kiều",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500903",
    name: "Xã Hưng Thạnh",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500904",
    name: "Xã Láng Biển",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500905",
    name: "Xã Mỹ An",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500906",
    name: "Xã Mỹ Đông",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500907",
    name: "Xã Mỹ Hòa",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500908",
    name: "Xã Mỹ Quý",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500909",
    name: "Xã Phú Điền",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500910",
    name: "Xã Tân Kiều",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500911",
    name: "Xã Thạnh Lợi",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500912",
    name: "Xã Thanh Mỹ",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "500913",
    name: "Xã Trường Xuân",
    is_higher_price: false,
    district_code: "2030",
  },
  {
    code: "501001",
    name: "Thị trấn Lai Vung",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501002",
    name: "Xã Định Hòa",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501003",
    name: "Xã Hòa Long",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501004",
    name: "Xã Hòa Thành",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501005",
    name: "Xã Long Hậu",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501006",
    name: "Xã Long Thắng",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501007",
    name: "Xã Phong Hòa",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501008",
    name: "Xã Tân Dương",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501009",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "50101",
    name: "Phường Minh Khai",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "501010",
    name: "Xã Tân Phước",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501011",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "501012",
    name: "Xã Vĩnh Thới",
    is_higher_price: false,
    district_code: "1725",
  },
  {
    code: "50102",
    name: "Phường Ngọc Hà",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "50103",
    name: "Phường Nguyễn Trãi",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "50104",
    name: "Phường Quang Trung",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "50105",
    name: "Phường Trần Phú",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "50106",
    name: "Xã Ngọc Đường",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "50107",
    name: "Xã Phương Độ",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "50108",
    name: "Xã Phương Thiện",
    is_higher_price: false,
    district_code: "1600",
  },
  {
    code: "50111",
    name: "Thị trấn Cái Tàu Hạ",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "501110",
    name: "Xã Tân Nhuận Đông",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "501111",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "501112",
    name: "Xã Tân Phú Trung",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50112",
    name: "Xã An Hiệp",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50113",
    name: "Xã An Khánh",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50114",
    name: "Xã An Nhơn",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50115",
    name: "Xã An Phú Thuận",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50116",
    name: "Xã Hòa Tân",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50117",
    name: "Xã Phú Hựu",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50118",
    name: "Xã Phú Long",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "50119",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "3155",
  },
  {
    code: "501201",
    name: "Phường An Lạc",
    is_higher_price: false,
    district_code: "2059",
  },
  {
    code: "501202",
    name: "Phường An Lộc",
    is_higher_price: false,
    district_code: "2059",
  },
  {
    code: "501203",
    name: "Phường An Thạnh",
    is_higher_price: false,
    district_code: "2059",
  },
  {
    code: "501204",
    name: "Xã An Bình A",
    is_higher_price: false,
    district_code: "2059",
  },
  {
    code: "501205",
    name: "Xã An Bình B",
    is_higher_price: false,
    district_code: "2059",
  },
  {
    code: "501206",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "2059",
  },
  {
    code: "501207",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "2059",
  },
  {
    code: "50201",
    name: "Thị trấn Đồng Văn",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50202",
    name: "Thị trấn Phó Bảng",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50203",
    name: "Xã Hố Quáng Phìn",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50204",
    name: "Xã Lũng Cú",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50205",
    name: "Xã Lũng Phìn",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50206",
    name: "Xã Lũng Táo",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50207",
    name: "Xã Lũng Thầu",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50208",
    name: "Xã Má Lé",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50209",
    name: "Xã Phố Cáo",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50210",
    name: "Xã Phố Là",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50211",
    name: "Xã Sảng Tủng",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50212",
    name: "Xã Sính Lủng",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50213",
    name: "Xã Sủng Là",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50214",
    name: "Xã Sủng Trái",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50215",
    name: "Xã Tả Lủng",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50216",
    name: "Xã Tả Phìn",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50217",
    name: "Xã Thài Phìn Tủng",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50218",
    name: "Xã Vần Chải",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50219",
    name: "Xã Xà Phìn",
    is_higher_price: false,
    district_code: "1928",
  },
  {
    code: "50301",
    name: "Thị trấn Mèo Vạc",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50302",
    name: "Xã Cán Chu Phìn",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50303",
    name: "Xã Giàng Chu Phìn",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50304",
    name: "Xã Khâu Vai",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50305",
    name: "Xã Lũng Chinh",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50306",
    name: "Xã Lũng Pù",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50307",
    name: "Xã Nậm Ban",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50308",
    name: "Xã Niêm Sơn",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50309",
    name: "Xã Niêm Tòng",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50310",
    name: "Xã Pả Vi",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50311",
    name: "Xã Pải Lủng",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50312",
    name: "Xã Sơn Vĩ",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50313",
    name: "Xã Sủng Máng",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50314",
    name: "Xã Sủng Trà",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50315",
    name: "Xã Tả Lủng",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50316",
    name: "Xã Tát Ngà",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50317",
    name: "Xã Thượng Phùng",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50318",
    name: "Xã Xín Cái",
    is_higher_price: false,
    district_code: "1973",
  },
  {
    code: "50401",
    name: "Thị trấn Yên Minh",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50402",
    name: "Xã Bạch Đích",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50403",
    name: "Xã Đông Minh",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50404",
    name: "Xã Du Già",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50405",
    name: "Xã Du Tiến",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50406",
    name: "Xã Đường Thượng",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50407",
    name: "Xã Hữu Vinh",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50408",
    name: "Xã Lao Và Chải",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50409",
    name: "Xã Lũng Hồ",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50410",
    name: "Xã Mậu Duệ",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50411",
    name: "Xã Mậu Long",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50412",
    name: "Xã Na Khê",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50413",
    name: "Xã Ngam La",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50414",
    name: "Xã Ngọc Long",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50415",
    name: "Xã Phú Lũng",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50416",
    name: "Xã Sủng Thài",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50417",
    name: "Xã Sủng Tráng",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50418",
    name: "Xã Thắng Mố",
    is_higher_price: false,
    district_code: "2053",
  },
  {
    code: "50501",
    name: "Thị trấn Tam Sơn",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50502",
    name: "Xã Bát Đại Sơn",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50503",
    name: "Xã Cán Tỷ",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50504",
    name: "Xã Cao Mã Pờ",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50505",
    name: "Xã Đông Hà",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50506",
    name: "Xã Lùng Tám",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50507",
    name: "Xã Nghĩa Thuận",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50508",
    name: "Xã Quản Bạ",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50509",
    name: "Xã Quyết Tiến",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50510",
    name: "Xã Tả Ván",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50511",
    name: "Xã Thái An",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50512",
    name: "Xã Thanh Vân",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50513",
    name: "Xã Tùng Vài",
    is_higher_price: false,
    district_code: "1999",
  },
  {
    code: "50601",
    name: "Thị trấn Nông Trường Việt Lâm",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50602",
    name: "Thị trấn Vị Xuyên",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50603",
    name: "Xã Bạch Ngọc",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50604",
    name: "Xã Cao Bồ",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50605",
    name: "Xã Đạo Đức",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50606",
    name: "Xã Kim Linh",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50607",
    name: "Xã Kim Thạch",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50608",
    name: "Xã Lao Chải",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50609",
    name: "Xã Linh Hồ",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50610",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50611",
    name: "Xã Ngọc Linh",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50612",
    name: "Xã Ngọc Minh",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50613",
    name: "Xã Phong Quang",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50614",
    name: "Xã Phú Linh",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50615",
    name: "Xã Phương Tiến",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50616",
    name: "Xã Quảng Ngần",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50617",
    name: "Xã Thanh Đức",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50618",
    name: "Xã Thanh Thủy",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50619",
    name: "Xã Thuận Hoà",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50620",
    name: "Xã Thượng Sơn",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50621",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50622",
    name: "Xã Tùng Bá",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50623",
    name: "Xã Việt Lâm",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50624",
    name: "Xã Xín Chải",
    is_higher_price: false,
    district_code: "2256",
  },
  {
    code: "50701",
    name: "Thị trấn Yên Phú",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50702",
    name: "Xã Đường Âm",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50703",
    name: "Xã Đường Hồng",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50704",
    name: "Xã Giáp Trung",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50705",
    name: "Xã Lạc Nông",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50706",
    name: "Xã Minh Ngọc",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50707",
    name: "Xã Minh Sơn",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50708",
    name: "Xã Phiêng Luông",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50709",
    name: "Xã Phú Nam",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50710",
    name: "Xã Thượng Tân",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50711",
    name: "Xã Yên Cường",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50712",
    name: "Xã Yên Định",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50713",
    name: "Xã Yên Phong",
    is_higher_price: false,
    district_code: "2075",
  },
  {
    code: "50801",
    name: "Thị trấn Vinh Quang",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50802",
    name: "Xã Bản Luốc",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50803",
    name: "Xã Bản Máy",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50804",
    name: "Xã Bản Nhùng",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50805",
    name: "Xã Bản Péo",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50806",
    name: "Xã Bản Phùng",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50807",
    name: "Xã Chiến Phố",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50808",
    name: "Xã Đản Ván",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50809",
    name: "Xã Hồ Thầu",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50810",
    name: "Xã Nậm Dịch",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50811",
    name: "Xã Nậm Khòa",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50812",
    name: "Xã Nam Sơn",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50813",
    name: "Xã Nậm Tỵ",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50814",
    name: "Xã Nàng Đôn",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50815",
    name: "Xã Ngàm Đăng Vài",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50816",
    name: "Xã Pố Lồ",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50817",
    name: "Xã Pờ Ly Ngài",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50818",
    name: "Xã Sán Xả Hồ",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50819",
    name: "Xã Tả Sử Choóng",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50820",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50821",
    name: "Xã Thàng Tín",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50822",
    name: "Xã Thèn Chu Phìn",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50823",
    name: "Xã Thông Nguyên",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50824",
    name: "Xã Tụ Nhân",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50825",
    name: "Xã Túng Sán",
    is_higher_price: false,
    district_code: "1945",
  },
  {
    code: "50901",
    name: "Thị trấn Cốc Pài",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50902",
    name: "Xã Bản Díu",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50903",
    name: "Xã Bản Ngò",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50904",
    name: "Xã Chế Là",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50905",
    name: "Xã Chí Cà",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50906",
    name: "Xã Cốc Rế",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50907",
    name: "Xã Khuôn Lùng",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50908",
    name: "Xã Nà Chì",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50909",
    name: "Xã Nấm Dẩn",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50910",
    name: "Xã Nàn Ma",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50911",
    name: "Xã Nàn Xỉn",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50912",
    name: "Xã Ngán Chiên",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50913",
    name: "Xã Pà Vầy Sủ",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50914",
    name: "Xã Quảng Nguyên",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50915",
    name: "Xã Tả Nhìu",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50916",
    name: "Xã Thèn Phàng",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50917",
    name: "Xã Thu Tà",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50918",
    name: "Xã Trung Thịnh",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "50919",
    name: "Xã Xín Mần",
    is_higher_price: false,
    district_code: "2052",
  },
  {
    code: "51001",
    name: "Thị trấn Việt Quang",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51002",
    name: "Thị trấn Vĩnh Tuy",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51003",
    name: "Xã Bằng Hành",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51004",
    name: "Xã Đồng Tâm",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51005",
    name: "Xã Đông Thành",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51006",
    name: "Xã Đồng Tiến",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51007",
    name: "Xã Đồng Yên",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51008",
    name: "Xã Đức Xuân",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51009",
    name: "Xã Hùng An",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51010",
    name: "Xã Hữu Sản",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "510101",
    name: "Phường Mỹ Bình",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510102",
    name: "Phường Mỹ Long",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510103",
    name: "Phường Đông Xuyên",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510104",
    name: "Phường Mỹ Xuyên",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510105",
    name: "Phường Bình Đức",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510106",
    name: "Phường Bình Khánh",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510107",
    name: "Phường Mỹ Phước",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510108",
    name: "Phường Mỹ Quý",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510109",
    name: "Phường Mỹ Thới",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "51011",
    name: "Xã Kim Ngọc",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "510110",
    name: "Phường Mỹ Thạnh",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510111",
    name: "Phường Mỹ Hòa",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510112",
    name: "Xã Mỹ Khánh",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "510113",
    name: "Xã Mỹ Hoà Hưng",
    is_higher_price: false,
    district_code: "1566",
  },
  {
    code: "51012",
    name: "Xã Liên Hiệp",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51013",
    name: "Xã Quang Minh",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51014",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51015",
    name: "Xã Tân Quang",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51016",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51017",
    name: "Xã Thượng Bình",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51018",
    name: "Xã Tiên Kiều",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51019",
    name: "Xã Việt Hồng",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51020",
    name: "Xã Việt Vinh",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "510201",
    name: "Phường Châu Phú B",
    is_higher_price: false,
    district_code: "1753",
  },
  {
    code: "510202",
    name: "Phường Châu Phú A",
    is_higher_price: false,
    district_code: "1753",
  },
  {
    code: "510203",
    name: "Phường Vĩnh Mỹ",
    is_higher_price: false,
    district_code: "1753",
  },
  {
    code: "510204",
    name: "Phường Núi Sam",
    is_higher_price: false,
    district_code: "1753",
  },
  {
    code: "510205",
    name: "Phường Vĩnh Ngươn",
    is_higher_price: false,
    district_code: "1753",
  },
  {
    code: "510206",
    name: "Xã Vĩnh Tế",
    is_higher_price: false,
    district_code: "1753",
  },
  {
    code: "510207",
    name: "Xã Vĩnh Châu",
    is_higher_price: false,
    district_code: "1753",
  },
  {
    code: "51021",
    name: "Xã Vĩnh Hảo",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51022",
    name: "Xã Vĩnh Phúc",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "51023",
    name: "Xã Vô Điếm",
    is_higher_price: false,
    district_code: "1893",
  },
  {
    code: "510301",
    name: "Thị trấn An Phú",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510302",
    name: "Xã Khánh An",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510303",
    name: "Thị Trấn Long Bình",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510304",
    name: "Xã Khánh Bình",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510305",
    name: "Xã Quốc Thái",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510306",
    name: "Xã Nhơn Hội",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510307",
    name: "Xã Phú Hữu",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510308",
    name: "Xã Phú Hội",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510309",
    name: "Xã Phước Hưng",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510310",
    name: "Xã Vĩnh Lộc",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510311",
    name: "Xã Vĩnh Hậu",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510312",
    name: "Xã Vĩnh Trường",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510313",
    name: "Xã Vĩnh Hội Đông",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510314",
    name: "Xã Đa Phước",
    is_higher_price: false,
    district_code: "1754",
  },
  {
    code: "510401",
    name: "Phường Long Thạnh",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510402",
    name: "Phường Long Hưng",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510403",
    name: "Phường Long Châu",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510404",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510405",
    name: "Xã Vĩnh Xương",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510406",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510407",
    name: "Xã Tân Thạnh",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510408",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510409",
    name: "Xã Long An",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510410",
    name: "Phường Long Phú",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510411",
    name: "Xã Châu Phong",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510412",
    name: "Xã Phú Vĩnh",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510413",
    name: "Xã Lê Chánh",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510414",
    name: "Phường Long Sơn",
    is_higher_price: false,
    district_code: "1755",
  },
  {
    code: "510501",
    name: "Thị trấn Phú Mỹ",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510502",
    name: "Thị trấn Chợ Vàm",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510503",
    name: "Xã Long Hoà",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510504",
    name: "Xã Phú Long",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510505",
    name: "Xã Phú Lâm",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510506",
    name: "Xã Phú Hiệp",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510507",
    name: "Xã Phú Thạnh",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510508",
    name: "Xã Hoà Lạc",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510509",
    name: "Xã Phú Thành",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510510",
    name: "Xã Phú An",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510511",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510512",
    name: "Xã Hiệp Xương",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510513",
    name: "Xã Phú Bình",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510514",
    name: "Xã Phú Thọ",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510515",
    name: "Xã Phú Hưng",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510516",
    name: "Xã Bình Thạnh Đông",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510517",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510518",
    name: "Xã Tân Trung",
    is_higher_price: false,
    district_code: "1756",
  },
  {
    code: "510601",
    name: "Thị trấn Chi Lăng",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510602",
    name: "Thị trấn Nhà Bàng",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510603",
    name: "Thị trấn Tịnh Biên",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510604",
    name: "Xã An Cư",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510605",
    name: "Xã An Hảo",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510606",
    name: "Xã An Nông",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510607",
    name: "Xã An Phú",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510608",
    name: "Xã Nhơn Hưng",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510609",
    name: "Xã Núi Voi",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510610",
    name: "Xã Tân Lập",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510611",
    name: "Xã Tân Lợi",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510612",
    name: "Xã Thới Sơn",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510613",
    name: "Xã Văn Giáo",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510614",
    name: "Xã Vĩnh Trung",
    is_higher_price: false,
    district_code: "1752",
  },
  {
    code: "510701",
    name: "Thị trấn Ba Chúc",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510702",
    name: "Thị trấn Tri Tôn",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510703",
    name: "Xã An Tức",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510704",
    name: "Xã Châu Lăng",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510705",
    name: "Xã Cô Tô",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510706",
    name: "Xã Lạc Quới",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510707",
    name: "Xã Lê Trì",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510708",
    name: "Xã Lương An Trà",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510709",
    name: "Xã Lương Phi",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510710",
    name: "Xã Núi Tô",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510711",
    name: "Xã Ô Lâm",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510712",
    name: "Xã Tà Đảnh",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510713",
    name: "Xã Tân Tuyến",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510714",
    name: "Xã Vĩnh Gia",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510715",
    name: "Xã Vĩnh Phước",
    is_higher_price: false,
    district_code: "1751",
  },
  {
    code: "510801",
    name: "Thị trấn Cái Dầu",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510802",
    name: "Xã Bình Chánh",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510803",
    name: "Xã Bình Long",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510804",
    name: "Xã Bình Mỹ",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510805",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510806",
    name: "Xã Bình Thủy",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510807",
    name: "Xã Đào Hữu Cảnh",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510808",
    name: "Xã Khánh Hòa",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510809",
    name: "Xã Mỹ Đức",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510810",
    name: "Xã Mỹ Phú",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510811",
    name: "Xã Ô Long Vỹ",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510812",
    name: "Xã Thạnh Mỹ Tây",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510813",
    name: "Xã Vĩnh Thạnh Trung",
    is_higher_price: false,
    district_code: "1758",
  },
  {
    code: "510901",
    name: "Thị trấn Chợ Mới",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510902",
    name: "Thị trấn Mỹ Luông",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510903",
    name: "Xã An Thạnh Trung",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510904",
    name: "Xã Bình Phước Xuân",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510905",
    name: "Xã Hòa An",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510906",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510907",
    name: "Xã Hội An",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510908",
    name: "Xã Kiến An",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510909",
    name: "Xã Kiến Thành",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510910",
    name: "Xã Long Điền A",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510911",
    name: "Xã Long Điền B",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510912",
    name: "Xã Long Giang",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510913",
    name: "Xã Long Kiến",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510914",
    name: "Xã Mỹ An",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510915",
    name: "Xã Mỹ Hiệp",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510916",
    name: "Xã Mỹ Hội Đông",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510917",
    name: "Xã Nhơn Mỹ",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "510918",
    name: "Xã Tấn Mỹ",
    is_higher_price: false,
    district_code: "1757",
  },
  {
    code: "511001",
    name: "Thị trấn An Châu",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511002",
    name: "Xã An Hòa",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511003",
    name: "Xã Bình Hòa",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511004",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511005",
    name: "Xã Cần Đăng",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511006",
    name: "Xã Hòa Bình Thạnh",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511007",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511008",
    name: "Xã Vĩnh An",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511009",
    name: "Xã Vĩnh Bình",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "51101",
    name: "Thị trấn Yên Bình",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "511010",
    name: "Xã Vĩnh Hanh",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511011",
    name: "Xã Vĩnh Lợi",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511012",
    name: "Xã Vĩnh Nhuận",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "511013",
    name: "Xã Vĩnh Thành",
    is_higher_price: false,
    district_code: "1718",
  },
  {
    code: "51102",
    name: "Xã Bản Rịa",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51103",
    name: "Xã Bằng Lang",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51104",
    name: "Xã Hương Sơn",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51105",
    name: "Xã Nà Khương",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51106",
    name: "Xã Tân Bắc",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51107",
    name: "Xã Tân Nam",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51108",
    name: "Xã Tân Trịnh",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51109",
    name: "Xã Tiên Nguyên",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51110",
    name: "Xã Tiên Yên",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "511101",
    name: "Thị trấn Núi Sập",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511102",
    name: "Thị Trấn Óc Eo",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511103",
    name: "Thị trấn Phú Hoà",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511104",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511105",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511106",
    name: "Xã Định Mỹ",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511107",
    name: "Xã Định Thành",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511108",
    name: "Xã Mỹ Phú Đông",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511109",
    name: "Xã Phú Thuận",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "51111",
    name: "Xã Vĩ Thượng",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "511110",
    name: "Xã Tây Phú",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511111",
    name: "Xã Thoại Giang",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511112",
    name: "Xã Vĩnh Chánh",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511113",
    name: "Xã Vĩnh Khánh",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511114",
    name: "Xã Vĩnh Phú",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511115",
    name: "Xã Vĩnh Trạch",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511116",
    name: "Xã Vọng Đông",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "511117",
    name: "Xã Vọng Thê",
    is_higher_price: false,
    district_code: "1750",
  },
  {
    code: "51112",
    name: "Xã Xuân Giang",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51113",
    name: "Xã Xuân Minh",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51114",
    name: "Xã Yên Hà",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "51115",
    name: "Xã Yên Thành",
    is_higher_price: false,
    district_code: "2001",
  },
  {
    code: "520101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520102",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520103",
    name: "Phường 11",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520104",
    name: "Phường 12",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520105",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520106",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520107",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520108",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520109",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520110",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520111",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520112",
    name: "Phường Nguyễn An Ninh",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520113",
    name: "Phường Rạch Dừa",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520114",
    name: "Phường Thắng Nhất",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520115",
    name: "Phường Thắng Nhì",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520116",
    name: "Phường Thắng Tam",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520117",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "1544",
  },
  {
    code: "520201",
    name: "Phường Kim Dinh",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520202",
    name: "Phường Long Hương",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520203",
    name: "Phường Long Tâm",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520204",
    name: "Phường Long Toàn",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520205",
    name: "Phường Phước Hiệp",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520206",
    name: "Phường Phước Hưng",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520207",
    name: "Phường Phước Nguyên",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520208",
    name: "Phường Phước Trung",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520209",
    name: "Xã Hoà Long",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520210",
    name: "Xã Long Phước",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520211",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1667",
  },
  {
    code: "520301",
    name: "Thị trấn Phước Bửu",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520302",
    name: "Xã Bàu Lâm",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520303",
    name: "Xã Bình Châu",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520304",
    name: "Xã Bông Trang",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520305",
    name: "Xã Bưng Riềng",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520306",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520307",
    name: "Xã Hòa Hiệp",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520308",
    name: "Xã Hòa Hội",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520309",
    name: "Xã Hòa Hưng",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520310",
    name: "Xã Phước Tân",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520311",
    name: "Xã Phước Thuận",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520312",
    name: "Xã Tân Lâm",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520313",
    name: "Xã Xuyên Mộc",
    is_higher_price: false,
    district_code: "1699",
  },
  {
    code: "520401",
    name: "Thị trấn Long Điền",
    is_higher_price: false,
    district_code: "1689",
  },
  {
    code: "520402",
    name: "Thị trấn Long Hải",
    is_higher_price: false,
    district_code: "1689",
  },
  {
    code: "520403",
    name: "Xã An Ngãi",
    is_higher_price: false,
    district_code: "1689",
  },
  {
    code: "520404",
    name: "Xã An Nhứt",
    is_higher_price: false,
    district_code: "1689",
  },
  {
    code: "520405",
    name: "Xã Phước Hưng",
    is_higher_price: false,
    district_code: "1689",
  },
  {
    code: "520406",
    name: "Xã Phước Tỉnh",
    is_higher_price: false,
    district_code: "1689",
  },
  {
    code: "520407",
    name: "Xã Tam Phước",
    is_higher_price: false,
    district_code: "1689",
  },
  {
    code: "520601",
    name: "Thị trấn Phú Mỹ",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520602",
    name: "Xã Châu Pha",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520603",
    name: "Xã Hắc Dịch",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520604",
    name: "Xã Mỹ Xuân",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520605",
    name: "Xã Phước Hoà",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520606",
    name: "Xã Sông Xoài",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520607",
    name: "Xã Tân Hải",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520608",
    name: "Xã Tân Hoà",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520609",
    name: "Xã Tân Phước",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520610",
    name: "Xã Tóc Tiên",
    is_higher_price: false,
    district_code: "1701",
  },
  {
    code: "520701",
    name: "Thị trấn Ngãi Giao",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520702",
    name: "Xã Bàu Chinh",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520703",
    name: "Xã Bình Ba",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520704",
    name: "Xã Bình Giã",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520705",
    name: "Xã Bình Trung",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520706",
    name: "Xã Cù Bị",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520707",
    name: "Xã Đá Bạc",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520708",
    name: "Xã Kim Long",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520709",
    name: "Xã Láng Lớn",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520710",
    name: "Xã Nghĩa Thành",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520711",
    name: "Xã Quảng Thành",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520712",
    name: "Xã Sơn Bình",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520713",
    name: "Xã Suối Nghệ",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520714",
    name: "Xã Suối Rao",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520715",
    name: "Xã Xà Bang",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520716",
    name: "Xã Xuân Sơn",
    is_higher_price: false,
    district_code: "1709",
  },
  {
    code: "520801",
    name: "Thị trấn Đất Đỏ",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "520802",
    name: "Thị trấn Phước Hải",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "520803",
    name: "Xã Láng Dài",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "520804",
    name: "Xã Lộc An",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "520805",
    name: "Xã Long Mỹ",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "520806",
    name: "Xã Long Tân",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "520807",
    name: "Xã Phước Hội",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "520808",
    name: "Xã Phước Long Thọ",
    is_higher_price: false,
    district_code: "1690",
  },
  {
    code: "530101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530102",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530103",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530104",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530105",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530106",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530107",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530108",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530109",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530110",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530111",
    name: "Phường Tân Long",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530112",
    name: "Xã Đạo Thạnh",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530113",
    name: "Xã Mỹ Phong",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530114",
    name: "Xã Phước Thạnh",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530115",
    name: "Xã Tân Mỹ Chánh",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530116",
    name: "Xã Thới Sơn",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530117",
    name: "Xã Trung An",
    is_higher_price: false,
    district_code: "1556",
  },
  {
    code: "530201",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530202",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530203",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530204",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530205",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530206",
    name: "Xã Bình Đông",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530207",
    name: "Xã Bình Xuân",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530208",
    name: "Xã Long Chánh",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530209",
    name: "Xã Long Hòa",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530210",
    name: "Xã Long Hưng",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530211",
    name: "Xã Long Thuận",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530212",
    name: "Xã Tân Trung",
    is_higher_price: false,
    district_code: "2057",
  },
  {
    code: "530301",
    name: "Thị trấn Cái Bè",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530302",
    name: "Xã An Cư",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530303",
    name: "Xã An Hữu",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530304",
    name: "Xã An Thái Đông",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530305",
    name: "Xã An Thái Trung",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530306",
    name: "Xã Đông Hòa Hiệp",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530307",
    name: "Xã Hậu Mỹ Bắc A",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530308",
    name: "Xã Hậu Mỹ Bắc B",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530309",
    name: "Xã Hậu Mỹ Phú",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530310",
    name: "Xã Hậu Mỹ Trinh",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530311",
    name: "Xã Hậu Thành",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530312",
    name: "Xã Hòa Hưng",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530313",
    name: "Xã Hòa Khánh",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530314",
    name: "Xã Mỹ Đức Đông",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530315",
    name: "Xã Mỹ Đức Tây",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530316",
    name: "Xã Mỹ Hội",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530317",
    name: "Xã Mỹ Lợi A",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530318",
    name: "Xã Mỹ Lợi B",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530319",
    name: "Xã Mỹ Lương",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530320",
    name: "Xã Mỹ Tân",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530321",
    name: "Xã Mỹ Trung",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530322",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530323",
    name: "Xã Tân Thanh",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530324",
    name: "Xã Thiện Trí",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530325",
    name: "Xã Thiện Trung",
    is_higher_price: false,
    district_code: "1900",
  },
  {
    code: "530401",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530402",
    name: "Xã Cẩm Sơn",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530403",
    name: "Xã Hiệp Đức",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530404",
    name: "Xã Hội Xuân",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530405",
    name: "Xã Long Tiên",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530406",
    name: "Xã Long Trung",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530407",
    name: "Xã Mỹ Long",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530408",
    name: "Xã Mỹ Thành Bắc",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530409",
    name: "Xã Mỹ Thành Nam",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530410",
    name: "Xã Ngũ Hiệp",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530411",
    name: "Xã Phú An",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530412",
    name: "Xã Phú Cường",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530413",
    name: "Xã Phú Nhuận",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530414",
    name: "Xã Tam Bình",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530415",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "530416",
    name: "Xã Thạnh Lộc",
    is_higher_price: false,
    district_code: "2084",
  },
  {
    code: "53051",
    name: "Thị trấn Tân Hiệp",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530510",
    name: "Xã Long An",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530511",
    name: "Xã Long Định",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530512",
    name: "Xã Long Hưng",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530513",
    name: "Xã Nhị Bình",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530514",
    name: "Xã Phú Phong",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530515",
    name: "Xã Song Thuận",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530516",
    name: "Xã Tam Hiệp",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530517",
    name: "Xã Tân Hội Đông",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530518",
    name: "Xã Tân Hương",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530519",
    name: "Xã Tân Lý Đông",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53052",
    name: "Xã Bàn Long",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530520",
    name: "Xã Tân Lý Tây",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530521",
    name: "Xã Thân Cửu Nghĩa",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530522",
    name: "Xã Thạnh Phú",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530523",
    name: "Xã Vĩnh Kim",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53053",
    name: "Xã Bình Đức",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53054",
    name: "Xã Bình Trưng",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53055",
    name: "Xã Điềm Hy",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53056",
    name: "Xã Đông Hòa",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53057",
    name: "Xã Dưỡng Điềm",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53058",
    name: "Xã Hữu Đạo",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "53059",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "1740",
  },
  {
    code: "530601",
    name: "Thị trấn Chợ Gạo",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530602",
    name: "Xã An Thạnh Thủy",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530603",
    name: "Xã Bình Ninh",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530604",
    name: "Xã Bình Phan",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530605",
    name: "Xã Bình Phục Nhứt",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530606",
    name: "Xã Đăng Hưng Phước",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530607",
    name: "Xã Hòa Định",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530608",
    name: "Xã Hòa Tịnh",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530609",
    name: "Xã Long Bình Điền",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530610",
    name: "Xã Lương Hòa Lạc",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530611",
    name: "Xã Mỹ Tịnh An",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530612",
    name: "Xã Phú Kiết",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530613",
    name: "Xã Quơn Long",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530614",
    name: "Xã Song Bình",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530615",
    name: "Xã Tân Bình Thạnh",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530616",
    name: "Xã Tân Thuận Bình",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530617",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530618",
    name: "Xã Trung Hòa",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530619",
    name: "Xã Xuân Đông",
    is_higher_price: false,
    district_code: "1741",
  },
  {
    code: "530701",
    name: "Thị trấn Vĩnh Bình",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530702",
    name: "Xã Bình Nhì",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530703",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530704",
    name: "Xã Bình Tân",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530705",
    name: "Xã Đồng Sơn",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530706",
    name: "Xã Đồng Thạnh",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530707",
    name: "Xã Long Bình",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530708",
    name: "Xã Long Vĩnh",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530709",
    name: "Xã Thành Công",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530710",
    name: "Xã Thạnh Nhựt",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530711",
    name: "Xã Thạnh Trị",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530712",
    name: "Xã Vĩnh Hựu",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530713",
    name: "Xã Yên Luông",
    is_higher_price: false,
    district_code: "1933",
  },
  {
    code: "530801",
    name: "Thị trấn Tân Hòa",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530802",
    name: "Thị trấn Vàm Láng",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530803",
    name: "Xã Bình Ân",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530804",
    name: "Xã Bình Nghị",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530805",
    name: "Xã Gia Thuận",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530806",
    name: "Xã Kiểng Phước",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530807",
    name: "Xã Phước Trung",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530808",
    name: "Xã Tân Điền",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530809",
    name: "Xã Tân Đông",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530810",
    name: "Xã Tân Phước",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530811",
    name: "Xã Tân Tây",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530812",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530813",
    name: "Xã Tăng Hoà",
    is_higher_price: false,
    district_code: "1932",
  },
  {
    code: "530901",
    name: "Thị trấn Mỹ Phước",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530902",
    name: "Xã Hưng Thạnh",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530903",
    name: "Xã Mỹ Phước",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530904",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530905",
    name: "Xã Phước Lập",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530906",
    name: "Xã Tân Hòa Đông",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530907",
    name: "Xã Tân Hòa Tây",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530908",
    name: "Xã Tân Hòa Thành",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530909",
    name: "Xã Tân Lập 1",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530910",
    name: "Xã Tân Lập 2",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530911",
    name: "Xã Thạnh Hoà",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530912",
    name: "Xã Thạnh Mỹ",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "530913",
    name: "Xã Thạnh Tân",
    is_higher_price: false,
    district_code: "3275",
  },
  {
    code: "531001",
    name: "Xã Phú Đông",
    is_higher_price: false,
    district_code: "2216",
  },
  {
    code: "531002",
    name: "Xã Phú Tân",
    is_higher_price: false,
    district_code: "2216",
  },
  {
    code: "531003",
    name: "Xã Phú Thạnh",
    is_higher_price: false,
    district_code: "2216",
  },
  {
    code: "531004",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "2216",
  },
  {
    code: "531005",
    name: "Xã Tân Thạnh",
    is_higher_price: false,
    district_code: "2216",
  },
  {
    code: "531006",
    name: "Xã Tân Thới",
    is_higher_price: false,
    district_code: "2216",
  },
  {
    code: "531101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531106",
    name: "Phường Nhị Mỹ",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531107",
    name: "Xã Long Khánh",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531108",
    name: "Xã Mỹ Hạnh Đông",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531109",
    name: "Xã Mỹ Hạnh Trung",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531110",
    name: "Xã Mỹ Phước Tây",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531111",
    name: "Xã Nhị Quý",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531112",
    name: "Xã Phú Quý",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531113",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531114",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531115",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "531116",
    name: "Xã Thanh Hòa",
    is_higher_price: false,
    district_code: "2055",
  },
  {
    code: "540101",
    name: "Phường An Bình",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540102",
    name: "Phường An Hòa",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540103",
    name: "Phường Rạch Sỏi",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540104",
    name: "Phường Vĩnh Bảo",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540105",
    name: "Phường Vĩnh Hiệp",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540106",
    name: "Phường Vĩnh Lạc",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540107",
    name: "Phường Vĩnh Lợi",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540108",
    name: "Phường Vĩnh Quang",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540109",
    name: "Phường Vĩnh Thanh",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540110",
    name: "Phường Vĩnh Thanh Vân",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540111",
    name: "Phường Vĩnh Thông",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540112",
    name: "Xã Phi Thông",
    is_higher_price: false,
    district_code: "1570",
  },
  {
    code: "540201",
    name: "Phường Bình San",
    is_higher_price: false,
    district_code: "2058",
  },
  {
    code: "540202",
    name: "Phường Đông Hồ",
    is_higher_price: false,
    district_code: "2058",
  },
  {
    code: "540203",
    name: "Phường Pháo Đài",
    is_higher_price: false,
    district_code: "2058",
  },
  {
    code: "540204",
    name: "Phường Tô Châu",
    is_higher_price: false,
    district_code: "2058",
  },
  {
    code: "540205",
    name: "Xã Mỹ Đức",
    is_higher_price: false,
    district_code: "2058",
  },
  {
    code: "540206",
    name: "Xã Thuận Yên",
    is_higher_price: false,
    district_code: "2058",
  },
  {
    code: "540207",
    name: "Xã Tiên Hải",
    is_higher_price: false,
    district_code: "2058",
  },
  {
    code: "540301",
    name: "Thị trấn Kiên Lương",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540302",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540303",
    name: "Xã Bình Trị",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540304",
    name: "Xã Dương Hòa",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540305",
    name: "Xã Hòa Điền",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540306",
    name: "Xã Hòn Nghệ",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540307",
    name: "Xã Kiên Bình",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540308",
    name: "Xã Sơn Hải",
    is_higher_price: false,
    district_code: "1950",
  },
  {
    code: "540401",
    name: "Thị trấn Hòn Đất",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540402",
    name: "Thị trấn Sóc Sơn",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540403",
    name: "Xã Bình Giang",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540404",
    name: "Xã Bình Sơn",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540405",
    name: "Xã Lình Huỳnh",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540406",
    name: "Xã Mỹ Hiệp Sơn",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540407",
    name: "Xã Mỹ Lâm",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540408",
    name: "Xã Mỹ Phước",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540409",
    name: "Xã Mỹ Thái",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540410",
    name: "Xã Mỹ Thuận",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540411",
    name: "Xã Nam Thái Sơn",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540412",
    name: "Xã Sơn Bình",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540413",
    name: "Xã Sơn Kiên",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540414",
    name: "Xã Thổ Sơn",
    is_higher_price: false,
    district_code: "1830",
  },
  {
    code: "540501",
    name: "Thị trấn Tân Hiệp",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540502",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540503",
    name: "Xã Tân Hiệp A",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540504",
    name: "Xã Tân Hiệp B",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540505",
    name: "Xã Tân Hoà",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540506",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540507",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540508",
    name: "Xã Thạnh Đông",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540509",
    name: "Xã Thạnh Đông A",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540510",
    name: "Xã Thạnh Đông B",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "540511",
    name: "Xã Thạnh Trị",
    is_higher_price: false,
    district_code: "1831",
  },
  {
    code: "54061",
    name: "Thị trấn Minh Lương",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "540610",
    name: "Xã Vĩnh Hoà Phú",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54062",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54063",
    name: "Xã Giục Tượng",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54064",
    name: "Xã Minh Hòa",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54065",
    name: "Xã Mong Thọ",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54066",
    name: "Xã Mong Thọ A",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54067",
    name: "Xã Mong Thọ B",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54068",
    name: "Xã Thạnh Lộc",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "54069",
    name: "Xã Vĩnh Hòa Hiệp",
    is_higher_price: false,
    district_code: "1719",
  },
  {
    code: "540701",
    name: "Thị Trấn Giồng Riềng",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540702",
    name: "Xã  Hòa Thuận",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540703",
    name: "Xã Bàn Tân Định",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540704",
    name: "Xã Bàn Thạch",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540705",
    name: "Xã Hoà An",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540706",
    name: "Xã Hòa Hưng",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540707",
    name: "Xã Hoà Lợi",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540708",
    name: "Xã Long Thạnh",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540709",
    name: "Xã Ngọc Chúc",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540710",
    name: "Xã Ngọc Hoà",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540711",
    name: "Xã Ngọc Thành",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540712",
    name: "Xã Ngọc Thuận",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540713",
    name: "Xã Thạnh Bình",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540714",
    name: "Xã Thạnh Hòa",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540715",
    name: "Xã Thạnh Hưng",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540716",
    name: "Xã Thạnh Lộc",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540717",
    name: "Xã Thạnh Phước",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540718",
    name: "Xã Vĩnh Phú",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540719",
    name: "Xã Vĩnh Thạnh",
    is_higher_price: false,
    district_code: "1832",
  },
  {
    code: "540801",
    name: "Thị trấn Gò Quao",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540802",
    name: "Xã Định An",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540803",
    name: "Xã Định Hòa",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540804",
    name: "Xã Thới Quản",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540805",
    name: "Xã Thủy Liễu",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540806",
    name: "Xã Vĩnh Hòa Hưng Bắc",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540807",
    name: "Xã Vĩnh Hòa Hưng Nam",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540808",
    name: "Xã Vĩnh Phước A",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540809",
    name: "Xã Vĩnh Phước B",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540810",
    name: "Xã Vĩnh Thắng",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540811",
    name: "Xã Vĩnh Tuy",
    is_higher_price: false,
    district_code: "2132",
  },
  {
    code: "540901",
    name: "Thị trấn Thứ Ba",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540902",
    name: "Xã Đông Thái",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540903",
    name: "Xã Đông Yên",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540904",
    name: "Xã Hưng Yên",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540905",
    name: "Xã Nam Thái",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540906",
    name: "Xã Nam Thái A",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540907",
    name: "Xã Nam Yên",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540908",
    name: "Xã Tây Yên",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "540909",
    name: "Xã Tây Yên A",
    is_higher_price: false,
    district_code: "1833",
  },
  {
    code: "541001",
    name: "Thị trấn Thứ Mười Một",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541002",
    name: "Xã Đông Hòa",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541003",
    name: "Xã Đông Hưng",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541004",
    name: "Xã Đông Hưng A",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541005",
    name: "Xã Đông Hưng B",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541006",
    name: "Xã Đông Thạnh",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541007",
    name: "Xã Tân Thạnh",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541008",
    name: "Xã Thuận Hoà",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541009",
    name: "Xã Vân Khánh",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541010",
    name: "Xã Vân Khánh Đông",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541011",
    name: "Xã Vân Khánh Tây",
    is_higher_price: false,
    district_code: "3125",
  },
  {
    code: "541101",
    name: "Thị trấn Vĩnh Thuận",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541102",
    name: "Xã Bình Minh",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541103",
    name: "Xã Phong Đông",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541104",
    name: "Xã Tân Thuận",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541105",
    name: "Xã Vĩnh Bình Bắc",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541106",
    name: "Xã Vĩnh Bình Nam",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541107",
    name: "Xã Vĩnh Phong",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541108",
    name: "Xã Vĩnh Thuận",
    is_higher_price: false,
    district_code: "2260",
  },
  {
    code: "541201",
    name: "Thị trấn An Thới",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541202",
    name: "Thị trấn Dương Đông",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541203",
    name: "Xã Bãi Thơm",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541204",
    name: "Xã Cửa Cạn",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541205",
    name: "Xã Cửa Dương",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541206",
    name: "Xã Dương Tơ",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541207",
    name: "Xã Gành Dầu",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541208",
    name: "Xã Hàm Ninh",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541209",
    name: "Xã Hòn Thơm",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541210",
    name: "Xã Thổ Châu",
    is_higher_price: false,
    district_code: "2115",
  },
  {
    code: "541301",
    name: "Xã An Sơn",
    is_higher_price: false,
    district_code: "2113",
  },
  {
    code: "541302",
    name: "Xã Hòn Tre",
    is_higher_price: false,
    district_code: "2113",
  },
  {
    code: "541303",
    name: "Xã Lại Sơn",
    is_higher_price: false,
    district_code: "2113",
  },
  {
    code: "541304",
    name: "Xã Nam Du",
    is_higher_price: false,
    district_code: "2113",
  },
  {
    code: "541305",
    name: "Côn Đảo",
    is_higher_price: false,
    district_code: "2111",
  },
  {
    code: "541306",
    name: "Hoàng Sa",
    is_higher_price: false,
    district_code: "2112",
  },
  {
    code: "541401",
    name: "Xã An Minh Bắc",
    is_higher_price: false,
    district_code: "2251",
  },
  {
    code: "541402",
    name: "Xã Hoà Chánh",
    is_higher_price: false,
    district_code: "2251",
  },
  {
    code: "541403",
    name: "Xã Minh Thuận",
    is_higher_price: false,
    district_code: "2251",
  },
  {
    code: "541404",
    name: "Xã Thạnh Yên",
    is_higher_price: false,
    district_code: "2251",
  },
  {
    code: "541405",
    name: "Xã Thạnh Yên A",
    is_higher_price: false,
    district_code: "2251",
  },
  {
    code: "541406",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "2251",
  },
  {
    code: "541501",
    name: "Xã Phú Lợi",
    is_higher_price: false,
    district_code: "2134",
  },
  {
    code: "541502",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "2134",
  },
  {
    code: "541503",
    name: "Xã Tân Khánh Hòa",
    is_higher_price: false,
    district_code: "2134",
  },
  {
    code: "541504",
    name: "Xã Vĩnh Điều",
    is_higher_price: false,
    district_code: "2134",
  },
  {
    code: "541505",
    name: "Xã Vĩnh Phú",
    is_higher_price: false,
    district_code: "2134",
  },
  {
    code: "550101",
    name: "Phường An Bình",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550102",
    name: "Phường An Cư",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550103",
    name: "Phường An Hòa",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550104",
    name: "Phường An Hội",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550105",
    name: "Phường An Khánh",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550106",
    name: "Phường An Lạc",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550107",
    name: "Phường An Nghiệp",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550108",
    name: "Phường An Phú",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550109",
    name: "Phường Cái Khế",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550110",
    name: "Phường Hưng Lợi",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550111",
    name: "Phường Tân An",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550112",
    name: "Phường Thới Bình",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550113",
    name: "Phường Xuân Khánh",
    is_higher_price: false,
    district_code: "1572",
  },
  {
    code: "550201",
    name: "Phường An Thới",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550202",
    name: "Phường Bình Thủy",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550203",
    name: "Phường Bùi Hữu Nghĩa",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550204",
    name: "Phường Long Hòa",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550205",
    name: "Phường Long Tuyền",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550206",
    name: "Phường Thới An Đông",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550207",
    name: "Phường Trà An",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550208",
    name: "Phường Trà Nóc",
    is_higher_price: false,
    district_code: "1573",
  },
  {
    code: "550301",
    name: "Phường Ba Láng",
    is_higher_price: false,
    district_code: "1574",
  },
  {
    code: "550302",
    name: "Phường Hưng Phú",
    is_higher_price: false,
    district_code: "1574",
  },
  {
    code: "550303",
    name: "Phường Hưng Thạnh",
    is_higher_price: false,
    district_code: "1574",
  },
  {
    code: "550304",
    name: "Phường Lê Bình",
    is_higher_price: false,
    district_code: "1574",
  },
  {
    code: "550305",
    name: "Phường Phú Thứ",
    is_higher_price: false,
    district_code: "1574",
  },
  {
    code: "550306",
    name: "Phường Tân Phú",
    is_higher_price: false,
    district_code: "1574",
  },
  {
    code: "550307",
    name: "Phường Thường Thạnh",
    is_higher_price: false,
    district_code: "1574",
  },
  {
    code: "550401",
    name: "Phường Châu Văn Liêm",
    is_higher_price: false,
    district_code: "1575",
  },
  {
    code: "550402",
    name: "Phường Long Hưng",
    is_higher_price: false,
    district_code: "1575",
  },
  {
    code: "550403",
    name: "Phường Phước Thới",
    is_higher_price: false,
    district_code: "1575",
  },
  {
    code: "550404",
    name: "Phường Thới An",
    is_higher_price: false,
    district_code: "1575",
  },
  {
    code: "550405",
    name: "Phường Thới Hòa",
    is_higher_price: false,
    district_code: "1575",
  },
  {
    code: "550406",
    name: "Phường Thới Long",
    is_higher_price: false,
    district_code: "1575",
  },
  {
    code: "550407",
    name: "Phường Trường Lạc",
    is_higher_price: false,
    district_code: "1575",
  },
  {
    code: "550501",
    name: "Thị trấn Phong Điền",
    is_higher_price: false,
    district_code: "3250",
  },
  {
    code: "550502",
    name: "Xã Giai Xuân",
    is_higher_price: false,
    district_code: "3250",
  },
  {
    code: "550503",
    name: "Xã Mỹ Khánh",
    is_higher_price: false,
    district_code: "3250",
  },
  {
    code: "550504",
    name: "Xã Nhơn Ái",
    is_higher_price: false,
    district_code: "3250",
  },
  {
    code: "550505",
    name: "Xã Nhơn Nghĩa",
    is_higher_price: false,
    district_code: "3250",
  },
  {
    code: "550506",
    name: "Xã Tân Thới",
    is_higher_price: false,
    district_code: "3250",
  },
  {
    code: "550507",
    name: "Xã Trường Long",
    is_higher_price: false,
    district_code: "3250",
  },
  {
    code: "550601",
    name: "Thị trấn Cờ Đỏ",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550602",
    name: "Xã Đông Hiệp",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550603",
    name: "Xã Đông Thắng",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550604",
    name: "Xã Thạnh Phú",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550605",
    name: "Xã Thới Đông",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550606",
    name: "Xã Thới Hưng",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550607",
    name: "Xã Thới Xuân",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550608",
    name: "Xã Trung An",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550609",
    name: "Xã Trung Hưng",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "550610",
    name: "Xã Trung Thạnh",
    is_higher_price: false,
    district_code: "3150",
  },
  {
    code: "55071",
    name: "Thị trấn Thanh An",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "550710",
    name: "Xã Vĩnh Bình",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "550711",
    name: "Xã Vĩnh Trinh",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55072",
    name: "Thị trấn Vĩnh Thạnh",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55073",
    name: "Xã Thạnh An",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55074",
    name: "Xã Thạnh Lộc",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55075",
    name: "Xã Thạnh Lợi",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55076",
    name: "Xã Thạnh Mỹ",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55077",
    name: "Xã Thạnh Qưới",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55078",
    name: "Xã Thạnh Thắng",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "55079",
    name: "Xã Thạnh Tiến",
    is_higher_price: false,
    district_code: "3317",
  },
  {
    code: "550801",
    name: "Phường Tân Hưng",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550802",
    name: "Phường Tân Lộc",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550803",
    name: "Phường Thạnh Hoà",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550804",
    name: "Phường Thới Thuận",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550805",
    name: "Phường Thốt Nốt",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550806",
    name: "Phường Thuận An",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550807",
    name: "Phường Thuận Hưng",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550808",
    name: "Phường Trung Kiên",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550809",
    name: "Phường Trung Nhứt",
    is_higher_price: false,
    district_code: "1576",
  },
  {
    code: "550901",
    name: "Thị trấn Thới Lai",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550902",
    name: "Xã Định Môn",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550903",
    name: "Xã Đông Bình",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550904",
    name: "Xã Đông Thuận",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550905",
    name: "Xã Tân Thạnh",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550906",
    name: "Xã Thới Tân",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550907",
    name: "Xã Thới Thạnh",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550908",
    name: "Xã Trường Thắng",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550909",
    name: "Xã Trường Thành",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550910",
    name: "Xã Trường Xuân",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550911",
    name: "Xã Trường Xuân A",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550912",
    name: "Xã Trường Xuân B",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "550913",
    name: "Xã Xuân Thắng",
    is_higher_price: false,
    district_code: "3300",
  },
  {
    code: "560101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560106",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560107",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560108",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560109",
    name: "Phường Phú Khương",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560110",
    name: "Phường Phú Tân",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560111",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560112",
    name: "Xã Mỹ Thành",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560113",
    name: "Xã Mỹ Thạnh An",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560114",
    name: "Xã Nhơn Thạnh",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560115",
    name: "Xã Phú Hưng",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560116",
    name: "Xã Phú Nhuận",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "560117",
    name: "Xã Sơn Đông",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "56021",
    name: "Thị trấn Châu Thành",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560210",
    name: "Xã Phú Đức",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560211",
    name: "Xã Phú Túc",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560212",
    name: "Xã Phước Thạnh",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560213",
    name: "Xã Qưới Sơn",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560214",
    name: "Xã Quới Thành",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560215",
    name: "Xã Sơn Hòa",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560216",
    name: "Xã Tam Phước",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560217",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560218",
    name: "Xã Tân Thạch",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560219",
    name: "Xã Thành Triệu",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56022",
    name: "Xã An Hiệp",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560220",
    name: "Xã Tiên Long",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560221",
    name: "Xã Tiên Thủy",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560222",
    name: "Xã Tường Đa",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56023",
    name: "Xã An Hóa",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56024",
    name: "Xã An Khánh",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56025",
    name: "Xã An Phước",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56026",
    name: "Xã Giao Hòa",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56027",
    name: "Xã Giao Long",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56028",
    name: "Xã Hữu Định",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "56029",
    name: "Xã Phú An Hòa",
    is_higher_price: false,
    district_code: "1742",
  },
  {
    code: "560301",
    name: "Thị trấn Chợ Lách",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560302",
    name: "Xã Hòa Nghĩa",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560303",
    name: "Xã Hưng Khánh Trung B",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560304",
    name: "Xã Long Thới",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560305",
    name: "Xã Phú Phụng",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560306",
    name: "Xã Phú Sơn",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560307",
    name: "Xã Sơn Định",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560308",
    name: "Xã Tân Thiềng",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560309",
    name: "Xã Vĩnh Bình",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560310",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560311",
    name: "Xã Vĩnh Thành",
    is_higher_price: false,
    district_code: "3158",
  },
  {
    code: "560401",
    name: "Xã Hòa Lộc",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560402",
    name: "Xã Hưng Khánh Trung A",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560403",
    name: "Xã Khánh Thạnh Tân",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560404",
    name: "Xã Nhuận Phú Tân",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560405",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560406",
    name: "Xã Phước Mỹ Trung",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560407",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560408",
    name: "Xã Tân Phú Tây",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560409",
    name: "Xã Tân Thành Bình",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560410",
    name: "Xã Tân Thanh Tây",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560411",
    name: "Xã Thành An",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560412",
    name: "Xã Thạnh Ngãi",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560413",
    name: "Xã Thanh Tân",
    is_higher_price: false,
    district_code: "1974",
  },
  {
    code: "560501",
    name: "Thị trấn Giồng Trôm",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560502",
    name: "Xã Bình Hoà",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560503",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560504",
    name: "Xã Châu Bình",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560505",
    name: "Xã Châu Hòa",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560506",
    name: "Xã Hưng Lễ",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560507",
    name: "Xã Hưng Nhượng",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560508",
    name: "Xã Hưng Phong",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560509",
    name: "Xã Long Mỹ",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560510",
    name: "Xã Lương Hòa",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560511",
    name: "Xã Lương Phú",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560512",
    name: "Xã Lương Quới",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560513",
    name: "Xã Mỹ Thạnh",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560514",
    name: "Xã Phong Mỹ",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560515",
    name: "Xã Phong Nẫm",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560516",
    name: "Xã Phước Long",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560517",
    name: "Xã Sơn Phú",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560518",
    name: "Xã Tân Hào",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560519",
    name: "Xã Tân Lợi Thạnh",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560520",
    name: "Xã Tân Thanh",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560521",
    name: "Xã Thạnh Phú Đông",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560522",
    name: "Xã Thuận Điền",
    is_higher_price: false,
    district_code: "1937",
  },
  {
    code: "560601",
    name: "Thị trấn Bình Đại",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560602",
    name: "Xã Bình Thắng",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560603",
    name: "Xã Bình Thới",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560604",
    name: "Xã Châu Hưng",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560605",
    name: "Xã Đại Hòa Lộc",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560606",
    name: "Xã Định Trung",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560607",
    name: "Xã Lộc Thuận",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560608",
    name: "Xã Long Định",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560609",
    name: "Xã Long Hòa",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560610",
    name: "Xã Phú Long",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560611",
    name: "Xã Phú Thuận",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560612",
    name: "Xã Phú Vang",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560613",
    name: "Xã Tam Hiệp",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560614",
    name: "Xã Thạnh Phước",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560615",
    name: "Xã Thạnh Trị",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560616",
    name: "Xã Thới Lai",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560617",
    name: "Xã Thới Thuận",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560618",
    name: "Xã Thừa Đức",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560619",
    name: "Xã Vang Quới Đông",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560620",
    name: "Xã Vang Quới Tây",
    is_higher_price: false,
    district_code: "1895",
  },
  {
    code: "560701",
    name: "Thị trấn Ba Tri",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560702",
    name: "Xã An Bình Tây",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560703",
    name: "Xã An Đức",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560704",
    name: "Xã An Hiệp",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560705",
    name: "Xã An Hòa Tây",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560706",
    name: "Xã An Ngãi Tây",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560707",
    name: "Xã An Ngãi Trung",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560708",
    name: "Xã An Phú Trung",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560709",
    name: "Xã An Thủy",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560710",
    name: "Xã Bảo Thạnh",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560711",
    name: "Xã Bảo Thuận",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560712",
    name: "Xã Mỹ Chánh",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560713",
    name: "Xã Mỹ Hòa",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560714",
    name: "Xã Mỹ Nhơn",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560715",
    name: "Xã Mỹ Thạnh",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560716",
    name: "Xã Phú Lễ",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560717",
    name: "Xã Phú Ngãi",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560718",
    name: "Xã Phước Tuy",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560719",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560720",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560721",
    name: "Xã Tân Thủy",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560722",
    name: "Xã Tân Xuân",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560723",
    name: "Xã Vĩnh An",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560724",
    name: "Xã Vĩnh Hòa",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "560801",
    name: "Thị trấn Thạnh Phú",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560802",
    name: "Xã An Điền",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560803",
    name: "Xã An Nhơn",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560804",
    name: "Xã An Quy",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560805",
    name: "Xã An Thạnh",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560806",
    name: "Xã An Thuận",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560807",
    name: "Xã Bình Thạnh",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560808",
    name: "Xã Đại Điền",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560809",
    name: "Xã Giao Thạnh",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560810",
    name: "Xã Hòa Lợi",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560811",
    name: "Xã Mỹ An",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560812",
    name: "Xã Mỹ Hưng",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560813",
    name: "Xã Phú Khánh",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560814",
    name: "Xã Quới Điền",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560815",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560816",
    name: "Xã Thạnh Hải",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560817",
    name: "Xã Thạnh Phong",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560818",
    name: "Xã Thới Thạnh",
    is_higher_price: false,
    district_code: "2028",
  },
  {
    code: "560901",
    name: "Thị trấn Mỏ Cày",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560902",
    name: "Xã An Định",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560903",
    name: "Xã An Thạnh",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560904",
    name: "Xã An Thới",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560905",
    name: "Xã Bình Khánh Đông",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560906",
    name: "Xã Bình Khánh Tây",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560907",
    name: "Xã Cẩm Sơn",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560908",
    name: "Xã Đa Phước Hội",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560909",
    name: "Xã Định Thủy",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560910",
    name: "Xã Hương Mỹ",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560911",
    name: "Xã Minh Đức",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560912",
    name: "Xã Ngãi Đăng",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560913",
    name: "Xã Phước Hiệp",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560914",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560915",
    name: "Xã Tân Trung",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560916",
    name: "Xã Thành Thới A",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "560917",
    name: "Xã Thành Thới B",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "570101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570106",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570107",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570108",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570109",
    name: "Xã Tân Hội",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570110",
    name: "Xã Tân Ngãi",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570111",
    name: "Xã Trường An",
    is_higher_price: false,
    district_code: "1562",
  },
  {
    code: "570201",
    name: "Thị trấn Long Hồ",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570202",
    name: "Xã An Bình",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570203",
    name: "Xã Bình Hòa Phước",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570204",
    name: "Xã Đồng Phú",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570205",
    name: "Xã Hòa Ninh",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570206",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570207",
    name: "Xã Lộc Hòa",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570208",
    name: "Xã Long An",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570209",
    name: "Xã Long Phước",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570210",
    name: "Xã Phú Đức",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570211",
    name: "Xã Phú Quới",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570212",
    name: "Xã Phước Hậu",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570213",
    name: "Xã Tân Hạnh",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570214",
    name: "Xã Thanh Đức",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570215",
    name: "Xã Thạnh Quới",
    is_higher_price: false,
    district_code: "1962",
  },
  {
    code: "570301",
    name: "Thị trấn Cái Nhum",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570302",
    name: "Xã An Phước",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570303",
    name: "Xã Bình Phước",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570304",
    name: "Xã Chánh An",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570305",
    name: "Xã Chánh Hội",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570306",
    name: "Xã Hòa Tịnh",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570307",
    name: "Xã Long Mỹ",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570308",
    name: "Xã Mỹ An",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570309",
    name: "Xã Mỹ Phước",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570310",
    name: "Xã Nhơn Phú",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570311",
    name: "Xã Tân An Hội",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570312",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570313",
    name: "Xã Tân Long Hội",
    is_higher_price: false,
    district_code: "2164",
  },
  {
    code: "570401",
    name: "Phường Cái Vồn",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570402",
    name: "Phường Đông Thuận",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570403",
    name: "Phường Thành Phước",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570404",
    name: "Xã Đông Bình",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570405",
    name: "Xã Đông Thành",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570406",
    name: "Xã Đông Thạnh",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570407",
    name: "Xã Mỹ Hòa",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570408",
    name: "Xã Thuận An",
    is_higher_price: false,
    district_code: "2054",
  },
  {
    code: "570501",
    name: "Thị trấn Tam Bình",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570502",
    name: "Xã Bình Ninh",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570503",
    name: "Xã Hậu Lộc",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570504",
    name: "Xã Hòa Hiệp",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570505",
    name: "Xã Hoà Lộc",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570506",
    name: "Xã Hòa Thạnh",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570507",
    name: "Xã Loan Mỹ",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570508",
    name: "Xã Long Phú",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570509",
    name: "Xã Mỹ Lộc",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570510",
    name: "Xã Mỹ Thạnh Trung",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570511",
    name: "Xã Ngãi Tứ",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570512",
    name: "Xã Phú Lộc",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570513",
    name: "Xã Phú Thịnh",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570514",
    name: "Xã Song Phú",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570515",
    name: "Xã Tân Lộc",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570516",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570517",
    name: "Xã Tường Lộc",
    is_higher_price: false,
    district_code: "2008",
  },
  {
    code: "570601",
    name: "Thị trấn Trà Ôn",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570602",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570603",
    name: "Xã Hựu Thành",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570604",
    name: "Xã Lục Sỹ Thành",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570605",
    name: "Xã Nhơn Bình",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570606",
    name: "Xã Phú Thành",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570607",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570608",
    name: "Xã Thiện Mỹ",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570609",
    name: "Xã Thới Hòa",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570610",
    name: "Xã Thuận Thới",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570611",
    name: "Xã Tích Thiện",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570612",
    name: "Xã Trà Côn",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570613",
    name: "Xã Vĩnh Xuân",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570614",
    name: "Xã Xuân Hiệp",
    is_higher_price: false,
    district_code: "2034",
  },
  {
    code: "570701",
    name: "Thị trấn Vũng Liêm",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570702",
    name: "Xã Hiếu Nghĩa",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570703",
    name: "Xã Hiếu Nhơn",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570704",
    name: "Xã Hiếu Phụng",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570705",
    name: "Xã Hiếu Thành",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570706",
    name: "Xã Hiếu Thuận",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570707",
    name: "Xã Quới An",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570708",
    name: "Xã Quới Thiện",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570709",
    name: "Xã Tân An Luông",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570710",
    name: "Xã Tân Quới Trung",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570711",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570712",
    name: "Xã Trung An",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570713",
    name: "Xã Trung Chánh",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570714",
    name: "Xã Trung Hiệp",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570715",
    name: "Xã Trung Hiếu",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570716",
    name: "Xã Trung Ngãi",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570717",
    name: "Xã Trung Nghĩa",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570718",
    name: "Xã Trung Thành",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570719",
    name: "Xã Trung Thành Đông",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570720",
    name: "Xã Trung Thành Tây",
    is_higher_price: false,
    district_code: "2263",
  },
  {
    code: "570801",
    name: "Xã Mỹ Thuận",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570802",
    name: "Xã Nguyễn Văn Thảnh",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570803",
    name: "Xã Tân An Thạnh",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570804",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570805",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570806",
    name: "Xã Tân Lược",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570807",
    name: "Xã Tân Quới",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570808",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570809",
    name: "Xã Thành Đông",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570810",
    name: "Xã Thành Lợi",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "570811",
    name: "Xã Thành Trung",
    is_higher_price: false,
    district_code: "2081",
  },
  {
    code: "580101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580104",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580105",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580106",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580107",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580108",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580109",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580110",
    name: "Xã Long Đức",
    is_higher_price: false,
    district_code: "1560",
  },
  {
    code: "580201",
    name: "Thị trấn Càng Long",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580202",
    name: "Xã An Trường",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580203",
    name: "Xã An Trường A",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580204",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580205",
    name: "Xã Đại Phúc",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580206",
    name: "Xã Đại Phước",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580207",
    name: "Xã Đức Mỹ",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580208",
    name: "Xã Huyền Hội",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580209",
    name: "Xã Mỹ Cẩm",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580210",
    name: "Xã Nhị Long",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580211",
    name: "Xã Nhị Long Phú",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580212",
    name: "Xã Phương Thạnh",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580213",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580214",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "2086",
  },
  {
    code: "580301",
    name: "Thị trấn Cầu Kè",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580302",
    name: "Xã An Phú Tân",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580303",
    name: "Xã Châu Điền",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580304",
    name: "Xã Hòa Ân",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580305",
    name: "Xã Hoà Tân",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580306",
    name: "Xã Ninh Thới",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580307",
    name: "Xã Phong Phú",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580308",
    name: "Xã Phong Thạnh",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580309",
    name: "Xã Tam Ngãi",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580310",
    name: "Xã Thạnh Phú",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580311",
    name: "Xã Thông Hòa",
    is_higher_price: false,
    district_code: "2091",
  },
  {
    code: "580401",
    name: "Thị trấn Cầu Quan",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580402",
    name: "Thị trấn Tiểu Cần",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580403",
    name: "Xã Hiếu Trung",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580404",
    name: "Xã Hiếu Tử",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580405",
    name: "Xã Hùng Hòa",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580406",
    name: "Xã Long Thới",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580407",
    name: "Xã Ngãi Hùng",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580408",
    name: "Xã Phú Cần",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580409",
    name: "Xã Tân Hòa",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580410",
    name: "Xã Tân Hùng",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "580411",
    name: "Xã Tập Ngãi",
    is_higher_price: false,
    district_code: "2020",
  },
  {
    code: "58051",
    name: "Thị trấn Châu Thành",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "580510",
    name: "Xã Mỹ Chánh",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "580511",
    name: "Xã Nguyệt Hóa",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "580512",
    name: "Xã Phước Hảo",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "580513",
    name: "Xã Song Lộc",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "580514",
    name: "Xã Thanh Mỹ",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58052",
    name: "Xã Đa Lộc",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58053",
    name: "Xã Hòa Lợi",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58054",
    name: "Xã Hòa Minh",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58055",
    name: "Xã Hòa Thuận",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58056",
    name: "Xã Hưng Mỹ",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58057",
    name: "Xã Long Hòa",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58058",
    name: "Xã Lương Hòa",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "58059",
    name: "Xã Lương Hoà A",
    is_higher_price: false,
    district_code: "1911",
  },
  {
    code: "580601",
    name: "Thị trấn Định An",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580602",
    name: "Thị trấn Trà Cú",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580603",
    name: "Xã An Quảng Hữu",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580604",
    name: "Xã Đại An",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580605",
    name: "Xã Định An",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580606",
    name: "Xã Hàm Giang",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580607",
    name: "Xã Hàm Tân",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580608",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580609",
    name: "Xã Long Hiệp",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580610",
    name: "Xã Lưu Nghiệp Anh",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580611",
    name: "Xã Ngãi Xuyên",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580612",
    name: "Xã Ngọc Biên",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580613",
    name: "Xã Phước Hưng",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580614",
    name: "Xã Tân Hiệp",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580615",
    name: "Xã Tân Sơn",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580616",
    name: "Xã Tập Sơn",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580617",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "2033",
  },
  {
    code: "580701",
    name: "Thị trấn Cầu Ngang",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580702",
    name: "Thị trấn Mỹ Long",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580703",
    name: "Xã Hiệp Hòa",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580704",
    name: "Xã Hiệp Mỹ Đông",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580705",
    name: "Xã Hiệp Mỹ Tây",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580706",
    name: "Xã Kim Hòa",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580707",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580708",
    name: "Xã Mỹ Hòa",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580709",
    name: "Xã Mỹ Long Bắc",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580710",
    name: "Xã Mỹ Long Nam",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580711",
    name: "Xã Nhị Trường",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580712",
    name: "Xã Thạnh Hòa Sơn",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580713",
    name: "Xã Thuận Hòa",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580714",
    name: "Xã Trường Thọ",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580715",
    name: "Xã Vĩnh Kim",
    is_higher_price: false,
    district_code: "1908",
  },
  {
    code: "580801",
    name: "Thị trấn Long Thành",
    is_higher_price: false,
    district_code: "2103",
  },
  {
    code: "580802",
    name: "Xã Đôn Châu",
    is_higher_price: false,
    district_code: "2103",
  },
  {
    code: "580803",
    name: "Xã Đôn Xuân",
    is_higher_price: false,
    district_code: "2103",
  },
  {
    code: "580804",
    name: "Xã Đông Hải",
    is_higher_price: false,
    district_code: "2103",
  },
  {
    code: "580805",
    name: "Xã Long Khánh",
    is_higher_price: false,
    district_code: "2103",
  },
  {
    code: "580806",
    name: "Xã Long Vĩnh",
    is_higher_price: false,
    district_code: "2103",
  },
  {
    code: "580807",
    name: "Xã Ngũ Lạc",
    is_higher_price: false,
    district_code: "2103",
  },
  {
    code: "580901",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "3443",
  },
  {
    code: "580902",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "3443",
  },
  {
    code: "580903",
    name: "Xã Dân Thành",
    is_higher_price: false,
    district_code: "3443",
  },
  {
    code: "580904",
    name: "Xã Hiệp Thạnh",
    is_higher_price: false,
    district_code: "3443",
  },
  {
    code: "580905",
    name: "Xã Long Hữu",
    is_higher_price: false,
    district_code: "3443",
  },
  {
    code: "580906",
    name: "Xã Long Toàn",
    is_higher_price: false,
    district_code: "3443",
  },
  {
    code: "580907",
    name: "Xã Trường Long Hòa",
    is_higher_price: false,
    district_code: "3443",
  },
  {
    code: "590101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590102",
    name: "Phường 10",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590103",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590104",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590105",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590106",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590107",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590108",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590109",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590110",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1568",
  },
  {
    code: "590201",
    name: "Thị trấn An Lạc Thôn",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590202",
    name: "Thị trấn Kế Sách",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590203",
    name: "Xã An Lạc Tây",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590204",
    name: "Xã An Mỹ",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590205",
    name: "Xã Ba Trinh",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590206",
    name: "Xã Đại Hải",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590207",
    name: "Xã Kế An",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590208",
    name: "Xã Kế Thành",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590209",
    name: "Xã Nhơn Mỹ",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590210",
    name: "Xã Phong Nẫm",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590211",
    name: "Xã Thới An Hội",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590212",
    name: "Xã Trinh Phú",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590213",
    name: "Xã Xuân Hòa",
    is_higher_price: false,
    district_code: "1949",
  },
  {
    code: "590301",
    name: "Thị trấn Huỳnh Hữu Nghĩa",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590302",
    name: "Xã Hưng Phú",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590303",
    name: "Xã Long Hưng",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590304",
    name: "Xã Mỹ Hương",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590305",
    name: "Xã Mỹ Phước",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590306",
    name: "Xã Mỹ Thuận",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590307",
    name: "Xã Mỹ Tú",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590308",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590309",
    name: "Xã Thuận Hưng",
    is_higher_price: false,
    district_code: "2173",
  },
  {
    code: "590401",
    name: "Thị trấn Mỹ Xuyên",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590402",
    name: "Xã Đại Tâm",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590403",
    name: "Xã Gia Hòa 1",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590404",
    name: "Xã Gia Hòa 2",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590405",
    name: "Xã Hòa Tú 1",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590406",
    name: "Xã Hòa Tú II",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590407",
    name: "Xã Ngọc Đông",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590408",
    name: "Xã Ngọc Tố",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590409",
    name: "Xã Tham Đôn",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590410",
    name: "Xã Thạnh Phú",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590411",
    name: "Xã Thạnh Quới",
    is_higher_price: false,
    district_code: "1743",
  },
  {
    code: "590501",
    name: "Thị trấn Hưng Lợi",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590502",
    name: "Thị trấn Phú Lộc",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590503",
    name: "Xã Châu Hưng",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590504",
    name: "Xã Lâm Kiết",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590505",
    name: "Xã Lâm Tân",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590506",
    name: "Xã Thạnh Tân",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590507",
    name: "Xã Thạnh Trị",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590508",
    name: "Xã Tuân Tức",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590509",
    name: "Xã Vĩnh Lợi",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590510",
    name: "Xã Vĩnh Thành",
    is_higher_price: false,
    district_code: "2238",
  },
  {
    code: "590601",
    name: "Thị trấn Đại Ngãi",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590602",
    name: "Thị trấn Long Phú",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590603",
    name: "Xã Châu Khánh",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590604",
    name: "Xã Hậu Thạnh",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590605",
    name: "Xã Long Đức",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590606",
    name: "Xã Long Phú",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590607",
    name: "Xã Phú Hữu",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590608",
    name: "Xã Song Phụng",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590609",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590610",
    name: "Xã Tân Thạnh",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590611",
    name: "Xã Trường Khánh",
    is_higher_price: false,
    district_code: "2161",
  },
  {
    code: "590701",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590702",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590703",
    name: "Phường Khánh Hòa",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590704",
    name: "Phường Vĩnh Phước",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590705",
    name: "Xã Hòa Đông",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590706",
    name: "Xã Lạc Hòa",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590707",
    name: "Xã Lai Hòa",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590708",
    name: "Xã Vĩnh Hải",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590709",
    name: "Xã Vĩnh Hiệp",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590710",
    name: "Xã Vĩnh Tân",
    is_higher_price: false,
    district_code: "2272",
  },
  {
    code: "590801",
    name: "Thị trấn Cù Lao Dung",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590802",
    name: "Xã An Thạnh 1",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590803",
    name: "Xã An Thạnh 2",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590804",
    name: "Xã An Thạnh 3",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590805",
    name: "Xã An Thạnh Đông",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590806",
    name: "Xã An Thạnh Nam",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590807",
    name: "Xã An Thạnh Tây",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590808",
    name: "Xã Đại Ân 1",
    is_higher_price: false,
    district_code: "2093",
  },
  {
    code: "590901",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "590902",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "590903",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "590904",
    name: "Xã Long Bình",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "590905",
    name: "Xã Mỹ Bình",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "590906",
    name: "Xã Mỹ Quới",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "590907",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "590908",
    name: "Xã Vĩnh Quới",
    is_higher_price: false,
    district_code: "2062",
  },
  {
    code: "59101",
    name: "Thị trấn Châu Thành",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "59102",
    name: "Xã An Hiệp",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "59103",
    name: "Xã An Ninh",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "59104",
    name: "Xã Hồ Đắc Kiện",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "59105",
    name: "Xã Phú Tâm",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "59106",
    name: "Xã Phú Tân",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "59107",
    name: "Xã Thiện Mỹ",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "59108",
    name: "Xã Thuận Hòa",
    is_higher_price: false,
    district_code: "1910",
  },
  {
    code: "591101",
    name: "Thị trấn Lịch Hội Thượng",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591102",
    name: "Thị trấn Trần Đề",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591103",
    name: "Xã Đại Ân  2",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591104",
    name: "Xã Lịch Hội Thượng",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591105",
    name: "Xã Liêu Tú",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591106",
    name: "Xã Tài Văn",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591107",
    name: "Xã Thạnh Thới An",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591108",
    name: "Xã Thạnh Thới Thuận",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591109",
    name: "Xã Trung Bình",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591110",
    name: "Xã Viên An",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "591111",
    name: "Xã Viên Bình",
    is_higher_price: false,
    district_code: "2037",
  },
  {
    code: "600101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600103",
    name: "Phường 3",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600104",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600105",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600106",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600107",
    name: "Phường Nhà Mát",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600108",
    name: "Xã Hiệp Thành",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600109",
    name: "Xã Vĩnh Trạch",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600110",
    name: "Xã Vĩnh Trạch Đông",
    is_higher_price: false,
    district_code: "1655",
  },
  {
    code: "600201",
    name: "Thị trấn Châu Hưng",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600202",
    name: "Xã Châu Hưng A",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600203",
    name: "Xã Châu Thới",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600204",
    name: "Xã Hưng Hội",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600205",
    name: "Xã Hưng Thành",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600206",
    name: "Xã Long Thạnh",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600207",
    name: "Xã Vĩnh Hưng",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600208",
    name: "Xã Vĩnh Hưng A",
    is_higher_price: false,
    district_code: "2050",
  },
  {
    code: "600301",
    name: "Thị trấn Ngan Dừa",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600302",
    name: "Xã Lộc Ninh",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600303",
    name: "Xã Ninh Hòa",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600304",
    name: "Xã Ninh Quới",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600305",
    name: "Xã Ninh Quới A",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600306",
    name: "Xã Ninh Thạnh Lợi",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600307",
    name: "Xã Ninh Thạnh Lợi A",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600308",
    name: "Xã Vĩnh Lộc",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600309",
    name: "Xã Vĩnh Lộc A",
    is_higher_price: false,
    district_code: "1946",
  },
  {
    code: "600401",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600402",
    name: "Phường Hộ Phòng",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600403",
    name: "Phường Láng Tròn",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600404",
    name: "Xã Phong Tân",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600405",
    name: "Xã Phong Thạnh",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600406",
    name: "Xã Phong Thạnh A",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600407",
    name: "Xã Phong Thạnh Đông",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600408",
    name: "Xã Phong Thạnh Tây",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600409",
    name: "Xã Tân Phong",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600410",
    name: "Xã Tân Thạnh",
    is_higher_price: false,
    district_code: "1935",
  },
  {
    code: "600501",
    name: "Thị trấn Phước Long",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600502",
    name: "Xã Hưng Phú",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600503",
    name: "Xã Phong Thạnh Tây A",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600504",
    name: "Xã Phong Thạnh Tây B",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600505",
    name: "Xã Phước Long",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600506",
    name: "Xã Vĩnh Phú Đông",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600507",
    name: "Xã Vĩnh Phú Tây",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600508",
    name: "Xã Vĩnh Thanh",
    is_higher_price: false,
    district_code: "1998",
  },
  {
    code: "600601",
    name: "Thị trấn Gành Hào",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600602",
    name: "Xã An Phúc",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600603",
    name: "Xã An Trạch",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600604",
    name: "Xã An Trạch A",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600605",
    name: "Xã Điền Hải",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600606",
    name: "Xã Định Thành",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600607",
    name: "Xã Định Thành A",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600608",
    name: "Xã Long Điền",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600609",
    name: "Xã Long Điền Đông",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600610",
    name: "Xã Long Điền Đông A",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600611",
    name: "Xã Long Điền Tây",
    is_higher_price: false,
    district_code: "1926",
  },
  {
    code: "600701",
    name: "Thị trấn Hòa Bình",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "600702",
    name: "Xã Minh Diệu",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "600703",
    name: "Xã Vĩnh Bình",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "600704",
    name: "Xã Vĩnh Hậu",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "600705",
    name: "Xã Vĩnh Hậu A",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "600706",
    name: "Xã Vĩnh Mỹ A",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "600707",
    name: "Xã Vĩnh Mỹ B",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "600708",
    name: "Xã Vĩnh Thịnh",
    is_higher_price: false,
    district_code: "1723",
  },
  {
    code: "60101",
    name: "Phường Đề Thám",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60102",
    name: "Phường Duyệt Trung",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60103",
    name: "Phường Hoà Chung",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60104",
    name: "Phường Hợp Giang",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60105",
    name: "Phường Ngọc Xuân",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60106",
    name: "Phường Sông Bằng",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60107",
    name: "Phường Sông Hiến",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60108",
    name: "Phường Tân Giang",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60109",
    name: "Xã Chu Trinh",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60110",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60111",
    name: "Xã Vĩnh Quang",
    is_higher_price: false,
    district_code: "1641",
  },
  {
    code: "60201",
    name: "Thị trấn Bảo Lạc",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60202",
    name: "Xã Bảo Toàn",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60203",
    name: "Xã Cô Ba",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60204",
    name: "Xã Cốc Pàng",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60205",
    name: "Xã Đình Phùng",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60206",
    name: "Xã Hồng An",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60207",
    name: "Xã Hồng Trị",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60208",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60209",
    name: "Xã Hưng Thịnh",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60210",
    name: "Xã Huy Giáp",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60211",
    name: "Xã Khánh Xuân",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60212",
    name: "Xã Kim Cúc",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60213",
    name: "Xã Phan Thanh",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60214",
    name: "Xã Sơn Lập",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60215",
    name: "Xã Sơn Lộ",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60216",
    name: "Xã Thượng Hà",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60217",
    name: "Xã Xuân Trường",
    is_higher_price: false,
    district_code: "3130",
  },
  {
    code: "60301",
    name: "Thị trấn Thông Nông",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60302",
    name: "Xã Bình Lãng",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60303",
    name: "Xã Cần Nông",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60304",
    name: "Xã Cần Yên",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60305",
    name: "Xã Đa Thông",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60306",
    name: "Xã Lương Can",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60307",
    name: "Xã Lương Thông",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60308",
    name: "Xã Ngọc Động",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60309",
    name: "Xã Thanh Long",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60310",
    name: "Xã Vị Quang",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60311",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "3299",
  },
  {
    code: "60401",
    name: "Thị trấn Xuân Hòa",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60402",
    name: "Xã Cải Viên",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60403",
    name: "Xã Đào Ngạn",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60404",
    name: "Xã Hạ Thôn",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60405",
    name: "Xã Hồng Sĩ",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60406",
    name: "Xã Kéo Yên",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60407",
    name: "Xã Lũng Nặm",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60408",
    name: "Xã Mã Ba",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60409",
    name: "Xã Nà Sác",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60410",
    name: "Xã Nội Thôn",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60411",
    name: "Xã Phù Ngọc",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60412",
    name: "Xã Quý Quân",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60413",
    name: "Xã Sĩ Hai",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60414",
    name: "Xã Sóc Hà",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60415",
    name: "Xã Thượng Thôn",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60416",
    name: "Xã Tổng Cọt",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60417",
    name: "Xã Trường Hà",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60418",
    name: "Xã Vân An",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60419",
    name: "Xã Vần Dính",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "60501",
    name: "Thị trấn Hùng Quốc",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60502",
    name: "Xã Cao Chương",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60503",
    name: "Xã Cô Mười",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60504",
    name: "Xã Lưu Ngọc",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60505",
    name: "Xã Quang Hán",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60506",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60507",
    name: "Xã Quang Vinh",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60508",
    name: "Xã Quốc Toản",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60509",
    name: "Xã Tri Phương",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60510",
    name: "Xã Xuân Nội",
    is_higher_price: false,
    district_code: "3305",
  },
  {
    code: "60601",
    name: "Thị trấn Trùng Khánh",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60602",
    name: "Xã Cảnh Tiên",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60603",
    name: "Xã Cao Thăng",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60604",
    name: "Xã Chí Viễn",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60605",
    name: "Xã Đàm Thuỷ",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60606",
    name: "Xã Đình Minh",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60607",
    name: "Xã Đình Phong",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60608",
    name: "Xã Đoài Côn",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60609",
    name: "Xã Đức Hồng",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60610",
    name: "Xã Khâm Thành",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60611",
    name: "Xã Lăng Hiếu",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60612",
    name: "Xã Lăng Yên",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60613",
    name: "Xã Ngọc Chung",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60614",
    name: "Xã Ngọc Côn",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60615",
    name: "Xã Ngọc Khê",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60616",
    name: "Xã Phong Châu",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60617",
    name: "Xã Phong Nậm",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60618",
    name: "Xã Thân Giáp",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60619",
    name: "Xã Thông Hoè",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60620",
    name: "Xã Trung Phúc",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "60701",
    name: "Thị trấn Nguyên Bình",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60702",
    name: "Thị trấn Tĩnh Túc",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60703",
    name: "Xã Bắc Hợp",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60704",
    name: "Xã Ca Thành",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60705",
    name: "Xã Hoa Thám",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60706",
    name: "Xã Hưng Đạo",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60707",
    name: "Xã Lang Môn",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60708",
    name: "Xã Mai Long",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60709",
    name: "Xã Minh Tâm",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60710",
    name: "Xã Minh Thanh",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60711",
    name: "Xã Phan Thanh",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60712",
    name: "Xã Quang Thành",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60713",
    name: "Xã Tam Kim",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60714",
    name: "Xã Thái Học",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60715",
    name: "Xã Thành Công",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60716",
    name: "Xã Thể Dục",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60717",
    name: "Xã Thịnh Vượng",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60718",
    name: "Xã Triệu Nguyên",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60719",
    name: "Xã Vũ Nông",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60720",
    name: "Xã Yên Lạc",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "60801",
    name: "Thị trấn Nước Hai",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60802",
    name: "Xã Bạch Đằng",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60803",
    name: "Xã Bế Triều",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60804",
    name: "Xã Bình Dương",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60805",
    name: "Xã Bình Long",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60806",
    name: "Xã Công Trừng",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60807",
    name: "Xã Đại Tiến",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60808",
    name: "Xã Dân Chủ",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60809",
    name: "Xã Đức Long",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60810",
    name: "Xã Đức Xuân",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60811",
    name: "Xã Hà Trì",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60812",
    name: "Xã Hoàng Tung",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60813",
    name: "Xã Hồng Nam",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60814",
    name: "Xã Hồng Việt",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60815",
    name: "Xã Lê Chung",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60816",
    name: "Xã Nam Tuấn",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60817",
    name: "Xã Ngũ Lão",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60818",
    name: "Xã Nguyễn Huệ",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60819",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60820",
    name: "Xã Trương Lương",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60821",
    name: "Xã Trương Vương",
    is_higher_price: false,
    district_code: "1943",
  },
  {
    code: "60901",
    name: "Thị trấn Quảng Uyên",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60902",
    name: "Xã Bình Lăng",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60903",
    name: "Xã Cai Bộ",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60904",
    name: "Xã Chí Thảo",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60905",
    name: "Xã Đoài Khôn",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60906",
    name: "Xã Độc Lập",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60907",
    name: "Xã Hạnh Phúc",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60908",
    name: "Xã Hoàng Hải",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60909",
    name: "Xã Hồng Định",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60910",
    name: "Xã Hồng Quang",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60911",
    name: "Xã Ngọc Động",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60912",
    name: "Xã Phi Hải",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60913",
    name: "Xã Phúc Sen",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60914",
    name: "Xã Quảng Hưng",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60915",
    name: "Xã Quốc Dân",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60916",
    name: "Xã Quốc Phong",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "60917",
    name: "Xã Tự Do",
    is_higher_price: false,
    district_code: "3259",
  },
  {
    code: "61001",
    name: "Thị trấn Đông Khê",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61002",
    name: "Xã Canh Tân",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61003",
    name: "Xã Danh Sỹ",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61004",
    name: "Xã Đức Long",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61005",
    name: "Xã Đức Thông",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61006",
    name: "Xã Đức Xuân",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61007",
    name: "Xã Kim Đồng",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61008",
    name: "Xã Lê Lai",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61009",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61010",
    name: "Xã Minh Khai",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "610101",
    name: "Phường 1",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610102",
    name: "Phường 2",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610103",
    name: "Phường 4",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610104",
    name: "Phường 5",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610105",
    name: "Phường 6",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610106",
    name: "Phường 7",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610107",
    name: "Phường 8",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610108",
    name: "Phường 9",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610109",
    name: "Phường Tân Thành",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "61011",
    name: "Xã Quang Trọng",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "610110",
    name: "Phường Tân Xuyên",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610111",
    name: "Xã An Xuyên",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610112",
    name: "Xã Định Bình",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610113",
    name: "Xã Hòa Tân",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610114",
    name: "Xã Hòa Thành",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610115",
    name: "Xã Lý Văn Lâm",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610116",
    name: "Xã Tắc Vân",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "610117",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1654",
  },
  {
    code: "61012",
    name: "Xã Thái Cường",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61013",
    name: "Xã Thị Ngân",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61014",
    name: "Xã Thụy Hùng",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61015",
    name: "Xã Trọng Con",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "61016",
    name: "Xã Vân Trình",
    is_higher_price: false,
    district_code: "3289",
  },
  {
    code: "610201",
    name: "Thị trấn Thới Bình",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610202",
    name: "Xã Biển Bạch",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610203",
    name: "Xã Biển Bạch Đông",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610204",
    name: "Xã Hồ Thị Kỷ",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610205",
    name: "Xã Tân Bằng",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610206",
    name: "Xã Tân Lộc",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610207",
    name: "Xã Tân Lộc Bắc",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610208",
    name: "Xã Tân Lộc Đông",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610209",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610210",
    name: "Xã Thới Bình",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610211",
    name: "Xã Trí Lực",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610212",
    name: "Xã Trí Phải",
    is_higher_price: false,
    district_code: "1782",
  },
  {
    code: "610301",
    name: "Thị trấn U Minh",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610302",
    name: "Xã Khánh An",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610303",
    name: "Xã Khánh Hòa",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610304",
    name: "Xã Khánh Hội",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610305",
    name: "Xã Khánh Lâm",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610306",
    name: "Xã Khánh Thuận",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610307",
    name: "Xã Khánh Tiến",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610308",
    name: "Xã Nguyễn Phích",
    is_higher_price: false,
    district_code: "2042",
  },
  {
    code: "610401",
    name: "Thị trấn Sông Đốc",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610402",
    name: "Thị trấn Trần Văn Thời",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610403",
    name: "Xã Khánh Bình",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610404",
    name: "Xã Khánh Bình Đông",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610405",
    name: "Xã Khánh Bình Tây",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610406",
    name: "Xã Khánh Bình Tây Bắc",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610407",
    name: "Xã Khánh Hải",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610408",
    name: "Xã Khánh Hưng",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610409",
    name: "Xã Khánh Lộc",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610410",
    name: "Xã Lợi An",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610411",
    name: "Xã Phong Điền",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610412",
    name: "Xã Phong Lạc",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610413",
    name: "Xã Trần Hợi",
    is_higher_price: false,
    district_code: "2038",
  },
  {
    code: "610501",
    name: "Thị trấn Cái Nước",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610502",
    name: "Xã Đông Hưng",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610503",
    name: "Xã Đông Thới",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610504",
    name: "Xã Hoà Mỹ",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610505",
    name: "Xã Hưng Mỹ",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610506",
    name: "Xã Lương Thế Trân",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610507",
    name: "Xã Phú Hưng",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610508",
    name: "Xã Tân Hưng",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610509",
    name: "Xã Tân Hưng Đông",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610510",
    name: "Xã Thạnh Phú",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610511",
    name: "Xã Trần Thới",
    is_higher_price: false,
    district_code: "1901",
  },
  {
    code: "610601",
    name: "Thị trấn Đầm Dơi",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610602",
    name: "Xã Ngọc Chánh",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610603",
    name: "Xã Nguyễn Huân",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610604",
    name: "Xã Quách Phẩm",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610605",
    name: "Xã Quách Phẩm Bắc",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610606",
    name: "Xã Tạ An Khương",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610607",
    name: "Xã Tạ An Khương  Đông",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610608",
    name: "Xã Tạ An Khương  Nam",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610609",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610610",
    name: "Xã Tân Đức",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610611",
    name: "Xã Tân Duyệt",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610612",
    name: "Xã Tân Thuận",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610613",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610614",
    name: "Xã Tân Trung",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610615",
    name: "Xã Thanh Tùng",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610616",
    name: "Xã Trần Phán",
    is_higher_price: false,
    district_code: "1922",
  },
  {
    code: "610701",
    name: "Thị trấn Rạch Gốc",
    is_higher_price: false,
    district_code: "2186",
  },
  {
    code: "610702",
    name: "Xã Đất Mũi",
    is_higher_price: false,
    district_code: "2186",
  },
  {
    code: "610703",
    name: "Xã Tam Giang Tây",
    is_higher_price: false,
    district_code: "2186",
  },
  {
    code: "610704",
    name: "Xã Tân Ân",
    is_higher_price: false,
    district_code: "2186",
  },
  {
    code: "610705",
    name: "Xã Tân Ân Tây",
    is_higher_price: false,
    district_code: "2186",
  },
  {
    code: "610706",
    name: "Xã Viên An",
    is_higher_price: false,
    district_code: "2186",
  },
  {
    code: "610707",
    name: "Xã Viên An Đông",
    is_higher_price: false,
    district_code: "2186",
  },
  {
    code: "610801",
    name: "Thị Trấn Năm Căn",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "610802",
    name: "Xã Đất Mới",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "610803",
    name: "Xã Hàm Rồng",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "610804",
    name: "Xã Hàng Vịnh",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "610805",
    name: "Xã Hiệp Tùng",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "610806",
    name: "Xã Lâm Hải",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "610807",
    name: "Xã Tam Giang",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "610808",
    name: "Xã Tam Giang Đông",
    is_higher_price: false,
    district_code: "1783",
  },
  {
    code: "61091",
    name: "Thị trấn Cái Đôi Vàm",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61092",
    name: "Xã Phú Mỹ",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61093",
    name: "Xã Phú Tân",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61094",
    name: "Xã Phú Thuận",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61095",
    name: "Xã Rạch Chèo",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61096",
    name: "Xã Tân Hải",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61097",
    name: "Xã Tân Hưng Tây",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61098",
    name: "Xã Việt Khái",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61099",
    name: "Xã Việt Thắng",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "61101",
    name: "Thị trấn Thanh Nhật",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61102",
    name: "Xã An Lạc",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61103",
    name: "Xã Cô Ngân",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61104",
    name: "Xã Đồng Loan",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61105",
    name: "Xã Đức Quang",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61106",
    name: "Xã Kim Loan",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61107",
    name: "Xã Lý Quốc",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61108",
    name: "Xã Minh Long",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61109",
    name: "Xã Quang Long",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61110",
    name: "Xã Thái Đức",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61111",
    name: "Xã Thắng Lợi",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61112",
    name: "Xã Thị Hoa",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61113",
    name: "Xã Việt Chu",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61114",
    name: "Xã Vinh Quý",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "61201",
    name: "Thị trấn Pác Miầu",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61202",
    name: "Xã Đức Hạnh",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61203",
    name: "Xã Lý Bôn",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61204",
    name: "Xã Mông Ân",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61205",
    name: "Xã Nam Cao",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61206",
    name: "Xã Nam Quang",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61207",
    name: "Xã Quảng Lâm",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61208",
    name: "Xã Tân Việt",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61209",
    name: "Xã Thạch Lâm",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61210",
    name: "Xã Thái Học",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61211",
    name: "Xã Thái Sơn",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61212",
    name: "Xã Vĩnh Phong",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61213",
    name: "Xã Vĩnh Quang",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61214",
    name: "Xã Yên Thổ",
    is_higher_price: false,
    district_code: "1890",
  },
  {
    code: "61301",
    name: "Thị trấn Hoà Thuận",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61302",
    name: "Thị trấn Tà Lùng",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61303",
    name: "Xã Cách Linh",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61304",
    name: "Xã Đại Sơn",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61305",
    name: "Xã Hồng Đại",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61306",
    name: "Xã Lương Thiện",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61307",
    name: "Xã Mỹ Hưng",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61308",
    name: "Xã Tiên Thành",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "61309",
    name: "Xã Triệu ẩu",
    is_higher_price: false,
    district_code: "1997",
  },
  {
    code: "620101",
    name: "Phường Him Lam",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620102",
    name: "Phường Mường Thanh",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620103",
    name: "Phường Nam Thanh",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620104",
    name: "Phường Noong Bua",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620105",
    name: "Phường Tân Thanh",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620106",
    name: "Phường Thanh Bình",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620107",
    name: "Phường Thanh Trường",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620108",
    name: "Xã Tà Lèng",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620109",
    name: "Xã Thanh Minh",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "620201",
    name: "Phường Na Lay",
    is_higher_price: false,
    district_code: "2060",
  },
  {
    code: "620202",
    name: "Phường Sông Đà",
    is_higher_price: false,
    district_code: "2060",
  },
  {
    code: "620203",
    name: "Xã Lay Nưa",
    is_higher_price: false,
    district_code: "2060",
  },
  {
    code: "620301",
    name: "Xã Hẹ Muông",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620302",
    name: "Xã Hua Thanh",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620303",
    name: "Xã Mường Lói",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620304",
    name: "Xã Mường Nhà",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620305",
    name: "Xã Mường Phăng",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620306",
    name: "Xã Mường Pồn",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620307",
    name: "Xã Nà Nhạn",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620308",
    name: "Xã Nà Tấu",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620309",
    name: "Xã Na Tông",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620310",
    name: "Xã Na Ư",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620311",
    name: "Xã Noọng Hẹt",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620312",
    name: "Xã Noong Luống",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620313",
    name: "Xã Núa Ngam",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620314",
    name: "Xã Pá Khoang",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620315",
    name: "Xã Pa Thơm",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620316",
    name: "Xã Phu Luông",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620317",
    name: "Xã Pom Lót",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620318",
    name: "Xã Sam Mứn",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620319",
    name: "Xã Thanh An",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620320",
    name: "Xã Thanh Chăn",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620321",
    name: "Xã Thanh Hưng",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620322",
    name: "Xã Thanh Luông",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620323",
    name: "Xã Thanh Nưa",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620324",
    name: "Xã Thanh Xương",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620325",
    name: "Xã Thanh Yên",
    is_higher_price: false,
    district_code: "1923",
  },
  {
    code: "620401",
    name: "Thị trấn Tuần Giáo",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620402",
    name: "Xã Chiềng Đông",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620403",
    name: "Xã Chiềng Sinh",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620404",
    name: "Xã Mùn Chung",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620405",
    name: "Xã Mường Khong",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620406",
    name: "Xã Mường Mùn",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620407",
    name: "Xã Mường Thín",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620408",
    name: "Xã Nà Sáy",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620409",
    name: "Xã Nà Tòng",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620410",
    name: "Xã Phình Sáng",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620411",
    name: "Xã Pú Nhung",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620412",
    name: "Xã Pú Xi",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620413",
    name: "Xã Quài Cang",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620414",
    name: "Xã Quài Nưa",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620415",
    name: "Xã Quài Tở",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620416",
    name: "Xã Rạng Đông",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620417",
    name: "Xã Ta Ma",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620418",
    name: "Xã Tênh Phông",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620419",
    name: "Xã Tỏa Tình",
    is_higher_price: false,
    district_code: "2022",
  },
  {
    code: "620501",
    name: "Thị Trấn Mường Chà",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620502",
    name: "Xã Hừa Ngài",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620503",
    name: "Xã Huổi Lèng",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620504",
    name: "Xã Huổi Mí",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620505",
    name: "Xã Ma Thì Hồ",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620506",
    name: "Xã Mường Mươn",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620507",
    name: "Xã Mường Tùng",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620508",
    name: "Xã Na Sang",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620509",
    name: "Xã Nậm Nèn",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620510",
    name: "Xã Pa Ham",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620511",
    name: "Xã Sa Lông",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620512",
    name: "Xã Xá Tổng",
    is_higher_price: false,
    district_code: "1978",
  },
  {
    code: "620601",
    name: "Thị trấn Tủa Chùa",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620602",
    name: "Xã Huổi Só",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620603",
    name: "Xã Lao Xả Phình",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620604",
    name: "Xã Mường Báng",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620605",
    name: "Xã Mường Đun",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620606",
    name: "Xã Sáng Nhè",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620607",
    name: "Xã Sính Phình",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620608",
    name: "Xã Tả Phìn",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620609",
    name: "Xã Tả Sìn Thàng",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620610",
    name: "Xã Trung Thu",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620611",
    name: "Xã Tủa Thàng",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620612",
    name: "Xã Xín Chải",
    is_higher_price: false,
    district_code: "2021",
  },
  {
    code: "620701",
    name: "Thị trấn Điện Biên Đông",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620702",
    name: "Xã Chiềng Sơ",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620703",
    name: "Xã Háng Lìa",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620704",
    name: "Xã Keo Lôm",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620705",
    name: "Xã Luân Giới",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620706",
    name: "Xã Mường Luân",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620707",
    name: "Xã Na Son",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620708",
    name: "Xã Nong U",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620709",
    name: "Xã Phì Nhừ",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620710",
    name: "Xã Phình Giàng",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620711",
    name: "Xã Pú Hồng",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620712",
    name: "Xã Pú Nhi",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620713",
    name: "Xã Tìa Dình",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620714",
    name: "Xã Xa Dung",
    is_higher_price: false,
    district_code: "2123",
  },
  {
    code: "620801",
    name: "Xã Chung Chải",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620802",
    name: "Xã Huổi Lếnh",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620803",
    name: "Xã Leng Su Sìn",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620804",
    name: "Xã Mường Nhé",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620805",
    name: "Xã Mường Toong",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620806",
    name: "Xã Nậm Kè",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620807",
    name: "Xã Nậm Vì",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620808",
    name: "Xã Pá Mỳ",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620809",
    name: "Xã Quảng Lâm",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620810",
    name: "Xã Sen Thượng",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620811",
    name: "Xã Sín Thầu",
    is_higher_price: false,
    district_code: "1979",
  },
  {
    code: "620901",
    name: "Thị trấn Mường Ảng",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620902",
    name: "Xã Ẳng Cang",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620903",
    name: "Xã Ẳng Nưa",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620904",
    name: "Xã Ẳng Tở",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620905",
    name: "Xã Búng Lao",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620906",
    name: "Xã Mường Đăng",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620907",
    name: "Xã Mường Lạn",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620908",
    name: "Xã Nặm Lịch",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620909",
    name: "Xã Ngối Cáy",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "620910",
    name: "Xã Xuân Lao",
    is_higher_price: false,
    district_code: "2170",
  },
  {
    code: "621001",
    name: "Xã Chà Cang",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621002",
    name: "Xã Chà Nưa",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621003",
    name: "Xã Chà Tở",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621004",
    name: "Xã Nà Bủng",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621005",
    name: "Xã Na Cô Sa",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621006",
    name: "Xã Nà Hỳ",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621007",
    name: "Xã Nà Khoa",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621008",
    name: "Xã Nậm Chua",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621009",
    name: "Xã Nậm Khăn",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621010",
    name: "Xã Nậm Nhừ",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621011",
    name: "Xã Nậm Tin",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621012",
    name: "Xã Pa Tần",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621013",
    name: "Xã Phìn Hồ",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621014",
    name: "Xã Si Pa Phìn",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "621015",
    name: "Xã Vàng Đán",
    is_higher_price: false,
    district_code: "2179",
  },
  {
    code: "630101",
    name: "Phường Nghĩa Đức",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630102",
    name: "Phường Nghĩa Phú",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630103",
    name: "Phường Nghĩa Tân",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630104",
    name: "Phường Nghĩa Thành",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630105",
    name: "Phường Nghĩa Trung",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630106",
    name: "Xã Đắk Nia",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630107",
    name: "Xã Đăk R'Moan",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630108",
    name: "Xã Quảng Thành",
    is_higher_price: false,
    district_code: "1627",
  },
  {
    code: "630201",
    name: "Thị trấn Kiến Đức",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630202",
    name: "Xã Đắk Ru",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630203",
    name: "Xã Đắk Sin",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630204",
    name: "Xã Đắk Wer",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630205",
    name: "Xã Đạo Nghĩa",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630206",
    name: "Xã Hưng Bình",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630207",
    name: "Xã Kiến Thành",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630208",
    name: "Xã Nghĩa Thắng",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630209",
    name: "Xã Nhân Cơ",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630210",
    name: "Xã Nhân Đạo",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630211",
    name: "Xã Quảng Tín",
    is_higher_price: false,
    district_code: "1790",
  },
  {
    code: "630301",
    name: "Thị trấn Đắk Mil",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630302",
    name: "Xã Đắk Lao",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630303",
    name: "Xã Đắk Gằn",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630304",
    name: "Xã Đắk N'Drót",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630305",
    name: "Xã Đắk R'La",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630306",
    name: "Xã Đắk Sắk",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630307",
    name: "Xã Đức Mạnh",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630308",
    name: "Xã Đức Minh",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630309",
    name: "Xã Long Sơn",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630310",
    name: "Xã Thuận An",
    is_higher_price: false,
    district_code: "1792",
  },
  {
    code: "630401",
    name: "Thị trấn Ea T'Ling",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630402",
    name: "Xã Cư Knia",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630403",
    name: "Xã Đắk DRông",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630404",
    name: "Xã Đắk Wil",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630405",
    name: "Xã Ea Pô",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630406",
    name: "Xã Nam Dong",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630407",
    name: "Xã Tâm Thắng",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630408",
    name: "Xã Trúc Sơn",
    is_higher_price: false,
    district_code: "3152",
  },
  {
    code: "630501",
    name: "Thị trấn Đức An",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630502",
    name: "Xã Đắk Hòa",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630503",
    name: "Xã Đắk Môl",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630504",
    name: "Xã Đắk N'Dung",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630505",
    name: "Xã Nam Bình",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630506",
    name: "Xã Nâm N'Jang",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630507",
    name: "Xã Thuận Hà",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630508",
    name: "Xã Thuận Hạnh",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630509",
    name: "Xã Trường Xuân",
    is_higher_price: false,
    district_code: "2120",
  },
  {
    code: "630601",
    name: "Thị trấn Đắk Mâm",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630602",
    name: "Xã Buôn Choah",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630603",
    name: "Xã Đắk Drô",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630604",
    name: "Xã Đắk Nang",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630605",
    name: "Xã Đắk Sôr",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630606",
    name: "Xã Đức Xuyên",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630607",
    name: "Xã Nam Đà",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630608",
    name: "Xã Nâm N'Đir",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630609",
    name: "Xã Nâm Nung",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630610",
    name: "Xã Nam Xuân",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630611",
    name: "Xã Quảng Phú",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630612",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "2151",
  },
  {
    code: "630701",
    name: "Xã Đắk Ha",
    is_higher_price: false,
    district_code: "1791",
  },
  {
    code: "630702",
    name: "Xã Đắk Plao",
    is_higher_price: false,
    district_code: "1791",
  },
  {
    code: "630703",
    name: "Xã Đắk R'Măng",
    is_higher_price: false,
    district_code: "1791",
  },
  {
    code: "630704",
    name: "Xã Đắk Som",
    is_higher_price: false,
    district_code: "1791",
  },
  {
    code: "630705",
    name: "Xã Quảng Hoà",
    is_higher_price: false,
    district_code: "1791",
  },
  {
    code: "630706",
    name: "Xã Quảng Khê",
    is_higher_price: false,
    district_code: "1791",
  },
  {
    code: "630707",
    name: "Xã Quảng Sơn",
    is_higher_price: false,
    district_code: "1791",
  },
  {
    code: "630801",
    name: "Xã Đắk Búk So",
    is_higher_price: false,
    district_code: "2227",
  },
  {
    code: "630802",
    name: "Xã Đắk Ngo",
    is_higher_price: false,
    district_code: "2227",
  },
  {
    code: "630803",
    name: "Xã Đắk R'Tíh",
    is_higher_price: false,
    district_code: "2227",
  },
  {
    code: "630804",
    name: "Xã Quảng Tâm",
    is_higher_price: false,
    district_code: "2227",
  },
  {
    code: "630805",
    name: "Xã Quảng Tân",
    is_higher_price: false,
    district_code: "2227",
  },
  {
    code: "630806",
    name: "Xã Quảng Trực",
    is_higher_price: false,
    district_code: "2227",
  },
  {
    code: "640101",
    name: "Phường I",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640102",
    name: "Phường III",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640103",
    name: "Phường IV",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640104",
    name: "Phường V",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640105",
    name: "Phường VII",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640106",
    name: "Xã Hoả Lựu",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640107",
    name: "Xã Hoả Tiến",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640108",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640109",
    name: "Xã Vị Tân",
    is_higher_price: false,
    district_code: "1653",
  },
  {
    code: "640201",
    name: "Thị trấn Nàng Mau",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640202",
    name: "Xã Vị Bình",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640203",
    name: "Xã Vị Đông",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640204",
    name: "Xã Vị Thắng",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640205",
    name: "Xã Vị Thanh",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640206",
    name: "Xã Vị Thuỷ",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640207",
    name: "Xã Vị Trung",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640208",
    name: "Xã Vĩnh Thuận Tây",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640209",
    name: "Xã Vĩnh Trung",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640210",
    name: "Xã Vĩnh Tường",
    is_higher_price: false,
    district_code: "2048",
  },
  {
    code: "640301",
    name: "Xã Lương Nghĩa",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640302",
    name: "Xã Lương Tâm",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640303",
    name: "Xã Thuận Hòa",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640304",
    name: "Xã Thuận Hưng",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640305",
    name: "Xã Vĩnh Thuận Đông",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640306",
    name: "Xã Vĩnh Viễn",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640307",
    name: "Xã Vĩnh Viễn A",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640308",
    name: "Xã Xà Phiên",
    is_higher_price: false,
    district_code: "3445",
  },
  {
    code: "640401",
    name: "Thị trấn Búng Tàu",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640402",
    name: "Thị trấn Cây Dương",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640403",
    name: "Thị trấn Kinh Cùng",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640404",
    name: "Xã Bình Thành",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640405",
    name: "Xã Hiệp Hưng",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640406",
    name: "Xã Hòa An",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640407",
    name: "Xã Hòa Mỹ",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640408",
    name: "Xã Long Thạnh",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640409",
    name: "Xã Phụng Hiệp",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640410",
    name: "Xã Phương Bình",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640411",
    name: "Xã Phương Phú",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640412",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640413",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640414",
    name: "Xã Tân Phước Hưng",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "640415",
    name: "Xã Thạnh Hòa",
    is_higher_price: false,
    district_code: "1824",
  },
  {
    code: "64051",
    name: "Thị trấn Mái Dầm",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64052",
    name: "Thị Trấn Ngã Sáu",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64053",
    name: "Xã Đông Phú",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64054",
    name: "Xã Đông Phước",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64055",
    name: "Xã Đông Phước A",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64056",
    name: "Xã Đông Thạnh",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64057",
    name: "Xã Phú An",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64058",
    name: "Xã Phú Hữu",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "64059",
    name: "Xã Phú Tân",
    is_higher_price: false,
    district_code: "2096",
  },
  {
    code: "640601",
    name: "Thị trấn Bảy Ngàn",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640602",
    name: "Thị trấn Cái Tắc",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640603",
    name: "Thị trấn Một Ngàn",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640604",
    name: "Thị trấn Rạch Gòi",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640605",
    name: "Xã Nhơn Nghĩa A",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640606",
    name: "Xã Tân Hoà",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640607",
    name: "Xã Tân Phú Thạnh",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640608",
    name: "Xã Thạnh Xuân",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640609",
    name: "Xã Trường Long A",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640610",
    name: "Xã Trường Long Tây",
    is_higher_price: false,
    district_code: "1912",
  },
  {
    code: "640701",
    name: "Phường Hiệp Thành",
    is_higher_price: false,
    district_code: "1823",
  },
  {
    code: "640702",
    name: "Phường Lái Hiếu",
    is_higher_price: false,
    district_code: "1823",
  },
  {
    code: "640703",
    name: "Phường Ngã Bảy",
    is_higher_price: false,
    district_code: "1823",
  },
  {
    code: "640704",
    name: "Xã Đại Thành",
    is_higher_price: false,
    district_code: "1823",
  },
  {
    code: "640705",
    name: "Xã Hiệp Lợi",
    is_higher_price: false,
    district_code: "1823",
  },
  {
    code: "640706",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1823",
  },
  {
    code: "640801",
    name: "Phường Bình Thạnh",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640802",
    name: "Phường Thuận An",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640803",
    name: "Phường Trà Lồng",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640804",
    name: "Phường Vĩnh Tường",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640805",
    name: "Xã Long Bình",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640806",
    name: "Xã Long Phú",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640807",
    name: "Xã Long Trị",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640808",
    name: "Xã Long Trị A",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "640809",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "3218",
  },
  {
    code: "70101",
    name: "Phường Đoàn Kết",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "70102",
    name: "Phường Đông Phong",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "70103",
    name: "Phường Quyết Thắng",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "70104",
    name: "Phường Quyết Tiến",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "70105",
    name: "Phường Tân Phong",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "70106",
    name: "Xã Nậm Loỏng",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "70107",
    name: "Xã San Thàng",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "70201",
    name: "Thị trấn Tam Đường",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70202",
    name: "Xã Bản Bo",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70203",
    name: "Xã Bản Giang",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70204",
    name: "Xã Bản Hon",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70205",
    name: "Xã Bình Lư",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70206",
    name: "Xã Giang Ma",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70207",
    name: "Xã Hồ Thầu",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70208",
    name: "Xã Khun Há",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70209",
    name: "Xã Nà Tăm",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70210",
    name: "Xã Nùng Nàng",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70211",
    name: "Xã Sơn Bình",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70212",
    name: "Xã Sùng Phài",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70213",
    name: "Xã Tả Lèng",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70214",
    name: "Xã Thèn Sin",
    is_higher_price: false,
    district_code: "2010",
  },
  {
    code: "70301",
    name: "Thị trấn Phong Thổ",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70302",
    name: "Xã Bản Lang",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70303",
    name: "Xã Dào San",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70304",
    name: "Xã Hoang Thèn",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70305",
    name: "Xã Huổi Luông",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70306",
    name: "Xã Khổng Lào",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70307",
    name: "Xã Lả Nhì Thàng",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70308",
    name: "Xã Ma Li Chải",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70309",
    name: "Xã Ma Ly Pho",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70310",
    name: "Xã Mồ Sì San",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70311",
    name: "Xã Mù Sang",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70312",
    name: "Xã Mường So",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70313",
    name: "Xã Nậm Xe",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70314",
    name: "Xã Pa Vây Sử",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70315",
    name: "Xã Sì Lờ Lầu",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70316",
    name: "Xã Sin Suối Hồ",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70317",
    name: "Xã Tông Qua Lìn",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70318",
    name: "Xã Vàng Ma Chải",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "70401",
    name: "Thị trấn Sìn Hồ",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70402",
    name: "Xã Căn Co",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70403",
    name: "Xã Chăn Nưa",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70404",
    name: "Xã Hồng Thu",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70405",
    name: "Xã Làng Mô",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70406",
    name: "Xã Lùng Thàng",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70407",
    name: "Xã Ma Quai",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70408",
    name: "Xã Nậm Cha",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70409",
    name: "Xã Nậm Cuổi",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70410",
    name: "Xã Nậm Hăn",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70411",
    name: "Xã Nậm Mạ",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70412",
    name: "Xã Nậm Tăm",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70413",
    name: "Xã Noong Hẻo",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70414",
    name: "Xã Pa Khoá",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70415",
    name: "Xã Pa Tần",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70416",
    name: "Xã Phăng Sô Lin",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70417",
    name: "Xã Phìn Hồ",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70418",
    name: "Xã Pu Sam Cáp",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70419",
    name: "Xã Sà Dề Phìn",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70420",
    name: "Xã Tả Ngảo",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70421",
    name: "Xã Tả Phìn",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70422",
    name: "Xã Tủa Sín Chải",
    is_higher_price: false,
    district_code: "2006",
  },
  {
    code: "70501",
    name: "Thị trấn Mường Tè",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70502",
    name: "Xã Bum Nưa",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70503",
    name: "Xã Bun Tở",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70504",
    name: "Xã Ka Lăng",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70505",
    name: "Xã Kan Hồ",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70506",
    name: "Xã Mù Cả",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70507",
    name: "Xã Mường Tè",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70508",
    name: "Xã Nậm Khao",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70509",
    name: "Xã Pa ủ",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70510",
    name: "Xã Pa Vệ Sử",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70511",
    name: "Xã Tá Bạ",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70512",
    name: "Xã Tà Tổng",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70513",
    name: "Xã Thu Lũm",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70514",
    name: "Xã Vàng San",
    is_higher_price: false,
    district_code: "1980",
  },
  {
    code: "70601",
    name: "Thị trấn Than Uyên",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70602",
    name: "Xã Hua Nà",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70603",
    name: "Xã Khoen On",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70604",
    name: "Xã Mường Cang",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70605",
    name: "Xã Mường Kim",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70606",
    name: "Xã Mường Mít",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70607",
    name: "Xã Mường Than",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70608",
    name: "Xã Pha Mu",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70609",
    name: "Xã Phúc Than",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70610",
    name: "Xã Tà Gia",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70611",
    name: "Xã Tà Hừa",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70612",
    name: "Xã Tà Mung",
    is_higher_price: false,
    district_code: "2025",
  },
  {
    code: "70701",
    name: "Thị trấn Tân Uyên",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70702",
    name: "Xã Hố Mít",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70703",
    name: "Xã Mường Khoa",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70704",
    name: "Xã Nậm Cần",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70705",
    name: "Xã Nậm Sỏ",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70706",
    name: "Xã Pắc Ta",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70707",
    name: "Xã Phúc Khoa",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70708",
    name: "Xã Tà Mít",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70709",
    name: "Xã Thân Thuộc",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70710",
    name: "Xã Trung Đồng",
    is_higher_price: false,
    district_code: "2017",
  },
  {
    code: "70801",
    name: "Thị trấn Nậm Nhùn",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70802",
    name: "Xã Hua Bun",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70803",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70804",
    name: "Xã Mường Mô",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70805",
    name: "Xã Nậm Ban",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70806",
    name: "Xã Nậm Chà",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70807",
    name: "Xã Nậm Hàng",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70808",
    name: "Xã Nậm Manh",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70809",
    name: "Xã Nậm Pì",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70810",
    name: "Xã Pú Đao",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "70811",
    name: "Xã Trung Chải",
    is_higher_price: false,
    district_code: "1984",
  },
  {
    code: "761202",
    name: "Xã B' Lá",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761203",
    name: "Xã Lộc An",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761204",
    name: "Xã Lộc Bắc",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761205",
    name: "Xã Lộc Bảo",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761206",
    name: "Xã Lộc Đức",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761207",
    name: "Xã Lộc Lâm",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761208",
    name: "Xã Lộc Nam",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761209",
    name: "Xã Lộc Ngãi",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761210",
    name: "Xã Lộc Phú",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761211",
    name: "Xã Lộc Quảng",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761212",
    name: "Xã Lộc Tân",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761213",
    name: "Xã Lộc Thành",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "761214",
    name: "Xã Tân Lạc",
    is_higher_price: false,
    district_code: "1839",
  },
  {
    code: "800000",
    name: "Xã Phúc Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "800001",
    name: "Thị Trấn Tây Yên Tử",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "800002",
    name: "Xã Vĩnh An",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "800003",
    name: "Xã Đại Sơn",
    is_higher_price: false,
    district_code: "1761",
  },
  {
    code: "800004",
    name: "Thị Trấn Nham Biền",
    is_higher_price: false,
    district_code: "1764",
  },
  {
    code: "800005",
    name: "Xã Nguyễn Việt Khái",
    is_higher_price: false,
    district_code: "1883",
  },
  {
    code: "800006",
    name: "Xã Trần Hưng Đạo",
    is_higher_price: false,
    district_code: "1970",
  },
  {
    code: "800007",
    name: "Xã Khánh Vĩnh Yên",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "800008",
    name: "Xã Kim Song Trường",
    is_higher_price: false,
    district_code: "3143",
  },
  {
    code: "800009",
    name: "Xã Nam Phúc Thăng",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "800010",
    name: "Xã Yên Hòa",
    is_higher_price: false,
    district_code: "1815",
  },
  {
    code: "800011",
    name: "Xã Điền Mỹ",
    is_higher_price: false,
    district_code: "1812",
  },
  {
    code: "800012",
    name: "Xã An Hòa Thịnh",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "800013",
    name: "Xã Kim Hoa",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "800014",
    name: "Xã Quang Diệm",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "800015",
    name: "Xã Tân Mỹ Hà",
    is_higher_price: false,
    district_code: "3201",
  },
  {
    code: "800016",
    name: "Xã Lâm Hợp",
    is_higher_price: false,
    district_code: "1811",
  },
  {
    code: "800017",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "800018",
    name: "Thị Trấn Lộc Hà",
    is_higher_price: false,
    district_code: "3220",
  },
  {
    code: "800019",
    name: "Xã Đan Trường",
    is_higher_price: false,
    district_code: "1813",
  },
  {
    code: "800020",
    name: "Xã Lưu Vĩnh Sơn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "800021",
    name: "Xã Nam Điền",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "800022",
    name: "Xã Tân Lâm Hương",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "800023",
    name: "Xã Việt Tiến",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "800024",
    name: "Xã Đỉnh Bàn",
    is_higher_price: false,
    district_code: "2024",
  },
  {
    code: "800025",
    name: "Xã Quang Thọ",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "800026",
    name: "Xã Thọ Điền",
    is_higher_price: false,
    district_code: "3320",
  },
  {
    code: "800027",
    name: "Xã An Dũng",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800028",
    name: "Xã Bùi La Nhân",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800029",
    name: "Xã Hòa Lạc",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800030",
    name: "Xã Lâm Trung Thủy",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800031",
    name: "Xã Quang Vĩnh",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800032",
    name: "Xã Thanh Bình Thịnh",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800033",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800034",
    name: "Xã Tùng Châu",
    is_higher_price: false,
    district_code: "3188",
  },
  {
    code: "800035",
    name: "Xã Hợp Phong",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "800036",
    name: "Xã Thạch Yên",
    is_higher_price: false,
    district_code: "2087",
  },
  {
    code: "800037",
    name: "Xã Hùng Sơn",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "800038",
    name: "Xã Hợp Tiến",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "800039",
    name: "Xã Kim Lập",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "800040",
    name: "Xã Xuân Thủy",
    is_higher_price: false,
    district_code: "2146",
  },
  {
    code: "800041",
    name: "Xã Cao Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "800042",
    name: "Xã Thanh Cao",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "800043",
    name: "Xã Thanh Sơn",
    is_higher_price: false,
    district_code: "1968",
  },
  {
    code: "800044",
    name: "Xã Quyết Thắng",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "800045",
    name: "Xã Vũ Bình",
    is_higher_price: false,
    district_code: "2156",
  },
  {
    code: "800046",
    name: "Thị Trấn Ba Hàng Đồi",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "800047",
    name: "Xã Phú Nghĩa",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "800048",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "2157",
  },
  {
    code: "800049",
    name: "Xã Sơn Thủy",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "800050",
    name: "Xã Thành Sơn",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "800051",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "800052",
    name: "Xã Đồng Tân",
    is_higher_price: false,
    district_code: "2163",
  },
  {
    code: "800053",
    name: "Xã Nhân Mỹ",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "800054",
    name: "Xã Suối Hoa",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "800055",
    name: "Xã Vân Sơn",
    is_higher_price: false,
    district_code: "2014",
  },
  {
    code: "800056",
    name: "Xã Nánh Nghê",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "800057",
    name: "Xã Tú Lý",
    is_higher_price: false,
    district_code: "1916",
  },
  {
    code: "800058",
    name: "Xã Minh Phượng",
    is_higher_price: false,
    district_code: "2018",
  },
  {
    code: "800059",
    name: "Xã Vĩnh Hưng",
    is_higher_price: false,
    district_code: "1816",
  },
  {
    code: "800060",
    name: "Xã Định Sơn",
    is_higher_price: false,
    district_code: "1817",
  },
  {
    code: "800061",
    name: "Xã Kim Liên",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "800062",
    name: "Xã Tuấn Việt",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "800063",
    name: "Xã Đồng Cẩm",
    is_higher_price: false,
    district_code: "1953",
  },
  {
    code: "800064",
    name: "Xã An Phượng",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "800065",
    name: "Xã Thanh Quang",
    is_higher_price: false,
    district_code: "3292",
  },
  {
    code: "800066",
    name: "Xã Hồng Phong",
    is_higher_price: false,
    district_code: "3294",
  },
  {
    code: "800067",
    name: "Xã Chí Minh",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "800068",
    name: "Xã Đại Sơn",
    is_higher_price: false,
    district_code: "3287",
  },
  {
    code: "800069",
    name: "Thị Trấn Măng Đen",
    is_higher_price: false,
    district_code: "1834",
  },
  {
    code: "800070",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "2031",
  },
  {
    code: "800071",
    name: "Xã Tân Bình",
    is_higher_price: false,
    district_code: "2016",
  },
  {
    code: "800072",
    name: "Thị Trấn Phước Cát",
    is_higher_price: false,
    district_code: "3146",
  },
  {
    code: "800073",
    name: "Xã Bắc Quỳnh",
    is_higher_price: false,
    district_code: "3134",
  },
  {
    code: "800074",
    name: "Xã Thiện Tân",
    is_higher_price: false,
    district_code: "1948",
  },
  {
    code: "800075",
    name: "Xã Khánh Xuân",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "800076",
    name: "Xã Minh Hiệp",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "800077",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "1963",
  },
  {
    code: "800078",
    name: "Xã Bắc Hùng",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "800079",
    name: "Xã Bắc Việt",
    is_higher_price: false,
    district_code: "3310",
  },
  {
    code: "800080",
    name: "Xã An Sơn",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "800081",
    name: "Xã Liên Hội",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "800082",
    name: "Xã Điềm He",
    is_higher_price: false,
    district_code: "3311",
  },
  {
    code: "800083",
    name: "Thị Trấn Ninh Cường",
    is_higher_price: false,
    district_code: "3308",
  },
  {
    code: "800084",
    name: "Xã Minh Châu",
    is_higher_price: false,
    district_code: "1847",
  },
  {
    code: "800085",
    name: "Xã Châu Nhân",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "800086",
    name: "Xã Hưng Nghĩa",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "800087",
    name: "Xã Hưng Thành",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "800088",
    name: "Xã Long Xá",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "800089",
    name: "Xã Xuân Lam",
    is_higher_price: false,
    district_code: "1947",
  },
  {
    code: "800090",
    name: "Xã Thượng Tân Lộc",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "800091",
    name: "Xã Trung Phúc Cường",
    is_higher_price: false,
    district_code: "3233",
  },
  {
    code: "800092",
    name: "Xã Nghĩa Thành",
    is_higher_price: false,
    district_code: "1851",
  },
  {
    code: "800093",
    name: "Xã Đại Đồng",
    is_higher_price: false,
    district_code: "3291",
  },
  {
    code: "800094",
    name: "Thị Trấn Cẩm Khê",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "800095",
    name: "Xã Hùng Việt",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "800096",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1905",
  },
  {
    code: "800097",
    name: "Xã Tứ Hiệp",
    is_higher_price: false,
    district_code: "1938",
  },
  {
    code: "800098",
    name: "Xã Phùng Nguyên",
    is_higher_price: false,
    district_code: "1959",
  },
  {
    code: "800099",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "1994",
  },
  {
    code: "800100",
    name: "Xã Bắc Sơn",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "800101",
    name: "Xã Dân Quyền",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "800102",
    name: "Xã Lam Sơn",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "800103",
    name: "Xã Vạn Xuân",
    is_higher_price: false,
    district_code: "3272",
  },
  {
    code: "800104",
    name: "Xã Quảng Yên",
    is_higher_price: false,
    district_code: "3290",
  },
  {
    code: "800105",
    name: "Xã Đồng Trung",
    is_higher_price: false,
    district_code: "2237",
  },
  {
    code: "800106",
    name: "Xã Hùng Xuyên",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "800107",
    name: "Xã Hợp Nhất",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "800108",
    name: "Xã Phú Lâm",
    is_higher_price: false,
    district_code: "1925",
  },
  {
    code: "800109",
    name: "Xã An Hòa Hải",
    is_higher_price: false,
    district_code: "3284",
  },
  {
    code: "800110",
    name: "Xã Thanh An",
    is_higher_price: false,
    district_code: "1903",
  },
  {
    code: "800111",
    name: "Xã Linh Trường",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "800112",
    name: "Xã Phong Bình",
    is_higher_price: false,
    district_code: "1936",
  },
  {
    code: "800113",
    name: "Xã Lìa",
    is_higher_price: false,
    district_code: "1860",
  },
  {
    code: "800114",
    name: "Xã Hải Định",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "800115",
    name: "Xã Hiền Thành",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "800116",
    name: "Xã Kim Thạch",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "800117",
    name: "Xã Trung Nam",
    is_higher_price: false,
    district_code: "1861",
  },
  {
    code: "800118",
    name: "Xã Sốp Cộp",
    is_higher_price: false,
    district_code: "3266",
  },
  {
    code: "800119",
    name: "Xã Phổng Lăng",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "800120",
    name: "Xã Tông Lạnh",
    is_higher_price: false,
    district_code: "2032",
  },
  {
    code: "800121",
    name: "Xã Hoạt Giang",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "800122",
    name: "Xã Lĩnh Toại",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "800123",
    name: "Xã Nga Phượng",
    is_higher_price: false,
    district_code: "3241",
  },
  {
    code: "800124",
    name: "Xã Tiên Trang",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "800125",
    name: "Xã Minh Tâm",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "800126",
    name: "Xã Tân Châu",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "800127",
    name: "Xã Phú Xuân",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "800128",
    name: "Xã Thuận Minh",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "800129",
    name: "Xã Trường Xuân",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "800130",
    name: "Xã Xuân Hồng",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "800131",
    name: "Xã Xuân Sinh",
    is_higher_price: false,
    district_code: "1873",
  },
  {
    code: "800132",
    name: "Thị Trấn Nưa",
    is_higher_price: false,
    district_code: "2249",
  },
  {
    code: "800133",
    name: "Xã Ninh Khang",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "800134",
    name: "Xã Quảng Nhâm",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "800135",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "800136",
    name: "Xã Hương Xuân",
    is_higher_price: false,
    district_code: "3234",
  },
  {
    code: "800137",
    name: "Xã Giang Hải",
    is_higher_price: false,
    district_code: "1882",
  },
  {
    code: "800138",
    name: "Xã Phú Gia",
    is_higher_price: false,
    district_code: "1749",
  },
  {
    code: "800139",
    name: "Xã Trường Sinh",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "800140",
    name: "Xã Tân Thanh",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "800141",
    name: "Thị Trấn Bút Sơn",
    is_higher_price: false,
    district_code: "1748",
  },
  {
    code: "800142",
    name: "Thị Trấn Tân Thanh",
    is_higher_price: false,
    district_code: "2027",
  },
  {
    code: "800143",
    name: "Xã Khánh Hợp",
    is_higher_price: false,
    district_code: "1854",
  },
  {
    code: "800144",
    name: "Thị Trấn Diên Sanh",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "800145",
    name: "Xã Hải Hưng",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "800146",
    name: "Xã Hải Phong",
    is_higher_price: false,
    district_code: "2137",
  },
  {
    code: "800147",
    name: "Thị Trấn Phong Sơn",
    is_higher_price: false,
    district_code: "3147",
  },
  {
    code: "800148",
    name: "Xã Yên Dương",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "800149",
    name: "Xã Yến Sơn",
    is_higher_price: false,
    district_code: "1877",
  },
  {
    code: "800150",
    name: "Thị Trấn Tân Phong",
    is_higher_price: false,
    district_code: "1747",
  },
  {
    code: "800151",
    name: "Thị Trấn Thiệu Hóa",
    is_higher_price: false,
    district_code: "3298",
  },
  {
    code: "800152",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1881",
  },
  {
    code: "800153",
    name: "Xã Lâm Đớt",
    is_higher_price: false,
    district_code: "1885",
  },
  {
    code: "800154",
    name: "Thị Trấn Dầu Giây",
    is_higher_price: false,
    district_code: "1705",
  },
  {
    code: "800155",
    name: "Xã Tiên Sơn",
    is_higher_price: false,
    district_code: "1802",
  },
  {
    code: "800156",
    name: "Phường Hưng Trí",
    is_higher_price: false,
    district_code: "3441",
  },
  {
    code: "800157",
    name: "Phường An Lưu",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "800158",
    name: "Phường Phạm Thái",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "800159",
    name: "Phường Cầu Mây",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800160",
    name: "Xã Hoàng Liên",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800161",
    name: "Phường Hàm Rồng",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800162",
    name: "Xã Liên Minh",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800163",
    name: "Xã Mường Bo",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800164",
    name: "Xã Mường Hoa",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800165",
    name: "Xã Ngũ Chỉ Sơn",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800166",
    name: "Phường Phan Si Păng",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800167",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800168",
    name: "Phường Ô Quý Hồ",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "800169",
    name: "Xã Quang Thành",
    is_higher_price: false,
    district_code: "1818",
  },
  {
    code: "800170",
    name: "Xã Bình Tiến",
    is_higher_price: false,
    district_code: "1697",
  },
  {
    code: "800171",
    name: "Phường Lam Hạ",
    is_higher_price: false,
    district_code: "1614",
  },
  {
    code: "800172",
    name: "Xã Đồng Môn",
    is_higher_price: false,
    district_code: "1618",
  },
  {
    code: "800173",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800174",
    name: "Xã Hợp Thịnh",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800175",
    name: "Xã Mông Hóa",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800176",
    name: "Xã Phú Minh",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800177",
    name: "Xã Quang Tiến",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800178",
    name: "Xã Độc Lập",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800179",
    name: "Xã An Thượng",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "800180",
    name: "Xã Gia Xuyên",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "800181",
    name: "Xã Liên Hồng",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "800182",
    name: "Xã Ngọc Sơn",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "800183",
    name: "Xã Quyết Thắng",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "800184",
    name: "Xã Tiền Tiến",
    is_higher_price: false,
    district_code: "1598",
  },
  {
    code: "800185",
    name: "Xã Bằng Cả",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800186",
    name: "Xã Hòa Bình",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800187",
    name: "Xã Kỳ Thượng",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800188",
    name: "Xã Lê Lợi",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800189",
    name: "Xã Quảng La",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800190",
    name: "Xã Sơn Dương",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800191",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800192",
    name: "Xã Tân Dân",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800193",
    name: "Xã Vũ Oai",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800194",
    name: "Xã Đồng Lâm",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800195",
    name: "Xã Đồng Sơn",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800196",
    name: "Xã Long Anh",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "800197",
    name: "Xã Huống Thượng",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "800198",
    name: "Xã Linh Sơn",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "800199",
    name: "Xã Đồng Liên",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "800200",
    name: "Xã Kim Phú",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "800201",
    name: "Phường Mỹ Lâm",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "800202",
    name: "Xã Mường Phăng",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "800203",
    name: "Xã Nà Nhạn",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "800204",
    name: "Xã Nà Tấu",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "800205",
    name: "Xã Pá Khoang",
    is_higher_price: false,
    district_code: "1676",
  },
  {
    code: "800206",
    name: "Phường An Hưng",
    is_higher_price: false,
    district_code: "1616",
  },
  {
    code: "800207",
    name: "Xã Dân Chủ",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800208",
    name: "Thị Trấn Trới",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800209",
    name: "Phường Châu Sơn",
    is_higher_price: false,
    district_code: "1684",
  },
  {
    code: "800210",
    name: "Phường Chùa Hang",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "800211",
    name: "Xã Sơn Cẩm",
    is_higher_price: false,
    district_code: "1639",
  },
  {
    code: "800212",
    name: "Xã Xuân Đình",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "800213",
    name: "Xã Sen Phương",
    is_higher_price: false,
    district_code: "1807",
  },
  {
    code: "800214",
    name: "Xã Nam Tiến",
    is_higher_price: false,
    district_code: "3255",
  },
  {
    code: "800215",
    name: "Thị Trấn Thông Nông",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800216",
    name: "Xã Cần Yên",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800217",
    name: "Xã Cần Nông",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800218",
    name: "Xã Lương Thông",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800219",
    name: "Xã Đa Thông",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800220",
    name: "Xã Ngọc Động",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800221",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800222",
    name: "Xã Lương Can",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800223",
    name: "Xã Thanh Long",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800224",
    name: "Xã Hồng Sỹ",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800225",
    name: "Xã Ngọc Đào",
    is_higher_price: false,
    district_code: "1939",
  },
  {
    code: "800226",
    name: "Thị Trấn Trà Lĩnh",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800227",
    name: "Xã Tri Phương",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800228",
    name: "Xã Quang Hán",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800229",
    name: "Xã Xuân Nội",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800230",
    name: "Xã Quang Trung",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800231",
    name: "Xã Quang Vinh",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800232",
    name: "Xã Cao Chương",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800233",
    name: "Xã Đoài Dương",
    is_higher_price: false,
    district_code: "2041",
  },
  {
    code: "800234",
    name: "Xã Thống Nhất",
    is_higher_price: false,
    district_code: "3194",
  },
  {
    code: "800235",
    name: "Xã Vũ Minh",
    is_higher_price: false,
    district_code: "3246",
  },
  {
    code: "800236",
    name: "Xã Hiệp Lực",
    is_higher_price: false,
    district_code: "3242",
  },
  {
    code: "800237",
    name: "Xã Tân Tú",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "800238",
    name: "Xã Quân Hà",
    is_higher_price: false,
    district_code: "1889",
  },
  {
    code: "800239",
    name: "Xã Đồng Thắng",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "800240",
    name: "Xã Yên Phong",
    is_higher_price: false,
    district_code: "1913",
  },
  {
    code: "800241",
    name: "Thị Trấn Đồng Tâm",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "800242",
    name: "Xã Thanh Thịnh",
    is_higher_price: false,
    district_code: "1914",
  },
  {
    code: "800243",
    name: "Xã Văn Vũ",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "800244",
    name: "Xã Văn Lang",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "800245",
    name: "Xã Sơn Thành",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "800246",
    name: "Xã Trần Phú",
    is_higher_price: false,
    district_code: "3232",
  },
  {
    code: "800247",
    name: "Xã Cốc San",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "800248",
    name: "Xã Lùng Thẩn",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "800249",
    name: "Xã Quan Hồ Thẩn",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "800250",
    name: "Xã Phúc Khánh",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "800251",
    name: "Xã Sùng Phài",
    is_higher_price: false,
    district_code: "1675",
  },
  {
    code: "800252",
    name: "Xã Sì Lở Lầu",
    is_higher_price: false,
    district_code: "1989",
  },
  {
    code: "800253",
    name: "Xã Nghĩa Lộ",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "800254",
    name: "Xã Sơn A",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "800255",
    name: "Xã Phù Nham",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "800256",
    name: "Xã Thanh Lương",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "800257",
    name: "Xã Hạnh Sơn",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "800258",
    name: "Xã Phúc Sơn",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "800259",
    name: "Xã Thạch Lương",
    is_higher_price: false,
    district_code: "2063",
  },
  {
    code: "800260",
    name: "Phường Kỳ Sơn",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800261",
    name: "Xã Thịnh Minh",
    is_higher_price: false,
    district_code: "1678",
  },
  {
    code: "800262",
    name: "Phường Hoành Bồ",
    is_higher_price: false,
    district_code: "1604",
  },
  {
    code: "800263",
    name: "Xã Tân Phú",
    is_higher_price: false,
    district_code: "1733",
  },
  {
    code: "800264",
    name: "Phường Hải Sơn",
    is_higher_price: false,
    district_code: "1707",
  },
  {
    code: "800265",
    name: "Xã Châu Sơn",
    is_higher_price: false,
    district_code: "1868",
  },
  {
    code: "800266",
    name: "Xã Hà Giang",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "800267",
    name: "Xã Liên Hoa",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "800268",
    name: "Xã Hồng Bạch",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "800269",
    name: "Xã Đông Quan",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "800270",
    name: "Xã Minh Phú",
    is_higher_price: false,
    district_code: "1715",
  },
  {
    code: "800271",
    name: "Xã Hồng Dũng",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800272",
    name: "Xã An Tân",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800273",
    name: "Xã Dương Phúc",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800274",
    name: "Xã Dương Hồng Thủy",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800275",
    name: "Xã Hòa An",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800276",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800277",
    name: "Xã Tân Học",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800278",
    name: "Xã Thuần Thành",
    is_higher_price: false,
    district_code: "1869",
  },
  {
    code: "800279",
    name: "Thị Trấn Kiến Xương",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "800280",
    name: "Xã Tây Sơn",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "800281",
    name: "Xã Minh Quang",
    is_higher_price: false,
    district_code: "1951",
  },
  {
    code: "800282",
    name: "Xã Phúc Thắng",
    is_higher_price: false,
    district_code: "3243",
  },
  {
    code: "800283",
    name: "Xã Xuân Chính",
    is_higher_price: false,
    district_code: "3205",
  },
  {
    code: "800284",
    name: "Phường Đồng Hải",
    is_higher_price: false,
    district_code: "1619",
  },
  {
    code: "800285",
    name: "Xã Liên Trường",
    is_higher_price: false,
    district_code: "3258",
  },
  {
    code: "800286",
    name: "Xã Hải Phú",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "800287",
    name: "Thị Trấn Phong Nha",
    is_higher_price: false,
    district_code: "1858",
  },
  {
    code: "800288",
    name: "Xã Ngư Thủy",
    is_higher_price: false,
    district_code: "1857",
  },
  {
    code: "800289",
    name: "Xã Quế Mỹ",
    is_higher_price: false,
    district_code: "2003",
  },
  {
    code: "800290",
    name: "Thị Trấn Tân Bình",
    is_higher_price: false,
    district_code: "2139",
  },
  {
    code: "800291",
    name: "Xã Ninh Phước",
    is_higher_price: false,
    district_code: "2182",
  },
  {
    code: "800292",
    name: "Xã Bình Thanh",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "800293",
    name: "Xã Bình Tân Phú",
    is_higher_price: false,
    district_code: "1898",
  },
  {
    code: "800294",
    name: "Xã Trà Thanh",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "800295",
    name: "Xã Sơn Trà",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "800296",
    name: "Xã Trà Phong",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "800297",
    name: "Xã Hương Trà",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "800298",
    name: "Xã Trà Xinh",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "800299",
    name: "Xã Trà Tây",
    is_higher_price: false,
    district_code: "3304",
  },
  {
    code: "800300",
    name: "Phường Nguyễn Nghiêm",
    is_higher_price: false,
    district_code: "1930",
  },
  {
    code: "800301",
    name: "Xã Bình Lộc",
    is_higher_price: false,
    district_code: "1739",
  },
  {
    code: "800302",
    name: "Phường Long Hoa",
    is_higher_price: false,
    district_code: "1721",
  },
  {
    code: "800303",
    name: "Xã Phước Bình",
    is_higher_price: false,
    district_code: "2035",
  },
  {
    code: "800304",
    name: "Phường An Hội",
    is_higher_price: false,
    district_code: "1558",
  },
  {
    code: "800305",
    name: "Xã Bình Khánh",
    is_higher_price: false,
    district_code: "1975",
  },
  {
    code: "800306",
    name: "Xã Phước Ngãi",
    is_higher_price: false,
    district_code: "1888",
  },
  {
    code: "800307",
    name: "Xã Quốc Toản",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800308",
    name: "Thị Trấn Quảng Uyên",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800309",
    name: "Xã Phi Hải",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800310",
    name: "Xã Quảng Hưng",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800311",
    name: "Xã Độc Lập",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800312",
    name: "Xã Cai Bộ",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800313",
    name: "Xã Phúc Sen",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800314",
    name: "Xã Chí Thảo",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800315",
    name: "Xã Tự Do",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800316",
    name: "Xã Hồng Quang",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800317",
    name: "Xã Ngọc Động",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800318",
    name: "Xã Hạnh Phúc",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800319",
    name: "Thị Trấn Tà Lùng",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800320",
    name: "Xã Bế Văn Đàn",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800321",
    name: "Xã Cách Linh",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800322",
    name: "Xã Đại Sơn",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800323",
    name: "Xã Tiên Thành",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800324",
    name: "Thị Trấn Hòa Thuận",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "800325",
    name: "Xã Mỹ Hưng",
    is_higher_price: false,
    district_code: "3694",
  },
  {
    code: "80101",
    name: "Phường Bắc Cường",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80102",
    name: "Phường Bắc Lệnh",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80103",
    name: "Phường Bình Minh",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80104",
    name: "Phường Cốc Lếu",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80105",
    name: "Phường Duyên Hải",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80106",
    name: "Phường Kim Tân",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80107",
    name: "Phường Lào Cai",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80108",
    name: "Phường Nam Cường",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80109",
    name: "Phường Phố Mới",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80110",
    name: "Phường Pom Hán",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80111",
    name: "Phường Thống Nhất",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80112",
    name: "Phường Xuân Tăng",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80113",
    name: "Xã Cam Đường",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80114",
    name: "Xã Đồng Tuyển",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80115",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80116",
    name: "Xã Tả Phời",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80117",
    name: "Xã Vạn Hoà",
    is_higher_price: false,
    district_code: "1682",
  },
  {
    code: "80201",
    name: "Xã Bản Mế",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80202",
    name: "Xã Cán Cấu",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80203",
    name: "Xã Cán Hồ",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80204",
    name: "Xã Lử Thẩn",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80205",
    name: "Xã Lùng Sui",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80206",
    name: "Xã Mản Thẩn",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80207",
    name: "Xã Nàn Sán",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80208",
    name: "Xã Nàn Xín",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80209",
    name: "Xã Quan Thần Sán",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80210",
    name: "Xã Sán Chải",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80211",
    name: "Xã Si Ma Cai",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80212",
    name: "Xã Sín Chéng",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80213",
    name: "Xã Thào Chư Phìn",
    is_higher_price: false,
    district_code: "2264",
  },
  {
    code: "80301",
    name: "Thị trấn Bát Xát",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80302",
    name: "Xã A Lù",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80303",
    name: "Xã A Mú Sung",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80304",
    name: "Xã Bản Qua",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80305",
    name: "Xã Bản Vược",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80306",
    name: "Xã Bản Xèo",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80307",
    name: "Xã Cốc Mỳ",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80308",
    name: "Xã Cốc San",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80309",
    name: "Xã Dền Sáng",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80310",
    name: "Xã Dền Thàng",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80311",
    name: "Xã Mường Hum",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80312",
    name: "Xã Mường Vi",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80313",
    name: "Xã Nậm Chạc",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80314",
    name: "Xã Nậm Pung",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80315",
    name: "Xã Ngải Thầu",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80316",
    name: "Xã Pa Cheo",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80317",
    name: "Xã Phìn Ngan",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80318",
    name: "Xã Quang Kim",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80319",
    name: "Xã Sàng Ma Sáo",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80320",
    name: "Xã Tòng Sành",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80321",
    name: "Xã Trịnh Tường",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80322",
    name: "Xã Trung Lèng Hồ",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80323",
    name: "Xã Y Tý",
    is_higher_price: false,
    district_code: "1744",
  },
  {
    code: "80401",
    name: "Thị trấn N.T Phong Hải",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80402",
    name: "Thị trấn Phố Lu",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80403",
    name: "Thị trấn Tằng Loỏng",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80404",
    name: "Xã Bản Cầm",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80405",
    name: "Xã Bản Phiệt",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80406",
    name: "Xã Gia Phú",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80407",
    name: "Xã Phố Lu",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80408",
    name: "Xã Phong Niên",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80409",
    name: "Xã Phú Nhuận",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80410",
    name: "Xã Sơn Hà",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80411",
    name: "Xã Sơn Hải",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80412",
    name: "Xã Thái Niên",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80413",
    name: "Xã Trì Quang",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80414",
    name: "Xã Xuân Giao",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80415",
    name: "Xã Xuân Quang",
    is_higher_price: false,
    district_code: "2073",
  },
  {
    code: "80501",
    name: "Thị trấn Sa Pa",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80502",
    name: "Xã Bản Hồ",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80503",
    name: "Xã Bản Khoang",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80504",
    name: "Xã Bản Phùng",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80505",
    name: "Xã Hầu Thào",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80506",
    name: "Xã Lao Chải",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80507",
    name: "Xã Nậm Cang",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80508",
    name: "Xã Nậm Sài",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80509",
    name: "Xã Sa Pả",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80510",
    name: "Xã San Sả Hồ",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80511",
    name: "Xã Sử Pán",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80512",
    name: "Xã Suối Thầu",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80513",
    name: "Xã Tả Giàng Phình",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80514",
    name: "Xã Tả Phìn",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80515",
    name: "Xã Tả Van",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80516",
    name: "Xã Thanh Kim",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80517",
    name: "Xã Thanh Phú",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80518",
    name: "Xã Trung Chải",
    is_higher_price: false,
    district_code: "2005",
  },
  {
    code: "80601",
    name: "Thị trấn Khánh Yên",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80602",
    name: "Xã Chiềng Ken",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80603",
    name: "Xã Dần Thàng",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80604",
    name: "Xã Dương Quỳ",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80605",
    name: "Xã Hoà Mạc",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80606",
    name: "Xã Khánh Yên Hạ",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80607",
    name: "Xã Khánh Yên Thượng",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80608",
    name: "Xã Khánh Yên Trung",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80609",
    name: "Xã Làng Giàng",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80610",
    name: "Xã Liêm Phú",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80611",
    name: "Xã Minh Lương",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80612",
    name: "Xã Nậm Chầy",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80613",
    name: "Xã Nậm Mả",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80614",
    name: "Xã Nậm Rạng",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80615",
    name: "Xã Nậm Tha",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80616",
    name: "Xã Nậm Xây",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80617",
    name: "Xã Nậm Xé",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80618",
    name: "Xã Sơn Thuỷ",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80619",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80620",
    name: "Xã Tân Thượng",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80621",
    name: "Xã Thẩm Dương",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80622",
    name: "Xã Văn Sơn",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80623",
    name: "Xã Võ Lao",
    is_higher_price: false,
    district_code: "2043",
  },
  {
    code: "80701",
    name: "Thị trấn Phố Ràng",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80702",
    name: "Xã Bảo Hà",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80703",
    name: "Xã Cam Cọn",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80704",
    name: "Xã Điện Quan",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80705",
    name: "Xã Kim Sơn",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80706",
    name: "Xã Long Khánh",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80707",
    name: "Xã Long Phúc",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80708",
    name: "Xã Lương Sơn",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80709",
    name: "Xã Minh Tân",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80710",
    name: "Xã Nghĩa Đô",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80711",
    name: "Xã Tân Dương",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80712",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80713",
    name: "Xã Thượng Hà",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80714",
    name: "Xã Việt Tiến",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80715",
    name: "Xã Vĩnh Yên",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80716",
    name: "Xã Xuân Hoà",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80717",
    name: "Xã Xuân Thượng",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80718",
    name: "Xã Yên Sơn",
    is_higher_price: false,
    district_code: "1891",
  },
  {
    code: "80801",
    name: "Thị trấn Bắc Hà",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80802",
    name: "Xã Bản Cái",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80803",
    name: "Xã Bản Già",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80804",
    name: "Xã Bản Liền",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80805",
    name: "Xã Bản Phố",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80806",
    name: "Xã Bảo Nhai",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80807",
    name: "Xã Cốc Lầu",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80808",
    name: "Xã Cốc Ly",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80809",
    name: "Xã Hoàng Thu Phố",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80810",
    name: "Xã Lầu Thí Ngài",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80811",
    name: "Xã Lùng Cải",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80812",
    name: "Xã Lùng Phình",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80813",
    name: "Xã Na Hối",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80814",
    name: "Xã Nậm Đét",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80815",
    name: "Xã Nậm Khánh",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80816",
    name: "Xã Nậm Lúc",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80817",
    name: "Xã Nậm Mòn",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80818",
    name: "Xã Tà Chải",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80819",
    name: "Xã Tả Củ Tỷ",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80820",
    name: "Xã Tả Van Chư",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80821",
    name: "Phường để test",
    is_higher_price: false,
    district_code: "1892",
  },
  {
    code: "80901",
    name: "Thị trấn Mường Khương",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80902",
    name: "Xã Bản Lầu",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80903",
    name: "Xã Bản Sen",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80904",
    name: "Xã Cao Sơn",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80905",
    name: "Xã Dìn Chin",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80906",
    name: "Xã La Pan Tẩn",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80907",
    name: "Xã Lùng Khấu Nhin",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80908",
    name: "Xã Lùng Vai",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80909",
    name: "Xã Nậm Chảy",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80910",
    name: "Xã Nấm Lư",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80911",
    name: "Xã Pha Long",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80912",
    name: "Xã Tả Gia Khâu",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80913",
    name: "Xã Tả Ngải Chồ",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80914",
    name: "Xã Tả Thàng",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80915",
    name: "Xã Thanh Bình",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "80916",
    name: "Xã Tung Chung Phố",
    is_higher_price: false,
    district_code: "2171",
  },
  {
    code: "90101",
    name: "Phường Hưng Thành",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90102",
    name: "Phường Minh Xuân",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90103",
    name: "Phường Nông Tiến",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90104",
    name: "Phường Phan Thiết",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90105",
    name: "Phường Tân Hà",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90106",
    name: "Phường Tân Quang",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90107",
    name: "Phường Ỷ La",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90108",
    name: "Xã An Khang",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90109",
    name: "Xã An Tường",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90110",
    name: "Xã Đội Cấn",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90111",
    name: "Xã Lưỡng Vượng",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90112",
    name: "Xã Thái Long",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90113",
    name: "Xã Tràng Đà",
    is_higher_price: false,
    district_code: "1601",
  },
  {
    code: "90201",
    name: "Xã Bình An",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90202",
    name: "Xã Hồng Quang",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90203",
    name: "Xã Khuôn Hà",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90204",
    name: "Xã Lăng Can",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90205",
    name: "Xã Phúc Yên",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90206",
    name: "Xã Thổ Bình",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90207",
    name: "Xã Thượng Lâm",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90208",
    name: "Xã Xuân Lập",
    is_higher_price: false,
    district_code: "1957",
  },
  {
    code: "90301",
    name: "Thị trấn Nà Hang",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90302",
    name: "Xã Côn Lôn",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90303",
    name: "Xã Đà Vị",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90304",
    name: "Xã Hồng Thái",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90305",
    name: "Xã Khau Tinh",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90306",
    name: "Xã Năng Khả",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90307",
    name: "Xã Sinh Long",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90308",
    name: "Xã Sơn Phú",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90309",
    name: "Xã Thanh Tương",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90310",
    name: "Xã Thượng Giáp",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90311",
    name: "Xã Thượng Nông",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90312",
    name: "Xã Yên Hoa",
    is_higher_price: false,
    district_code: "1982",
  },
  {
    code: "90401",
    name: "Thị trấn Vĩnh Lộc",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90402",
    name: "Xã Bình Nhân",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90403",
    name: "Xã Bình Phú",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90404",
    name: "Xã Hà Lang",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90405",
    name: "Xã Hòa An",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90406",
    name: "Xã Hòa Phú",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90407",
    name: "Xã Hùng Mỹ",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90408",
    name: "Xã Kiên Đài",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90409",
    name: "Xã Kim Bình",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90410",
    name: "Xã Linh Phú",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90411",
    name: "Xã Minh Quang",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90412",
    name: "Xã Ngọc Hội",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90413",
    name: "Xã Nhân Lý",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90414",
    name: "Xã Phú Bình",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90415",
    name: "Xã Phúc Sơn",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90416",
    name: "Xã Phúc Thịnh",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90417",
    name: "Xã Tân An",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90418",
    name: "Xã Tân Mỹ",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90419",
    name: "Xã Tân Thịnh",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90420",
    name: "Xã Tri Phú",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90421",
    name: "Xã Trung Hà",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90422",
    name: "Xã Trung Hòa",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90423",
    name: "Xã Vinh Quang",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90424",
    name: "Xã Xuân Quang",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90425",
    name: "Xã Yên Lập",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90426",
    name: "Xã Yên Nguyên",
    is_higher_price: false,
    district_code: "3157",
  },
  {
    code: "90501",
    name: "Thị trấn Tân Yên",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90502",
    name: "Xã Bạch Xa",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90503",
    name: "Xã Bằng Cốc",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90504",
    name: "Xã Bình Xa",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90505",
    name: "Xã Đức Ninh",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90506",
    name: "Xã Hùng Đức",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90507",
    name: "Xã Minh Dân",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90508",
    name: "Xã Minh Hương",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90509",
    name: "Xã Minh Khương",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90510",
    name: "Xã Nhân Mục",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90511",
    name: "Xã Phù Lưu",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90512",
    name: "Xã Tân Thành",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90513",
    name: "Xã Thái Hòa",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90514",
    name: "Xã Thái Sơn",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90515",
    name: "Xã Thành Long",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90516",
    name: "Xã Yên Lâm",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90517",
    name: "Xã Yên Phú",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90518",
    name: "Xã Yên Thuận",
    is_higher_price: false,
    district_code: "1941",
  },
  {
    code: "90601",
    name: "Thị trấn Tân Bình",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90602",
    name: "Xã Chân Sơn",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90603",
    name: "Xã Chiêu Yên",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90604",
    name: "Xã Công Đa",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90605",
    name: "Xã Đạo Viện",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90606",
    name: "Xã Đội Bình",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90607",
    name: "Xã Hoàng Khai",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90608",
    name: "Xã Hùng Lợi",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90609",
    name: "Xã Kiến Thiết",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90610",
    name: "Xã Kim Phú",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90611",
    name: "Xã Kim Quan",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90612",
    name: "Xã Lang Quán",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90613",
    name: "Xã Lực Hành",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90614",
    name: "Xã Mỹ Bằng",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90615",
    name: "Xã Nhữ Hán",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90616",
    name: "Xã Nhữ Khê",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90617",
    name: "Xã Phú Lâm",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90618",
    name: "Xã Phú Thịnh",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90619",
    name: "Xã Phúc Ninh",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90620",
    name: "Xã Quí Quân",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90621",
    name: "Xã Tân Long",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90622",
    name: "Xã Tân Tiến",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90623",
    name: "Xã Thái Bình",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90624",
    name: "Xã Thắng Quân",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90625",
    name: "Xã Tiến Bộ",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90626",
    name: "Xã Trung Minh",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90627",
    name: "Xã Trung Môn",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90628",
    name: "Xã Trung Sơn",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90629",
    name: "Xã Trung Trực",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90630",
    name: "Xã Tứ Quận",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90631",
    name: "Xã Xuân Vân",
    is_higher_price: false,
    district_code: "1745",
  },
  {
    code: "90701",
    name: "Thị trấn Sơn Dương",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90702",
    name: "Xã Bình Yên",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90703",
    name: "Xã Cấp Tiến",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90704",
    name: "Xã Chi Thiết",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90705",
    name: "Xã Đại Phú",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90706",
    name: "Xã Đông Lợi",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90707",
    name: "Xã Đồng Quý",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90708",
    name: "Xã Đông Thọ",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90709",
    name: "Xã Hào Phú",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90710",
    name: "Xã Hồng Lạc",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90711",
    name: "Xã Hợp Hòa",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90712",
    name: "Xã Hợp Thành",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90713",
    name: "Xã Kháng Nhật",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90714",
    name: "Xã Lâm Xuyên",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90715",
    name: "Xã Lương Thiện",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90716",
    name: "Xã Minh Thanh",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90717",
    name: "Xã Ninh Lai",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90718",
    name: "Xã Phú Lương",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90719",
    name: "Xã Phúc Ứng",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90720",
    name: "Xã Quyết Thắng",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90721",
    name: "Xã Sầm Dương",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90722",
    name: "Xã Sơn Nam",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90723",
    name: "Xã Tam Đa",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90724",
    name: "Xã Tân Trào",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90725",
    name: "Xã Thanh Phát",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90726",
    name: "Xã Thiện Kế",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90727",
    name: "Xã Thượng Ấm",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90728",
    name: "Xã Trung Yên",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90729",
    name: "Xã Tú Thịnh",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90730",
    name: "Xã Tuân Lộ",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90731",
    name: "Xã Văn Phú",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90732",
    name: "Xã Vân Sơn",
    is_higher_price: false,
    district_code: "3267",
  },
  {
    code: "90733",
    name: "Xã Vĩnh Lợi",
    is_higher_price: false,
    district_code: "3267",
  },
];

export default WARD;
