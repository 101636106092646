import axios, { AxiosInstance } from "axios";
import StorageService from "../services/StorageService";
import { API_URL_DONATION } from "../utils/setting";

const axiosDonation = axios.create({
  baseURL: API_URL_DONATION ,
  // API_URL_DONATION
});

// createCheckLoginInterceptor(axiosDonation);
createConfigInterceptor(axiosDonation);

function createConfigInterceptor(ins: AxiosInstance) {
  ins.interceptors.request.use(
    (config: any) => {
      const token = StorageService.token
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      throw error;
    }
  );
}

// function createCheckLoginInterceptor(ins: AxiosInstance) {
//   ins.interceptors.response.use(async (response) => {
//     if (response.data.code === 401) {
//       StorageService.profile = undefined;
//       StorageService.token = "";
//     }
//     return response;
//   });
// }

export default axiosDonation;
