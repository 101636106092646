import React from "react";
import clsx from "clsx";

import styles from "./Search.module.scss";

export interface SearchProps {
  className?: string;
}

const Search = (props: SearchProps) => {
  const { className } = props;
  return (
    <div className={clsx(styles.root, className && className)}>
      <div className={styles.searchWrap}>
        <form action="">
          <div className={styles.inputWrap}>
            <input
              type="text"
              placeholder="Nhập mã túi"
              className={styles.input}
            />
          </div>

          <div className={styles.buttonWrap}>
            <button className={styles.button}>Xác nhận đã nhận hàng</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Search;
