import styles from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import loginApi from "../../apis/loginApi";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import StorageService from "../../services/StorageService";

export interface LoginProps {
  className?: string;
}

// const autoLogin = async () => {
//   const res = await loginApi.loginHydra({
//     password: "123456789",
//     username: "donation@reshare.vn",
//   });

//   const dataResponse = res.data.data;

//   localStorage.setItem("tokenHYDRA", dataResponse?.token);
// };

// const checkRefreshToken = async () => {
//   const tokenHYDRAInLocal = localStorage.getItem("tokenHYDRA");
//   if (tokenHYDRAInLocal) {
//     const tokenHYDRA = JSON.parse(tokenHYDRAInLocal);
//     const jwtPayload = JSON.parse(window.atob(tokenHYDRA.split(".")[1]));

//     const exp = new Date(jwtPayload.exp * 1000);
//     const now = new Date();
//     if (now > exp) {
//       await autoLogin();
//     }
//   } else {
//     await autoLogin();
//   }
// };

const Login = (props: LoginProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const login = async (e:any) => {
    e.preventDefault();
    try {
      const res = await loginApi.loginHydra({
        username,
        password
      });
      if (res.data.code === 0) {
        StorageService.token = res?.data?.data?.token;
        navigate('/');
        enqueueSnackbar("Đăng nhập thành công", { variant: "success" });
      }
      else{
        setError(true);
      }
    } catch (error) {
      setError(true);
      enqueueSnackbar("Lỗi hệ thống", {variant: "error"});
    }
  }

  useEffect(()=> {
    if (StorageService.token) {
      navigate('/');
    }
  },[navigate])

  return (
    <div className={styles.root}>
      <div className={styles.loginWrap}>
        <div className={styles.login}>
          <div className={styles.loginTitle}>Login Admin Donation</div>
          <form className={styles.loginForm} onSubmit={login}>
            <div className={styles.loginFormRow}>
              <div className={styles.loginFormRowWrap}>
                <input
                  className={styles.loginFormRowInput}
                  type="email"
                  placeholder="Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.loginFormRow}>
              <div className={styles.loginFormRowWrap}>
                <input
                  className={styles.loginFormRowInput}
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e)=> setPassword(e.target.value)}
                />
              </div>
            </div>
            {
              error && <i className={styles.error}>Tài khoản hoặc mật khẩu không chính xác.</i>
            }
            <div className={styles.loginFormRow}>
              <div className={styles.loginFormRowWrap}>
                <div className={styles.buttonWrap}>
                  <button className={styles.loginFormRowButton} type="submit">
                    Login
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
