interface TotalOrderProps {
  statisticalData?: any;
}
const TotalOrder = (props: TotalOrderProps) => {

  return (
    <div>
      <p>Tổng đơn : {props?.statisticalData?.totalOrder} đơn</p>
      <p>
        Tổng tiền:{" "}
        {props?.statisticalData?.pricesWithMethod?.total.toLocaleString("vi")} VNĐ =
        Tiền mặt:{" "}
        {props?.statisticalData?.pricesWithMethod?.offline.toLocaleString("vi")} + 
        Momo: {" "}
        {props?.statisticalData?.pricesWithMethod?.momo.toLocaleString("vi")} + 
        VNPAY:{" "}
        {props?.statisticalData?.pricesWithMethod?.vnPay.toLocaleString("vi")} + 
        MasterCard:{" "}
        {props?.statisticalData?.pricesWithMethod?.masterCard.toLocaleString("vi")}
      </p>
    </div>
  );
};

export default TotalOrder;
