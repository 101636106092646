import { Button } from "@mui/material";
import CustomInput from "../CustomInput/CustomInput";
import styles from "./CreateOrderCode.module.scss";
import { useState } from "react";
import donationApi from "../../apis/donationApi";
import { useSnackbar } from "notistack";
export interface ConfirmCancelDeliveryProps {
  id: string;
  handleClose: () => void;
  deliveryId?: string,
  handleReload: () => void
}

const ConfirmCancelDelivery = (props: ConfirmCancelDeliveryProps) => {
  const [reason, setReason] = useState("");
  const { id,handleClose, deliveryId } = props;
  const {enqueueSnackbar} = useSnackbar()
  const handleChangeInput = (e: any) => {
    setReason(e.value);
  };

  const handleClickYes = () => {
    try {
        if(id){
            (async () => {
                const res = await donationApi.cancelDelivery({deliveryId: deliveryId, note: reason});
                if (res?.data.code === 0) {
                    enqueueSnackbar("Hủy đơn thành công", { variant: "success" });
                    props.handleReload();
                    handleClose();
                }
                else{
                    enqueueSnackbar("Không thể hủy đơn", { variant: "error" });
                    handleClose();
                }
            })()
        }
    } catch (error) {
        console.log(error);
    }
  }


  return (
    <div className={styles.addOrder}>
      <div className={styles.title}>Xác nhận hủy vận chuyển </div>
      <CustomInput
        onChange={handleChangeInput}
        value={reason}
        name="reason"
        type="text"
        label="Lí do hủy"
      />
      <div className={styles.btn}>
        <Button onClick={handleClickYes} className={styles.btnAccept} variant="contained" size="small">
          Xác nhận
        </Button>
        <Button onClick={handleClose} variant="outlined" size="small">
          Thoát
        </Button>
      </div>
    </div>
  );
};

export default ConfirmCancelDelivery;
