import styles from "./Overview.module.scss";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import RedeemIcon from '@mui/icons-material/Redeem';
import clsx from "clsx";

type OverviewProps = {
  customerInfo: any,
  orderInfo: any,
  orderAmountInfo: any,
  orderWeightInfo: any
}

const OverView = (props:OverviewProps) => {
  const {customerInfo, orderInfo, orderAmountInfo, orderWeightInfo} = props;
  return (
    <div className={styles.overview}>
     <div className={clsx(styles.overItem, styles.itemUser)}>
        <div className={styles.title}>
            <div className={styles.number}>
                <h2>{customerInfo?.customerQuantity?.toLocaleString("vi")}</h2>
                <h4>Khách hàng</h4>
            </div>
            <div className={styles.icon}>
                <SupervisedUserCircleIcon style={{fontSize: "34px"}}/>
            </div>
        </div>
        <div className={styles.des}></div>
      </div>
      <div className={clsx(styles.overItem, styles.itemOrder)}>
        <div className={styles.title}>
            <div className={styles.number}>
                <h2>{orderInfo?.orderQuantity?.toLocaleString("vi")}</h2>
                <h4>Đơn hàng</h4>
            </div>
            <div className={styles.icon}>
                <RedeemIcon  style={{fontSize: "34px"}}/>
            </div>
        </div>
        <div className={styles.des}></div>
      </div>
      <div className={clsx(styles.overItem, styles.itemWeight)}>
        <div className={styles.title}>
            <div className={styles.number}>
                <h2>{orderAmountInfo?.orderAmount?.toLocaleString("vi")}</h2>
                <h4>Doanh thu</h4>
            </div>
            <div className={styles.icon}>
                <SupervisedUserCircleIcon style={{fontSize: "34px"}}/>
            </div>
        </div>
        <div className={styles.des}></div>
      </div>
      <div className={clsx(styles.overItem, styles.itemWeight1)}>
        <div className={styles.title}>
            <div className={styles.number}>
                <h2>{orderWeightInfo?.weightKg?.toLocaleString("vi")}</h2>
                <h4>Khối lượng</h4>
            </div>
            <div className={styles.icon}>
                <SupervisedUserCircleIcon style={{fontSize: "34px"}}/>
            </div>
        </div>
        <div className={styles.des}></div>
      </div>
    </div>
  );
};

export default OverView;
