import axiosDonation from "./axiosDonation";

const partnerWarehouse = {
  searchPartnerWarehouse: (params: any) => {
    const url = "/serene/partner-warehouse/search-partner-warehouse";
    return axiosDonation.post(url, params);
  },
};

export default partnerWarehouse;
