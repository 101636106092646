import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styles from "./App.module.scss";
import SideBar from "./components/SideBar";
import Home from "./pages/Home";
import Orders from "./pages/Orders";
import SelectWarehouse from "./pages/SelectWarehouse";
import ShippingFee from "./pages/ShippingFee";
import OrderShip from "./pages/OrderShip";
import ZeroWasteFee from "./pages/ZeroWasteFee";
import Recycle from "./pages/Recycle";
import StorageService from "./services/StorageService";
import { jwtDecode } from "jwt-decode";
import loginApi from "./apis/loginApi";
  
function App() {
  const navigate = useNavigate();
  
  useEffect(()=> {
    if (!StorageService.token) {
      navigate('/login')
    }
  },[navigate])

  useEffect(() => {
    const token = StorageService.token;
    if (token) {
      const decodedToken = jwtDecode(token);
      
      if (decodedToken && decodedToken.exp && decodedToken.exp < Date.now() / 1000) {
        (async () => {
          const res = await loginApi.loginHydra({
            username: "donation@reshare.vn",
            password: "123456789"
          });
          if (res.data.code === 0) {
            StorageService.token = res?.data?.data?.token;
          }
          else{
            StorageService.token = "";
            navigate("/login");
          }
        })()
      }
    }
  }, [navigate]);

  return (
    <div className={styles.root}>
      <div className={styles.sidebarWrap}>
        <SideBar />
      </div>
      <div className={styles.contentWrap}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/shipping-fee" element={<ShippingFee />} />
          <Route path="*" element={<div>Not found</div>} />
          <Route path="/orders-ship" element={<OrderShip />} />
          <Route path="/select-warehouse" element={<SelectWarehouse />} />
          <Route path="/zero-waste-fee" element={<ZeroWasteFee />} />
          <Route path="/recycles" element={<Recycle/>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
