import {Button} from "@mui/material";
import { useState, useEffect } from "react";
import donationApi from "../../apis/donationApi";
import { useSnackbar } from "notistack";

export interface CreateOrderCodeDirectProps {
    id: string,
    deliveryId?: string,
    reload: () => void,
    status: string
  }
const CreateOrderCodeDirect = (props:CreateOrderCodeDirectProps) => {
    const {id, deliveryId, status} = props;
    const [state, setState] = useState({
        donationId: id,
        service: "RS_DE"
    });
    const {enqueueSnackbar} = useSnackbar();
    const [deliveryTrackingCode, setDeliveryTrackingCode] = useState('');


    
    const createOrderCode = () => {
        (async () => {
            const res = await donationApi.createDelivery(state);
            if (res.data.code === 0) {
                enqueueSnackbar("Tạo mã vận chuyển thành công", { variant: "success" });
                props.reload()
            }
            else{
                enqueueSnackbar(res.data.message, { variant: "error" });
            }
        })()
    }

    useEffect(()=> {
        (async () => {
          try {
            if (deliveryId) {
              const resp = await donationApi.getInfoDelivery({deliveryId: deliveryId});
              if (resp?.data.code === 0) {
                setDeliveryTrackingCode(resp?.data?.data?.deliveryTrackingCode);
              }
              else{
                setDeliveryTrackingCode("");  
              }
            }
          } catch (error) {
            setDeliveryTrackingCode("");  
            enqueueSnackbar("Có lỗi xảy ra", { variant: "error" });
          }
        })()
      },[deliveryId, enqueueSnackbar])
    
    return(
        <>
            {
                !deliveryId && status !== "cancel" &&
                <Button onClick={createOrderCode} size="small" variant="contained">
                    Tạo mã
                </Button>
            }
            {
                deliveryId && deliveryTrackingCode && 
                <p>{deliveryTrackingCode}</p>
            }
        </>
            
    )
}

export default CreateOrderCodeDirect