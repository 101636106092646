export const STATUS_TAB = [
  {
    title: "Mới tạo",
    id: "new",
    value: ["new"],
  },

  {
    id: "confirm",
    title: "Đã xác nhận",
    value: ["confirm"],
  },

  {
    title: "Đang nhận hàng",
    id: "processing",
    value: ["processing"],
  },

  {
    title: "Đã hoàn thành",
    id: "done",
    value: ["done"],
  },
  {
    title: "Đang thanh toán",
    id: "pending",
    value: ["pending","payment_online"],
  },
  {
    id: "all",
    title: "Tất cả",
    value: ["new", "processing", "done", "cancel", "confirm","pending","payment_online"],
  },

  {
    title: "Đã huỷ",
    id: "cancel",
    value: ["cancel"],
  },
];

export const CHART_TYPE = [
  {
    key: 1,
    label: "Năm",
    value: "year"
  },
  {
    key: 2,
    label: "Tháng",
    value: "month"
  },
  {
    key: 3,
    label: "Ngày",
    value: "day"
  }
]

export const parseStatus = (status: string) => {
  switch (status) {
    case "new":
      return "Mới";
    case "processing":
      return "Đang nhận hàng";
    case "done":
      return "Đã hoàn thành";
    case "confirm":
      return "Đã xác nhận";
    case "cancel":
      return "Đã hủy";
    case "pending": 
      return "Đang thanh toán"
    default:
      return "";
  }
};

export const parseShipperType = (shipperType: string) => {
  switch (shipperType) {
    case "fast":
      return "Giao nhanh";
    case "saving":
      return "Tiết kiệm";
    case "none":
      return "Không";
    default:
      return "";
  }
};

export const HEADER_TABLE = [
  {
    id: "1",
    title: "Mã đơn hàng",
  },

  {
    id: "2",
    title: "Địa chỉ nhận hàng",
  },

  {
    id: "3",
    title: "Mã túi",
  },

  {
    id: "4",
    title: "Thêm túi",
  },
  {
    id: "14",
    title: "Hình thức"
  },
  {
    id: "5",
    title: "Hình thức vận chuyển",
  },
  {
    id: "13",
    title: "Tổng tiền",
  },
  {
    id: "6",
    title: "Trạng thái",
  },
  {
    id: "12",
    title: "Ghi chú",
  },
  {
    id: "7",
    title: "Phương thức thanh toán",
  },
  {
    id: "8",
    title: "Trạng thái thanh toán",
  },
  {
    id: "9",
    title: "Ngày tạo",
  },

  {
    id: "10",
    title: "Thời gian cập nhật",
  },

  {
    id: "11",
    title: "Hành động",
  },
];

export const HEADER_TABLE_SHIPPING_FEE = [
  {
    id: "1",
    title: "Mã Tỉnh/Thành phố",
  },
  {
    id: "2",
    title: "Tên Tỉnh/Thành phố",
  },

  {
    id: "3",
    title: "Trọng lượng",
  },
  {
    id: "4",
    title: "Phí vận chuyển",
  },

  {
    id: "5",
    title: "Hành động",
  },
];

export const PACKAGE_TYPE = [
  { label: "1 đến 5 kg", packageType: "1To5kg" },
  { label: "6 đến 10 kg", packageType: "6To10kg" },
  { label: "11 đến 15 kg", packageType: "11To15kg" },
  { label: "16 đến 20 kg", packageType: "16To20kg" },
  { label: "21 đến 30 kg", packageType: "21To30kg"}
];

export const PACKAGE_TYPE_VER_2 = [
  { label: "1 đến 5 kg", packageTypeId: "456" },
  { label: "5 đến 10 kg", packageTypeId: "457" },
  { label: "11 đến 15 kg", packageTypeId: "458" },
  { label: "16 đến 20 kg", packageTypeId: "459" },
  { label: "21 đến 30 kg", packageTypeId: "461"}
];

export const COLLECT_TIME_SANG = {
  collectFromTime: "08:30:00",
  collectToTime: "11:30:00",
};

export const COLLECT_TIME_CHIEU = {
  collectFromTime: "12:30:00",
  collectToTime: "17:30:00",
};

export const COLLECT_TIME = [
  { label: "Sáng", ...COLLECT_TIME_SANG },
  { label: "Chiều", ...COLLECT_TIME_CHIEU },
];

export const parseDonationForm = (donationForm : string) =>{
  switch (donationForm) {
    case "charity":
      return "Gửi REshare để hoạt động thiện nguyện";
    case "cyclic_processing":
      return "Giao toàn quyền cho REshare xử lý";
    case "environmental_protection":
      return "Uỷ quyền cho REshare sử dụng vào mục đích phù hợp không gây lãng phí và ô nhiễm môi trường";
    default:
      return "";
  }
}

export const colorPaymentStatus = (paymentStatus : string) =>{
  switch (paymentStatus) {
    case "init":
      return "#FFCC33";
    case "paid_failed":
      return "#FF0000";
    case "complete":
      return "#33CC33";
    case "cancel":
      return "#FF0000";
    case "transferred":
      return "#33CC33";
    default:
      return "";
  }
}
export const paymentStatus = (paymentStatus : string) =>{
  switch (paymentStatus) {
    case "init":
      return "Chưa thanh toán";
    case "paid_failed":
      return "Thanh toán thất bại";
    case "complete":
      return "Đã thanh toán";
    case "cancel":
      return "Đã hủy";
    case "transferred":
      return "Đã chuyển khoản";
    default:
      return "Chưa thanh toán";
  }
};

export const paymentMethod = (paymentMethod: string) => {
  switch (paymentMethod) {
    case "offline":
      return "Tiền mặt";
    case "masterCard":
      return "Master Card";
    case "vnPay":
      return "VNPAY";
    case "momo":
      return "MOMO";
    default:
      return "";
  }
};
export const processesStatus = (status: string) => {
  switch (status) {
    case "waiting_to_pick":
      return "Chờ lấy hàng";
    case "delivering":
      return "Đang giao hàng";
    case "delivered":
      return "Hàng đã được giao";
    case "returned":
      return "Hàng đã trả lại kho";
    case "cancelled":
      return "Giao hàng bị huỷ";
    case "delay_pickup":
      return "Lấy hàng trễ";
    case "picking":
      return "Đang lấy hàng";
    case "picked":
      return "Đã lấy hàng";
    case "pickup_failed":
      return "Lấy hàng không thành công";
    case "delay_delivery":
      return "Giao hàng trễ";
    case "delivery_failed":
      return "Giao hàng thất bại";
    case "waiting_to_return":
      return "Chờ trả hàng";
    case "returning":
      return "Đang trả hàng";
    case "return_failed":
      return "Trả hàng không thành công";
    case "delay_return":
      return "Trả hàng trễ";
    case "compensation":
      return "Đền bù hàng";
    default:
      return "";
  }
};

export const colorPaymentMethod = (paymentMethod: string) =>  {
  switch (paymentMethod) {
    case "offline":
      return "#33CC33";
    case "masterCard":
      return "#E4001A";
    case "vnPay":
      return "#0058A5";
    case "momo":
      return "#A91F6D";
    default:
      return "";
  }
};

export const typeZeroWaste = (partnerPackageTypesConfigId :string) => {
  switch(partnerPackageTypesConfigId){
    case "456":
      return "1-5kg";
    case "457":
      return "5-10kg";
    case "458":
      return "11-15kg";
    case "459": 
      return "16-20kg";
    case "461": 
      return "21-30kg"
    default:
      return "";
  }
}

export const typeRecycle = (type :string) => {
  switch(type){
    case "100To500":
      return "100kg - 500kg";
    case "600To1000":
      return "600kg - 1000kg";
    case "2000To5000":
      return "2000kg - 5000kg";
    default:
      return type;
  }
}

export const serviceType = (type :string) => {
  switch(type){
    case "suitable-product":
      return "Dịch Vụ Tái Chế Và Nhận Lại Sản Phẩm Tái Chế Phù Hợp";
    case "non-fading-treatment":
      return "Dịch Vụ Tái Chế Và Xử Lý Không Phai";
    case "new-recycled-clothes":
      return "Dịch Vụ Tái Chế Và Nhận Lại Quần Áo Tái Chế Mới";
    default:
      return type;
  }
}
