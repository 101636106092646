import { useEffect, useState } from "react";
import clsx from "clsx";

import styles from "./Home.module.scss";
import OverView from "../../components/Dashboard/Overview/Overview";
import CustomerChart from "../../components/Dashboard/CustomerChart/CustomerChart";
import dashboard from "../../apis/dashboard";
import { useSnackbar } from "notistack";
import OrderQuantityChart from "../../components/Dashboard/OrderQuantityChart/OrderQuantityChart";
import OrderAmountChart from "../../components/Dashboard/OrderAmountChart/OrderAmountChart";
import OrderWeightChart from "../../components/Dashboard/OrderWeightChart/OrderWeightChart";

export interface HomeProps {
  className?: string;
}

const Home = (props: HomeProps) => {
  const { className } = props;
  const [infoCustomerChart, setInfoCustomerChart] = useState();
  const [infoCustomer, setInfoCustomer] = useState();
  const [infoOrder, setInfoOrder] = useState();
  const [infoOrderAmount, setInfoOrderAmount] = useState();
  const [infoOrderWeight, setInfoOrderWeight] = useState();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    try {
      (async () => {
        const res = await dashboard.getOrderAmountInfo({});
        if (res?.data?.code === 0) {
          setInfoOrderAmount(res.data.data)
        }
        else{
          enqueueSnackbar(res.data?.data?.data.message, {variant:"error"} )
        }
      })()
    } catch (error:any) {
      enqueueSnackbar(error.message, {variant:"error"} )
    }
  },[])

  useEffect(() => {
    try {
      (async () => {
        const res = await dashboard.getOrderWeightInfo({});
        if (res?.data?.code === 0) {
          setInfoOrderWeight(res.data.data)
        }
        else{
          enqueueSnackbar(res.data?.data?.data.message, {variant:"error"} )
        }
      })()
    } catch (error:any) {
      enqueueSnackbar(error.message, {variant:"error"} )
    }
  },[])


  useEffect(() => {
    try {
      (async () => {
        const res = await dashboard.getOrderQuantityInfo({});
        if (res?.data?.code === 0) {
          setInfoOrder(res.data.data)
        }
        else{
          enqueueSnackbar(res.data?.data?.data.message, {variant:"error"} )
        }
      })()
    } catch (error:any) {
      enqueueSnackbar(error.message, {variant:"error"} )
    }
  },[])


  useEffect(() => {
    try {
      (async () => {
        const res = await dashboard.getCustomerQuantityChartInfo({});
        if (res?.data?.code === 0) {
          setInfoCustomerChart(res.data?.data?.data)
        }
        else{
          enqueueSnackbar(res.data?.data?.data.message, {variant:"error"} )
        }
      })()
    } catch (error:any) {
      enqueueSnackbar(error.message, {variant:"error"} )
    }
  },[])

  useEffect(() => {
    try {
      (async () => {
        const res = await dashboard.getCustomerQuantityInfo({});
        if (res?.data?.code === 0) {
          setInfoCustomer(res.data?.data)
        }
        else{
          enqueueSnackbar(res.data?.data?.data.message, {variant:"error"} )
        }
      })()
    } catch (error:any) {
      enqueueSnackbar(error.message, {variant:"error"} )
    }
  },[])

  return (
    <div className={clsx(styles.root, className && className)}>
      <OverView orderAmountInfo={infoOrderAmount} orderWeightInfo={infoOrderWeight} orderInfo={infoOrder} customerInfo={infoCustomer}/>
      <div className={styles.chart}>
        <CustomerChart dataInfo={infoCustomer} data={infoCustomerChart}/>
        <OrderQuantityChart infoOrder={infoOrder}/>
        <OrderAmountChart/>
        <OrderWeightChart/>
      </div>
    </div>
  );
};

export default Home;
