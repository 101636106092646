import {
  Box,
  FormControl,
  Modal,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent, 
  Button
} from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./ModalUpdatePaymentStatus.module.scss"
const optionStatus = [
    {
      id: "1",
      value: "init",
      label: "Chưa thanh toán", 
    },
    {
      id: "2",
      value: "complete",
      label: "Đã thanh toán", 
    },
    {
      id: "3",
      value: "cancel",
      label: "Đã hủy", 
    },
    {
      id: "4",
      value: "transferred",
      label: "Đã chuyển khoản", 
    }
]
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
export interface UpdatePaymentProps {
    className?: string;
    open: boolean;
    handleClose: () => void;
    dataUpdatePayment: any;
    setDataUpdatePayment: any;
    handleUpdatePaymentStatus: any;
  }
const ModalUpdatePaymentStatus = (props: UpdatePaymentProps) => {
  const {open, handleClose, className, dataUpdatePayment,setDataUpdatePayment,handleUpdatePaymentStatus} = props
  const [status, setStatus] = useState("")
  
  useEffect(() => {
    if(dataUpdatePayment){
      setStatus(dataUpdatePayment.status)
    }
  },[dataUpdatePayment.status, open])
  
  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className={styles.title} id="modal-modal-title" variant="h6" component="h2">
            Cập nhật trạng thái thanh toán
          </Typography>
          <FormControl className={styles.form} sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Trạng thái</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={status ? status : ""}
              label="Status"
              onChange={handleChange}
            >
              {
                optionStatus.map((status) => {
                  return(
                  <MenuItem key={status.id} value={status.value}>{status.label}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <div className={styles.button}>
          <Button onClick={() => {
            handleUpdatePaymentStatus(dataUpdatePayment.donationId, status );
            handleClose();
            }} variant="contained" size="small">Cập nhật</Button>
          <Button onClick={handleClose} className={styles.btnCancel} variant="outlined"  size="small">Thoát</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalUpdatePaymentStatus;
