import { Button } from "@mui/material";
import DISTRICTS from "../../../constants/district";
import PROVINCE from "../../../constants/province";
import WARD from "../../../constants/ward";
import styles from "./OrderItem.module.scss";
import dayjs from "dayjs";
import { PACKAGE_TYPE, colorPaymentStatus, paymentStatus } from "../../../constants/constant";
import donationApi from "../../../apis/donationApi";
import { useSnackbar } from "notistack";

interface OrderItemProps {
  order: any;
  handleReload: () => void;
}
const OrderItem = (props: OrderItemProps) => {
  const { handleReload } = props;
  const { enqueueSnackbar } = useSnackbar();

  console.log(props);

  const parseAddress = (address: any) => {
    const province = PROVINCE.find(
      (p: any) => p.code === address?.provinceID
    ) || { label: "" };

    const district = DISTRICTS.find(
      (d: any) => d.code === address?.districtID
    ) || { name: "" };

    const ward = WARD.find((w: any) => w.code === address?.wardID) || {
      name: "",
    };

    return `${address?.address}, ${ward.name}, ${district.name}, ${province.label}`;
  };

  const handleConfirmDonation = async (id: string) => {
    try {
      const res = await donationApi.confirmDonationShipment({ id });
      if (res?.data?.data) {
        handleReload();
        enqueueSnackbar("Xác nhận thành công", { variant: "success" });
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePostDonationShipment = async (id: string) => {
    try {
      const res = await donationApi.postDonationShipment({ id });
      if (res?.data?.data) {
        handleReload();
        enqueueSnackbar("Cập nhật thành công", { variant: "success" });
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <tr>
      <td width="30%">
        <p>Mã đơn: {props.order.code}</p>
        <p>
          Trạng thái:{" "}
          <span
            style={{ color: props.order.status === "new" ? "blue" : "#34C3A7" }}
          >
            {props.order.status === "new" ? "Mới" : "Đã xác nhận"}
          </span>
        </p>
        <p>
          Trạng thái thanh toán:{" "}
          <span
            style={{ color: colorPaymentStatus(props.order.paymentStatus) }}
          >
            {paymentStatus(props.order.paymentStatus)}
          </span>
        </p>
        <p>
          Ngày tạo: {dayjs(props.order.createdAt).format("DD/MM/YYYY HH:mm")}
        </p>
        {props.order.customerNote && (
          <p>Ghi chú : {props.order.customerNote}</p>
        )}
        <p>
          Trọng lượng hàng:{" "}
          {
            PACKAGE_TYPE.find((p: any) => p.packageType === props.order.packageType)
              ?.label
          }
        </p>
        <p>
          Ngày dự kiến nhận hàng: {dayjs(props.order.collectDate).format("DD/MM/YYYY")}
        </p>
        <p>
          Giá vận chuyển: {props.order.shippingPrice.toLocaleString("vi")} VNĐ
        </p>
        <p>
          Tổng tiền: {props.order.totalPrice.toLocaleString("vi")} VNĐ
        </p>
      </td>
      <td width="20%">
        <p>Tên: {props.order.customerName}</p>
        <p>Sđt: {props.order.customerPhone}</p>
      </td>
      <td width="25%">
        <p>{parseAddress(props.order?.customerAddress)}</p>
      </td>
      <td>
        {props.order.status && props.order.status === "new" && (
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => handleConfirmDonation(props.order.id)}
          >
            Xác nhận
          </Button>
        )}
        {props.order.status && props.order.status === "confirm" && (
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => handlePostDonationShipment(props.order.id)}
          >
            Đang lấy hàng
          </Button>
        )}
      </td>
    </tr>
  );
};

export default OrderItem;
