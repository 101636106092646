import React, { useEffect, useState, Fragment, useCallback } from "react";
import clsx from "clsx";

import styles from "./Orders.module.scss";
import donationApi from "../../apis/donationApi";
import OrderList from "../../components/Orders/OrderList";
import Loading from "../../components/Loading";
import Search from "../../components/Orders/Search";
import Filter from "../../components/Orders/Filter";
import { STATUS_TAB } from "../../constants/constant";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ModalValidateCancel from "../../components/ModalValidateCancel";
import Pagination from "@mui/material/Pagination";
import TotalOrder from "../../components/Orders/TotalOrder/TotalOrder";
export interface OrdersProps {
  className?: string;
}

export const INIT_DATA_REQUEST_FILTER = {
  ids: [],
  shippingTypeId: "",
  packageTypeId: "",
  code: "",
  customerId: "",
  customerName: "",
  customerPhone: "",
  collectFromTime: "",
  collectToTime: "",
  statuses: STATUS_TAB[0].value,
};

const Orders = (props: OrdersProps) => {
  const { className } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [dataAddBag, setDataAddBag] = useState<any>({});
  const [reload, setReload] = useState(false);

  const [openValidate, setOpenValidate] = useState(false);
  const handleOpenValidate = () => setOpenValidate(true);
  const handleCloseValidate = () => setOpenValidate(false);

  const [selectedTab, setSelectedTab] = useState<string[]>(STATUS_TAB[0].value);
  const [arrIndexShowmore, setArrIndexShowmore] = useState<number[]>([]);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [statisticalData, setStatisticalData] = useState();

  const [totalData, setTotalData] = useState(0);

  const [dataRequest, setDataRequest] = useState({
    filter: {
      ...INIT_DATA_REQUEST_FILTER,
    },
    paging: {
      offset: 0,
      limit: 10,
    },
  });
  
  const [userDonations, setUserDonations] = useState([]);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    (async () => {
      const res = await donationApi.countOrder(dataRequest);
      if (res.data.code === 0) {
        setTotalData(res.data.data.quantity);
      } else {
        enqueueSnackbar(res?.data?.message, { variant: "error" });
      }
    })();
  }, [dataRequest.filter, selectedTab]);

  useEffect(() => {
    setOffset((page - 1) * dataRequest.paging.limit);

    setDataRequest((dataRequest) => {
      return {
        ...dataRequest,
        paging: {
          offset: offset,
          limit: 10,
        },
      };
    });
  }, [offset, page]);

  const handleReload = useCallback(() => {
    setReload((reload) => !reload);
  }, []);

  useEffect(() => {
    (async () => {
      try {
          setLoading(true);
          const res = await donationApi.getDonations(dataRequest);
          if (res.data.code === 0) {
            setUserDonations(res?.data?.data?.userDonations);
            setLoading(false);
          }
          else{
            enqueueSnackbar(res.data.message, {variant:"error"} )
          }
      } catch (error) {
        enqueueSnackbar("Lỗi hệ thống", {variant:"error"} )
      }
    })();
  }, [dataRequest, enqueueSnackbar, reload]);

  useEffect(() => {
    (async () => {
      const res = await donationApi.searchStatisticalData(dataRequest);
      if (res.data.code === 0) {
        setStatisticalData(res.data.data);
      }
    })();
  }, [dataRequest]);

  const handlePostDonationShipment = async (id: string) => {
    try {
      const res = await donationApi.postDonationShipment({ id });
      if (res?.data?.data) {
        enqueueSnackbar("Cập nhật thành công", { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDonation = async (id: string) => {
    try {
      const res = await donationApi.confirmDonationShipment({ id });
      if (res?.data?.data) {
        enqueueSnackbar("Xác nhận thành công", { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [idOrderCancel, setIdOrderCancel] = useState<string>("");

  const handleCancelDonation = async (id: any) => {
    const dataRequest = {
      id,
      reason_code: "1009OTHERS",
      reason_note: "01GKHPKTNV87GPD8B457XKTJBN",
    };

    try {
      const res = await donationApi.cancelDonation(dataRequest);
      if (res?.data?.data) {
        enqueueSnackbar(res.data.message, { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickCancelDonation = (id: string) => {
    setIdOrderCancel(id);
    handleOpenValidate();
  };

  const handleDoneDonation = async (id: string) => {
    try {
      const res = await donationApi.doneDonation({
        id,
      });
      if (res?.data?.data) {
        enqueueSnackbar(res.data.message, { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddBag = async (id: string, donationID: string) => {
    try {
      const res = await donationApi.addBag({
        id,
        orderId: donationID,
        orderType: "1101UD",
      });
      if (res?.data?.data) {
        enqueueSnackbar(res.data.message, { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdatePaymentStatus = async (
    donationId: string,
    statusPayment: string
  ) => {
    try {
      const res = await donationApi.updatePaymentStatus({
        donationId: donationId,
        status: statusPayment,
      });
      if (res?.data?.data) {
        enqueueSnackbar("Cập nhật thành công", { variant: "success" });
        handleReload();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeTab = (value: string[]) => {
    setSelectedTab(value);
    setArrIndexShowmore([]);
    setDataRequest({
      filter: {
        ...dataRequest.filter,
        statuses: value,
      },
      paging: { limit: 10, offset: 0 },
    });
  };

  const handleShowMore = (index: number) => {
    if (arrIndexShowmore.includes(index)) {
      const newArr = arrIndexShowmore.filter((item: number) => item !== index);
      setArrIndexShowmore(newArr);
    } else {
      setArrIndexShowmore([...arrIndexShowmore, index]);
    }
  };

  return (
    <div className={clsx(styles.root, className && className)}>
      {userDonations ? (
        <Fragment>
          <Search />
          <Filter setDataRequest={setDataRequest} dataRequest={dataRequest} />
          <OrderList
            userDonations={userDonations}
            setDataRequest={setDataRequest}
            dataRequest={dataRequest}
            loading={loading}
            handlePostDonationShipment={handlePostDonationShipment}
            handleCancelDonation={handleClickCancelDonation}
            handleConfirmDonation={handleConfirmDonation}
            handleDoneDonation={handleDoneDonation}
            handleUpdatePaymentStatus={handleUpdatePaymentStatus}
            setDataAddBag={setDataAddBag}
            dataAddBag={dataAddBag}
            handleAddBag={handleAddBag}
            handleChangeTab={handleChangeTab}
            selectedTab={selectedTab}
            arrIndexShowmore={arrIndexShowmore}
            handleShowMore={handleShowMore}
            reload={handleReload}
          />
          <div className={styles.pagination}>
            <TotalOrder statisticalData={statisticalData} />
            <Pagination
              count={Math.ceil(totalData / dataRequest.paging.limit)}
              page={page}
              onChange={changePage}
              variant="outlined"
              color="primary"
              size="large"
            />
          </div>

          <ModalValidateCancel
            open={openValidate}
            handleClose={handleCloseValidate}
            handleOpen={handleOpenValidate}
            handleCancelDonation={handleCancelDonation}
            idOrderCancel={idOrderCancel}
          />
        </Fragment>
      ) : (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Orders;
