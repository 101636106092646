import { useEffect, useState } from "react";
import styles from "./OrderAmountChart.module.scss";
import MenuIcon from "@mui/icons-material/Menu";
import dashboard from "../../../apis/dashboard";
import { useSnackbar } from "notistack";
import ReactApexChart from "react-apexcharts";
import { CHART_TYPE } from "../../../constants/constant";
import DatePicker from "../../DatePicker";
import dayjs from "dayjs";

const initParams = {
  chartType: "day",
  createdAts: {
    from: dayjs(),
    to: dayjs(),
  },
};

type OrderAmountChartProps = {
  infoOrder?: any;
};

const OrderAmountChart = (props: OrderAmountChartProps) => {
  const { infoOrder } = props;
  const [dataChart, setDataChart] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [params, setParams] = useState(initParams);
  const [showTypeChart, setShowTypeChart] = useState(false);
  const dayjs = require('dayjs')
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)

  useEffect(() => {
    (async () => {
      try {
        const res = await dashboard.getOrderAmountChartInfo({
          ...params,
          createdAts: {
            from: dayjs(params.createdAts.from).startOf("day"),
            to: dayjs(params.createdAts.to).endOf("day"),
          },
        });
        if (res.data.code === 0) {
          setDataChart({
            series: [
              {
                name: "Phí vận chuyển",
                data: res.data.data.data.map(
                  (item: any) => item.shipOrderAmount
                ),
              },
              {
                name: "Phí xử lý",
                data: res.data.data.data.map(
                  (item: any) => item.zeroWasteOrderAmount
                ),
              },
            ],
            options: {
              dataLabels: {
                enabled: true,
                formatter: function (val:number) {
                  return val.toLocaleString("vi");
                },
               
                style: {
                  fontSize: '12px',
                  colors: ["#304758"]
                }
              },
              yaxis: {
                axisBorder: {
                  show: false
                },
                axisTicks: {
                  show: false,
                },
                labels: {
                  show: false,
                  formatter: function (val:number) {
                    return val.toLocaleString("vi");
                  }
                }
              },
              chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: "bottom",
                      offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    total: {
                      enabled: true,
                      style: {
                        fontSize: "13px",
                        fontWeight: 900,
                      },
                    },
                  },
                },
              },
              xaxis: {
                type: "string",
                categories: res.data.data.data.map(
                  (item: any) => item[params.chartType]
                ),
              },
              legend: {
                position: "top",
                offsetY: 6,
              },
              fill: {
                opacity: 1,
              },
            },
          });
        } else {
          enqueueSnackbar(res.data?.message);
        }
      } catch (error: any) {
        enqueueSnackbar(error.message);
      }
    })();
  }, [params]);

  const handleShowTypeChart = () => {
    setShowTypeChart(!showTypeChart);
  };

  const handleChangeTypeChart = (type: string) => {
    setParams({
      ...params,
      chartType: type,
    });
    setShowTypeChart(false);
  };

  return (
    <div className={styles.chart}>
      <h4>Doanh thu</h4>
      <div className={styles.filter}>
        <div className={styles.date}>
          <div className={styles.filDate}>
            <div style={{width: "200px"}}>
            <DatePicker
              label="Từ ngày"
              value={params.createdAts.from}
              onChange={(value:any) => setParams({
                ...params,
                createdAts: {
                  ...params.createdAts,
                  from: value
                }
              })}
              setValue={(value:any) => setParams({
                ...params,
                createdAts: {
                  ...params.createdAts,
                  from: value
                }
              })}
            />
            </div>
            <div style={{width: "200px"}}>
            <DatePicker
              label="Đến ngày"
              value={params.createdAts.to}
              onChange={(value:any) => setParams({
                ...params,
                createdAts: {
                  ...params.createdAts,
                  to: value
                }
              })}
              setValue={(value:any) => setParams({
                ...params,
                createdAts: {
                  ...params.createdAts,
                  to: value
                }
              })}
            />
            </div>
          </div>
        </div>
        <div className={styles.info} onClick={handleShowTypeChart}>
          <MenuIcon className={styles.optionsTypeChart} />
        </div>
      </div>

      {showTypeChart && (
        <div className={styles.options}>
          <div className={styles.op}>
            {CHART_TYPE.map((item: any) => (
              <li onClick={() => handleChangeTypeChart(item.value)}>
                {item.label}
              </li>
            ))}
          </div>
        </div>
      )}
      <div id="chart">
        <div className="mixed-chart">
          {dataChart && (
            <ReactApexChart
              options={dataChart.options}
              series={dataChart.series}
              type="bar"
              width="100%"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderAmountChart;
