const DISTRICTS = [
  {
    code: "1482",
    name: "Quận Bắc Từ Liêm",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1484",
    name: "Quận Ba Đình",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1485",
    name: "Quận Cầu Giấy",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1486",
    name: "Quận Đống Đa",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1488",
    name: "Quận Hai Bà Trưng",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1489",
    name: "Quận Hoàn Kiếm",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1490",
    name: "Quận Hoàng Mai",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1491",
    name: "Quận Long Biên",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1492",
    name: "Quận Tây Hồ",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1493",
    name: "Quận Thanh Xuân",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1542",
    name: "Quận Hà Đông",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1581",
    name: "Huyện Mê Linh",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1582",
    name: "Huyện Đông Anh",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1583",
    name: "Huyện Sóc Sơn",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1703",
    name: "Huyện Gia Lâm",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1710",
    name: "Huyện Thanh Trì",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1711",
    name: "Thị xã Sơn Tây",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1803",
    name: "Huyện Ba Vì",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1804",
    name: "Huyện Đan Phượng",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1805",
    name: "Huyện Hoài Đức",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1806",
    name: "Huyện Mỹ Đức",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1807",
    name: "Huyện Phúc Thọ",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1808",
    name: "Huyện Thạch Thất",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1809",
    name: "Huyện Thanh Oai",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1810",
    name: "Huyện Ứng Hòa",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1915",
    name: "Huyện Chương Mỹ",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "2004",
    name: "Huyện Quốc Oai ",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "3255",
    name: "Huyện Phú Xuyên",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "3303",
    name: "Huyện Thường Tín",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "3440",
    name: "Quận Nam Từ Liêm",
    is_higher_price: false,
    province_code: "201",
  },
  {
    code: "1442",
    name: "Quận 1",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1443",
    name: "Quận 2",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1444",
    name: "Quận 3",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1446",
    name: "Quận 4",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1447",
    name: "Quận 5",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1448",
    name: "Quận 6",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1449",
    name: "Quận 7",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1450",
    name: "Quận 8",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1451",
    name: "Quận 9",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1452",
    name: "Quận 10",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1453",
    name: "Quận 11",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1454",
    name: "Quận 12",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1455",
    name: "Quận Tân Bình",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1456",
    name: "Quận Tân Phú",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1457",
    name: "Quận Phú Nhuận",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1458",
    name: "Quận Bình Tân",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1459",
    name: "Huyện Hóc Môn",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1460",
    name: "Huyện Củ Chi",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1461",
    name: "Quận Gò Vấp",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1462",
    name: "Quận Bình Thạnh",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1463",
    name: "Quận Thủ Đức",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1533",
    name: "Huyện Bình Chánh",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1534",
    name: "Huyện Nhà Bè",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "2090",
    name: "Huyện Cần Giờ",
    is_higher_price: false,
    province_code: "202",
  },
  {
    code: "1526",
    name: "Quận Hải Châu",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "1527",
    name: "Quận Thanh Khê",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "1528",
    name: "Quận Sơn Trà",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "1529",
    name: "Quận Ngũ Hành Sơn",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "1530",
    name: "Quận Liên Chiểu",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "1531",
    name: "Quận Cẩm Lệ",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "1687",
    name: "Huyện Hòa Vang",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "2112",
    name: "Huyện đảo Hoàng Sa",
    is_higher_price: false,
    province_code: "203",
  },
  {
    code: "1536",
    name: "Thành phố Biên Hòa",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1691",
    name: "Huyện Trảng Bom",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1692",
    name: "Thị xã Long Khánh",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1693",
    name: "Huyện Tân Phú",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1694",
    name: "Huyện Long Thành",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1700",
    name: "Huyện Định Quán",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1702",
    name: "Huyện Cẩm Mỹ",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1704",
    name: "Huyện Xuân Lộc",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1705",
    name: "Huyện Thống Nhất",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1708",
    name: "Huyện Nhơn Trạch",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "2049",
    name: "Huyện Vĩnh Cửu",
    is_higher_price: false,
    province_code: "204",
  },
  {
    code: "1538",
    name: "Thành phố Thủ Dầu Một",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "1540",
    name: "Thị xã Dĩ An",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "1541",
    name: "Thị xã Thuận An",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "1695",
    name: "Thị xã Tân Uyên",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "1696",
    name: "Thị xã Bến Cát",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "1746",
    name: "Huyện Dầu Tiếng",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "1992",
    name: "Huyện Phú Giáo",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "3132",
    name: "Huyện Bàu Bàng",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "3135",
    name: "Huyện Bắc Tân Uyên",
    is_higher_price: false,
    province_code: "205",
  },
  {
    code: "1544",
    name: "Thành phố Vũng Tàu",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1667",
    name: "Thành phố Bà Rịa",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1688",
    name: "TT Phú Mỹ - cũ",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1689",
    name: "Huyện Long Điền",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1690",
    name: "Huyện Đất Đỏ",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1699",
    name: "Huyện Xuyên Mộc",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1701",
    name: "Thị xã Phú Mỹ",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1709",
    name: "Huyện Châu Đức",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "2111",
    name: "Huyện đảo Côn Đảo",
    is_higher_price: false,
    province_code: "206",
  },
  {
    code: "1546",
    name: "Thành phố Pleiku",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1793",
    name: "Huyện Ia Grai",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1794",
    name: "Huyện Đức Cơ",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1795",
    name: "Huyện Chư Prông",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1796",
    name: "Huyện Chư Sê",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1797",
    name: "Huyện Phú Thiện",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1798",
    name: "Thị xã Ayun Pa",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1799",
    name: "Huyện Ia Pa",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1800",
    name: "Thị xã An Khê",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1801",
    name: "Huyện Chư Păh",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "2101",
    name: "Huyện Chư Pưh",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "2118",
    name: "Huyện Đắk Đoa",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "2119",
    name: "Huyện Đắk Pơ",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "2144",
    name: "Huyện KBang",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "2149",
    name: "Huyện Kông Chro",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "2152",
    name: "Huyện Krông Pa",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "2165",
    name: "Huyện Mang Yang",
    is_higher_price: false,
    province_code: "207",
  },
  {
    code: "1548",
    name: "Thành phố Nha Trang",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "1664",
    name: "Thành phố Cam Ranh",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "1739",
    name: "Huyện Diên Khánh",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "1829",
    name: "Huyện Vạn Ninh",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "1902",
    name: "Huyện Cam Lâm",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "2061",
    name: "Thị xã Ninh Hòa",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "2117",
    name: "Huyện đảo Trường Sa",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "3212",
    name: "Huyện Khánh Sơn",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "3213",
    name: "Huyện Khánh Vĩnh",
    is_higher_price: false,
    province_code: "208",
  },
  {
    code: "1550",
    name: "Thành phố Đà Lạt",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1836",
    name: "Huyện Đơn Dương",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1837",
    name: "Huyện Đức Trọng",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1838",
    name: "Thành phố Bảo Lộc",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1839",
    name: "Huyện Bảo Lâm",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1919",
    name: "Huyện Đam Rông",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1956",
    name: "Huyện Lạc Dương",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1958",
    name: "Huyện Lâm Hà",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "2104",
    name: "Huyện Đạ Huoai",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "2106",
    name: "Huyện Đạ Tẻh",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "3146",
    name: "Huyện Cát Tiên",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "3160",
    name: "Huyện Di Linh",
    is_higher_price: false,
    province_code: "209",
  },
  {
    code: "1552",
    name: "Thành phố Buôn Ma Thuột",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1784",
    name: "Huyện Buôn Ðôn",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1785",
    name: "Huyện Cư M gar",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1786",
    name: "Huyện Ea H leo",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1787",
    name: "Huyện Krông Năng",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1788",
    name: "Thị Xã Buôn Hồ",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1789",
    name: "Huyện Krông Bông",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1884",
    name: "Huyện Krông Ana",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1931",
    name: "Huyện Ea Kar",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1954",
    name: "Huyện Krông Pắc",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "2131",
    name: "Huyện Ea Súp",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "2150",
    name: "Huyện Krông Búk",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "3153",
    name: "Huyện Cư Kuin",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "3217",
    name: "Huyện Lắk",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "3418",
    name: "Huyện M đrắk",
    is_higher_price: false,
    province_code: "210",
  },
  {
    code: "1554",
    name: "Thành phố Tân An",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "1894",
    name: "Huyện Bến Lức",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "1906",
    name: "Huyện Cần Đước",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "1907",
    name: "Huyện Cần Giuộc",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "1909",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "1929",
    name: "Huyện Đức Hòa",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "2016",
    name: "Huyện Tân Trụ",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "2031",
    name: "Huyện Thủ Thừa",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "2129",
    name: "Huyện Đức Huệ",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "3227",
    name: "Huyện Mộc Hóa",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "3273",
    name: "Huyện Tân Hưng",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "3276",
    name: "Huyện Tân Thạnh",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "3293",
    name: "Huyện Thạnh Hóa",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "3315",
    name: "Huyện Vĩnh Hưng",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "3329",
    name: "Thị xã Kiến Tường",
    is_higher_price: false,
    province_code: "211",
  },
  {
    code: "1556",
    name: "Thành phố Mỹ Tho",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "1740",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "1741",
    name: "Huyện Chợ Gạo",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "1900",
    name: "Huyện Cái Bè",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "1932",
    name: "Huyện Gò Công Đông",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "1933",
    name: "Huyện Gò Công Tây",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "2055",
    name: "Thị xã Cai Lậy",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "2057",
    name: "Thị xã Gò Công",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "2084",
    name: "Huyện Cai Lậy",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "2216",
    name: "Huyện Tân Phú Đông",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "3275",
    name: "Huyện Tân Phước",
    is_higher_price: false,
    province_code: "212",
  },
  {
    code: "1558",
    name: "Thành phố Bến Tre",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "1742",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "1888",
    name: "Huyện Ba Tri",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "1895",
    name: "Huyện Bình Đại",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "1937",
    name: "Huyện Giồng Trôm",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "1974",
    name: "Huyện Mỏ Cày Bắc",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "1975",
    name: "Huyện Mỏ Cày Nam",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "2028",
    name: "Huyện Thạnh Phú",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "3158",
    name: "Huyện Chợ Lách",
    is_higher_price: false,
    province_code: "213",
  },
  {
    code: "1560",
    name: "Thành phố Trà Vinh",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "1908",
    name: "Huyện Cầu Ngang",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "1911",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "2020",
    name: "Huyện Tiểu Cần",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "2033",
    name: "Huyện Trà Cú",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "2086",
    name: "Huyện Càng Long",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "2091",
    name: "Huyện Cầu Kè",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "2103",
    name: "Huyện Duyên Hải",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "3443",
    name: "Thị xã Duyên Hải",
    is_higher_price: false,
    province_code: "214",
  },
  {
    code: "1562",
    name: "Thành phố Vĩnh Long",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "1962",
    name: "Huyện Long Hồ",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "2008",
    name: "Huyện Tam Bình",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "2034",
    name: "Huyện Trà Ôn",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "2054",
    name: "Thị xã Bình Minh",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "2081",
    name: "Huyện Bình Tân",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "2164",
    name: "Huyện Mang Thít",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "2263",
    name: "Huyện Vũng Liêm",
    is_higher_price: false,
    province_code: "215",
  },
  {
    code: "1564",
    name: "Thành phố Cao Lãnh",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "1668",
    name: "Thành phố Sa Đéc",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "1724",
    name: "Huyện Cao Lãnh",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "1725",
    name: "Huyện Lai Vung",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "1961",
    name: "Huyện Lấp Vò",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "2011",
    name: "Huyện Tam Nông",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "2013",
    name: "Huyện Tân Hồng",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "2026",
    name: "Huyện Thanh Bình",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "2030",
    name: "Huyện Tháp Mười",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "2059",
    name: "Thị xã Hồng Ngự",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "3155",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "3200",
    name: "Huyện Hồng Ngự",
    is_higher_price: false,
    province_code: "216",
  },
  {
    code: "1566",
    name: "Thành phố Long Xuyên",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1718",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1750",
    name: "Huyện Thoại Sơn",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1751",
    name: "Huyện Tri Tôn",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1752",
    name: "Huyện Tịnh Biên",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1753",
    name: "Thành phố Châu Đốc",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1754",
    name: "Huyện An Phú",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1755",
    name: "Huyện Tân Châu",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1756",
    name: "Huyện Phú Tân",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1757",
    name: "Huyện Chợ Mới",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1758",
    name: "Huyện Châu Phú",
    is_higher_price: false,
    province_code: "217",
  },
  {
    code: "1568",
    name: "Thành phố Sóc Trăng",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "1743",
    name: "Huyện Mỹ Xuyên",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "1910",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "1949",
    name: "Huyện Kế Sách",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "2037",
    name: "Huyện Trần Đề",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "2062",
    name: "Thị xã Ngã Năm",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "2093",
    name: "Huyện Cù Lao Dung",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "2161",
    name: "Huyện Long Phú",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "2173",
    name: "Huyện Mỹ Tú",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "2238",
    name: "Huyện Thạnh Trị",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "2272",
    name: "Thị xã Vĩnh Châu",
    is_higher_price: false,
    province_code: "218",
  },
  {
    code: "1570",
    name: "Thành phố Rạch Giá",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "1719",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "1830",
    name: "Huyện Hòn Đất",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "1831",
    name: "Huyện Tân Hiệp",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "1832",
    name: "Huyện Giồng Riềng",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "1833",
    name: "Huyện An Biên",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "1950",
    name: "Huyện Kiên Lương",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "2058",
    name: "Thị xã Hà Tiên",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "2113",
    name: "Huyện đảo Kiên Hải",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "2115",
    name: "Huyện đảo Phú Quốc",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "2132",
    name: "Huyện Gò Quao",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "2134",
    name: "Huyện Giang Thành",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "2251",
    name: "Huyện U Minh Thượng",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "2260",
    name: "Huyện Vĩnh Thuận",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "3125",
    name: "Huyện An Minh",
    is_higher_price: false,
    province_code: "219",
  },
  {
    code: "1572",
    name: "Quận Ninh Kiều",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "1573",
    name: "Quận Bình Thủy",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "1574",
    name: "Quận Cái Răng",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "1575",
    name: "Quận Ô Môn",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "1576",
    name: "Quận Thốt Nốt",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "3150",
    name: "Huyện Cờ Đỏ",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "3250",
    name: "Huyện Phong Điền",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "3300",
    name: "Huyện Thới Lai",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "3317",
    name: "Huyện Vĩnh Thạnh",
    is_higher_price: false,
    province_code: "220",
  },
  {
    code: "1578",
    name: "Thành phố Vĩnh Yên",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "1732",
    name: "Huyện Bình Xuyên",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "1733",
    name: "Huyện Vĩnh Tường",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "1734",
    name: "Huyện Yên Lạc",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "1960",
    name: "Huyện Lập Thạch",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "2009",
    name: "Huyện Tam Dương",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "2065",
    name: "Thị xã Phúc Yên",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "3265",
    name: "Huyện Sông Lô",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "3271",
    name: "Huyện Tam Đảo",
    is_higher_price: false,
    province_code: "221",
  },
  {
    code: "1585",
    name: "Thành phố Huế",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "1697",
    name: "Thị xã Hương Trà",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "1698",
    name: "Thị xã Hương Thủy",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "1749",
    name: "Huyện Phú Vang",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "1882",
    name: "Huyện Phú Lộc",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "1885",
    name: "Huyện A Lưới",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "2193",
    name: "Huyện Phong Điền",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "3234",
    name: "Huyện Nam Đông",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "3257",
    name: "Huyện Quảng Điền",
    is_higher_price: false,
    province_code: "223",
  },
  {
    code: "1587",
    name: "Quận Ngô Quyền",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1588",
    name: "Quận Lê Chân",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1589",
    name: "Quận Hồng Bàng",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1590",
    name: "Quận Kiến An",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1591",
    name: "Quận Hải An",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1706",
    name: "Quận Dương Kinh",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1707",
    name: "Quận Đồ Sơn",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1726",
    name: "Huyện Thủy Nguyên",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1819",
    name: "Huyện An Dương",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1820",
    name: "Huyện An Lão",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1821",
    name: "Huyện Tiên Lãng",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1822",
    name: "Huyện Vĩnh Bảo",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "2107",
    name: "Huyện đảo Bạch Long Vĩ",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "2108",
    name: "Huyện đảo Cát Hải",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "3203",
    name: "Huyện Kiến Thụy",
    is_higher_price: false,
    province_code: "224",
  },
  {
    code: "1598",
    name: "Thành phố Hải Dương",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "1727",
    name: "Huyện Nam Sách",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "1816",
    name: "Huyện Bình Giang",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "1817",
    name: "Huyện Cẩm Giàng",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "1818",
    name: "Huyện Kinh Môn",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "1934",
    name: "Huyện Gia Lộc",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "1953",
    name: "Huyện Kim Thành",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "2056",
    name: "Thị xã Chí Linh",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "3238",
    name: "Huyện Ninh Giang",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "3287",
    name: "Huyện Tứ Kỳ",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "3292",
    name: "Huyện Thanh Hà",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "3294",
    name: "Huyện Thanh Miện",
    is_higher_price: false,
    province_code: "225",
  },
  {
    code: "1599",
    name: "Thành phố Thái Bình",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "1715",
    name: "Huyện Đông Hưng",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "1716",
    name: "Huyện Vũ Thư",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "1867",
    name: "Huyện Hưng Hà",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "1868",
    name: "Huyện Quỳnh Phụ",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "1869",
    name: "Huyện Thái Thụy",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "1951",
    name: "Huyện Kiến Xương",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "3281",
    name: "Huyện Tiền Hải",
    is_higher_price: false,
    province_code: "226",
  },
  {
    code: "1600",
    name: "Thành phố Hà Giang",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "1893",
    name: "Huyện Bắc Quang",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "1928",
    name: "Huyện Đồng Văn",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "1945",
    name: "Huyện Hoàng Su Phì",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "1973",
    name: "Huyện Mèo Vạc",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "1999",
    name: "Huyện Quản Bạ",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "2001",
    name: "Huyện Quang Bình",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "2052",
    name: "Huyện Xín Mần",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "2053",
    name: "Huyện Yên Minh",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "2075",
    name: "Huyện Bắc Mê",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "2256",
    name: "Huyện Vị Xuyên",
    is_higher_price: false,
    province_code: "227",
  },
  {
    code: "1601",
    name: "Thành phố Tuyên Quang",
    is_higher_price: false,
    province_code: "228",
  },
  {
    code: "1745",
    name: "Huyện Yên Sơn",
    is_higher_price: false,
    province_code: "228",
  },
  {
    code: "1941",
    name: "Huyện Hàm Yên",
    is_higher_price: false,
    province_code: "228",
  },
  {
    code: "1957",
    name: "Huyện Lâm Bình",
    is_higher_price: false,
    province_code: "228",
  },
  {
    code: "1982",
    name: "Huyện Na Hang",
    is_higher_price: false,
    province_code: "228",
  },
  {
    code: "3157",
    name: "Huyện Chiêm Hóa",
    is_higher_price: false,
    province_code: "228",
  },
  {
    code: "3267",
    name: "Huyện Sơn Dương",
    is_higher_price: false,
    province_code: "228",
  },
  {
    code: "1602",
    name: "Thành phố Việt Trì",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "1905",
    name: "Huyện Cẩm Khê",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "1925",
    name: "Huyện Đoan Hùng",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "1938",
    name: "Huyện Hạ Hòa",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "1959",
    name: "Huyện Lâm Thao",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "1994",
    name: "Huyện Phù Ninh",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "2015",
    name: "Huyện Tân Sơn",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "2029",
    name: "Huyện Thanh Sơn",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "2064",
    name: "Thị xã Phú Thọ",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "2237",
    name: "Huyện Thanh Thủy",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "2268",
    name: "Huyện Yên Lập",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "3272",
    name: "Huyện Tam Nông",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "3290",
    name: "Huyện Thanh Ba",
    is_higher_price: false,
    province_code: "229",
  },
  {
    code: "1603",
    name: "Thành phố Móng Cái",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "1604",
    name: "Thành phố Hạ Long",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "1683",
    name: "Thành phố Cẩm Phả",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "1686",
    name: "Thành phố Uông Bí",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "1896",
    name: "Huyện Bình Liêu",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "1920",
    name: "Huyện đảo Vân Đồn",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "1940",
    name: "Huyện Hải Hà",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "2019",
    name: "Huyện Tiên Yên",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "2066",
    name: "Thị xã Quảng Yên",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "2109",
    name: "Huyện đảo Cô Tô",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "3126",
    name: "Huyện Ba Chẽ",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "3180",
    name: "Huyện Đầm Hà",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "3185",
    name: "Huyện Đông Triều",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "3199",
    name: "Huyện Hoành Bồ",
    is_higher_price: false,
    province_code: "230",
  },
  {
    code: "1613",
    name: "Thành phố Nam Định",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "1840",
    name: "Huyện Hải Hậu",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "1841",
    name: "Huyện Ý Yên",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "1981",
    name: "Huyện Mỹ Lộc",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "1983",
    name: "Huyện Nam Trực",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "3193",
    name: "Huyện Giao Thủy",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "3243",
    name: "Huyện Nghĩa Hưng",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "3308",
    name: "Huyện Trực Ninh",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "3319",
    name: "Huyện Vụ Bản",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "3323",
    name: "Huyện Xuân Trường",
    is_higher_price: false,
    province_code: "231",
  },
  {
    code: "1614",
    name: "Thành phố Phủ Lý",
    is_higher_price: false,
    province_code: "232",
  },
  {
    code: "1802",
    name: "Huyện Duy Tiên",
    is_higher_price: false,
    province_code: "232",
  },
  {
    code: "1897",
    name: "Huyện Bình Lục",
    is_higher_price: false,
    province_code: "232",
  },
  {
    code: "1952",
    name: "Huyện Kim Bảng",
    is_higher_price: false,
    province_code: "232",
  },
  {
    code: "1970",
    name: "Huyện Lý Nhân",
    is_higher_price: false,
    province_code: "232",
  },
  {
    code: "2027",
    name: "Huyện Thanh Liêm",
    is_higher_price: false,
    province_code: "232",
  },
  {
    code: "1615",
    name: "Thành phố Ninh Bình",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "1713",
    name: "Thị xã Tam Điệp",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "1714",
    name: "Huyện Yên Khánh",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "1944",
    name: "Huyện Hoa Lư",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "3191",
    name: "Huyện Gia Viễn",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "3205",
    name: "Huyện Kim Sơn",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "3247",
    name: "Huyện Nho Quan",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "3327",
    name: "Huyện Yên Mô",
    is_higher_price: false,
    province_code: "233",
  },
  {
    code: "1616",
    name: "Thành phố Thanh Hóa",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1712",
    name: "Thị xã Sầm Sơn",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1747",
    name: "Huyện Quảng Xương",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1748",
    name: "Huyện Hoằng Hóa",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1870",
    name: "Huyện Tĩnh Gia",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1871",
    name: "Huyện Như Xuân",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1872",
    name: "Huyện Thường Xuân",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1873",
    name: "Huyện Thọ Xuân",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1874",
    name: "Huyện Ngọc Lặc",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1875",
    name: "Huyện Yên Định",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1876",
    name: "Thị xã Bỉm Sơn",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1877",
    name: "Huyện Hà Trung",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1878",
    name: "Huyện Mường Lát",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1879",
    name: "Huyện Quan Hóa",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1880",
    name: "Huyện Thạch Thành",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1881",
    name: "Huyện Vĩnh Lộc",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1927",
    name: "Huyện Đông Sơn",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1942",
    name: "Huyện Hậu Lộc",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "2000",
    name: "Huyện Quan Sơn",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "2070",
    name: "Huyện Bá Thước",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "2181",
    name: "Huyện Nông Cống",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "2190",
    name: "Huyện Như Thanh",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "2249",
    name: "Huyện Triệu Sơn",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "3147",
    name: "Huyện Cẩm Thủy",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "3216",
    name: "Huyện Lang Chánh",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "3241",
    name: "Huyện Nga Sơn",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "3298",
    name: "Huyện Thiệu Hóa",
    is_higher_price: false,
    province_code: "234",
  },
  {
    code: "1617",
    name: "Thành phố Vinh",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1842",
    name: "Thị xã Cửa Lò",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1843",
    name: "Huyện Đô Lương",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1844",
    name: "Huyện Anh Sơn",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1845",
    name: "Huyện Tân Kỳ",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1846",
    name: "Huyện Yên Thành",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1847",
    name: "Huyện Diễn Châu",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1848",
    name: "Huyện Quỳnh Lưu",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1849",
    name: "Thị xã Hoàng Mai",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1850",
    name: "Thị xã Thái Hòa",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1851",
    name: "Huyện Nghĩa Đàn",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1852",
    name: "Huyện Quỳ Hợp",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1853",
    name: "Huyện Con Cuông",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1854",
    name: "Huyện Nghi Lộc",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1947",
    name: "Huyện Hưng Nguyên",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "3211",
    name: "Huyện Kỳ Sơn",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "3233",
    name: "Huyện Nam Đàn",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "3260",
    name: "Huyện Quế Phong",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "3261",
    name: "Huyện Quỳ Châu",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "3288",
    name: "Huyện Tương Dương",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "3291",
    name: "Huyện Thanh Chương",
    is_higher_price: false,
    province_code: "235",
  },
  {
    code: "1618",
    name: "Thành phố Hà Tĩnh",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "1811",
    name: "Huyện Kỳ Anh",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "1812",
    name: "Huyện Hương Khê",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "1813",
    name: "Huyện Nghi Xuân",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "1814",
    name: "Thị xã Hồng Lĩnh",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "1815",
    name: "Huyện Cẩm Xuyên",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "2024",
    name: "Huyện Thạch Hà",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "3143",
    name: "Huyện Can Lộc",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "3188",
    name: "Huyện Đức Thọ",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "3201",
    name: "Huyện Hương Sơn",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "3220",
    name: "Huyện Lộc Hà",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "3320",
    name: "Huyện Vũ Quang",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "3441",
    name: "Thị xã Kỳ Anh",
    is_higher_price: false,
    province_code: "236",
  },
  {
    code: "1619",
    name: "Thành Phố Đồng Hới",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "1857",
    name: "Huyện Lệ Thủy",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "1858",
    name: "Huyện Bố Trạch",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "1859",
    name: "Thị xã Ba Đồn",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "2002",
    name: "Huyện Quảng Ninh",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "3224",
    name: "Huyện Minh Hóa",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "3258",
    name: "Huyện Quảng Trạch",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "3286",
    name: "Huyện Tuyên Hóa",
    is_higher_price: false,
    province_code: "237",
  },
  {
    code: "1620",
    name: "Thành phố Đông Hà",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "1621",
    name: "Thị xã Quảng Trị",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "1860",
    name: "Huyện Hướng Hóa",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "1861",
    name: "Huyện Vĩnh Linh",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "1903",
    name: "Huyện Cam Lộ",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "1936",
    name: "Huyện Gio Linh",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "2040",
    name: "Huyện Triệu Phong",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "2105",
    name: "Huyện Đa Krông",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "2110",
    name: "Huyện đảo Cồn Cỏ",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "2137",
    name: "Huyện Hải Lăng",
    is_higher_price: false,
    province_code: "238",
  },
  {
    code: "1625",
    name: "Thị xã Đồng Xoài",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1722",
    name: "Huyện Đồng Phú",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1772",
    name: "Huyện Chơn Thành",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1773",
    name: "Huyện Hớn Quản",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1774",
    name: "Thị xã Bình Long",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1775",
    name: "Thị xã Phước Long",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1899",
    name: "Huyện Bù Đăng",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1964",
    name: "Huyện Lộc Ninh",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "3140",
    name: "Huyện Bù Đốp",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "3141",
    name: "Huyện Bù Gia Mập",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "3444",
    name: "Huyện Phú Riềng",
    is_higher_price: false,
    province_code: "239",
  },
  {
    code: "1626",
    name: "Thành phố Tây Ninh",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1720",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1721",
    name: "Huyện Hòa Thành",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1862",
    name: "Huyện Tân Biên",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1863",
    name: "Huyện Tân Châu",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1864",
    name: "Huyện Dương Minh Châu",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1865",
    name: "Huyện Bến Cầu",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1866",
    name: "Huyện Gò Dầu",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "2035",
    name: "Huyện Trảng Bàng",
    is_higher_price: false,
    province_code: "240",
  },
  {
    code: "1627",
    name: "Thị xã Gia Nghĩa",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "1790",
    name: "Huyện Đắk R lấp",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "1791",
    name: "Huyện Đắk Glong",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "1792",
    name: "Huyện Đắk Mil",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "2120",
    name: "Huyện Đắk Song",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "2151",
    name: "Huyện Krông Nô",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "2227",
    name: "Huyện Tuy Đức",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "3152",
    name: "Huyện Cư Jút",
    is_higher_price: false,
    province_code: "241",
  },
  {
    code: "1630",
    name: "Thành phố Quảng Ngãi",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "1737",
    name: "Huyện Sơn Tịnh",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "1738",
    name: "Huyện Tư Nghĩa",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "1898",
    name: "Huyện Bình Sơn",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "1930",
    name: "Huyện Đức Phổ",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "1988",
    name: "Huyện Nghĩa Hành",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "2114",
    name: "Huyện đảo Lý Sơn",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "2167",
    name: "Huyện Minh Long",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "2210",
    name: "Huyện Sơn Hà",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "2222",
    name: "Huyện Tây Trà",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "3127",
    name: "Huyện Ba Tơ",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "3226",
    name: "Huyện Mộ Đức",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "3270",
    name: "Huyện Sơn Tây",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "3304",
    name: "Huyện Trà Bồng",
    is_higher_price: false,
    province_code: "242",
  },
  {
    code: "1631",
    name: "Thành phố Tam Kỳ",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "1632",
    name: "Thành phố Hội An",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "1735",
    name: "Huyện Duy Xuyên",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "1736",
    name: "Huyện Điện Bàn",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "1917",
    name: "Huyện Đại Lộc",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "1987",
    name: "Huyện Núi Thành",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "1995",
    name: "Huyện Phú Ninh",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2003",
    name: "Huyện Quế Sơn",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2078",
    name: "Huyện Bắc Trà My",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2125",
    name: "Huyện Đông Giang",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2139",
    name: "Huyện Hiệp Đức",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2177",
    name: "Huyện Nam Giang",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2178",
    name: "Huyện Nam Trà My",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2182",
    name: "Huyện Nông Sơn",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2198",
    name: "Huyện Phước Sơn",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2219",
    name: "Huyện Tây Giang",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2224",
    name: "Huyện Tiên Phước",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "2239",
    name: "Huyện Thăng Bình",
    is_higher_price: false,
    province_code: "243",
  },
  {
    code: "1639",
    name: "Thành phố Thái Nguyên",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "1684",
    name: "Thị xã Sông Công",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "1731",
    name: "Huyện Đồng Hỷ",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "1918",
    name: "Huyện Đại Từ",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "1924",
    name: "Huyện Định Hóa",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "1990",
    name: "Huyện Phổ Yên",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "1991",
    name: "Huyện Phú Bình",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "2051",
    name: "Huyện Võ Nhai",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "2195",
    name: "Huyện Phú Lương",
    is_higher_price: false,
    province_code: "244",
  },
  {
    code: "1640",
    name: "Thành Phố Bắc Kạn",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "1887",
    name: "Huyện Ba Bể",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "1889",
    name: "Huyện Bạch Thông",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "1913",
    name: "Huyện Chợ Đồn",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "1914",
    name: "Huyện Chợ Mới",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "3232",
    name: "Huyện Na Rì",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "3242",
    name: "Huyện Ngân Sơn",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "3249",
    name: "Huyện Pác Nặm",
    is_higher_price: false,
    province_code: "245",
  },
  {
    code: "1641",
    name: "Thành phố Cao Bằng",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "1890",
    name: "Huyện Bảo Lâm",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "1939",
    name: "Huyện Hà Quảng",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "1943",
    name: "Huyện Hòa An",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "1997",
    name: "Huyện Phục Hòa",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "2041",
    name: "Huyện Trùng Khánh",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3130",
    name: "Huyện Bảo Lạc",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3194",
    name: "Huyện Hạ Lang",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3246",
    name: "Huyện Nguyên Bình",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3259",
    name: "Huyện Quảng Uyên",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3289",
    name: "Huyện Thạch An",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3299",
    name: "Huyện Thông Nông",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3305",
    name: "Huyện Trà Lĩnh",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "3694",
    name: "Huyện Quảng Hòa",
    is_higher_price: false,
    province_code: "246",
  },
  {
    code: "1642",
    name: "Thành phố Lạng Sơn",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "1904",
    name: "Huyện Cao Lộc",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "1948",
    name: "Huyện Hữu Lũng",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "1963",
    name: "Huyện Lộc Bình",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "2036",
    name: "Huyện Tràng Định",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "3134",
    name: "Huyện Bắc Sơn",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "3138",
    name: "Huyện Bình Gia",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "3156",
    name: "Huyện Chi Lăng",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "3182",
    name: "Huyện Đình Lập",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "3310",
    name: "Huyện Văn Lãng",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "3311",
    name: "Huyện Văn Quan",
    is_higher_price: false,
    province_code: "247",
  },
  {
    code: "1643",
    name: "Thành phố Bắc Giang",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1759",
    name: "Huyện Hiệp Hòa",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1760",
    name: "Huyện Lạng Giang",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1761",
    name: "Huyện Sơn Động",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1762",
    name: "Huyện Tân Yên",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1763",
    name: "Huyện Việt Yên",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1764",
    name: "Huyện Yên Dũng",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1765",
    name: "Huyện Yên Thế",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1965",
    name: "Huyện Lục Nam",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1966",
    name: "Huyện Lục Ngạn",
    is_higher_price: false,
    province_code: "248",
  },
  {
    code: "1644",
    name: "Thành phố Bắc Ninh",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1728",
    name: "Huyện Quế Võ",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1729",
    name: "Huyện Tiên Du",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1730",
    name: "Thị xã Từ Sơn",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1766",
    name: "Huyện Gia Bình",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1767",
    name: "Huyện Thuận Thành",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1768",
    name: "Huyện Yên Phong",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1969",
    name: "Huyện Lương Tài",
    is_higher_price: false,
    province_code: "249",
  },
  {
    code: "1653",
    name: "Thành phố Vị Thanh",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "1823",
    name: "Thị xã Ngã Bảy",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "1824",
    name: "Huyện Phụng Hiệp",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "1912",
    name: "Huyện Châu Thành A",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "2048",
    name: "Huyện Vị Thuỷ",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "2096",
    name: "Huyện Châu Thành",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "3218",
    name: "Thị xã Long Mỹ",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "3445",
    name: "Huyện Long Mỹ",
    is_higher_price: false,
    province_code: "250",
  },
  {
    code: "1654",
    name: "Thành phố Cà Mau",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "1782",
    name: "Huyện Thới Bình",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "1783",
    name: "Huyện Năm Căn",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "1883",
    name: "Huyện Phú Tân",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "1901",
    name: "Huyện Cái Nước",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "1922",
    name: "Huyện Đầm Dơi",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "2038",
    name: "Huyện Trần Văn Thời",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "2042",
    name: "Huyện U Minh",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "2186",
    name: "Huyện Ngọc Hiển",
    is_higher_price: false,
    province_code: "252",
  },
  {
    code: "1655",
    name: "Thành phố Bạc Liêu",
    is_higher_price: false,
    province_code: "253",
  },
  {
    code: "1723",
    name: "Huyện Hòa Bình",
    is_higher_price: false,
    province_code: "253",
  },
  {
    code: "1926",
    name: "Huyện Đông Hải",
    is_higher_price: false,
    province_code: "253",
  },
  {
    code: "1935",
    name: "Huyện Giá Rai",
    is_higher_price: false,
    province_code: "253",
  },
  {
    code: "1946",
    name: "Huyện Hồng Dân",
    is_higher_price: false,
    province_code: "253",
  },
  {
    code: "1998",
    name: "Huyện Phước Long",
    is_higher_price: false,
    province_code: "253",
  },
  {
    code: "2050",
    name: "Huyện Vĩnh Lợi",
    is_higher_price: false,
    province_code: "253",
  },
  {
    code: "1652",
    name: "Long An",
    is_higher_price: false,
    province_code: "257",
  },
  {
    code: "1666",
    name: "Thành phố Phan Thiết",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "1776",
    name: "Huyện Hàm Thuận Nam",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "1777",
    name: "Huyện Hàm Thuận Bắc",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "1778",
    name: "Thị xã La Gi",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "1779",
    name: "Huyện Đức Linh",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "1780",
    name: "Huyện Bắc Bình",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "1781",
    name: "Huyện Tuy Phong",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "2012",
    name: "Huyện Tánh Linh",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "2116",
    name: "Huyện đảo Phú Quý",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "3196",
    name: "Huyện Hàm Tân",
    is_higher_price: false,
    province_code: "258",
  },
  {
    code: "1660",
    name: "Thành phố Kon Tum",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "1834",
    name: "Huyện Kon Plông",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "1835",
    name: "Huyện Đắk Hà",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "1921",
    name: "Huyện Đắk Glei",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "2121",
    name: "Huyện Đắk Tô",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "2148",
    name: "Huyện Kon Rẫy",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "2187",
    name: "Huyện Ngọc Hồi",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "2205",
    name: "Huyện Sa Thầy",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "2225",
    name: "Huyện Tu Mơ Rông",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "3446",
    name: "Huyện Ia H Drai",
    is_higher_price: false,
    province_code: "259",
  },
  {
    code: "1663",
    name: "Thành phố Tuy Hòa",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "1856",
    name: "Thị xã Sông Cầu",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "1993",
    name: "Huyện Phú Hòa",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "2206",
    name: "Huyện Sông Hinh",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "2211",
    name: "Huyện Sơn Hòa",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "3184",
    name: "Huyện Đông Hòa",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "3186",
    name: "Huyện Đồng Xuân",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "3278",
    name: "Huyện Tây Hòa",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "3284",
    name: "Huyện Tuy An",
    is_higher_price: false,
    province_code: "260",
  },
  {
    code: "1665",
    name: "Thành phố Phan Rang – Tháp Chàm",
    is_higher_price: false,
    province_code: "261",
  },
  {
    code: "1855",
    name: "Huyện Ninh Sơn",
    is_higher_price: false,
    province_code: "261",
  },
  {
    code: "1985",
    name: "Huyện Ninh Hải",
    is_higher_price: false,
    province_code: "261",
  },
  {
    code: "1986",
    name: "Huyện Ninh Phước",
    is_higher_price: false,
    province_code: "261",
  },
  {
    code: "3129",
    name: "Huyện Bác Ái",
    is_higher_price: false,
    province_code: "261",
  },
  {
    code: "3301",
    name: "Huyện Thuận Bắc",
    is_higher_price: false,
    province_code: "261",
  },
  {
    code: "3302",
    name: "Huyện Thuận Nam",
    is_higher_price: false,
    province_code: "261",
  },
  {
    code: "1662",
    name: "Thành phố Quy Nhơn",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "1769",
    name: "Thị xã An Nhơn",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "1770",
    name: "Huyện Phù Cát",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "1771",
    name: "Huyện Hoài Nhơn",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "1886",
    name: "Huyện An Lão",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "2023",
    name: "Huyện Tuy Phước",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "2140",
    name: "Huyện Hoài Ân",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "2258",
    name: "Huyện Vĩnh Thạnh",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "3254",
    name: "Huyện Phù Mỹ",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "3279",
    name: "Huyện Tây Sơn",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "3312",
    name: "Huyện Vân Canh",
    is_higher_price: false,
    province_code: "262",
  },
  {
    code: "1674",
    name: "Thành phố Yên Bái",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "1967",
    name: "Huyện Lục Yên",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "1977",
    name: "Huyện Mù Cang Chải",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "2039",
    name: "Huyện Trấn Yên",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "2044",
    name: "Huyện Văn Chấn",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "2047",
    name: "Huyện Văn Yên",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "2063",
    name: "Thị xã Nghĩa Lộ",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "2248",
    name: "Huyện Trạm Tấu",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "2266",
    name: "Huyện Yên Bình",
    is_higher_price: false,
    province_code: "263",
  },
  {
    code: "1675",
    name: "Thành phố Lai Châu",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "1980",
    name: "Huyện Mường Tè",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "1984",
    name: "Huyện Nậm Nhùm",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "1989",
    name: "Huyện Phong Thổ",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "2006",
    name: "Huyện Sìn Hồ",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "2010",
    name: "Huyện Tam Đường",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "2017",
    name: "Huyện Tân Uyên",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "2025",
    name: "Huyện Than Uyên",
    is_higher_price: false,
    province_code: "264",
  },
  {
    code: "1676",
    name: "Thành phố Điện Biên Phủ",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "1923",
    name: "Huyện Điện Biên",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "1978",
    name: "Huyện Mường Chà",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "1979",
    name: "Huyện Mường Nhé",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "2021",
    name: "Huyện Tủa Chùa",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "2022",
    name: "Huyện Tuần Giáo",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "2060",
    name: "Thị Xã Mường Lay",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "2123",
    name: "Huyện Điện Biên Đông",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "2170",
    name: "Huyện Mường Ảng",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "2179",
    name: "Huyện Nậm Pồ",
    is_higher_price: false,
    province_code: "265",
  },
  {
    code: "1677",
    name: "Thành phố Sơn La",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "1971",
    name: "Huyện Mai Sơn",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "1976",
    name: "Huyện Mộc Châu",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "1996",
    name: "Huyện Phù Yên",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "2007",
    name: "Huyện Sông Mã",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "2032",
    name: "Huyện Thuận Châu",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "2079",
    name: "Huyện Bắc Yên",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "2204",
    name: "Huyện Quỳnh Nhai",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "2255",
    name: "Huyện Vân Hồ",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "2267",
    name: "Huyện Yên Châu",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "3230",
    name: "Huyện Mường La",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "3266",
    name: "Huyện Sốp Cộp",
    is_higher_price: false,
    province_code: "266",
  },
  {
    code: "1678",
    name: "Thành phố Hòa Bình",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "1916",
    name: "Huyện Đà Bắc",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "1955",
    name: "Huyện Kỳ Sơn",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "1968",
    name: "Huyện Lương Sơn",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "2014",
    name: "Huyện Tân Lạc",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "2087",
    name: "Huyện Cao Phong",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "2146",
    name: "Huyện Kim Bôi",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "2156",
    name: "Huyện Lạc Sơn",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "2157",
    name: "Huyện Lạc Thủy",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "2163",
    name: "Huyện Mai Châu",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "2270",
    name: "Huyện Yên Thủy",
    is_higher_price: false,
    province_code: "267",
  },
  {
    code: "1680",
    name: "Thành phố Hưng Yên",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "1717",
    name: "Huyện Kim Động",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "1825",
    name: "Huyện Ân Thi",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "1826",
    name: "Huyện Khoái Châu",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "1827",
    name: "Huyện Mỹ Hào",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "1828",
    name: "Huyện Yên Mỹ",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "2018",
    name: "Huyện Tiên Lữ",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "2045",
    name: "Huyện Văn Giang",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "2046",
    name: "Huyện Văn Lâm",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "2194",
    name: "Huyện Phù Cừ",
    is_higher_price: false,
    province_code: "268",
  },
  {
    code: "1682",
    name: "Thành phố Lào Cai",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "1744",
    name: "Huyện Bát Xát",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "1891",
    name: "Huyện Bảo Yên",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "1892",
    name: "Huyện Bắc Hà",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "2005",
    name: "Huyện Sa Pa",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "2043",
    name: "Huyện Văn Bàn",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "2073",
    name: "Huyện Bảo Thắng",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "2171",
    name: "Huyện Mường Khương",
    is_higher_price: false,
    province_code: "269",
  },
  {
    code: "2264",
    name: "Huyện Xi Ma Cai",
    is_higher_price: false,
    province_code: "269",
  },
];

export default DISTRICTS;
