import axiosDonation from "./axiosDonation";

const donationApi = {
  getDonations: (data: any) => {
    const url = "/serene/user-donation/search-user-donation";
    return axiosDonation.post(url, data);
  },

  searchStatisticalData: (data:any) => {
    const url = "/serene/user-donation/search-statistical-data";
    return axiosDonation.post(url, data)
  },

  exportFile: (data:any) => {
    const url = "/serene/user-donation/export";
    return axiosDonation.post(url, data, {responseType:'blob'})
  },

  postDonationShipment: (data: any) => {
    const url = "/serene/user-donation/process-user-donation";
    return axiosDonation.post(url, data);
  },

  updateNoteDonation: (data:any) => {
    const url = "/serene/user-donation/update-user-donation";
    return axiosDonation.post(url, data);
  },

  confirmDonationShipment: (data: any) => {
    const url = "/serene/user-donation/confirm-user-donation";
    return axiosDonation.post(url, data);
  },

  cancelDonation: (data: any) => {
    const url = "/serene/user-donation/cancel-user-donation";
    return axiosDonation.post(url, data);
  },

  doneDonation: (data: any) => {
    const url = "/serene/user-donation/finish-user-donation";
    return axiosDonation.post(url, data);
  },

  addBag: (data: any) => {
    const url = "/serene/user-donation/add-user-donation-bag";
    return axiosDonation.post(url, data);
  },

  updatePaymentStatus: (data:any) => {
    const url = "/serene/user-donation/update-payment-status";
    return axiosDonation.post(url, data)
  },

  getDeliveryLocation: (data:any) => {
    const url = "/serene/delivery-gateway/get-delivery-location";
    return axiosDonation.post(url, data)
  },

  updateDeliveryInfo: (data:any) => {
    const url = "/serene/delivery-gateway/update-info";
    return axiosDonation.post(url, data)
  },

  createDelivery: (data:any) => {
    const url = "/serene/delivery-gateway/create";
    return axiosDonation.post(url, data)
  },

  estimateFee: (data: any) => {
    const url = "/serene/delivery-gateway/estimate-fee";
    return axiosDonation.post(url,data);
  },

  cancelDelivery: (data:any) => {
    const url = "/serene/delivery-gateway/cancel";
    return axiosDonation.post(url,data);
  },

  getLogs: (data:any) => {
    const url = "/serene/delivery-gateway/get-logs";
    return axiosDonation.post(url,data);
  },

  getInfoDelivery: (data:any) => {
    const url = "/serene/delivery-gateway/get-info";
    return axiosDonation.post(url,data);
  },
  
  countOrder: (data:any) => {
    const url = "/serene/user-donation/count-user-donation";
    return axiosDonation.post(url,data);
  },

  searchZeroWaste: (data:any) => {
    const url = "/serene/zero-waste-fee-config/search";
    return axiosDonation.post(url,data);
  },

  createZeroWaste: (data:any) => {
    const url = "/serene/zero-waste-fee-config/create";
    return axiosDonation.post(url,data);
  },

  updateZeroWaste: (data:any) => {
    const url = "/serene/zero-waste-fee-config/update";
    return axiosDonation.post(url,data);
  },

  deleteZeroWaste: (data:any) => {
    const url = "/serene/zero-waste-fee-config/delete";
    return axiosDonation.post(url,data);
  }

};

export default donationApi;
